<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Multiselect from '@/components/Multiselect/main.vue';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Footer from '@/components/Footer/main.vue';
import TableListComponent from '@/components/TableList/main.vue';

import { masterMethods, prizeMethods, clientMethods } from '@/state/helpers';
import { checkPermission, checkPermissionName } from '@/utils/handlers';
import { InputCheckBox } from '@/components/Input';

export default {
    page: {
        title: '賞品管理一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Layout,
        Multiselect,
        Footer,
        InputCheckBox,
        TableListComponent
    },
    data() {
        return {
            listData: [],
            listDataCampaign: [],
            listTagFreeWord: [],
            listTagKeyWord: [],
            form: {
                name: '',
                remaining_quantity: ''
            },
            has: {
                campaign: ''
            },
            filter: {
                type: '',
                quantity: '',
                other_than_zero: ''
            },
            date_from: {
                quantity: ''
            },
            configKeyword: {
                arrayTag: [],
                placeholder: 'キャンペーン名　賞品名 ...',
                isShowLable: true
            },
            configTable: {
                headers: [
                    {
                        label: 'キャンペーン名'
                    },
                    {
                        label: '賞品タイトル'
                    },
                    {
                        label: '賞品名'
                    },
                    {
                        label: '数量'
                    },
                    {
                        label: '賞品必要数'
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            },

            campaign: null,
            checkShowFilter: false,
            isLoading: false
        };
    },
    watch: {
        campaign: function (newName) {
            this.has.campaign = newName?.id ?? '';
        },

        'configKeyword.arrayTag': function (newOld) {
            if (!this.checkShowFilter) {
                this.listTagFreeWord = newOld;
            } else {
                this.listTagKeyWord = newOld;
            }
        }
    },
    mounted() {
        this.getListCampaign();
        this.getList();
    },

    methods: {
        checkPermission,
        checkPermissionName,
        ...masterMethods,
        ...prizeMethods,
        ...clientMethods,

        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.arrayTag = this.listTagKeyWord;
            } else {
                this.configKeyword.placeholder = 'キャンペーン名　賞品名 ...';
                this.configKeyword.arrayTag = this.listTagFreeWord;
            }
        },

        getListCampaign() {
            this.listMaster('all/campaigns').then((data) => {
                this.listDataCampaign = data;
            });
        },

        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];
            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${value}`);
                    }
                }

                if (this.filter) {
                    for (const [key, value] of Object.entries(this.filter)) {
                        if (key && value) query.push(`filters[${key}]=${value}`);
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${value}`);
                    }
                }
                if (this.date_from) {
                    let date_from = Object.assign({}, this.date_from);
                    for (const [key, value] of Object.entries(date_from)) {
                        if (key && value) {
                            if (key == 'remaining_quantity') {
                                query.push(`from[${key}]=1`);
                            } else {
                                query.push(`from[${key}]=${value}`);
                            }
                        }
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }
            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listPrize(query).then((data) => {
                this.listData = data.data;
                this.configPage.total = data._metadata.total;
                this.configPage.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            });
        },

        search() {
            this.getList();
        },
        clear() {
            this.configKeyword = {
                arrayTag: [],
                placeholder: 'キャンペーン名名　賞品名 ...',
                isShowLable: true
            };

            this.form = {
                name: '',
                remaining_quantity: ''
            };
            this.filter = {
                type: '',
                remaining_quantity: '',
                other_than_zero: ''
            };
            this.campaign = null;
            this.getList();
        },
        goToViewCampaign(path, id) {
            this.$router.push({ path: path, query: { id: id } });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">賞品検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>

                                    <div v-if="checkShowFilter">
                                        <div class="form-group row" v-if="!checkPermissionName(['partner'])">
                                            <label class="col-sm-3" for="validationCustom05">賞品名</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン名</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-campaign-default`"
                                                    :value.sync="campaign"
                                                    :options="listDataCampaign"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'name',
                                                        error: false,
                                                        isRemove: false
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">数量</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        id="validationCustom05"
                                                        v-model="filter.quantity"
                                                        type="text"
                                                        style="width: 180px"
                                                        class="form-control"
                                                    />
                                                    <div>
                                                        <InputCheckBox
                                                            class="ml-4"
                                                            :model.sync="date_from.quantity"
                                                            :id="`checkd`"
                                                            :value="1"
                                                            :label="`0以外`"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>

                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0">
                            賞品検索結果 <span>件数（{{ configPage.total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableListComponent
                            :config="configTable"
                            :dataList="listData"
                            :isLoading="isLoading"
                            :configPage.sync="configPage"
                            @onChangePage="getList()"
                        >
                            <template #row="{ row }">
                                <tr @dblclick="goToEdit('/prize/form', row)">
                                    <td class="text-left">
                                        <div class="row-data-medium" v-b-tooltip.hover :title="row.campaign_event_prize?.campaign?.name ?? ''">
                                            <a
                                                href="javascript:void(0)"
                                                @click="goToViewCampaign('/campaign/form/view', row.campaign_event_prize?.campaign?.id ?? '')"
                                            >
                                                {{ row.campaign_event_prize?.campaign?.name ?? '' }}</a
                                            >
                                        </div>
                                    </td>

                                    <td>
                                        <div class="row-data-small" v-b-tooltip.hover :title="row.campaign_event_prize?.title ?? ''">
                                            {{ row?.campaign_event_prize?.title ?? '' }}
                                        </div>
                                    </td>

                                    <td>
                                        <div class="row-data-small" v-b-tooltip.hover :title="row?.name ?? ''">
                                            {{ row?.name ?? '' }}
                                        </div>
                                    </td>

                                    <td>
                                        {{ row.quantity }}
                                    </td>
                                    <td>{{ row.quantity_for_event_prize }}</td>

                                    <td align="center">
                                        <a href="javascript:void(0)" class="text-info border-right-1" @click="goToEdit('/prize/form/view', row)"
                                            >詳細</a
                                        >
                                        <a
                                            v-if="checkPermission('prize.register')"
                                            href="javascript:void(0)"
                                            class="text-success"
                                            @click="goToEdit('/prize/form', row)"
                                            >編集</a
                                        >
                                    </td>
                                </tr>
                            </template>
                        </TableListComponent>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
