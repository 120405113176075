<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.nameCampaign') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.campaign?.name ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"></label>
                    <div class="col-sm-9">
                        <UploadFileCommon @dataFile="handleFileBackgroundEditor" title="ファイルアップロード" :id="`bgrEditorImg`" />
                        <div v-if="dataForm.first_view.title_image?.origin_name" class="d-flex align-items-center my-2">
                            <a
                                target="_blank"
                                :href="dataForm.first_view.title_image?.path"
                                @click.prevent="downLoadFile(dataForm.first_view.title_image?.path, dataForm.first_view.title_image?.origin_name)"
                                >{{ dataForm.first_view.title_image?.origin_name }}</a
                            >
                            <span class="ml-2" style="cursor: pointer; font-size: 20px" @click="handleDeleteTitleImage"
                                ><i class="ri-close-line" style="vertical-align: bottom"></i
                            ></span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.detailFirstView') }} </label>
                    <div class="col-sm-9">
                        <Editor
                            :id="`editorFirstView`"
                            :isBackgroundImageEditor="true"
                            :model.sync="dataForm.first_view.detail"
                            :modelEvents="`change keydown blur focus paste`"
                            :configInit="configEditor"
                            @init="onEditorInit"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { validateField } from '@/utils/validate';
import { handleDownFile, IMAGE_EXTENSIONS } from '@/utils/format';
import { editorMethods } from '@/state/helpers';
import { uploadImageMethods } from '@/state/helpers';

import Editor from '@/components/Editor/main.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';

export default {
    name: 'SettingFirstViewAndTimeApplication',
    components: {
        Editor,
        UploadFileCommon
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            arrExtension: IMAGE_EXTENSIONS,
            objEditor: null,
            configEditor: {
                content_style: 'p { margin: 0 }',
                height: 600,
                menubar: true,
                plugins: [
                    'hr advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media template',
                    'paste code help wordcount table code lists imagetools '
                ],
                toolbar:
                    'myCustomToolbarButton hr undo redo | formatselect | bold italic | \
                                          alignleft aligncenter alignright | \
                                          link image media | \
                                          bullist numlist outdent indent | help code\
                                          table tableinsertdialog tablecellprops tableprops | fullscreen ',
                table_toolbar:
                    'tableprops tabledelete tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tablerowprops tabledeleterow | tableinsertcolbefore tableinsertcolafter tablecellprops tabledeletecol',
                images_upload_handler: this.acUploadImage
            }
        };
    },
    computed: {
        isPreviewImage() {
            return this.dataForm?.first_view?.title_image;
        }
    },
    methods: {
        ...editorMethods,
        ...uploadImageMethods,

        handleValidateField(configField) {
            validateField(configField);
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleFileBackgroundEditor(file) {
            this.dataForm.first_view.title_image = file;
            this.registerDataEditor({ backgroundImage: 'https://img.freepik.com/free-photo/view-3d-animated-cartoon-bird_23-2150946399.jpg' });
        },

        handleDeleteTitleImage() {
            this.dataForm.first_view.title_image = null;
            this.registerDataEditor({ backgroundImage: '' });
            // this.handleFileBackgroundEditor(this.dataForm.first_view.title_image);
        }
    }
};
</script>

<style lang="scss" scoped></style>
