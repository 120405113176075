<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
// import Multiselect from '@/components/Multiselect/main.vue';
import PopUpInfomation from '@/components/Campaign/TabPrize/components/Infomation/pop-up-infomation.vue';
import PopUpCompatible from '@/components/Campaign/TabPrize/components/NotVeryCompatible/pop-up-compatible.vue';
import PickerDate from '@/components/DateCustoms/PickerDate/main.vue';

import { authComputed, masterComputed, masterMethods, prizeMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { maxLength } from 'vuelidate/lib/validators';
import { showMessage } from '@/utils/messages';
import { validateAllField, validateField } from '@/utils/validate';
import { regexNumber } from '@/utils/regex';
import { InputText, InputRadio } from '@/components/Input';
import { handleDataDetail, initFormPrize, handleMapDataForm, PACKAGING_PHOTO } from './index';
import { CONFIG_INFOMATION, CONFIG_COMPATIBLE } from '@/components/Campaign/TabPrize/constans';

export default {
    page: {
        title: '賞品新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        // Multiselect,
        Layout,
        Footer,
        DateNormal,
        InputText,
        PopUpInfomation,
        PopUpCompatible,
        PickerDate,
        InputRadio
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            noteGift: '',
            digitalObject: {},
            form: initFormPrize(),
            listDataMasterGiftCategory: [],
            listDataMasterGiftDigital: [],
            submitted: false,
            configPopInfomation: CONFIG_INFOMATION,
            configPopCompatible: CONFIG_COMPATIBLE,
            packagingPhoto: PACKAGING_PHOTO
        };
    },
    computed: {
        ...masterComputed,
        ...authComputed
    },
    validations: {
        noteGift: { maxLength: maxLength(20) }
    },
    watch: {
        'form.number_of_applications.postcard_mail': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.web': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.palnavi': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.sns': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.twitter': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.instagram': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.line': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.others': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.invalid': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_inquiries.tel': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.email': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.number_of_cases': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.irregular': function () {
            this.updateTotalNumberOfInquiries();
        }
    },
    beforeRouteUpdate(to, from, next) {
        // Xác định nếu có tham số id trong đường dẫn mới
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
        }
        // Gọi next để tiếp tục chuyển route
        next();
    },
    mounted() {
        this.getListMasterType();
        this.getListMasterPrizeGiftDigital();

        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...prizeMethods,
        resetForm() {
            this.form = initFormPrize();
        },

        updateTotalNumberOfApplication() {
            const postcardMail = parseInt(this.form.number_of_applications.postcard_mail) || 0;
            const web = parseInt(this.form.number_of_applications.web) || 0;
            const palnavi = parseInt(this.form.number_of_applications.palnavi) || 0;
            const sns = parseInt(this.form.number_of_applications.sns) || 0;
            const twitter = parseInt(this.form.number_of_applications.twitter) || 0;
            const instagram = parseInt(this.form.number_of_applications.instagram) || 0;
            const line = parseInt(this.form.number_of_applications.line) || 0;
            const others = parseInt(this.form.number_of_applications.others) || 0;
            const invalid = parseInt(this.form.number_of_applications.invalid) || 0;
            const total = postcardMail + web + palnavi + sns + twitter + instagram + line + others + invalid;
            this.form.number_of_applications.total = total.toString();
        },
        updateTotalNumberOfInquiries() {
            const tel = parseInt(this.form.number_of_inquiries.tel) || 0;
            const email = parseInt(this.form.number_of_inquiries.email) || 0;
            const number_of_cases = parseInt(this.form.number_of_inquiries.number_of_cases) || 0;
            const irregular = parseInt(this.form.number_of_inquiries.irregular) || 0;

            const total = tel + email + number_of_cases + irregular;
            this.form.number_of_inquiries.total = total.toString();
        },
        getDetail(id) {
            this.loading = true;
            this.detailPrize(id).then((data) => {
                const resultDetailPrizes = handleDataDetail(data, this.form, this.digitalObject, this.noteGift);
                this.form = resultDetailPrizes.form;
                this.digitalObject = resultDetailPrizes.digitalObject;
                this.noteGift = resultDetailPrizes.noteGift;
                this.loading = false;
            });
        },
        getListMasterType() {
            this.listMaster('campaign.gift_category').then((data) => {
                this.listDataMasterGiftCategory = data;
            });
        },
        getListMasterPrizeGiftDigital() {
            this.listMaster('campaign.prize.digital_gift').then((data) => {
                this.listDataMasterGiftDigital = data;
            });
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            this.$v.$touch();
            let check = validateAllField(this.form);
            // this.$v.form.$invalid || this.$v.noteGift.$invalid ||
            if (!check) {
                return;
            } else {
                this.register();
            }
        },
        register() {
            if (this.loading) return;
            if (this.form.name) {
                this.loading = true;
                const mapDataSendServe = handleMapDataForm(this.form, this.digitalObject, this.noteGift);

                if (this.$route.query.id) {
                    this.updatePrize(mapDataSendServe).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '賞品が更新されました。');
                            this.getDetail(this.$route.query.id);
                        }
                        if (data.code == 422) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    });
                } else {
                    this.registerPrize(mapDataSendServe).then((data) => {
                        if (data.code == 200) {
                            this.$router
                                .push({
                                    path: '/prize/form/view',
                                    query: {
                                        id: data.data.id
                                    }
                                })
                                .then(() => {
                                    showMessage(data.code, this.$bvToast, '賞品が登録されました。');
                                });
                        }
                        if (data.code == 422) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    });
                }
            }
        },

        preventInputE(event) {
            // Ngăn chặn ký tự "e" từ được nhập vào ô input
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
        },

        inputNumber(key, key1) {
            if (key1) this.form[key][key1] = regexNumber(this.form[key][key1]);
            else this.form[key] = regexNumber(this.form[key]);
        },
        handleShowPopupInfo() {
            this.configPopInfomation.isShow = true;
        },
        handleShowPopupCompatible() {
            this.configPopCompatible.isShow = true;
        },

        handleSelectTypeObject() {
            this.form.coupon_value = '';
        },

        handleMatchEstimatedRemainingQuantity(form) {
            // 残予定数 = 入庫数 - 賞品必要数
            if (form.warehousing_quantity && form.quantity_for_event_prize) {
                form.estimated_remaining_quantity = form.warehousing_quantity - form.quantity_for_event_prize;
            } else {
                form.estimated_remaining_quantity = 0;
            }
            // 実あまり数 = 入庫数 - 発送件数
            if (form.warehousing_quantity && form.delivery_quantity) {
                form.remaining_quantity = form.warehousing_quantity - form.delivery_quantity;
            } else {
                form.remaining_quantity = 0;
            }
        },

        handleMatchQuantityForEventPrize(form) {
            // 賞品必要数 = 数量 * 当選人数
            if (form.number_of_win_prize && form.quantity) {
                form.quantity_for_event_prize = form.quantity * form.number_of_win_prize;
            } else {
                form.quantity_for_event_prize = 0;
            }
            this.handleMatchEstimatedRemainingQuantity(form);
        },

        handleValidateField(configField) {
            validateField(configField);
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-header">
                        <h5 class="title--screen">{{ $route.query.id ? '賞品関連情報' : '賞品新規登録' }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-8">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">キャンペーン名 <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <div>
                                                <InputText
                                                    :id="`キャンペーン名`"
                                                    :model.sync="form.campaign"
                                                    :config.sync="form.configFieldCampaign"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品タイトル</label>
                                        <div class="col-sm-8">
                                            <InputText :id="`賞品タイトル`" :model.sync="form.title" :config.sync="form.configFieldTitle" disabled />
                                        </div>
                                    </div>

                                    <!-- <div>
                                        <div class="form-group row">
                                            <label class="col-sm-4" for="validationCustom05">種類</label>
                                            <div class="col-sm-8">
                                                <div>
                                                    <Multiselect
                                                        :id="`select-type-object`"
                                                        :value.sync="form.type"
                                                        :options="listDataMasterGiftCategory"
                                                        :config="form.configFieldSelectType"
                                                        @select="handleSelectTypeObject"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="form-group row"
                                            v-if="
                                                form.type?.value == '通常の金券' ||
                                                form.type?.value == '電子版の金券' ||
                                                form.type?.value == '旅行・宿泊・ﾚｼﾞｬｰへの招待券' ||
                                                form.type?.value == 'ファッション・高級ブランド・貴金属品'
                                            "
                                        >
                                            <label class="col-sm-4" for="validationCustom05">金額入力</label>
                                            <div class="col-sm-8">
                                                <div>
                                                    <InputText
                                                        :id="`coupon_value`"
                                                        :model.sync="form.coupon_value"
                                                        :config.sync="form.configFieldCouponValue"
                                                        @blur="handleValidateField(form.configFieldCouponValue)"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row" v-if="form.type?.value == 'デジタルギフト'">
                                            <label class="col-sm-4" for="validationCustom05">デジタルギフト ★ </label>
                                            <div class="col-sm-8">
                                                <div>
                                                    <Multiselect
                                                        :id="`select-digital-object`"
                                                        :value.sync="digitalObject"
                                                        :options="listDataMasterGiftDigital"
                                                        :config.sync="form.configFieldSelectDigitalGifts"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row" v-if="digitalObject.id">
                                            <label class="col-sm-4" for="validationCustom05">{{ digitalObject.value }}★ </label>
                                            <div class="col-sm-8">
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="noteGift"
                                                        :class="{
                                                            'is-invalid': submitted && $v.noteGift.$error
                                                        }"
                                                    />
                                                    <span style="width: 50px; margin-left: 9px">円分</span>
                                                </div>

                                                <span
                                                    :class="{
                                                        'is-invalid': submitted && $v.noteGift.$error
                                                    }"
                                                ></span>
                                                <div v-if="submitted && $v.noteGift.$error" class="invalid-feedback">
                                                    <span v-if="!$v.noteGift.maxLength">{{
                                                        $t('validateField.max20', {
                                                            field: digitalObject.value
                                                        })
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">当選人数</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`当選人数`"
                                                :model.sync="form.number_of_win_prize"
                                                :config="{
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">抽選人数</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`賞品タイトル`"
                                                :model.sync="form.number_of_people_drawing_lots"
                                                :config="{
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品名</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`賞品名`"
                                                :model.sync="form.name"
                                                :config.sync="form.configFieldName"
                                                @blur="handleValidateField(form.configFieldName)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">数量</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`quantity`"
                                                :model.sync="form.quantity"
                                                :config.sync="form.configFieldQuantity"
                                                @input="handleMatchQuantityForEventPrize(form)"
                                                @blur="handleValidateField(form.configFieldQuantity)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品必要数</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`quantity_for_event_prize`"
                                                :model.sync="form.quantity_for_event_prize"
                                                :config.sync="form.configFieldQuantityForEventPrize"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫予定日</label>
                                        <div class="col-sm-8">
                                            <DateNormal
                                                :year.sync="form.scheduled_arrived_at_y"
                                                :month.sync="form.scheduled_arrived_at_m"
                                                :day.sync="form.scheduled_arrived_at_d"
                                                :full_date.sync="form.scheduled_arrived_at"
                                                :config.sync="form.configFieldScheduledArrivedAt"
                                            >
                                                <div v-if="form.configFieldScheduledArrivedAt.error" class="invalid-feedback d-block">
                                                    <span>{{ form.configFieldScheduledArrivedAt.errorText }}</span>
                                                </div>
                                            </DateNormal>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫日</label>
                                        <div class="col-sm-8">
                                            <DateNormal
                                                :year.sync="form.warehousing_date_y"
                                                :month.sync="form.warehousing_date_m"
                                                :day.sync="form.warehousing_date_d"
                                                :full_date.sync="form.warehousing_date"
                                                :config.sync="form.configFieldWarehousingDate"
                                            >
                                                <div v-if="form.configFieldWarehousingDate.error" class="invalid-feedback d-block">
                                                    <span>{{ form.configFieldWarehousingDate.errorText }}</span>
                                                </div>
                                            </DateNormal>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫数</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`warehousing_quantity`"
                                                :model.sync="form.warehousing_quantity"
                                                :config.sync="form.configFieldWarehousingQuantity"
                                                @input="handleMatchEstimatedRemainingQuantity(form)"
                                                @blur="handleValidateField(form.configFieldWarehousingQuantity)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">残予定数</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`estimated_remaining_quantity`"
                                                :model.sync="form.estimated_remaining_quantity"
                                                :config="{
                                                    isNumber: true,
                                                    maxlength: 10,
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">詳細情報</label>
                                        <div class="col-sm-4">
                                            <button class="btn btn-sm btn-info" type="button" @click="handleShowPopupInfo()">詳細情報</button>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送・進呈予定日</label>
                                        <div class="col-sm-4">
                                            <PickerDate :id="`deliveryDateSchedule`" :model.sync="form.delivery_date_schedule" disabled />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送日・進呈日</label>
                                        <div class="col-sm-4">
                                            <PickerDate :id="`deliveryDate`" :model.sync="form.delivery_date" disabled />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送件数</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`delivery_quantity`"
                                                :model.sync="form.delivery_quantity"
                                                :config="{
                                                    isNumber: true,
                                                    maxlength: 10,
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">実あまり数</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`remaining_quantity`"
                                                :model.sync="form.remaining_quantity"
                                                :config="{
                                                    isNumber: true,
                                                    maxlength: 10,
                                                    error: false
                                                }"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">あまり対応</label>
                                        <div class="col-sm-4">
                                            <button class="btn btn-sm btn-info" type="button" @click="handleShowPopupCompatible()">あまり対応</button>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">梱包写真必要有無</label>
                                        <div class="col-sm-4">
                                            <div class="d-flex">
                                                <div v-for="item in packagingPhoto" :key="item.id + 'packaging'">
                                                    <InputRadio
                                                        class="mr-4"
                                                        :model.sync="form.is_packaging_photo_required"
                                                        :name="`is_packaging_photo_required`"
                                                        :id="`is_packaging_photo_required-${item.id}`"
                                                        :value="item.id"
                                                        :label="item.label"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送日</label>
                                        <div class="col-sm-8">
                                            <DateNormal
                                                :year.sync="form.shipping_date_y"
                                                :month.sync="form.shipping_date_m"
                                                :day.sync="form.shipping_date_d"
                                                :full_date.sync="form.shipping_date"
                                                :config.sync="form.configFieldShippingDate"
                                            >
                                                <div v-if="form.configFieldShippingDate.error" class="invalid-feedback d-block">
                                                    <span>{{ form.configFieldShippingDate.errorText }}</span>
                                                </div>
                                            </DateNormal>
                                        </div>
                                    </div> -->

                                    <!-- <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送数</label>
                                        <div class="col-sm-4">
                                            <InputText
                                                :id="`shipment_quantity`"
                                                :model.sync="form.shipment_quantity"
                                                :config.sync="form.configFieldShipmentQuantity"
                                                @blur="handleValidateField(form.configFieldShipmentQuantity)"
                                            />
                                        </div>
                                    </div> -->

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">{{ $t('pageCampaign.tabPrize.memo') }}</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`memo`"
                                                :model.sync="form.memo"
                                                :config="form.configFieldMemo"
                                                @blur="handleValidateField(form.configFieldMemo)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">連絡事項</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`notification`"
                                                :model.sync="form.notification"
                                                :config.sync="form.configFieldRNotification"
                                                @blur="handleValidateField(form.configFieldRNotification)"
                                            />
                                        </div>
                                    </div>

                                    <!-- <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">再確認日程</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`reconfirmation_schedule`"
                                                :model.sync="form.reconfirmation_schedule"
                                                :config.sync="form.configFieldReconfirmationSchedule"
                                            />
                                        </div>
                                    </div> -->
                                    <!-- <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品あまり対応指示項目</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`instructions_regarding_prizes`"
                                                :model.sync="form.instructions_regarding_prizes"
                                                :config.sync="form.configFieldInstructionsRegardingPrizes"
                                            />
                                        </div>
                                    </div> -->

                                    <!-- <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">保管料がかかり始める月</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`month_start_fee_apply`"
                                                :model.sync="form.month_start_fee_apply"
                                                :config.sync="form.configFieldMothStartFeeApply"
                                            />
                                        </div>
                                    </div> -->
                                    <!-- <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">保管料</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`storage_material`"
                                                :model.sync="form.storage_material"
                                                :config.sync="form.configFieldStorageMaterial"
                                            />
                                        </div>
                                    </div> -->
                                    <!-- <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">箱数</label>
                                        <div class="col-sm-8">
                                            <InputText
                                                :id="`boxes_number`"
                                                :model.sync="form.boxes_number"
                                                :config.sync="form.configFieldBoxesNumber"
                                            />
                                        </div>
                                    </div> -->

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">応募数</label>
                                        <div class="col-sm-8">
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">総応募数</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`number_of_applications_total`"
                                                        :model.sync="form.number_of_applications.total"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">ハガキ・郵送</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`postcard_mail`"
                                                        :model.sync="form.number_of_applications.postcard_mail"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'postcard_mail')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">web</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`web`"
                                                        :model.sync="form.number_of_applications.web"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'web')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">パルなび</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`palnavi`"
                                                        :model.sync="form.number_of_applications.palnavi"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'palnavi')"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">SNS</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`sns`"
                                                        :model.sync="form.number_of_applications.sns"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'sns')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">Twitter</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`twitter`"
                                                        :model.sync="form.number_of_applications.twitter"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'twitter')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">Instagram</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`instagram`"
                                                        :model.sync="form.number_of_applications.instagram"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'instagram')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">LINE</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`line`"
                                                        :model.sync="form.number_of_applications.line"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'line')"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">その他</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`others`"
                                                        :model.sync="form.number_of_applications.others"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'others')"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">無効</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`invalid`"
                                                        :model.sync="form.number_of_applications.invalid"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_applications', 'invalid')"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">問い合わせ件数</label>
                                        <div class="col-sm-8">
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">総問い合わせ数</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`number_of_inquiries_total`"
                                                        :model.sync="form.number_of_inquiries.total"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">TEL</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`tel`"
                                                        :model.sync="form.number_of_inquiries.tel"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_inquiries', 'tel')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">メール</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`email`"
                                                        :model.sync="form.number_of_inquiries.email"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_inquiries', 'email')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">それ以外</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`number_of_cases`"
                                                        :model.sync="form.number_of_inquiries.number_of_cases"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_inquiries', 'number_of_cases')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">イレギュラー</label>
                                                <div class="col-sm-5">
                                                    <InputText
                                                        :id="`irregular`"
                                                        :model.sync="form.number_of_inquiries.irregular"
                                                        :config="{
                                                            classCustom: 'text-center',
                                                            maxlength: 10,
                                                            isNumber: true,
                                                            error: false
                                                        }"
                                                        @input="inputNumber('number_of_inquiries', 'irregular')"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4 pr-0" for="validationCustom05">イレギュラー内容</label>
                                                <div class="col-sm-5">
                                                    <textarea
                                                        type="text"
                                                        class="form-control"
                                                        v-model="form.number_of_inquiries.irregular_content"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/prize/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            v-if="checkPermission('prize.register') || (checkPermission('prize.edit') && $route.query.id)"
                            class="btn btn-primary ml-3"
                            @click="formSubmit"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
            <PopUpInfomation
                :id="`popup-info-master-prize`"
                :title="form.name"
                :isShow.sync="configPopInfomation.isShow"
                :dataInfo.sync="form.detail_information"
            />
            <PopUpCompatible
                :id="`popup-compatible-master-prize`"
                :title="form.name"
                :isShow.sync="configPopCompatible.isShow"
                :dataCompatible.sync="form.remaining_response"
            />
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
