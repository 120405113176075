import i18n from '@/i18n';

const configFormPage = () => {
    return {
        configFieldSelectPage: {
            trackBy: 'id',
            label: 'name',
            errorField: i18n.t('managerForm.settingPage.pageType'),
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        }
    };
};

const configEditFormPage = () => {
    return {
        configFieldSelectBlock: {
            trackBy: 'id',
            label: 'name',
            errorField: i18n.t('managerForm.settingPage.pageType'),
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: []
        },
        configFieldNamePage: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('managerForm.settingPage.titlePage'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        }
    };
};

const configModalAddPage = {
    title: 'ページを追加',
    model: false,
    scrollable: true,
    hideFooter: false,
    class: 'h-25',
    size: 'lg',
    validate: true
};

const configModalEditPage = {
    id: '',
    title: 'ページを追加',
    model: false,
    scrollable: true,
    hideFooter: false,
    class: 'h-100',
    size: '4xl',
    validate: true
};
const configModalPreviewForm = {
    id: '',
    title: 'プレビュー ',
    model: false,
    scrollable: true,
    hideFooter: false,
    class: 'h-100',
    size: '4xl',
    validate: false,
    masterPage: []
};

const configModalSendMailed = {
    id: '',
    title: 'プレビューURLを送る',
    model: false,
    scrollable: true,
    hideFooter: false,
    class: 'h-65',
    size: 'lg',
    validate: true,
    masterPage: [],
    titleBtnSave: i18n.t('managerForm.settingPage.btnSendEmail')
};

const initFormBlock = (data = null) => {
    let obj = {
        block_name: '',
        block_type: '',
        name: '',
        order_required: false,
        label: '',
        block_div_2: {
            label_q: '',
            label_a: ''
        },
        block_input: {
            minimum_number: '',
            maximum_number: '',
            from_range: '',
            to_range: '',
            number_after_decimal_point: '',
            number_of_digits: 15,
            number_of_decimal_places: 5,
            duplicate_input: 2,
            confirm_input_twice: 2
        },
        block_text_area: {
            rows: '',
            cols: ''
        },
        input_placeholder: '',
        input_field_size: '',
        input_format: 1,
        matrix_format: 2,
        maximum_file_size: '',
        allowed_extensions: '',
        block_date: {
            format_year: {
                type: 1,
                placeholder: '',
                type_calendar: 1,
                lower_limit: '',
                upper_limit: ''
            },
            format_month_day: {
                type: 1,
                placeholder_month: '',
                placeholder_day: ''
            }
        },
        block_full_name: {
            placeholder_last_name: '',
            placeholder_first_name: ''
        },
        block_post_code: {
            feature_search_address: 2,
            placeholder_1: '',
            placeholder_2: '',
            prefecture_insertion: null,
            city_insertion: null,
            town_area_name_insertion: null
        },
        block_phone: {
            placeholder_1: '',
            placeholder_2: '',
            placeholder_3: ''
        },
        block_remaining_number: {
            remaining_number: 1,
            display_content: 1,
            remaining_text: '',
            text_after_remaining_number: '',
            remaining_ratio: '',
            label_other: '',
            color_text: ''
        },
        block_keyword_box: {
            matching_condition: [1, 2, 3],
            field_mask_display: 2,
            keyword_characters: ''
        },
        block_parameter: {
            source_param: 1,
            param_name: '',
            param_url: '',
            is_duplicate: 2
        },
        option_value: [
            {
                option_index: 0,
                id: 1,
                value: '',
                number_of_receptions: ''
            }
        ],
        option_maxtrix: {
            choices_at_the_top: [
                {
                    id: 1,
                    value: ''
                }
            ],
            choices_on_the_front_side: [
                {
                    id: 1,
                    value: ''
                }
            ]
        },
        text_area_more_option: '',
        setting_others: {
            other_use: false,
            label_other: 'その他',
            value_other_required: false,
            width_other: '100',
            uncheck_use: false,
            label_uncheck: 'チェックを外す'
        },
        operator_limit_check: null,
        number_limit_check: '',
        confirmation_page_display: 1,
        personal_information_setting: false,
        placeholder: '',
        max_characters: '255',
        number_of_items_displayed_per_line: 2,
        choice_placement_specification: 'left',
        layout: 'row',
        cell_background_color: {
            bg_question: '',
            bg_answer: ''
        },
        setting_conditions: {
            condition: 1,
            table_condition: []
        },
        if_conditions_do_not_match: '',
        additional_setting: {
            edit_with_editor: '',
            error_message_block: []
        }
    };
    if (!data) {
        obj = {
            ...obj
        };
    } else {
        obj = {
            ...obj,
            ...data,
            name: data?.name,
            max_characters: data?.max_characters ?? '255'
        };
    }

    return obj;
};

export { configFormPage, initFormBlock, configEditFormPage, configModalAddPage, configModalEditPage, configModalPreviewForm, configModalSendMailed };
