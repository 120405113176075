import i18n from '@/i18n';

const configFormPrizeLP = {
    configFieldNamePrize: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingPrize.namePrize'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['required', 'max255']
    },
    configFieldTargetNumber: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingPrize.targetNumber'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['max255']
    }
};

const configFormTitleLP = {
    configFieldTitlePrize: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingPrize.titlePrize'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['required', 'max255']
    },
    configFieldSelectTypeTitle: {
        trackBy: 'id',
        label: 'value',
        errorField: i18n.t('pageCampaign.tabPrize.type'),
        valueSync: null,
        error: false,
        isRemove: false,
        isRequired: false,
        typesValidate: []
    }
};

const configFormPrizeSettings = () => {
    return {
        configFieldTitle: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingPrize.titleLPforPrize'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        }
    };
};

const configModalActionPrize = {
    id: '',
    title: '',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: '2xl',
    class: 'h-100',
    validate: true
};
const configModalActionTitle = {
    id: '',
    title: '',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: 'lg',
    class: 'h-100',
    validate: true
};

export { configFormPrizeLP, configFormTitleLP, configModalActionPrize, configModalActionTitle, configFormPrizeSettings };
