const initFormTargetProduct = () => {
    return {
        //----- start setting target product --------------
        title_target__product: '',
        detail_target_product: '',
        description_target_product: '',
        image_target_product: '',
        link_target_product: ''
        //----- end setting target product --------------
    };
};
const initFormDesign = () => {
    return {
        //----- start setting desgin --------------
        font_page: '',
        square_type: '',
        background_color: '',
        title_color: '',
        font_color: '',
        button_color: ''
        //----- end setting desgin --------------
    };
};

export { initFormTargetProduct, initFormDesign };
