<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

import { masterComputed, masterMethods, partnersMethods, settingPhoneMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { cleanConfigFormData } from '@/utils/format';
import { showMessage } from '@/utils/messages.js';
import { InputText } from '@/components/Input';
import { initFormPartnerPIC } from './index';
import { validateAllField, validateField } from '@/utils/validate';

export default {
    page: {
        title: ' 外部パートナー担当者登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer,
        InputText
    },
    data() {
        return {
            loading: false,
            partner_id: this.$route.query.partner_id,
            id: this.$route.query.id,
            form: initFormPartnerPIC(),
            chatworkTokenShow: '',
            inValidToken: false,
            chatworkTokenTemp: null,
            isWaitToken: false,
            isInputAccountID: false,
            objTempChatWork: {
                chatwork_token: '',
                account_id: null,
                chatwork_id: ''
            },
            isVerifyToken: false,
            hasButtonCheckToken: true,
            partnerData: {
                name_partner: ''
            }
        };
    },
    computed: {
        ...masterComputed
    },
    mounted() {
        if (this.$route.query.id && this.$route.query.partner_id) {
            let data = {
                partner_id: this.$route.query.partner_id,
                id: this.$route.query.id
            };
            this.getDetail(data);
        }
        if (this.$route.query.partner_id) {
            this.getDetailPartner(this.$route.query.partner_id);
        }
    },
    methods: {
        ...masterMethods,
        ...partnersMethods,
        ...settingPhoneMethods,

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        getDetailPartner(id) {
            this.loading = true;
            this.detailPartners(id).then((data) => {
                this.partnerData = {
                    name_partner: data?.name
                };

                this.loading = false;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailPartnersSupport(id).then((data) => {
                this.form = {
                    ...this.form,
                    ...data,
                    department: data?.partner_pic_detail?.department ?? ''
                };

                this.form.configFieldPassword.typesValidate = ['max255', 'password'];
                this.loading = false;
                this.objTempChatWork = {
                    chatwork_token: data.chatwork_token ?? '',
                    account_id: data.account_id ?? null,
                    chatwork_id: data.chatwork_id ?? ''
                };
                if (data.chatwork_token) {
                    this.chatworkTokenShow = data.chatwork_token ?? '';
                    this.isInputAccountID = true;
                } else {
                    this.chatworkTokenShow = '';
                    this.isInputAccountID = false;
                }
            });
        },
        formSubmit() {
            let checkAll = validateAllField(this.form);
            if (this.isVerifyToken) {
                showMessage(8080, this.$bvToast, this.$t('pageEmployee.verifyToken'));
            }
            if (this.inValidToken || this.isVerifyToken || !checkAll) return;
            this.register();
        },
        register() {
            if (this.loading) return;
            this.loading = true;
            let data = cleanConfigFormData(this.form);
            data.partner_id = this.partner_id;
            data.account_id = data?.account_id ? Number(data.account_id) : null;
            if (this.$route.query.id) {
                if (!data.password) {
                    delete data.password;
                }
                if (data.chatwork_token === this.chatworkTokenShow) {
                    delete data.chatwork_token;
                }
                this.updatePartnersSupport(data).then((data) => {
                    if (data.code == 200) {
                        showMessage(data.code, this.$bvToast, '外部パートナー担当者が更新されました。');
                        this.getDetailPartner(this.$route.query.partner_id);
                        this.form.password = null;
                    } else {
                        if (data.message === 'name.unique') {
                            showMessage(data.code, this.$bvToast, this.$t('notificationCommon.exitPersonInCharge'));
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    }
                });
            } else {
                this.registerPartnersSupport(data).then((data) => {
                    if (data.code == 200) {
                        this.$router
                            .push({
                                path: `/partner/support/view`,
                                query: { id: data.data.id, partner_id: this.$route.query.partner_id }
                            })
                            .then(() => {
                                showMessage(data.code, this.$bvToast, '外部パートナー担当者が登録されました。');
                            });
                    } else {
                        if (data.message === 'name.unique') {
                            showMessage(data.code, this.$bvToast, this.$t('notificationCommon.exitPersonInCharge'));
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                    }
                    this.loading = false;
                });
            }
        },

        handleCallChatWork() {
            if ((this.chatworkTokenTemp && this.chatworkTokenTemp === this.form.chatwork_token) || !this.form.chatwork_token) return;
            this.chatworkTokenTemp = this.form.chatwork_token;
            let chatwork_token = this.form.chatwork_token;
            this.isWaitToken = true;
            this.getInfoChatWork({ chatwork_token })
                .then((res) => {
                    this.isWaitToken = false;
                    this.isVerifyToken = false;
                    if (!res) {
                        this.inValidToken = true;
                        this.form.account_id = null;
                        this.form.chatwork_id = '';
                        this.form.configFieldChatWorkToken.error = true;
                        return;
                    }
                    this.inValidToken = false;
                    this.form.account_id = res?.account_id ?? null;
                    this.form.chatwork_id = res?.chatwork_id ?? '';
                    this.objTempChatWork = {
                        chatwork_token: this.form.chatwork_token,
                        account_id: this.form.account_id,
                        chatwork_id: this.form.chatwork_id
                    };
                })
                .catch((error) => {
                    this.isWaitToken = false;
                    console.log('error', error);
                });
        },
        handleInputChatWorkToken() {
            this.inValidToken = false;
            if (!this.form.chatwork_token) {
                this.isInputAccountID = false;
                this.isVerifyToken = false;
                this.hasButtonCheckToken = true;
                this.form.account_id = null;
                this.form.chatwork_id = '';
            } else {
                this.isInputAccountID = true;
                this.hasButtonCheckToken = false;
                if (this.form.chatwork_token !== this.chatworkTokenTemp) {
                    this.form.account_id = null;
                    this.form.chatwork_id = '';
                    this.isVerifyToken = true;
                } else {
                    this.form.account_id = this.objTempChatWork.account_id;
                    this.form.chatwork_id = this.objTempChatWork.chatwork_id;
                    this.isVerifyToken = false;
                }
            }
        },

        handleValidateField(configField) {
            validateField(configField);
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-6">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom01"> {{ $t('pagePartner.nameCompany') }}</label>
                                        <div class="col-sm-12">
                                            {{ partnerData.name_partner }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom02"> {{ $t('pagePartner.department') }}</label>

                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.department"
                                                :model.sync="form.department"
                                                :config.sync="form.configFieldDepartment"
                                                @blur="handleValidateField(form.configFieldDepartment)"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom03"
                                            >{{ $t('pagePartner.manager') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.name"
                                                :model.sync="form.name"
                                                :config.sync="form.configFieldName"
                                                @blur="handleValidateField(form.configFieldName)"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom04">{{ $t('pagePartner.personCharge') }} </label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.kana_name"
                                                :model.sync="form.kana_name"
                                                :config.sync="form.configFieldKanaName"
                                                @blur="handleValidateField(form.configFieldKanaName)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('pagePartner.telephoneNumber') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.phone_number"
                                                :model.sync="form.phone_number"
                                                :config.sync="form.configFieldPhoneNumber"
                                                @blur="handleValidateField(form.configFieldPhoneNumber)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom06">{{ $t('pagePartner.cellphoneNumber') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.mobile_number"
                                                :model.sync="form.mobile_number"
                                                :config.sync="form.configFieldMobileNumber"
                                                @blur="handleValidateField(form.configFieldMobileNumber)"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom07"
                                            >{{ $t('pagePartner.emailPartner') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.emailPartner"
                                                :model.sync="form.email"
                                                :config="form.configFieldEmail"
                                                @blur="handleValidateField(form.configFieldEmail)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom08"
                                            >{{ $t('pagePartner.password') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.password"
                                                :model.sync="form.password"
                                                :config.sync="form.configFieldPassword"
                                                @blur="handleValidateField(form.configFieldPassword)"
                                            />

                                            <div>
                                                <small v-if="this.$route.query.id && !form.configFieldPassword.error" style="font-size: 14px">{{
                                                    $t('notificationCommon.notePass2')
                                                }}</small>
                                                <small v-else-if="!this.$route.query.id && !form.configFieldPassword.error">{{
                                                    $t('notificationCommon.notePass')
                                                }}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="tokenUser">{{ $t('pageEmployee.tokenUser') }}</label>
                                        <div class="col-sm-12">
                                            <div class="d-flex">
                                                <div class="w-100">
                                                    <InputText
                                                        id="pagePartner.chatwork_token"
                                                        :model.sync="form.chatwork_token"
                                                        :config="form.configFieldChatWorkToken"
                                                        @input="handleInputChatWorkToken"
                                                        @blur="handleValidateField(form.configFieldChatWorkToken)"
                                                    />
                                                    <span v-if="inValidToken" class="text-danger">{{ $t('tokenNotExist') }}</span>
                                                </div>
                                                <div class="ml-3">
                                                    <b-button
                                                        :disabled="hasButtonCheckToken"
                                                        variant="success"
                                                        @click="handleCallChatWork"
                                                        :class="{
                                                            disabled_cursor: hasButtonCheckToken
                                                        }"
                                                        style="white-space: nowrap"
                                                        >{{ $t('btn.verify') }}</b-button
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="accountId">{{ $t('pageEmployee.accountId') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.account_id"
                                                :model.sync="form.account_id"
                                                :config="form.configFieldAccountID"
                                                :disabled="isInputAccountID"
                                                @blur="handleValidateField(form.configFieldAccountID)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="chatworkId">{{ $t('pageEmployee.chatworkId') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="pagePartner.chatwork_id"
                                                :model.sync="form.chatwork_id"
                                                :config="form.configFieldChatWorkID"
                                                @blur="handleValidateField(form.configFieldChatWorkID)"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light mr-3 btn-sm"
                            @click="
                                $router.push({
                                    path: `/partner/view?id=${$route.query.partner_id}`
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary ml-3"
                            v-if="checkPermiss('partner.register') || checkPermiss('partner.edit')"
                            :disabled="isWaitToken"
                            @click="formSubmit"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style scoped>
.btn__get--token {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    padding-top: 5px;
    padding-inline: 10px;
    background-color: transparent;
}
.btn__get--token--icon {
    font-size: 20px;
    color: #858585;
    opacity: 0.9;
}
.btn__get--token--icon:hover {
    opacity: 1;
}
</style>
