<template>
    <div>
        <ModalCommon :id="`${configModal?.id}`" :config="configModal" @save="handleSave()" @close="handleClose()" :isView="isViewSetting">
            <div class="form-group row flex-nowrap">
                <label class="col-sm-3 text-nowrap" style="min-width: 280px"
                    >{{ $t('managerForm.settingPage.titlePage') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9" style="min-width: 815px">
                    <InputText
                        :model.sync="configModal.title"
                        :config.sync="configEditFormPage.configFieldNamePage"
                        @blur="handleValidateField(configEditFormPage.configFieldNamePage)"
                    />
                </div>
            </div>
            <!-- block -->
            <div class="form-group row flex-nowrap" :index="indexLoading">
                <div class="col-sm-3" style="min-width: 280px">
                    <div v-if="isActivePageInput">
                        <p class="block_property">{{ $t('managerForm.settingPage.addedSettingItems') }}</p>
                        <div class="d-flex align-items-center p-3" style="gap: 12px">
                            <Multiselect
                                :id="`select-block`"
                                :value.sync="select_block"
                                :options="listBlock"
                                :config="configEditFormPage.configFieldSelectBlock"
                                @remove="handleRemoveBlock(configEditFormPage.configFieldSelectBlock)"
                            />

                            <button class="btn btn-success text-nowrap" type="button" @click="handleAddBlock">{{ $t('btn.addTag') }}</button>
                        </div>
                        <div>
                            <p class="block_property">{{ $t('managerForm.settingPage.block') }}</p>
                            <div class="block_list">
                                <Draggable v-model="listBlockState" tag="div" ghost-class="ghost--option" @change="onDraggableChange">
                                    <div class="border-bottom px-3 py-4" v-for="(itemBlock, idx) in listBlockState" :key="`block-${idx}`">
                                        <div class="d-flex">
                                            <p class="mb-0 p-0 btn text--block" @click="handleEditBlock(itemBlock)">
                                                {{ itemBlock.name }}
                                            </p>
                                            <InputCheckBox
                                                class="ml-auto"
                                                :model.sync="listChecked"
                                                :id="`check-itemBlock${idx}`"
                                                :value="idx"
                                                :label="``"
                                            />
                                        </div>
                                    </div>
                                </Draggable>
                            </div>
                            <div v-if="listBlockState.length > 0" class="d-flex justify-content-center mt-3" style="gap: 18px">
                                <button type="button" class="alldelete" @click="handleDeleteBlock">{{ $t('btn.delete') }}</button>
                                <button type="button" class="green02_copy" @click="handleCopyRight">{{ $t('btn.copy') }}</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="isActivePageConfirm">
                        <div>
                            <h5 class="border-top py-2">{{ $t('managerForm.settingPage.EditPage(confirmationPage)') }}</h5>
                        </div>
                        <p class="block_property">{{ $t('managerForm.settingPage.properties') }}</p>
                    </div>
                    <div v-if="isActivePageComplete">
                        <div>
                            <h5 class="border-top py-2">{{ $t('managerForm.settingPage.EditPage(completedPage)') }}</h5>
                        </div>
                        <p class="block_property">{{ $t('managerForm.settingPage.addedSettingItems') }}</p>
                        <div class="d-flex align-items-center p-3" style="gap: 12px">
                            <Multiselect
                                :id="`select-block`"
                                :value.sync="select_block"
                                :options="completeListBlock"
                                :config="configEditFormPage.configFieldSelectBlock"
                                @remove="handleRemoveBlock(configEditFormPage.configFieldSelectBlock)"
                            />

                            <button class="btn btn-success text-nowrap" type="button" @click="handleAddBlock">{{ $t('btn.addTag') }}</button>
                        </div>
                        <div>
                            <p class="block_property">{{ $t('managerForm.settingPage.block') }}</p>
                            <div class="block_list">
                                <Draggable v-model="listBlockState" tag="div" ghost-class="ghost--option" @change="onDraggableChange">
                                    <div class="border-bottom px-3 py-4" v-for="(itemBlock, idx) in listBlockState" :key="`block-${idx}`">
                                        <div class="d-flex">
                                            <p class="mb-0 p-0 btn text--block" @click="handleEditBlock(itemBlock)">
                                                {{ itemBlock.name }}
                                            </p>
                                            <InputCheckBox
                                                class="ml-auto"
                                                :model.sync="listChecked"
                                                :id="`check-itemBlock${idx}`"
                                                :value="idx"
                                                :label="``"
                                            />
                                        </div>
                                    </div>
                                </Draggable>
                            </div>
                            <div v-if="listBlockState.length > 0" class="d-flex justify-content-center mt-3" style="gap: 18px">
                                <button type="button" class="alldelete" @click="handleDeleteBlock">{{ $t('btn.delete') }}</button>
                                <button type="button" class="green02_copy" @click="handleCopyRight">{{ $t('btn.copy') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-9" style="min-width: 815px">
                    <SettingBlockForm
                        v-if="isSettingBlock"
                        :config="configSettingBlock"
                        :formBlock="initFormBlock"
                        :configFormInput="configFormInput"
                        :actionTimeAddBlock="actionTimeAddBlock"
                        @showSave="handleShowSaveBlock"
                        @cancel="handleCancelBlock"
                    />
                    <div v-else>
                        <div class="white_wrap mb-3">
                            <div class="row" style="gap: 12px">
                                <p
                                    class="btn mb-0 h-100"
                                    :class="activePreview == 'PC' ? 'btn-outline-success' : ''"
                                    @click="handleSwitchDevice('PC')"
                                >
                                    <i class="fa fa-laptop"></i> PC
                                </p>
                                <p
                                    class="btn mb-0 h-100"
                                    :class="activePreview == 'Mobile' ? 'btn-outline-success' : ''"
                                    @click="handleSwitchDevice('Mobile')"
                                >
                                    <i class="fa fa-mobile"></i> Mobile
                                </p>
                                <div class="ml-auto">
                                    <div class="d-flex align-items-center justify-content-end mr-3 mb-2">
                                        <InputText
                                            class="mr-1"
                                            style="max-width: 100px"
                                            :model.sync="pixel_input"
                                            :config="{
                                                isNumber: false,
                                                error: false
                                            }"
                                            @change="handleInputPixel"
                                        />
                                        <div>px</div>
                                    </div>
                                    <div style="font-size: 12px">{{ $t('managerForm.settingPage.noteResponsive') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right">画面サイズは横幅{{ pixel_action ? `${pixel_action}px` : '100%' }}で表示されています</div>

                        <div class="ifame_page d-flex justify-content-center">
                            <IframComponent
                                :typeHandle="`edit`"
                                :typePage="propTypePage"
                                :style="`width:${pixel_action ? `${pixel_action}px` : '100%'}`"
                                :dataForm="dataForm"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Draggable from 'vuedraggable';
import Swal from 'sweetalert2';
import ModalCommon from '@/components/Modal/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import SettingBlockForm from '../setting-block-form/main.vue';
import IframComponent from '../../iframe-page/main.vue';

import { managerFormMethods } from '@/state/helpers';
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { BLOCK_COMMON } from '@/config/constants';
import { initFormBlock, configEditFormPage } from './constants';
import { configFormInput } from '../setting-block-form/constants';
import { OPTION_INPUT_FORMAT, OPTION_INPUT_FORMAT_FULL_NAME, LIST_PROVINCE_BLOCK, handleAddConfigOptions } from '../setting-block-form/constants';
import { LABEL_TYPE_1, LABEL_TYPE_2 } from '../setting-block-form/listTypeActiveField';

export default {
    name: 'ModalEditPage',
    components: {
        ModalCommon,
        InputText,
        Multiselect,
        SettingBlockForm,
        Draggable,
        IframComponent,
        InputCheckBox
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configModal: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    watch: {
        'configModal.model': {
            handler(val) {
                if (val) {
                    this.indexLoading = this.indexLoading < 100 ? this.indexLoading++ : 0;
                    this.propTypePage = this.configModal?.childPage?.type_page_wl;
                    this.conditionShowField();
                    this.cloneBlocks = _.cloneDeep(this.$store.state.managerForm.listBlockCreated);
                }
            },
            immediate: true
        },
        getBlockCreated: {
            handler(val) {
                this.listBlockState = val;
            },
            immediate: true
        }
    },
    data() {
        return {
            initFormBlock: initFormBlock(),
            configFormInput: configFormInput(),
            configEditFormPage: configEditFormPage(),
            listBlock: BLOCK_COMMON,
            completeListBlock: BLOCK_COMMON.filter((blockCommonItem) => blockCommonItem.type == 'div_1'),
            originPath: window.location.origin,
            activePreview: 'PC',
            pixel_input: '',
            pixel_action: '',
            propTypePage: '',
            actionTimeAddBlock: 0,
            indexLoading: 0,
            isSettingBlock: false,
            isViewSetting: false,
            isActivePageInput: false,
            isActivePageConfirm: false,
            isActivePageComplete: false,
            select_block: null,
            configSettingBlock: null,
            listChecked: [],
            listBlockState: [],
            cloneBlocks: []
        };
    },
    computed: {
        getBlockCreated() {
            return _.cloneDeep(this.$store.state.managerForm.listBlockCreated);
        }
    },
    methods: {
        ...managerFormMethods,

        conditionShowField() {
            this.isActivePageInput = this.propTypePage == 'page_inputs';
            this.isActivePageConfirm = this.propTypePage == 'page_confirms';
            this.isActivePageComplete = this.propTypePage == 'page_completes';
        },
        onDraggableChange() {
            this.updateBlockList(this.listBlockState);
        },

        getBlockState() {
            return this.$store.state.managerForm.listBlockCreated;
        },

        handleSave() {
            let dataFormEdit = this.dataForm;
            let check = validateField(this.configEditFormPage.configFieldNamePage);
            if (!check) return;
            const foundPar = dataFormEdit.setting_page.pages.find((prItem) => prItem.id == this.configModal.parentPage.id);
            const foudCh = foundPar.page_child.find((chItem) => chItem.id == this.configModal.childPage.id);
            foudCh.blocks = this.listBlockState;

            this.configModal.model = false;
            this.isSettingBlock = false;
            this.select_block = null;
            this.$emit('updateConfig', this.configModal);
        },

        handleClose() {
            let dataFormEdit = this.dataForm;
            const foundPar = dataFormEdit.setting_page.pages.find((prItem) => prItem.id == this.configModal.parentPage.id);
            const foudCh = foundPar.page_child.find((chItem) => chItem.id == this.configModal.childPage.id);
            foudCh.blocks = this.cloneBlocks;
            this.isSettingBlock = false;
            this.select_block = null;
            this.configModal.model = false;
            this.configEditFormPage = configEditFormPage();
            this.activePreview = 'PC';
            this.pixel_input = '';
            this.pixel_action = '';
        },

        handleRemoveBlock(configFieldSelectBlock) {
            this.isViewSetting = false;
            this.isSettingBlock = false;
            this.handleValidateField(configFieldSelectBlock);
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddBlock() {
            this.actionTimeAddBlock = Math.random();
            if (!this.select_block?.id) return;
            this.isViewSetting = true;
            if (this.select_block.type == 'postcode-and-province') {
                this.isSettingBlock = false;
                return;
            }

            this.isSettingBlock = true;
            this.configSettingBlock = this.select_block;
            this.initFormBlock = initFormBlock();
            this.configFormInput = configFormInput();
            this.handleSetFollowTypeBlock(this.select_block);
            this.updateMessages();
        },

        handleEditBlock(itemBlock) {
            // console.log('itemBlock', itemBlock);
            itemBlock.type = itemBlock.block_type;
            this.actionTimeAddBlock = Math.random();
            this.isViewSetting = true;
            if (itemBlock.type == 'postcode-and-province') {
                this.isSettingBlock = false;
                return;
            }

            this.isSettingBlock = true;
            this.configSettingBlock = itemBlock;
            this.initFormBlock = initFormBlock(itemBlock);
            this.configFormInput = configFormInput(itemBlock);
            this.handleSetFollowTypeBlock(itemBlock);
            this.updateMessages(itemBlock.additional_setting.error_message_block);
        },

        handleSetFollowTypeBlock(itemBlock) {
            this.initFormBlock.block_name = itemBlock?.name;
            this.initFormBlock.block_type = itemBlock?.type;

            if (LABEL_TYPE_1.includes(this.initFormBlock?.block_type)) {
                this.configFormInput.configFieldMaxCharacters.errorField = this.$t('managerForm.settingPage.maxCharactersInput');
            }
            if (LABEL_TYPE_2.includes(this.initFormBlock?.block_type)) {
                this.configFormInput.configFieldMaxCharacters.errorField = `${this.$t('managerForm.settingPage.maxCharactersInput')}(入力欄毎)`;
            }

            if (this.initFormBlock.block_type == 'textarea') {
                this.initFormBlock.max_characters = itemBlock?.max_characters ?? '30000';
                this.configFormInput.configFieldMaxCharacters.max = 30000;
            } else if (this.initFormBlock.block_type == 'fullname') {
                this.initFormBlock.max_characters = itemBlock?.max_characters ?? '100';
            } else if (this.initFormBlock.block_type == 'keyword-box') {
                this.initFormBlock.max_characters = itemBlock?.max_characters ?? '32';
            } else {
                this.initFormBlock.max_characters = itemBlock?.max_characters ?? '255';
            }

            if (this.initFormBlock.block_type == 'province') {
                this.initFormBlock.option_value = LIST_PROVINCE_BLOCK;
                this.configFormInput.validateArrayOption = handleAddConfigOptions(
                    this.configFormInput.validateArrayOption,
                    this.initFormBlock.option_value
                );
            }

            this.OPTION_INPUT_FORMAT =
                this.initFormBlock.block_type == 'input'
                    ? OPTION_INPUT_FORMAT
                    : this.initFormBlock.block_type == 'fullname'
                      ? OPTION_INPUT_FORMAT_FULL_NAME
                      : [];

            if (this.initFormBlock.block_type == 'checkbox') {
                this.initFormBlock.setting_others.label_uncheck = '該当なし';
            }
        },

        handleSwitchDevice(device) {
            this.activePreview = device;
            this.pixel_input = device == 'PC' ? '' : device == 'Mobile' ? '400' : '';
            this.pixel_action = this.pixel_input;
        },

        handleInputPixel(value) {
            this.activePreview = '';
            if (value < 300) {
                this.pixel_input = 300;
            } else if (value > 2000) {
                this.pixel_input = 2000;
            }
            this.pixel_action = this.pixel_input;
        },

        handleShowSaveBlock(isShow) {
            this.isViewSetting = isShow;
            this.isSettingBlock = isShow;
            this.select_block = null;
            this.listBlockState = this.getBlockState();
        },

        handleCancelBlock(isCancel) {
            this.isViewSetting = isCancel;
            this.isSettingBlock = isCancel;
        },

        handleDeleteBlock() {
            if (this.listChecked.length <= 0) return;
            Swal.fire({
                title: 'ブロックを削除します。一度削除すると元に戻すことはできません。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.listBlockState = this.listBlockState.filter((o, idx) => !this.listChecked.includes(idx));
                    this.updateBlockList(this.listBlockState);
                    this.listChecked = [];
                    this.isViewSetting = false;
                    this.isSettingBlock = false;
                }
            });
        },

        handleCopyRight() {
            if (this.listChecked.length <= 0) return;
            const listCopyed = this.listBlockState.filter((o, idx) => this.listChecked.includes(idx));
            listCopyed.forEach((copyItem) => {
                delete copyItem.id;
                copyItem.name = `${copyItem.name} Copy ${moment().format('YYYY-MM-DD')} ${moment().format('HH:mm')}`;
                this.saveStateBlockForm(copyItem);
            });
            this.listChecked = [];
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-outline-success:hover {
    color: #1cbb8c;
    border-color: #1cbb8c;
    background-color: #fff;
}
.text--block {
    text-align: left;
    color: #339162;
    &:hover {
        color: #70be97;
    }
}

.ghost--option {
    opacity: 0.5;
    background-color: #f8f9fa;
    border: dashed 1px #ccc;
}
.alldelete {
    border-radius: 4px;
    padding: 5px 15px;
    background-color: #fff;
    border: 1px solid #707070;
    &:hover {
        background-color: #ededed;
    }
}

.green02_copy {
    border-radius: 4px;
    padding: 5px 15px;
    color: #339162;
    border: solid 1px #339162;
    background-color: #fff;
    &:hover {
        opacity: 0.7;
    }
}

.block_property {
    background: #808080;
    font-size: 12px;
    background: #e5e5e5;
    font-weight: normal;
    padding: 8px 15px 8px 20px;
}
</style>
