import { authHeader, handleResponse } from '@/api/handler/main';

export const managerFormService = {
    getListManagerForm,
    getDetailManagerForm,
    registerManagerForm,
    updateManagerForm,
    deleteManagerForm
};

function getListManagerForm(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/forms?${query}`, requestOptions).then(handleResponse);
}

function getDetailManagerForm(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/forms/${id}`, requestOptions).then(handleResponse);
}

function registerManagerForm(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_APIURL + `/forms`, requestOptions).then(handleResponse);
}

function updateManagerForm(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_APIURL + `/forms/${payload.id}`, requestOptions).then(handleResponse);
}

function deleteManagerForm(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_APIURL + `/forms/${payload.id}`, requestOptions).then(handleResponse);
}
