import { authHeader, handleResponse } from '@/api/handler/main';

export const campaignService = {
    registerCampaign,
    copyCampaign,
    listCampaign,
    detailCampaign,
    deleteCampaign,
    updateCampaign,
    getDataDocuments,
    lock,
    unlock,
    approve,
    addBookmark,
    deleteBookmark,
    getBookmark,
    triggerDocuments,
    getDocuments,
    uploadFile,
    postDocuments,
    historyDocuments,
    getRuleSavePayLoad,
    postRuleSavePayLoad,
    setBookmarkCampaign,
    unsetBookmarkCampaign
};

function registerCampaign(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns`, requestOptions).then(handleResponse);
}

function copyCampaign(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/copy`, requestOptions).then(handleResponse);
}

function detailCampaign(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/` + data, requestOptions).then(handleResponse);
}

function listCampaign(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns?` + query, requestOptions).then(handleResponse);
}

function deleteCampaign(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/` + data.id, requestOptions).then(handleResponse);
}

function updateCampaign(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/` + data.id, requestOptions).then(handleResponse);
}

function getDataDocuments() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/documents`, requestOptions).then(handleResponse);
}
function lock(id) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${id}/lock`, requestOptions).then(handleResponse);
}
function unlock(id) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${id}/unlock`, requestOptions).then(handleResponse);
}
function approve(id) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${id}/approve`, requestOptions).then(handleResponse);
}

function addBookmark(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/bookmarks`, requestOptions).then(handleResponse);
}
function triggerDocuments(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${data.id}/documents`, requestOptions).then(handleResponse);
}

function getDocuments(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${data.id}/documents?${data.query}`, requestOptions).then(handleResponse);
}

function postDocuments(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/documents`, requestOptions).then(handleResponse);
}
function historyDocuments(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/documents/histories?` + data, requestOptions).then(handleResponse);
}
function getBookmark(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/bookmarks?` + query, requestOptions).then(handleResponse);
}

function deleteBookmark(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/bookmarks/` + id, requestOptions).then(handleResponse);
}

function uploadFile(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/upload/get-presigned?` + query, requestOptions).then(handleResponse);
}

function getRuleSavePayLoad(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/documents/get-payload?campaign_id=${data.campaign_id}&source=${data.source}`, requestOptions).then(
        handleResponse
    );
}

function postRuleSavePayLoad(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/documents/save-payload`, requestOptions).then(handleResponse);
}

function setBookmarkCampaign(id) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${id}/set-bookmark`, requestOptions).then(handleResponse);
}

function unsetBookmarkCampaign(id) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${id}/unset-bookmark`, requestOptions).then(handleResponse);
}
