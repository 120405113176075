<template>
    <div class="custom-control custom-radio">
        <div>
            <input
                :id="`radio-${id}`"
                v-model="modelRadio"
                :type="configInit?.type ?? 'radio'"
                class="custom-control-input"
                :class="{
                    [configInit.classCustom]: configInit.classCustom,
                    disabled_cursor: disabled
                }"
                :name="name ?? ''"
                :value="value ?? 'on'"
                :disabled="disabled"
                @change="handleChangeRadio"
            />
            <label :for="`radio-${id}`" :class="`${disabled ? 'disabled_cursor' : ''} custom-control-label`">{{ label }}</label>
        </div>
        <div v-if="configInit.error" class="invalid-feedback d-block">
            <span v-if="configInit.isRequired">{{
                $t('validateField.required', {
                    field: configInit.errorField
                })
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputRadio',
    props: {
        model: {
            required: true
        },
        id: {
            type: String,
            default: 'input-radio'
        },
        name: {
            type: String,
            default: ''
        },
        value: {
            required: true,
            default: ''
        },
        label: {
            required: true,
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        config: {
            type: Object,
            default() {
                return {
                    classCustom: '',
                    type: 'radio',
                    isRequired: false,
                    error: false,
                    errorField: '',
                    valueSync: null
                };
            }
        }
    },
    data() {
        return {
            modelRadio: '',
            configInit: {
                classCustom: '',
                type: 'radio',
                isRequired: false,
                error: false,
                errorField: '',
                valueSync: null
            }
        };
    },
    watch: {
        '$props.model': {
            handler: function (newValue) {
                this.modelRadio = newValue;
            },
            immediate: true,
            deep: true
        },

        '$props.config': {
            handler: function (newConfig) {
                this.configInit = {
                    ...this.configInit,
                    ...newConfig
                };
            },
            immediate: true,
            deep: true
        },

        modelRadio: {
            handler: function (newModelRadio) {
                this.configInit.error = false;
                this.configInit.valueSync = newModelRadio;

                this.$emit('update:model', newModelRadio);
            }
        },

        configInit: {
            handler: function () {
                this.$emit('update:config', this.configInit);
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        handleChangeRadio() {
            this.configInit.error = false;
            this.configInit.valueSync = this.modelRadio;

            this.$emit('update:model', this.modelRadio);
            this.$emit('change', this.modelRadio);
        }
    }
};
</script>

<style lang="scss" scoped></style>
