<template>
    <div>
        <table class="table table-bordered">
            <thead>
                <th v-for="(item, k) in TABLE_CONDITION_BLOCK.headers" :key="k" style="background: #ededed" :style="`min-width: ${item.width}px`">
                    <div class="d-flex align-items-center">{{ item.label }}</div>
                </th>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <Multiselect
                            :id="`select-block-saved`"
                            :value.sync="var_table.block"
                            :options="BLOCK_COMMON"
                            :config="{ trackBy: 'id', label: 'name', error: false, isRemove: false }"
                            @remove="
                                () => {
                                    var_table.type_condition = TYPE_CONDITION_IN_TABLE[0];
                                    var_table.value_confirm = '';
                                }
                            "
                        />
                    </td>
                    <td>
                        <div v-if="var_table?.block?.id">
                            <Multiselect
                                :id="`select-type_condition`"
                                :value.sync="var_table.type_condition"
                                :options="TYPE_CONDITION_IN_TABLE"
                                :config="{ trackBy: 'id', label: 'value', error: false, isRemove: true }"
                            />
                        </div>
                    </td>
                    <td>
                        <div v-if="var_table?.block?.id">
                            <Multiselect
                                :id="`select-value-block`"
                                :value.sync="var_table.value_confirm"
                                :options="BLOCK_COMMON"
                                :config="{ trackBy: 'id', label: 'name', error: false, isRemove: false }"
                            />
                        </div>
                    </td>
                    <td>
                        <div v-if="var_table?.block?.id">
                            <div class="text-left text-nowrap">
                                <button type="button" class="btn btn-outline-success" @click="handleAddConditionBlock()">
                                    <span>{{ $t('managerForm.settingPage.add') }}</span>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center align-items-center flex-column">
            <span style="font-size: 28px">
                <i class="fa fa-solid fa-caret-down" style="color: #cccccc"></i>
            </span>
            <p>{{ $t('managerForm.settingPage.presetConditions') }}</p>
        </div>

        <div class="set_up">
            <table class="table table-bordered">
                <thead>
                    <th v-for="(item, k) in TABLE_CONDITION_BLOCK.headers" :key="k" style="background: #ededed" :style="`min-width: ${item.width}px`">
                        <div class="d-flex align-items-center">{{ item.label }}</div>
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(resultCondition, idx) in formBlock.setting_conditions.table_condition" :key="`tr---${idx}`">
                        <td>
                            <div>
                                {{ resultCondition.block?.name }}
                            </div>
                        </td>
                        <td>
                            <div>
                                {{ resultCondition.type_condition?.value }}
                            </div>
                        </td>
                        <td>
                            <div>
                                {{ resultCondition.value_confirm?.name }}
                            </div>
                        </td>
                        <td>
                            <div>
                                <div class="text-left text-nowrap btn">
                                    <div class="d-flex flex-column align-items-center delete--condition" @click="deleteConditionBlock(idx)">
                                        <div class="rounded-circle bg_trash--condition">
                                            <span class="d-flex justify-content-center align-items-center h-100">
                                                <i class="fa fa-trash"></i>
                                            </span>
                                        </div>
                                        <span class="clr--text--delete">{{ $t('btn.delete') }}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { TABLE_CONDITION_BLOCK, TYPE_CONDITION_IN_TABLE } from './constants';
import { BLOCK_COMMON } from '@/config/constants';

import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'TableCondition',
    components: {
        Multiselect
    },
    props: {
        formBlock: {
            type: Object,
            required: true,
            default: () => null
        },
        configFormInput: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            TABLE_CONDITION_BLOCK: TABLE_CONDITION_BLOCK,
            TYPE_CONDITION_IN_TABLE: TYPE_CONDITION_IN_TABLE,
            BLOCK_COMMON: BLOCK_COMMON,
            var_table: {
                block: null,
                type_condition: TYPE_CONDITION_IN_TABLE[0],
                value_confirm: ''
            }
        };
    },
    methods: {
        handleAddConditionBlock() {
            this.formBlock.setting_conditions.table_condition.push({
                block: this.var_table.block,
                type_condition: this.var_table.type_condition,
                value_confirm: this.var_table.value_confirm
            });
            this.var_table.value_confirm = '';
        },

        deleteConditionBlock(index) {
            this.formBlock.setting_conditions.table_condition.splice(index, 1);
        }
    },
    watch: {
        'formBlock.setting_conditions.table_condition': {
            handler: function (newTableCondition) {
                this.formBlock.if_conditions_do_not_match = newTableCondition && newTableCondition.length > 0 ? 1 : '';
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped>
.delete--condition {
    .bg_trash--condition {
        width: 30px;
        height: 30px;
        background-color: #f6f5f5;
    }

    .clr--text--delete {
        color: #339162;
    }

    &:hover {
        .bg_trash--condition {
            background-color: #dbdbdb;
        }
        .clr--text--delete {
            color: #70be97;
        }
    }
}
</style>
