<template>
    <div>
        <Layout>
            <div class="row form__common--center">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title--screen">LP検索</h3>
                        </div>
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-sm-6">
                                    <form class="needs-validation" autocomplete="off">
                                        <div v-if="checkShowFilter" class="row form-group">
                                            <div class="col-sm-12">
                                                <KeyWordTag :config="configKeyword" class="" />
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.nameCampaign') }}</label>
                                                <div class="col-sm-9">
                                                    <Multiselect
                                                        :id="`select-campaign-default`"
                                                        :value.sync="formSearch.campaign_name"
                                                        :options="listDataCampaign"
                                                        :config="configForm.campaign"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.nameLandingPage') }}</label>
                                                <div class="col-sm-9">
                                                    <Multiselect
                                                        :id="`select-campaign-default`"
                                                        :value.sync="formSearch.name"
                                                        :options="listDataNameLandingPage"
                                                        :config="configForm.nameLandingPage"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{
                                                    $t('landingPage.list.businessAssistant')
                                                }}</label>
                                                <div class="col-sm-9">
                                                    <InputText
                                                        :model.sync="formSearch.sale_staff"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.startDateCP') }}</label>
                                                <div class="col-sm-9">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <DateNormal :full_date.sync="formSearch.campaign_start_date"> </DateNormal>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.endDateCP') }}</label>
                                                <div class="col-sm-9">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <DateNormal :full_date.sync="formSearch.campaign_end_date"> </DateNormal>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.startDateLP') }}</label>
                                                <div class="col-sm-9">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <DateNormal :full_date.sync="formSearch.lp_start_date"> </DateNormal>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">{{ $t('landingPage.list.endDateLP') }}</label>
                                                <div class="col-sm-9">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <DateNormal :full_date.sync="formSearch.lp_end_date"> </DateNormal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center mt-4 position-relative">
                                            <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                            <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>
                                            <a @click="handleShowFilter()" class="filter" href="javascript:void(0)">
                                                <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title--screen">
                                キャンペーン検索結果 <span>件数（{{ configPage.total }})</span>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="d-flex align-items-center input-group-sm" style="white-space: nowrap">
                                        検索条件：
                                        <input type="text" class="form-control ml-3" v-model="bookmarkName" style="width: 200px" />
                                        <button class="btn btn-sm btn-primary ml-2" @click="addBookMark()">{{ $t('btn.save') }}</button>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="text-right">
                                        <span class="pagination__items__label mr-4"> {{ configPage.total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                                        <span>表示件数</span>
                                        <select v-model="configPage.limit" class="pagination__items__select ml-2" @change="onChange">
                                            <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div v-if="listDataTable.length > 0 && !isLoading" class="list-landingpage mt-5">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-xl-3" v-for="(itemLP, idx) in listDataTable" :key="`lp-${idx}`">
                                            <Transition name="slide-fade__table--list" appear :key="idx">
                                                <div class="card box-lp">
                                                    <div style="height: 25rem" class="mb-3 position-relative">
                                                        <img
                                                            style="cursor: pointer"
                                                            class="position-absolute w-100 h-100 object-fit-cover p-3"
                                                            src="https://www.optimizepress.com/wp-content/uploads/2022/09/ecommerce-landing-page.png"
                                                            alt="Card image cap"
                                                            @click="handleGoTo('/landing-page/view', itemLP)"
                                                        />
                                                    </div>
                                                    <div class="card-body">
                                                        <h5 class="text-truncate">{{ itemLP?.name ?? '' }}</h5>
                                                        <div style="height: 5rem" class="card-text pb-5">
                                                            <h6 class="card-title text-truncate">{{ itemLP?.campaign?.name ?? '' }}</h6>
                                                            <p v-if="itemLP?.lp_start_date && itemLP?.lp_end_date">
                                                                {{ formatDateCommon(itemLP?.lp_start_date, 'dateTimeJP') ?? '' }} -
                                                                {{ formatDateCommon(itemLP?.lp_end_date, 'dateTimeJP') ?? '' }}
                                                            </p>
                                                        </div>
                                                        <div class="row" style="gap: 15px">
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="btn btn-primary text-nowrap"
                                                                @click="handleGoTo('/landing-page/view', itemLP)"
                                                                >{{ $t('btn.detail') }}</a
                                                            >
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="btn btn-warning text-nowrap"
                                                                @click="handleGoTo('/landing-page/edit', itemLP)"
                                                                >{{ $t('btn.edit') }}</a
                                                            >
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="btn btn-info text-nowrap"
                                                                @click="handleCopy('/documents', itemLP)"
                                                                >{{ $t('btn.copy') }}</a
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="isLoading">
                                    <div class="d-flex justify-content-center">
                                        <LoadingIcon />
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="d-flex justify-content-center">
                                        {{ $t('notificationCommon.searchEmpty') }}
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                v-if="configPage.total && listDataTable.length > 0 && !isLoading"
                                :total="configPage.total"
                                :page.sync="configPage.page"
                                :per-page="configPage.limit"
                                @changePage="handleChangePage"
                            ></Pagination>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        <Footer />
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Pagination from '@/components/Pagination/main.vue';

import { configs, STATUS_FORM, initFormSearch, STATUS_SETTING_BASIC } from './constants';
import { InputText } from '@/components/Input';
import { masterMethods, landingPageMethods } from '@/state/helpers';
import { formatDateCommon } from '@/utils/format';

export default {
    name: 'LandingPage',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        Multiselect,
        DateNormal,
        InputText,
        KeyWordTag,
        LoadingIcon,
        Pagination
    },
    data() {
        return {
            configForm: configs,
            configPage: configs.configPage,
            statusForm: STATUS_FORM,
            formSearch: initFormSearch(),
            listDataTable: [],
            listDataCampaign: [],
            listDataNameLandingPage: [],
            perPageList: [10, 30, 50],
            configKeyword: {
                arrayTag: [],
                placeholder: this.$t('landingPage.list.placeholderSearch'),
                isShowLable: true
            },
            bookmarkName: '',
            isLoading: false,
            checkShowFilter: true
        };
    },
    computed: {
        from: function () {
            return (this.configPage.page - 1) * this.configPage.limit + 1;
        },
        to: function () {
            let to = this.configPage.page * this.configPage.limit;
            return to > this.configPage.total ? this.configPage.total : to;
        }
    },
    methods: {
        ...masterMethods,
        ...landingPageMethods,

        async handleGetMasterCampaign() {
            try {
                const dataRes = await this.listMaster('all/campaigns');
                this.listDataCampaign = dataRes ?? [];
            } catch (error) {
                console.log('error', error);
            }
        },

        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];
            let copyForm = Object.assign({}, this.formSearch);
            if (this.checkShowFilter) {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            } else {
                if (copyForm) {
                    if (copyForm.campaign_name !== null) copyForm.campaign_name = copyForm.campaign_name?.id;
                    for (const [key, value] of Object.entries(copyForm)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            }
            return query.join('&');
        },

        async getList() {
            let query = this.getQueryString();
            this.isLoading = true;
            try {
                const data = await this.getListLandingPage(query);
                this.listDataTable = data.data;
                this.configPage.total = data._metadata.total;
                this.configPage.limit = data._metadata.limit * 1;
                if (!this.listDataTable.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                console.log('error', error);
            }
        },

        addBookMark() {},
        clear() {
            this.configKeyword.arrayTag = [];
            this.formSearch = initFormSearch();
            this.getList();
        },
        search() {
            this.getList();
        },

        handleGoTo(url, row) {
            this.$router.push({ path: url, query: { id: row.id } });
        },
        handleCopy() {},
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            this.configKeyword.arrayTag = [];
            this.formSearch = initFormSearch();
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        handleStatus(status) {
            if (!status) return '';
            return STATUS_SETTING_BASIC.find((s) => s.id == status)?.value ?? '';
        },

        onChange() {
            this.configPage.page = 1;
            this.getList();
        },

        handleChangePage() {
            this.getList();
        }
    },
    mounted() {
        this.getList();
        this.handleGetMasterCampaign();
    }
};
</script>

<style lang="scss" scoped>
.list-landingpage {
    .card {
        &.box-lp {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            transition: all 0.35s;
            &:hover {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 10px;
            }
        }
    }
}
</style>
