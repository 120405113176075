<template>
    <div :index="index">
        <!-- {{ inforBlock }} -->
        <div v-if="inforBlock.block_type == 'div_1'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-12 fm-question text-${inforBlock?.choice_placement_specification}`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'div_2'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock?.block_div_2?.label_q)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div
                :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer text-${inforBlock?.choice_placement_specification}`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`"
            >
                <div v-html="textLineBreaks(inforBlock?.block_div_2?.label_a)"></div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'input'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div
                :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer text-${inforBlock?.choice_placement_specification}`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`"
            >
                <div v-if="defineShowTypeBlock">
                    <InputText
                        :id="`InputText-Form-${indexInputText}-${index}-${inforBlock.id}`"
                        :model.sync="configBlock.modelInput[inforBlock.id]"
                        :config.sync="configBlock.validateInput[inforBlock.id].configField"
                        @blur="handleValidateField(configBlock.validateInput[inforBlock.id].configField)"
                        :disabled="false"
                    />
                    <div v-if="configBlock.validateInput[inforBlock.id].configField.error" class="d-block invalid-feedback">
                        {{ configBlock.validateInput[inforBlock.id].configField.errorField }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'textarea'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer`" :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`">
                <div v-if="defineShowTypeBlock">
                    <TextareaComponent
                        :model="``"
                        :cols="``"
                        :rows="`5`"
                        :config.sync="configBlock.validateTextarea[inforBlock.id].configField"
                        @blur="handleValidateField(configBlock.validateTextarea[inforBlock.id].configField)"
                    >
                        <div v-if="configBlock.validateTextarea[inforBlock.id].configField.error" class="d-block invalid-feedback">
                            <span>
                                {{ configBlock.validateTextarea[inforBlock.id].configField.errorCustomMess }}
                            </span>
                        </div>
                    </TextareaComponent>
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'date'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer`" :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`">
                <div v-if="defineShowTypeBlock">
                    <DateNormal
                        :id="`DateForm-${inforBlock.id}`"
                        :full_date="``"
                        :config.sync="configBlock.validateDateForm[inforBlock.id].configField"
                        @blur="handleValidateField(configBlock.validateDateForm[inforBlock.id].configField)"
                    >
                        <div v-if="configBlock.validateDateForm[inforBlock.id].configField.error" class="invalid-feedback d-block">
                            <span>{{ configBlock.validateDateForm[inforBlock.id].configField.errorText }}</span>
                        </div>
                    </DateNormal>
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'phone'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer`" :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`">
                <div v-if="defineShowTypeBlock">
                    <div class="d-flex">
                        <InputText
                            :id="`InputPhone-1-Form-${inforBlock.id}`"
                            :model="``"
                            style="min-width: 50px"
                            :config.sync="configBlock.validateInputPhone_1[inforBlock.id].configField"
                            @blur="handleValidateFieldPhone(configBlock)"
                            :disabled="false"
                        />
                        <div class="p-1" style="font-size: 20px">-</div>
                        <InputText
                            :id="`InputPhone-2-Form-${inforBlock.id}`"
                            :model="``"
                            style="min-width: 50px"
                            :config.sync="configBlock.validateInputPhone_2[inforBlock.id].configField"
                            @blur="handleValidateFieldPhone(configBlock)"
                            :disabled="false"
                        />
                        <div class="p-1" style="font-size: 20px">-</div>
                        <InputText
                            :id="`InputPhone-3-Form-${inforBlock.id}`"
                            :model="``"
                            style="min-width: 50px"
                            :config.sync="configBlock.validateInputPhone_3[inforBlock.id].configField"
                            @blur="handleValidateFieldPhone(configBlock)"
                            :disabled="false"
                        />
                    </div>
                    <div class="invalid-feedback d-block">
                        {{ configBlock.validateInputPhone_1[inforBlock.id].configField.errorText }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'fullname'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div
                :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer text-${inforBlock?.choice_placement_specification}`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`"
            >
                <div v-if="defineShowTypeBlock">
                    <div class="d-flex">
                        <div class="form-group row">
                            <label class="col-sm-1 text-nowrap mt-2" style="min-width: 30px">{{ $t('managerForm.settingPage.firstName') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :id="`User-First_Name-Form-${inforBlock.id}`"
                                    :model="``"
                                    :config.sync="configBlock.validateFirstName[inforBlock.id].configField"
                                    @blur="handleValidateField(configBlock.validateFirstName[inforBlock.id].configField)"
                                    :disabled="false"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-1 text-nowrap mt-2" style="min-width: 30px">{{ $t('managerForm.settingPage.lastName') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :id="`User-Last_Name-Form-${inforBlock.id}`"
                                    :model="``"
                                    :config.sync="configBlock.validateLastName[inforBlock.id].configField"
                                    @blur="handleValidateField(configBlock.validateLastName[inforBlock.id].configField)"
                                    :disabled="false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'postcode'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div
                :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer text-${inforBlock?.choice_placement_specification}`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`"
            >
                <div v-if="defineShowTypeBlock">
                    <div class="d-flex">
                        <InputText
                            :id="`PostCode-1-Form-${inforBlock.id}`"
                            :model="``"
                            :config.sync="configBlock.validatePostcode_1[inforBlock.id].configField"
                            @blur="handleValidateFieldPostcode(configBlock)"
                            :disabled="false"
                        />
                        <div class="p-1" style="font-size: 20px">-</div>
                        <InputText
                            :id="`PostCode-2-Form-${inforBlock.id}`"
                            :model="``"
                            :config.sync="configBlock.validatePostcode_2[inforBlock.id].configField"
                            @blur="handleValidateFieldPostcode(configBlock)"
                            :disabled="false"
                        />
                        <div class="ml-3">
                            <button class="btn btn-light text-nowrap">
                                {{ $t('managerForm.settingPage.btnAddrSearch') }}
                            </button>
                        </div>
                    </div>
                    <div class="invalid-feedback d-block">
                        {{ configBlock.validatePostcode_1[inforBlock.id].configField.errorText }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'keyword-box'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div
                :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer text-${inforBlock?.choice_placement_specification}`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`"
            >
                <div v-if="defineShowTypeBlock">
                    <InputText
                        :id="`KeywordBox-Form-${inforBlock.id}`"
                        :model="``"
                        :config.sync="configBlock.validateKeywordBox[inforBlock.id].configField"
                        @blur="handleValidateField(configBlock.validateKeywordBox[inforBlock.id].configField)"
                        :disabled="false"
                    />
                    <div
                        v-if="
                            configBlock.validateKeywordBox[inforBlock.id].configField.error &&
                            configBlock.validateKeywordBox[inforBlock.id].configField.errorText
                        "
                        class="invalid-feedback d-block"
                    >
                        {{ configBlock.validateKeywordBox[inforBlock.id].configField.errorText }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { renderLineBreaks } from '@/utils/format';
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { validateFieldPhone, validatePostcode, validateKeywordBox, matchingMessageBlocks } from '../handlers';

import TextareaComponent from '@/components/Textarea/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';

export default {
    name: 'CommonBlockText',
    components: {
        InputText,
        TextareaComponent,
        DateNormal
    },
    props: {
        index: {
            type: Number,
            default: () => 0
        },
        inforBlock: {
            type: Object,
            default: () => null
        },
        configBlock: {
            type: Object,
            default: () => null
        },
        conditionPageShow: {
            type: String,
            default: () => 'input'
        }
    },
    computed: {
        formGroupLayout() {
            return this.inforBlock.layout == 'default' ? 'row' : this.inforBlock.layout;
        },
        labelLayout() {
            return ['row', 'default'].includes(this.inforBlock.layout);
        },

        defineShowTypeBlock() {
            return this.conditionPageShow == 'page_inputs';
        }
    },
    data() {
        return {
            indexInputText: 0
        };
    },
    methods: {
        textLineBreaks(text) {
            return renderLineBreaks(text);
        },

        handleValidateField(configField) {
            const blockType = this.inforBlock?.block_type ?? '';
            validateField(configField);
            if (blockType == 'keyword-box') {
                validateKeywordBox(this.configBlock, this.inforBlock);
            }
            if (blockType == 'input') {
                this.indexInputText++;
                this.indexInputText = this.indexInputText >= 100 ? 0 : this.indexInputText;
                matchingMessageBlocks(this.configBlock, [], this.inforBlock);
            }
        },

        handleValidateFieldPhone(configField) {
            validateFieldPhone(configField, this.inforBlock);
        },

        handleValidateFieldPostcode(configField) {
            validatePostcode(configField, this.inforBlock);
        }
    }
};
</script>

<style lang="scss">
.fm-answer,
.fm-question {
    & img {
        max-width: 100%;
        height: auto !important;
    }
}
</style>
