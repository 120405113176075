<template>
    <div>
        <Layout>
            <div v-if="!isLoading" class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-body">
                        <b-tabs
                            v-model="modelTab"
                            vertical
                            justified
                            nav-class="nav-tabs-custom"
                            content-class="p-3 text-muted scroll--content__landingpage"
                        >
                            <b-tab v-for="(tab, index) in TABS" :key="index" class="border-0" :disabled="tab.disabled">
                                <template v-slot:title>
                                    <span class="">{{ tab.name }}</span>
                                </template>
                                <div>
                                    <SettingBasicLP v-if="tab.id == 1" :dataForm="formData" :configForm="configFormBasicLP" />
                                    <SettingFirstView v-else-if="tab.id == 2" :dataForm="formData" />
                                    <SettingPrize v-else-if="tab.id == 3" :dataForm="formDataEventPrizes" :masterTypes="masterTypeTitlePrize" />
                                    <SettingTargetProduct
                                        v-else-if="tab.id == 4"
                                        :dataForm="formDataTargetProduct"
                                        :configForm="configFormTargetProduct"
                                    />
                                    <SettingDesign v-else-if="tab.id == 7" :dataForm="formData" />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <Footer>
                    <div class="d-flex justify-content-center">
                        <div class="text-center flex-fill">
                            <button type="submit" @click="handleCancel" class="btn btn-light mr-3">{{ $t('btn.cancel') }}</button>
                            <button type="submit" @click="handleSubmit" class="btn btn-primary">{{ $t('btn.register') }}</button>
                        </div>
                    </div>
                </Footer>
            </div>
            <div class="d-flex justify-content-center" v-else>
                <LoadingIcon />
            </div>
        </Layout>
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import SettingBasicLP from '../components/setting-basic/main.vue';
import SettingFirstView from '../components/setting-first_view/main.vue';
import SettingPrize from '../components/setting-prize/main.vue';
import SettingTargetProduct from '../components/setting-target_product/main.vue';
import SettingDesign from '../components/setting-design/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

import { initFormEventPrizes, initFormTargetProduct } from './index';
import { TABS, initFormRegister } from '../constants';
import { validateAllField } from '@/utils/validate';
import { configFormBasicLP } from '../components/setting-basic/constants';
import { configFormTargetProduct } from '../components/setting-target_product/constants';
import { masterMethods, landingPageMethods } from '@/state/helpers';
import { handleDataBeforeSaveLP, validateEndDate } from '../handlers';
import { showMessage } from '@/utils/messages';

export default {
    name: 'CreateLandingPage',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        SettingBasicLP,
        SettingFirstView,
        SettingPrize,
        SettingTargetProduct,
        SettingDesign,
        LoadingIcon
    },
    data() {
        return {
            TABS: TABS.map((tab) => {
                if (tab.id == 1) {
                    tab.disabled = false;
                } else {
                    tab.disabled = true;
                }
                return tab;
            }),
            formData: initFormRegister(),
            formDataEventPrizes: initFormEventPrizes(),
            formDataTargetProduct: initFormTargetProduct(),
            configFormBasicLP: configFormBasicLP(),
            configFormTargetProduct: configFormTargetProduct(),
            modelTab: 0,
            settingBasicTab: 0,
            masterTypeTitlePrize: [],
            isLoading: false
        };
    },
    methods: {
        ...masterMethods,
        ...landingPageMethods,

        handleCancel() {
            this.$router.push({
                path: '/landing-page/list'
            });
        },

        async handleSubmit() {
            let checkBasicLP = validateAllField(this.configFormBasicLP);
            let checkEndDateCP = validateEndDate(
                this.configFormBasicLP['configFieldCampaignStartDateTime'],
                this.configFormBasicLP['configFieldCampaignEndDateTime']
            );
            let checkEndDateLP = validateEndDate(
                this.configFormBasicLP['configFieldLPStartDateTime'],
                this.configFormBasicLP['configFieldLPEndDateTime']
            );
            // let checkFirstView = validateAllField(this.configFormFirstView);
            // let checkTargetProduct = validateAllField(this.configFormTargetProduct);
            // console.log('checkBasicLP', checkBasicLP, checkFirstView, checkTargetProduct, this.formData);
            if (!checkBasicLP || !checkEndDateCP || !checkEndDateLP) return;
            let payload = handleDataBeforeSaveLP(this.formData);
            this.isLoading = true;
            try {
                const data = await this.registerLandingPage(payload);
                if (data.code !== 200) {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                    return;
                }
                this.$router.push({ path: '/landing-page/list' }).then(() => {
                    showMessage(data.code, this.$bvToast, 'Success');
                });
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
            console.log('payload', checkBasicLP, payload);
        },

        async getListMaterCommon() {
            try {
                const dataRes = await this.listMaster('campaign/common-campaign-data');
                this.masterTypeTitlePrize = dataRes['event_prize.type'];
            } catch (error) {
                console.log('error', error);
            }
        }
    },
    mounted() {
        this.getListMaterCommon();
    }
};
</script>

<style lang="scss">
.scroll--content__landingpage {
    height: 74vh;
    overflow-y: auto;
}
</style>
