import { masterService } from '@/api/services/master.service';

export const getters = {
    dataList(state) {
        return state.dataList;
    }
};

export const actions = {
    registerHoliday({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return masterService.registerHoliday(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerHolidayYear({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return masterService.registerHolidayYear(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listHoliday({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return masterService.listHoliday(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    oneHolidayYear({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return masterService.oneHolidayYear(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listHolidayYear({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return masterService.listHolidayYear(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerMaster({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return masterService.register(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerPermission({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return masterService.registerPermission(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateMaster({ dispatch, commit }, data) {
        commit('updateRequest', data);
        masterService.update(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateHoliday({ dispatch, commit }, data) {
        commit('updateRequest', data);
        masterService.updateHoliday(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateHolidayYear({ dispatch, commit }, data) {
        commit('updateRequest', data);
        masterService.updateHolidayYear(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listMaster({ dispatch, commit }, data) {
        commit('listRequest', []);
        return masterService.list(data).then(
            (data) => {
                commit('listSuccess', data.data);

                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listPermission({ dispatch, commit }, data) {
        commit('listRequest', []);
        return masterService.listPermission(data).then(
            (data) => {
                commit('listSuccess', data.data);

                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    dataPermission({ dispatch, commit }, data) {
        commit('listRequest', []);
        return masterService.getPermission(data).then(
            (data) => {
                commit('listSuccess', data.data);

                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteMaster({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.deleteObj(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteMasterHoliday({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.deleteMasterHoliday(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteMasterHolidayYear({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.deleteMasterHolidayYear(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    findCompany({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.findCompany(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    histories({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.histories(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    templateList({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.templateList(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    templateGetDetail({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.templateGetDetail(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    templateUpdate({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.templateUpdate(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    templateCreate({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.templateCreate(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    templateGetDraf({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.templateGetDraf(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    saveDraf({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.saveDraf(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    removeDraf({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.removeDraf(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    templateDelete({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.templateDelete(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    documentPreview({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.documentPreview(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listRule({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.listRule(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listMasterRule1({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.listMasterRule1(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    createRule2({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.rule2Create(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerMasterRule({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.registerMasterRule1(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateRule2({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.rule2Update(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateMasterRule({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.updateMasterRule(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateMasterRule1({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.updateMasterRule1(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteRule2({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.rule2Delete(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteMasterRule1({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.deleteMasterRule1(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteMasterRule2({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.deleteMasterRule2(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    createMasterRule1({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.createMasterRule1(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getAllMasterRule({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.getAllMasterRule(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailMasterRule1({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.detailMasterRule1(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateMasterRuleDetail1({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.updateMasterRuleDetail1(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDefaultRule({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.getDefaultRule(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    createMasterRuleDetail1({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.createMasterRuleDetail1(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getAllDocuments({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.getAllDocuments(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDetailDocuments({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.getDetailDocuments(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getListScheduleFile({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.getListScheduleFile(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDetailScheduleFile({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.getDetailScheduleFile(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    createScheduleFile({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.createScheduleFile(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateScheduleFile({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.updateScheduleFile(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteScheduleFile({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.deleteScheduleFile(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDocumentFAQ({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.getDocumentFAQ(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateDocumentFAQ({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.updateDocumentFAQ(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteDocumentFAQ({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.deleteDocumentFAQ(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    postDocumentFAQ({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.postDocumentFAQ(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    postDocumentFAQPreview({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.postDocumentFAQPreview(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    postDocumentFMT({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.postDocumentFMT(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    partnerRequestSheet({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.partnerRequestSheet(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getPartnerRequestSheet({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.getPartnerRequestSheet(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updatePartnerRequestSheet({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.updatePartnerRequestSheet(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    setDefaultCampaign({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return masterService.setDefaultCampaign(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getMasterOrderScope({ dispatch, commit }) {
        commit('deleteRequest');
        return masterService.getMasterOrderScope().then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
