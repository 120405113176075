<template>
    <div>
        <div class="col-lg-12">
            <Draggable :list="listBlocks" handle=".handle" ghost-class="ghost--eventPrize" @start="dragList = true" @end="dragList = false">
                <div v-for="(blockItem, idx) in listBlocks" :key="idx" class="mb-3">
                    <div class="row">
                        <div class="" style="cursor: pointer">
                            <i class="fa fa-align-justify handle"></i>
                        </div>
                        <div class="col-sm-5">
                            <h5 v-b-tooltip.hover :title="blockItem.name" class="col-sm-12 text-truncate">{{ blockItem.name }}</h5>
                        </div>
                        <div class="col-sm-6">
                            <InputCheckBox
                                v-if="blockItem?.block_default"
                                :model.sync="blockItem.is_visibled"
                                :id="`checks${idx}`"
                                :value="blockItem.is_visibled"
                                :label="``"
                                class="text-right"
                            />
                            <div v-else class="text-right">
                                <button
                                    type="button"
                                    class="btn btn-outline-danger btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                    @click="handleDeleteBlock(idx)"
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
            <div class="card">
                <div class="text-center mb-5">
                    <button type="button" class="btn btn-outline-success btn-sm rounded" @click="handleAddBlocks()">
                        <i class="fa fa-plus"></i>
                        Add block
                    </button>
                </div>
            </div>
            <ModalActionBlock v-if="configModalActionBlock.model" :config="configModalActionBlock" @evtAdd="onHandleEvtAddBlock" />
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import ModalActionBlock from './components/modal-action-block.vue';

import { configModalActionBlock } from './constants';
import { InputCheckBox } from '@/components/Input';

export default {
    name: 'SettingBlockLP',
    components: { Draggable, ModalActionBlock, InputCheckBox },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            listBlocks: this.dataForm.blocks_settings,
            configModalActionBlock: configModalActionBlock
        };
    },
    methods: {
        handleAddBlocks() {
            this.configModalActionBlock.title = 'Add Block';
            this.configModalActionBlock.model = true;
        },

        onHandleEvtAddBlock(dataEvt) {
            let { title, category } = dataEvt;
            this.listBlocks.push({
                order_index: this.listBlocks.length,
                name: title,
                category: category,
                is_visibled: true,
                block_default: false
            });
        },

        handleDeleteBlock(idx) {
            this.listBlocks.splice(idx, 1);
        }
    }
};
</script>

<style lang="scss"></style>
