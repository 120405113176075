<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

import { campaignStatus } from '@/config/var-common';
import { masterComputed, masterMethods, partnersMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { showMessage } from '@/utils/messages';

export default {
    page: {
        title: '外部パートナー担当者詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer
    },
    data() {
        return {
            loading: false,
            partner_id: this.$route.query.partner_id,
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                password: '',
                department: '',
                note: '',
                chatwork_id: '',
                account_id: null,
                chatwork_token: ''
            },
            nameCompany: ''
        };
    },
    computed: {
        ...masterComputed
    },

    mounted() {
        if (this.$route.query.id && this.$route.query.partner_id) {
            let data = {
                partner_id: this.$route.query.partner_id,
                id: this.$route.query.id
            };
            this.getDetail(data);
        }
    },
    methods: {
        ...masterMethods,
        ...partnersMethods,
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getDetail(id) {
            this.loading = true;
            this.detailPartnersSupport(id).then((data) => {
                this.form = data;
                this.loading = false;
            });
            this.detailPartners(id.partner_id).then((data) => {
                this.nameCompany = data.name;
            });
        },

        goToEdit(path, id, partner_id) {
            this.$router.push({
                path: path,
                query: { id: id, partner_id: partner_id }
            });
        },
        renderSttText(numberStt) {
            const result = campaignStatus.find((item) => item.id === numberStt);
            return result ? result.text : '';
        },
        deleteObject(data) {
            Swal.fire({
                title: 'この外部パートナー担当者を削除してもよろしいですか。',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deletePartnersSupport(data).then((res) => {
                        if (res.code == 200) {
                            this.$router
                                .push({
                                    path: `/partner/view?id=${this.$route.query.partner_id}`
                                })
                                .then(() => {
                                    showMessage(res.code, this.$bvToast, '外部パートナー担当者が削除されました。');
                                });
                        } else {
                            showMessage(res.code, this.$bvToast, this.$t(res.message));
                        }
                    });
                }
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading" class="row form__body--card form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom01"> {{ $t('pagePartner.nameCompany') }}</label>
                                        <div class="col-sm-9">{{ nameCompany }}</div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom01"> {{ $t('pagePartner.department') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.partner_pic_detail?.department }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom02">{{ $t('pagePartner.manager') }}</label>
                                        <div class="col-sm-9">{{ form.name }}</div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom02">{{ $t('pagePartner.personCharge') }}</label>
                                        <div class="col-sm-9">{{ form.kana_name }}</div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom03">{{ $t('pagePartner.emailPartner') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.email }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="tokenUser">{{ $t('pageEmployee.tokenUser') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.chatwork_token }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="accountId">{{ $t('pageEmployee.accountId') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.account_id }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="chatworkId">{{ $t('pageEmployee.chatworkId') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.chatwork_id }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom03">{{ $t('pagePartner.telephoneNumber') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.phone_number }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom04">{{ $t('pagePartner.cellphoneNumber') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.mobile_number }}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: `/partner/view?id=${$route.query.partner_id}`
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>

                        <button
                            v-if="checkPermiss('partner.register') || checkPermiss('partner.edit')"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/partner/form/support', $route.query.id, $route.query.partner_id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss('partner.delete')"
                            type="button"
                            class="btn btn-danger ml-3"
                            @click="
                                deleteObject({
                                    id: $route.query.id,
                                    partner_id: $route.query.partner_id
                                })
                            "
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
