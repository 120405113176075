<template>
    <div class="custom-control custom-checkbox">
        <div>
            <input
                :id="`check-${id}`"
                v-model="modelCheck"
                :type="configInit?.type ?? 'checkbox'"
                class="custom-control-input"
                :class="{
                    [configInit.classCustom]: configInit.classCustom,
                    disabled_cursor: disabled
                }"
                :name="name ?? ''"
                :value="value ?? 'on'"
                :disabled="disabled"
                @change="handleChangeCheckBox"
            />
            <label :for="`check-${id}`" :class="`${disabled ? 'disabled_cursor' : ''} custom-control-label`">{{ label }}</label>
        </div>
        <div v-if="configInit.error" class="invalid-feedback d-block">
            <span v-if="configInit.isRequired">{{
                $t('validateField.required', {
                    field: configInit.errorField
                })
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputCheckBox',
    props: {
        id: {
            type: String,
            default: 'input-checkbox'
        },
        name: {
            type: String,
            default: ''
        },
        value: {
            required: true,
            default: ''
        },
        label: {
            required: true,
            type: String,
            default: ''
        },
        model: {
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        config: {
            type: Object,
            default() {
                return {
                    classCustom: '',
                    type: 'checkbox',
                    isRequired: false,
                    error: false,
                    errorField: '',
                    valueSync: []
                };
            }
        }
    },
    data() {
        return {
            modelCheck: [],
            configInit: {
                classCustom: '',
                type: 'checkbox',
                isRequired: false,
                error: false,
                errorField: '',
                valueSync: []
            }
        };
    },
    watch: {
        '$props.model': {
            handler: function (newValue) {
                this.modelCheck = newValue;
            },
            immediate: true,
            deep: true
        },

        '$props.config': {
            handler: function (newConfig) {
                this.configInit = {
                    ...this.configInit,
                    ...newConfig
                };
            },
            immediate: true
        },

        modelCheck: {
            handler: function (newModelCheck) {
                this.configInit.error = false;
                this.configInit.valueSync = newModelCheck;

                this.$emit('update:model', newModelCheck);
            },
            immediate: true,
            deep: true
        },

        configInit: {
            handler: function () {
                this.$emit('update:config', this.configInit);
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        handleChangeCheckBox() {
            this.configInit.error = false;
            this.configInit.valueSync = this.modelCheck;

            this.$emit('update:model', this.modelCheck);
            this.$emit('change', this.modelCheck);
        }
    }
};
</script>

<style lang="scss" scoped></style>
