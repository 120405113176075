import { settingPhoneService } from '@/api/services/setting-phone.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    registerPhone({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return settingPhoneService.registerPhone(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updatePhone({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return settingPhoneService.updatePhone(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateStepWorkflow({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return settingPhoneService.updateStepWorkflow(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailPhone({ dispatch, commit }, data) {
        commit('listRequest', []);
        return settingPhoneService.detailPhone(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getListUserInRoom({ dispatch, commit }, data) {
        commit('listRequest', []);
        return settingPhoneService.getListUserInRoom(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getInfoChatWork({ dispatch, commit }, data) {
        commit('listRequest', []);
        return settingPhoneService.getInfoChatWork(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getTemplateConfig({ dispatch, commit }, data) {
        commit('listRequest', []);
        return settingPhoneService.getTemplateConfig(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    }
};
