import { authHeader, handleResponse } from '@/api/handler/main';

export const employeeService = {
    registerEmployeePartTime,
    registerEmployee,
    listEmployeePartTime,
    listEmployee,
    detailEmployeePartTime,
    detailEmployee,
    deleteEmployee,
    deleteEmployeePartTime,
    updateEmployeePartTime,
    updateEmployee
};

function registerEmployeePartTime(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees`, requestOptions).then(handleResponse);
}

function detailEmployeePartTime(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees/` + data, requestOptions).then(handleResponse);
}

function detailEmployee(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees/` + data, requestOptions).then(handleResponse);
}

function updateEmployeePartTime(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees/` + data.id, requestOptions).then(handleResponse);
}

function listEmployeePartTime(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees?` + query, requestOptions).then(handleResponse);
}

function listEmployee(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees?` + query, requestOptions).then(handleResponse);
}

function deleteEmployee(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees/` + data.id, requestOptions).then(handleResponse);
}

function deleteEmployeePartTime(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees/` + data.id, requestOptions).then(handleResponse);
}

function registerEmployee(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees`, requestOptions).then(handleResponse);
}

function updateEmployee(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees/` + data.id, requestOptions).then(handleResponse);
}
