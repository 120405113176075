<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.nameCampaign') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.campaign?.name }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.nameClient') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.client?.name }}
                    </div>
                </div>

                <!-- フォーム名 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.nameForm') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.name }}
                    </div>
                </div>

                <!-- chọn kiểu -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.pageType') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.page_type?.name }}
                    </div>
                </div>

                <!-- ステータス -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.status') }} </label>
                    <div class="col-sm-9">
                        {{ handleMapOptionValue(dataForm.status) }}
                    </div>
                </div>
                <!-- 公開パス -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.publicPath') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.url_public }}
                    </div>
                </div>
                <!-- URLパラメータ -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.urlParam') }}</label>
                    <div class="col-sm-9">
                        {{ handleJoinParameter(dataForm.url_parameters) }}
                    </div>
                </div>
                <!-- 指定応募数 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.limitPersonApplication') }} </label>
                    <div class="col-sm-9">
                        <!-- 制限件数 -->
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.limitNumber') }} </label>
                            <div class="col-sm-9">
                                <div class="d-flex">
                                    {{ dataForm.application_limit }}
                                    <span class="ml-2">件</span>
                                </div>
                            </div>
                        </div>
                        <!-- 超過時リダイレクトURL -->
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 180px">{{ $t('managerForm.settingBasic.redirectURL') }} </label>
                            <div class="col-sm-9">
                                {{ dataForm.url_redirect_when_limit }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 公開予約中表示URL -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.urlPublicReservation') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.reservation_display_url }}
                    </div>
                </div>
                <!-- 公開終了後表示URL -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.urlAfterPublicationEnd') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm.expired_display_url }}
                    </div>
                </div>
                <!-- 送信先メールアドレス -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.sendMailed') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.mail_to }}
                    </div>
                </div>
                <!-- フォームの一時保存 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.saveTemp') }} </label>
                    <div class="col-sm-9">
                        {{ handleMapOptionValue(dataForm.save_form_temporarily, TEMPORARILY) }}
                    </div>
                </div>
                <!-- ソーシャルログイン設置 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 180px">{{ $t('managerForm.settingBasic.settingLoginSocial') }} </label>
                    <div class="col-sm-9">
                        <!--  -->
                        <!-- social.value -->
                    </div>
                </div>
                <!-- マイページ機能設置 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.settingMypage') }} </label>
                    <div class="col-sm-9">
                        <InputRadio :model.sync="dataForm.setting_my_page" :id="`setting_my_page`" :value="true" :label="``" :disabled="true" />
                    </div>
                </div>
                <!-- TAG -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.tag') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm?.hashtag?.join('、') }}
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { InputRadio } from '@/components/Input';
import { TEMPORARILY, SOCIAL_MEDIA_LOGIN, STATUS_FORM } from '../../../constants';
import { handleStatus } from '../../../handlers';

export default {
    name: 'SettingBasic',
    components: {
        InputRadio
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            TEMPORARILY: TEMPORARILY,
            STATUS_FORM: STATUS_FORM,
            SOCIAL_MEDIA_LOGIN: this.dataForm?.setting_login_social?.length > 0 ? this.dataForm.setting_login_social : SOCIAL_MEDIA_LOGIN
        };
    },
    methods: {
        handleMapOptionValue(status, list) {
            return handleStatus(status, list);
        },

        handleJoinParameter(params) {
            if (!params || params.length <= 0) return '';
            return params.map((item) => item.url_parameter).join('、');
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
