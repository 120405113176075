<script>
import Layout from '@/views/layouts/main';
import Footer from '@/components/Footer/main.vue';
import appConfig from '@/app.config';
import i18n from '@/i18n';
import LoadingIcon from '@/components/Loading/main.vue';
import UploadFile from '@/components/Uploads/Files/main.vue';

import { campaignMethods, manualService } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { showMessage } from '@/utils/messages';
import { handleDownFile } from '@/utils/format';

export default {
    page: {
        title: i18n.t('manual.title'),
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        LoadingIcon,
        UploadFile,
        Footer
    },
    data() {
        return {
            isLoading: false,
            initForm: {
                company_name: '',
                main_office: '',
                url: '',
                date_created: '',
                configDateCreated: {
                    error: false,
                    errorDateCreated: ''
                },
                capital: '',
                business_content: '',
                center_office: '',
                chief_executive_officer: '',
                number_of_employees: '',
                organizational_structure: '',
                certification: '',
                technical_safety: '',
                organizational_security_management: '',
                physical_security_management: '',
                human_security_management: '',
                logo: {
                    id: null,
                    path: ''
                }
            },
            isUploadFile: false
        };
    },

    mounted() {
        if (!this.checkPermiss('master.register')) {
            this.$router.push({ path: '/' }).then(() => {
                showMessage(8080, this.$bvToast, 'アクセス権限がありません。');
            });
        }
        this.getDetail();
    },
    methods: {
        ...campaignMethods,
        ...manualService,

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        handleFileInput(file) {
            this.initForm.logo.id = file?.id ?? '';
            this.initForm.logo.origin_name = file?.origin_name ?? '';
            this.initForm.logo.path = '';
        },

        handleSubmit() {
            let dataObject = Object.assign({}, this.initForm);
            delete dataObject.configDateCreated;
            if (!dataObject.logo.id) {
                dataObject.logo = {
                    id: null
                };
            }

            dataObject.code = 1;
            this.isLoading = true;
            this.updateManual(dataObject)
                .then((res) => {
                    this.isLoading = false;
                    if (res.code !== 200) {
                        showMessage(res.code, this.$bvToast, res.message);
                        return;
                    }
                    showMessage(res.code, this.$bvToast, '登録されました。');
                    this.getDetail();
                })
                .catch((error) => {
                    console.log('error', error);
                    this.isLoading = false;
                });
        },

        getDetail() {
            this.isLoading = true;
            let code = 1;
            this.detailManual(code)
                .then((res) => {
                    this.initForm = {
                        ...this.initForm,
                        ...res
                    };
                    this.initForm.logo = {
                        id: res.logo?.id ?? null,
                        origin_name: res.logo?.origin_name ?? '',
                        path: res.logo?.path ?? ''
                    };
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log('error', error);
                    this.isLoading = false;
                });
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!isLoading">
            <h4 class="ml-2">{{ $t('manual.overview') }}</h4>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="handleSubmit" autocomplete="off">
                            <h5 class="mb-5">{{ $t('manual.titleForm1') }}</h5>
                            <div class="form-group row">
                                <label class="col-sm-2" for="nameCompany">{{ $t('manual.nameCompany') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.company_name" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="mainOffice">{{ $t('manual.mainOffice') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.main_office" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="url">{{ $t('manual.url') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.url" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="dateCreated">{{ $t('manual.dateCreated') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.date_created" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="capital">{{ $t('manual.capital') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.capital" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="businessContent">{{ $t('manual.businessContent') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.business_content" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="centerOffice">{{ $t('manual.centerOffice') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.center_office" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="chiefExecutiveOfficer">{{ $t('manual.chiefExecutiveOfficer') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.chief_executive_officer" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="numberOfEmployees">{{ $t('manual.numberOfEmployees') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.number_of_employees" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="organizationalStructure">{{ $t('manual.organizationalStructure') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.organizational_structure" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="certification">{{ $t('manual.certification') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.certification" class="form-control" />
                                </div>
                            </div>
                            <h5 class="my-5">{{ $t('manual.titleForm2') }}</h5>
                            <div class="form-group row">
                                <label class="col-sm-2" for="technicalSafety">{{ $t('manual.technicalSafety') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.technical_safety" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="organizationalSecurityManagement"
                                    >{{ $t('manual.organizationalSecurityManagement') }}
                                </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.organizational_security_management" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="physicalSecurityManagement">{{ $t('manual.physicalSecurityManagement') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.physical_security_management" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="humanSecurityManagement">{{ $t('manual.humanSecurityManagement') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.human_security_management" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="to">{{ $t('manual.logo') }} </label>
                                <div class="col-sm-10">
                                    <UploadFile
                                        @dataFile="handleFileInput"
                                        :title="$t('btn.uploadFile')"
                                        :id="$t('manual.logo')"
                                        :typeUpload="'image'"
                                        :processUpload.sync="isUploadFile"
                                    />
                                    <div v-if="initForm.logo.origin_name" class="d-flex align-items-center my-2">
                                        <a
                                            target="_blank"
                                            :href="initForm.logo.path"
                                            @click.prevent="downLoadFile(initForm.logo.path, initForm.logo.origin_name)"
                                            >{{ initForm.logo.origin_name }}</a
                                        >
                                        <span
                                            class="ml-2"
                                            style="cursor: pointer; font-size: 20px"
                                            @click="
                                                () => {
                                                    initForm.logo.origin_name = '';
                                                    initForm.logo.id = null;
                                                    initForm.logo.path = '';
                                                }
                                            "
                                            ><i class="ri-close-line" style="vertical-align: bottom"></i
                                        ></span>
                                    </div>

                                    <b-img
                                        v-if="initForm.logo.path"
                                        thumbnail
                                        fluid
                                        :src="initForm.logo.path"
                                        :alt="initForm.logo.origin_name"
                                        rounded
                                        style="width: 300px; height: 300px; object-fit: cover; object-position: center"
                                    ></b-img>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="text-center flex-fill">
                    <button type="submit" class="btn btn-primary" @click="handleSubmit" :disabled="isUploadFile">
                        {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                    </button>
                </div>
            </Footer>
        </div>

        <div v-else>
            <div class="d-flex justify-content-center">
                <LoadingIcon />
            </div>
        </div>
    </Layout>
</template>
<style></style>
