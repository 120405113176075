<template>
    <div class="mt-3">
        <div v-if="isSettingStep1">
            <div class="mb-3 pb-3" style="border-bottom: 1px solid #949494">
                <div>
                    <h5>
                        {{
                            $t('managerForm.settingPage.editBlock', {
                                field: config?.name
                            })
                        }}
                    </h5>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.nameBlock') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <InputText
                        :model.sync="formBlock.name"
                        :config.sync="configFormInput.configFieldNameBlock"
                        @blur="handleValidateField(configFormInput.configFieldNameBlock)"
                    />
                    <p v-if="config?.type == 'div_1' || config?.type == 'div_2'" class="my-1">{{ $t('managerForm.settingPage.noteBlockDiv') }}</p>
                    <p v-else class="my-1">{{ $t('managerForm.settingPage.noteNameBlock') }}</p>
                </div>
            </div>
            <div v-if="isActiveOrderRequired" class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.orderRequired') }} </label>
                <div class="col-sm-9">
                    <InputCheckBox
                        :model.sync="formBlock.order_required"
                        :id="`check-order_required`"
                        :value="formBlock.order_required"
                        :label="``"
                    />
                </div>
            </div>
            <div v-if="isActiveLabel" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ ['div_1'].includes(config.type) ? $t('managerForm.settingPage.label_Div_1') : $t('managerForm.settingPage.label') }}
                    <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <QuillEditor
                        :id="`managerForm_settingPage_label`"
                        :refQuill="`managerForm_settingPage_label`"
                        :model.sync="formBlock.label"
                        :config.sync="configFormInput.configFieldLabelBlock"
                        @blur="handleValidateField(configFormInput.configFieldLabelBlock)"
                    >
                        <div v-if="configFormInput.configFieldLabelBlock.error">
                            <span>
                                {{
                                    $t('validateField.required', {
                                        field: configFormInput.configFieldLabelBlock.errorField
                                    })
                                }}
                            </span>
                        </div>
                    </QuillEditor>
                    <!-- <Editor
                        :id="`managerForm_settingPage_label`"
                        :model.sync="formBlock.label"
                        :configError.sync="configFormInput.configFieldLabelBlock"
                        @change="handleValidateField(configFormInput.configFieldLabelBlock)"
                    /> -->
                </div>
            </div>
            <div v-if="isActiveSourceParam" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.sourceParam') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-3" style="gap: 12px">
                        <div v-for="(sourceParamItem, idx) in OPTION_SOURCE_PARAMS" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.block_parameter.source_param"
                                :name="`sourceParamItem`"
                                :id="`sourceParamItem-${sourceParamItem.id}`"
                                :value="sourceParamItem.id"
                                :label="sourceParamItem.value"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActiveParamName" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.paramName') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <InputText
                        :model.sync="formBlock.block_parameter.param_name"
                        :config.sync="configFormInput.configFieldParamName"
                        @blur="handleValidateField(configFormInput.configFieldParamName)"
                    />
                </div>
            </div>
            <div v-if="isActiveParamURL" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.errorRedirectDestination') }}
                    <p>{{ $t('managerForm.settingPage.paramURL') }}</p></label
                >
                <div class="col-sm-9">
                    <InputText
                        :model.sync="formBlock.block_parameter.param_url"
                        :config.sync="configFormInput.configFieldParamUrl"
                        @blur="handleValidateField(configFormInput.configFieldParamUrl)"
                    />
                </div>
            </div>
            <div v-if="isActiveMatchingCondition" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.matchingCondition') }} </label>
                <div class="col-sm-9">
                    <div v-for="(matchingConditionItem, idx) in LIST_MATCHING_CONDITION" :key="idx">
                        <InputCheckBox
                            class="mb-2"
                            :model.sync="formBlock.block_keyword_box.matching_condition"
                            :id="`matchingCondition-${idx}`"
                            :value="matchingConditionItem.id"
                            :label="matchingConditionItem.value"
                        />
                    </div>
                </div>
            </div>
            <div v-if="isActiveFieldMaskDisplay" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.inputFieldMaskDisplay') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-3" style="gap: 12px">
                        <div v-for="(fieldMaskDisplayItem, idx) in OPTION_YES_NO" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.block_keyword_box.field_mask_display"
                                :name="`fieldMaskDisplayItem`"
                                :id="`fieldMaskDisplayItem-${fieldMaskDisplayItem.id}`"
                                :value="fieldMaskDisplayItem.id"
                                :label="fieldMaskDisplayItem.value"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActiveKeywordUpload" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.keywordUpload') }} <span class="text-danger ml-2">*</span>
                </label>
                <div class="col-sm-9">
                    <div class="mb-4">
                        <UploadFileCommon @dataFile="handleFileKeywordUpload" title="ファイルアップロード" :id="`keywordUpload`" />
                    </div>
                    <div>
                        <TextareaComponent
                            :model.sync="formBlock.block_keyword_box.keyword_characters"
                            :rows="`5`"
                            :config.sync="configFormInput.configFieldKeywordUpload"
                            @blur="handleValidateField(configFormInput.configFieldKeywordUpload)"
                        >
                            <div v-if="configFormInput.configFieldKeywordUpload.error" class="d-block invalid-feedback">
                                <span>
                                    {{ configFormInput.configFieldKeywordUpload.errorCustomMess }}
                                </span>
                            </div>
                        </TextareaComponent>
                    </div>
                </div>
            </div>
            <div v-if="isActivePhonePlaceholder" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.placeholderCommon') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex">
                        <InputText
                            :model.sync="formBlock.block_phone.placeholder_1"
                            :config.sync="configFormInput.configFieldPhonePlaceholder_1"
                            @blur="handleValidateField(configFormInput.configFieldPhonePlaceholder_1)"
                        />
                        <span class="mt-2 mx-2">-</span>
                        <InputText
                            :model.sync="formBlock.block_phone.placeholder_2"
                            :config.sync="configFormInput.configFieldPhonePlaceholder_2"
                            @blur="handleValidateField(configFormInput.configFieldPhonePlaceholder_2)"
                        />
                        <span class="mt-2 mx-2">-</span>
                        <InputText
                            :model.sync="formBlock.block_phone.placeholder_3"
                            :config.sync="configFormInput.configFieldPhonePlaceholder_3"
                            @blur="handleValidateField(configFormInput.configFieldPhonePlaceholder_3)"
                        />
                    </div>
                </div>
            </div>
            <div v-if="isActiveDiv2Label">
                <div class="form-group row pt-3 border-top-1-color">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingPage.label1Div_2') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <!-- <Editor
                            :id="`managerForm_settingPage_label1Div_2`"
                            :model.sync="formBlock.block_div_2.label_q"
                            :configError.sync="configFormInput.configFieldLabel_Q_Block1"
                            @change="handleValidateField(configFormInput.configFieldLabel_Q_Block1)"
                        /> -->
                        <QuillEditor
                            :id="`managerForm_settingPage_label1Div_2`"
                            :refQuill="`managerForm_settingPage_label1Div_2`"
                            :model.sync="formBlock.block_div_2.label_q"
                            :config.sync="configFormInput.configFieldLabel_Q_Block1"
                            @blur="handleValidateField(configFormInput.configFieldLabel_Q_Block1)"
                        >
                            <div v-if="configFormInput.configFieldLabel_Q_Block1.error">
                                <span>
                                    {{
                                        $t('validateField.required', {
                                            field: configFormInput.configFieldLabel_Q_Block1.errorField
                                        })
                                    }}
                                </span>
                            </div>
                        </QuillEditor>
                    </div>
                </div>
                <div class="form-group row pt-3 border-top-1-color">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingPage.label2Div_2') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <!-- <Editor
                            :id="`managerForm_settingPage_label2Div_2`"
                            :model.sync="formBlock.block_div_2.label_a"
                            :configError.sync="configFormInput.configFieldLabel_A_Block2"
                            @change="handleValidateField(configFormInput.configFieldLabel_A_Block2)"
                        /> -->
                        <QuillEditor
                            :id="`managerForm_settingPage_label2Div_2`"
                            :refQuill="`managerForm_settingPage_label2Div_2`"
                            :model.sync="formBlock.block_div_2.label_a"
                            :config.sync="configFormInput.configFieldLabel_A_Block2"
                            @blur="handleValidateField(configFormInput.configFieldLabel_A_Block2)"
                        >
                            <div v-if="configFormInput.configFieldLabel_A_Block2.error">
                                <span>
                                    {{
                                        $t('validateField.required', {
                                            field: configFormInput.configFieldLabel_A_Block2.errorField
                                        })
                                    }}
                                </span>
                            </div>
                        </QuillEditor>
                    </div>
                </div>
            </div>

            <div v-if="isActiveFormatYear" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.inputFormat(year)') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-3" style="gap: 12px">
                        <div v-for="(itemDateYear, idx) in OPTION_FORMAT_DATE" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.block_date.format_year.type"
                                :name="`itemDateYear`"
                                :id="`itemDateYear-${itemDateYear.id}`"
                                :value="itemDateYear.id"
                                :label="itemDateYear.value"
                                @change="handleChangeTypeYear"
                            />
                        </div>
                    </div>
                    <div class="pt-3 border-top-1-color">
                        <div v-if="formBlock.block_date?.format_year?.type == 2">
                            <div class="d-flex flex-wrap mb-3" style="gap: 12px">
                                <div v-for="(itemTypeCalendar, idx) in OPTION_CALENDAR_WEST_TIME" :key="idx">
                                    <InputRadio
                                        class="mr-4"
                                        :model.sync="formBlock.block_date.format_year.type_calendar"
                                        :name="`itemTypeCalendar`"
                                        :id="`itemTypeCalendar-${itemTypeCalendar.id}`"
                                        :value="itemTypeCalendar.id"
                                        :label="itemTypeCalendar.value"
                                    />
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <label class="col-sm-3 text-nowrap">{{ $t('managerForm.settingPage.lowerLimit') }} </label>
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="formBlock.block_date.format_year.lower_limit"
                                                :config.sync="configFormInput.configFieldLowerLimit"
                                                @blur="handleValidateField(configFormInput.configFieldLowerLimit)"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <label class="col-sm-3 text-nowrap">{{ $t('managerForm.settingPage.upperLimit') }} </label>
                                        <div class="col-sm-9">
                                            <InputText
                                                :model.sync="formBlock.block_date.format_year.upper_limit"
                                                :config.sync="configFormInput.configFieldUpperLimit"
                                                @blur="handleValidateField(configFormInput.configFieldUpperLimit)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="form-group row">
                            <label class="col-sm-2 text-nowrap" style="min-width: 150px"
                                >{{ $t('managerForm.settingPage.placeholderCommon') }}
                            </label>
                            <div class="col-sm-6">
                                <InputText
                                    :model.sync="formBlock.block_date.format_year.placeholder"
                                    :config.sync="configFormInput.configFieldPlaceholderYear"
                                    @blur="handleValidateField(configFormInput.configFieldPlaceholderYear)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActiveFormatMonthDay" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.inputFormat(month/day)') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-3" style="gap: 12px">
                        <div v-for="(itemDateMonth_Day, idx) in OPTION_FORMAT_DATE" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.block_date.format_month_day.type"
                                :name="`itemDateMonth_Day`"
                                :id="`itemDateMonth_Day-${itemDateMonth_Day.id}`"
                                :value="itemDateMonth_Day.id"
                                :label="itemDateMonth_Day.value"
                                @change="handleChangeTypeMonthDay"
                            />
                        </div>
                    </div>
                    <div v-if="formBlock.block_date?.format_month_day?.type == 1" class="pt-3 border-top-1-color">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('managerForm.settingPage.placeholderCommon') }}
                            </label>
                            <div class="col-sm-9">
                                <div class="d-flex" style="gap: 18px">
                                    <div class="d-flex">
                                        <InputText
                                            :model.sync="formBlock.block_date.format_month_day.placeholder_month"
                                            :config.sync="configFormInput.configFieldPlaceholderMonth"
                                            @blur="handleValidateField(configFormInput.configFieldPlaceholderMonth)"
                                        />
                                        <span class="mt-2 ml-1">月</span>
                                    </div>

                                    <div class="d-flex">
                                        <InputText
                                            :model.sync="formBlock.block_date.format_month_day.placeholder_day"
                                            :config.sync="configFormInput.configFieldPlaceholderDay"
                                            @blur="handleValidateField(configFormInput.configFieldPlaceholderDay)"
                                        />
                                        <span class="mt-2 ml-1">日</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isActiveMaximumFileSize" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.maximumFileSize') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <div class="d-flex">
                        <InputText
                            :model.sync="formBlock.maximum_file_size"
                            :config.sync="configFormInput.configFieldMaximumFileSize"
                            @blur="handleValidateField(configFormInput.configFieldMaximumFileSize)"
                        />
                        <span class="mt-2 ml-1">KB</span>
                    </div>
                </div>
            </div>

            <div v-if="isActiveAllowedExtensions" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.allowedExtensions') }} </label>
                <div class="col-sm-9">
                    <InputText
                        class="mb-2"
                        :model.sync="formBlock.allowed_extensions"
                        :config.sync="configFormInput.configFieldAllowedExtensions"
                        @blur="handleValidateField(configFormInput.configFieldAllowedExtensions)"
                    />
                    <span>{{ $t('managerForm.settingPage.noteAllowedExtensions') }}</span>
                </div>
            </div>
            <!-- 入力形式 -->
            <div v-if="isActiveDefineIncludesInput" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.inputFormat') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-2" style="gap: 12px">
                        <div v-for="(formatInput, idx) in OPTION_INPUT_FORMAT" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.input_format"
                                :name="`inputFormat`"
                                :id="`inputFormat-${formatInput.id}`"
                                :value="formatInput"
                                :label="formatInput.value"
                                @change="onHandleChangeInputFormat"
                            />
                        </div>
                    </div>
                    <span>{{ $t('managerForm.settingPage.noteMaxCharactersInput') }}</span>
                </div>
            </div>
            <!-- 入力形式 Matrix -->
            <div v-if="isActiveDefineIncludesMaxtrix" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.inputFormat') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-2" style="gap: 12px">
                        <div v-for="(formatMatrix, idx) in OPTION_DEFINE_MATRIX" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.matrix_format"
                                :name="`matrixFormat`"
                                :id="`matrixFormat-${formatMatrix.id}`"
                                :value="formatMatrix.id"
                                :label="formatMatrix.value"
                            />
                        </div>
                    </div>
                    <span>{{ $t('managerForm.settingPage.noteMaxCharactersInput') }}</span>
                </div>
            </div>
            <div v-if="isActiveIncludesOfInput" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.inputCheck') }} </label>
                <div class="col-sm-9">
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.characterCountCheck') }} </label>
                        <div class="col-sm-9">
                            <div class="form-group row">
                                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                    >{{ $t('managerForm.settingPage.minimumNumber') }}
                                </label>
                                <div class="col-sm-6">
                                    <InputText
                                        :model.sync="formBlock.block_input.minimum_number"
                                        :config.sync="configFormInput.configFieldMinimumNumber"
                                        @blur="handleValidateField(configFormInput.configFieldMinimumNumber)"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                    >{{ $t('managerForm.settingPage.maximumNumber') }}
                                </label>
                                <div class="col-sm-6">
                                    <InputText
                                        :model.sync="formBlock.block_input.maximum_number"
                                        :config.sync="configFormInput.configFieldMaximumNumber"
                                        @blur="handleValidateField(configFormInput.configFieldMaximumNumber)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 回答有効値の範囲 -->
                    <div
                        v-if="formBlock?.input_format?.type_input === 'numerical_value' || isNumericValueIncludingDecimalPoint"
                        class="form-group row"
                    >
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('managerForm.settingPage.rangeOfValidAnswerValue') }}
                        </label>
                        <div class="col-sm-9">
                            <div class="d-flex">
                                <div class="col-sm-5">
                                    <InputText
                                        class="mb-2"
                                        :model.sync="formBlock.block_input.from_range"
                                        :config.sync="configFormInput.configFieldFromRange"
                                        @blur="handleValidateField(configFormInput.configFieldFromRange, 'fromRange')"
                                    />
                                    <div v-if="configFormInput.configFieldFromRange.error" class="d-block invalid-feedback">
                                        <span v-if="!isNumericValueIncludingDecimalPoint">
                                            {{
                                                `${configFormInput.configFieldFromRange.maxlength - 1}桁以下でかつ${configFormInput.configFieldFromRange.min}から${configFormInput.configFieldFromRange.max}の整数を入力してください。`
                                            }}
                                        </span>
                                        <span v-else>
                                            {{
                                                `整数部${configFormInput.configFieldFromRange.integer}桁、小数部${configFormInput.configFieldFromRange.decimal}桁までの数値を入力してください。`
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <p class="mb-0 pt-2 mx-2">～</p>
                                <div class="col-sm-5">
                                    <InputText
                                        class="mb-2"
                                        :model.sync="formBlock.block_input.to_range"
                                        :config.sync="configFormInput.configFieldToRange"
                                        @blur="handleValidateField(configFormInput.configFieldToRange, 'toRange')"
                                    />
                                    <div v-if="configFormInput.configFieldToRange.error" class="d-block invalid-feedback">
                                        <div v-if="!isNumericValueIncludingDecimalPoint">
                                            <span v-if="configFormInput.configFieldToRange.errorCompare">
                                                {{ configFormInput.configFieldToRange.errorCompare }}
                                            </span>
                                            <span v-else>
                                                {{
                                                    `${configFormInput.configFieldToRange.maxlength - 1}桁以下でかつ${configFormInput.configFieldToRange.min}から${configFormInput.configFieldToRange.max}の整数を入力してください。`
                                                }}
                                            </span>
                                        </div>
                                        <div v-else>
                                            <span v-if="configFormInput.configFieldToRange.errorCompare">
                                                {{ configFormInput.configFieldToRange.errorCompare }}
                                            </span>
                                            <span v-else>
                                                {{
                                                    `整数部${configFormInput.configFieldToRange.integer}桁、小数部${configFormInput.configFieldToRange.decimal}桁までの数値を入力してください。`
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 小数点以下の回答有効桁数 -->
                    <div v-if="isNumericValueIncludingDecimalPoint" class="form-group row">
                        <label class="col-sm-4 text-nowrap" style="min-width: 150px"
                            >{{ $t('managerForm.settingPage.numberValidDigitsAnswerAfterDecimalPoint') }}
                        </label>
                        <div class="col-sm-6">
                            <InputText
                                :model.sync="formBlock.block_input.number_after_decimal_point"
                                :config.sync="configFormInput.configFieldNumberAfterDecimalPoint"
                                @blur="handleValidateField(configFormInput.configFieldNumberAfterDecimalPoint, 'validDigitsAnswerAfterDecimalPoint')"
                            />
                            <div v-if="configFormInput.configFieldNumberAfterDecimalPoint.error" class="d-block invalid-feedback">
                                {{ `${configFormInput.configFieldNumberAfterDecimalPoint.max}以下の正の整数を入力してください。` }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.duplicateInput') }} </label>
                        <div class="col-sm-9">
                            <div class="d-flex flex-wrap" style="gap: 12px">
                                <div v-for="(duplicateItem, idx) in OPTION_YES_NO" :key="idx">
                                    <InputRadio
                                        class="mr-4"
                                        :model.sync="formBlock.block_input.duplicate_input"
                                        :name="`duplicateInput`"
                                        :id="`duplicateInput-${duplicateItem.id}`"
                                        :value="duplicateItem.id"
                                        :label="duplicateItem.value"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActivePlaceholderInput" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.placeholderCommon') }} </label>
                <div class="col-sm-6">
                    <InputText
                        :model.sync="formBlock.input_placeholder"
                        :config.sync="configFormInput.configFieldInputPlaceholder"
                        @blur="handleValidateField(configFormInput.configFieldInputPlaceholder)"
                    />
                </div>
            </div>
            <div v-if="isActivePlaceholderPostcode" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.placeholderCommon') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex">
                        <InputText
                            :model.sync="formBlock.block_post_code.placeholder_1"
                            :config.sync="configFormInput.configFieldPostcodePlaceholder1"
                            @blur="handleValidateField(configFormInput.configFieldPostcodePlaceholder1)"
                        />
                        <span class="mt-2 mx-1">-</span>
                        <InputText
                            :model.sync="formBlock.block_post_code.placeholder_2"
                            :config.sync="configFormInput.configFieldPostcodePlaceholder2"
                            @blur="handleValidateField(configFormInput.configFieldPostcodePlaceholder2)"
                        />
                    </div>
                </div>
            </div>
            <div v-if="isActiveFeatureSearchAddress" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.featureSearchAddress') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-3" style="gap: 12px">
                        <div v-for="(fsearchAdd, idx) in OPTION_YES_NO" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.block_post_code.feature_search_address"
                                :name="`featureSearchAddress`"
                                :id="`featureSearchAddress-${fsearchAdd.id}`"
                                :value="fsearchAdd.id"
                                :label="fsearchAdd.value"
                                @change="handleChangeFeatureSearchAddress(formBlock.block_post_code)"
                            />
                        </div>
                    </div>
                    <div v-if="formBlock.block_post_code.feature_search_address == 1">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('managerForm.settingPage.prefectureInsertionBlock') }}
                            </label>
                            <div class="col-sm-9">
                                <Multiselect
                                    style="max-width: 200px"
                                    :id="`prefectureInsertionBlock`"
                                    :value.sync="formBlock.block_post_code.prefecture_insertion"
                                    :options="[]"
                                    :config="{ trackBy: 'id', label: 'value', error: false, isRemove: false }"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('managerForm.settingPage.cityInsertionBlock') }}
                            </label>
                            <div class="col-sm-9">
                                <Multiselect
                                    style="max-width: 200px"
                                    :id="`cityInsertionBlock`"
                                    :value.sync="formBlock.block_post_code.city_insertion"
                                    :options="[]"
                                    :config="{ trackBy: 'id', label: 'value', error: false, isRemove: false }"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('managerForm.settingPage.townAreaNameInsertionBlock') }}
                            </label>
                            <div class="col-sm-9">
                                <Multiselect
                                    style="max-width: 200px"
                                    :id="`townAreaNameInsertionBlock`"
                                    :value.sync="formBlock.block_post_code.town_area_name_insertion"
                                    :options="[]"
                                    :config="{ trackBy: 'id', label: 'value', error: false, isRemove: false }"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActivePlaceholderFullname" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.placeholderCommon') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex">
                        <div class="form-group row">
                            <label class="col-sm-1 text-nowrap mt-2" style="min-width: 50px">{{ $t('managerForm.settingPage.firstName') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="formBlock.block_full_name.placeholder_first_name"
                                    :config.sync="configFormInput.configFieldInputFirstNamePlaceholder"
                                    @blur="handleValidateField(configFormInput.configFieldInputFirstNamePlaceholder)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-1 text-nowrap mt-2" style="min-width: 50px">{{ $t('managerForm.settingPage.lastName') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="formBlock.block_full_name.placeholder_last_name"
                                    :config.sync="configFormInput.configFieldInputLastNamePlaceholder"
                                    @blur="handleValidateField(configFormInput.configFieldInputLastNamePlaceholder)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isActiveSizeTextarea" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.inputFieldSize') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex">
                        <div class="form-group row w-100">
                            <label class="col-sm-1 text-nowrap">{{ $t('managerForm.settingPage.rowsTextarea') }} </label>
                            <div class="col-sm-10">
                                <InputText
                                    :model.sync="formBlock.block_text_area.cols"
                                    :config.sync="configFormInput.configFieldColumnsTextarea"
                                    @blur="handleValidateField(configFormInput.configFieldColumnsTextarea)"
                                />
                            </div>
                        </div>
                        <div class="form-group row w-100">
                            <label class="col-sm-1 text-nowrap">{{ $t('managerForm.settingPage.colsTextarea') }} </label>
                            <div class="col-sm-10">
                                <InputText
                                    :model.sync="formBlock.block_text_area.rows"
                                    :config.sync="configFormInput.configFieldRowTextarea"
                                    @blur="handleValidateField(configFormInput.configFieldRowTextarea)"
                                />
                            </div>
                        </div>
                    </div>

                    <span>{{ $t('managerForm.settingPage.noteSizeArea') }}</span>
                </div>
            </div>
            <div v-if="isActiveFieldSize" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.inputFieldSize') }} </label>
                <div class="col-sm-6">
                    <InputText
                        class="mb-2"
                        :model.sync="formBlock.input_field_size"
                        :config.sync="configFormInput.configFieldInputFieldSize"
                        @blur="handleValidateField(configFormInput.configFieldInputFieldSize)"
                    />
                    <span>{{ $t('managerForm.settingPage.noteInputFieldSize') }}</span>
                </div>
            </div>
            <ActionOptionSelectCommon
                v-if="actionSelectCommon"
                :typeBlock="config?.type"
                :formBlock="formBlock"
                :configFormInput="configFormInput"
                class="pt-3 border-top-1-color"
            />
            <div v-if="isActiveOptionOther" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.optionOther') }} </label>
                <div class="col-sm-9">
                    <div v-if="['radio', 'checkbox'].includes(config?.type)" class="form-group row">
                        <label class="col-sm-2 text-nowrap">{{ $t('managerForm.settingPage.others') }} </label>
                        <div class="col-sm-9">
                            <InputCheckBox
                                class="mb-2"
                                :model.sync="formBlock.setting_others.other_use"
                                :id="`check-use`"
                                :value="formBlock.setting_others.other_use"
                                :label="$t('managerForm.settingPage.use')"
                                @change="onHandleOtherUserCheckbox"
                            />
                            <div class="form-group">
                                <label class="col-sm-3 text-nowrap pl-0" style="min-width: 150px"
                                    >{{ $t('managerForm.settingPage.labelOther') }}
                                </label>
                                <div class="col-sm-9 pl-0">
                                    <InputText
                                        :model.sync="formBlock.setting_others.label_other"
                                        :config.sync="configFormInput.configFieldLabelOther"
                                        @blur="handleValidateField(configFormInput.configFieldLabelOther)"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-9 pl-0">
                                    <div class="d-flex" style="gap: 20px">
                                        <InputCheckBox
                                            :model.sync="formBlock.setting_others.value_other_required"
                                            :id="`check-value_other_required`"
                                            :value="formBlock.setting_others.value_other_required"
                                            :label="`${$t('managerForm.settingPage.orderRequired')}`"
                                            :disabled="!formBlock.setting_others.other_use"
                                        />
                                        <div class="form-group row">
                                            <label class="col-sm-1 text-nowrap" style="min-width: 20px"
                                                >{{ $t('managerForm.settingPage.widthOther') }}
                                            </label>
                                            <div class="col-sm-9">
                                                <InputText
                                                    :model.sync="formBlock.setting_others.width_other"
                                                    :config.sync="configFormInput.configFieldWidthOther"
                                                    @blur="handleValidateField(configFormInput.configFieldWidthOther)"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <span>{{ $t('managerForm.settingPage.noteWidthOther') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 text-nowrap"
                            >{{ config.type == 'checkbox' ? $t('managerForm.settingPage.uncheckCheckbox') : $t('managerForm.settingPage.uncheck') }}
                        </label>
                        <div class="col-sm-9">
                            <InputCheckBox
                                class="mb-2"
                                :model.sync="formBlock.setting_others.uncheck_use"
                                :id="`check-uncheck_use`"
                                :value="formBlock.setting_others.uncheck_use"
                                :label="`${$t('managerForm.settingPage.use')}`"
                            />
                            <p class="my-3">
                                {{
                                    config.type == 'checkbox'
                                        ? $t('managerForm.settingPage.noteUncheckCheckbox')
                                        : $t('managerForm.settingPage.noteUncheck')
                                }}
                            </p>
                            <div class="form-group row">
                                <label class="col-sm-1 text-nowrap">{{ $t('managerForm.settingPage.labelOther') }} </label>
                                <div class="col-sm-9">
                                    <InputText
                                        :model.sync="formBlock.setting_others.label_uncheck"
                                        :config.sync="configFormInput.configFieldLabelUncheck"
                                        @blur="handleValidateField(configFormInput.configFieldLabelUncheck)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActiveRemainingNumber" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.remainingNumber') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-4" style="gap: 12px">
                        <div v-for="(remainingItem, idx) in CONFIRMATION_PAGE_DISPLAY" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.block_remaining_number.remaining_number"
                                :name="`remainingNumber`"
                                :id="`remainingNumber-${remainingItem.id}`"
                                :value="remainingItem.id"
                                :label="remainingItem.name"
                                @change="handleChangeRemainingItem"
                            />
                        </div>
                    </div>
                    <div v-if="formBlock.block_remaining_number?.remaining_number == 1" class="form-group row">
                        <label class="col-sm-3 text-nowrap">{{ $t('managerForm.settingPage.displayContent') }} </label>
                        <div class="col-sm-9">
                            <div class="d-flex flex-wrap mb-4" style="gap: 12px">
                                <div v-for="(displayContentItem, idx) in DISPLAY_CONTENT_BLOCK_REMAINING" :key="idx">
                                    <InputRadio
                                        class="mr-4"
                                        :model.sync="formBlock.block_remaining_number.display_content"
                                        :name="`displayContent`"
                                        :id="`displayContent-${displayContentItem.id}`"
                                        :value="displayContentItem.id"
                                        :label="displayContentItem.name"
                                        @change="handleChangeDisplayContentItem"
                                    />
                                </div>
                            </div>
                            <div v-if="formBlock.block_remaining_number?.display_content == 1" class="d-flex">
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                        >{{ $t('managerForm.settingPage.remainingText') }}
                                    </label>
                                    <div class="col-sm-6">
                                        <InputText
                                            :model.sync="formBlock.block_remaining_number.remaining_text"
                                            :config.sync="configFormInput.configFieldRemainingText"
                                            @blur="handleValidateField(configFormInput.configFieldRemainingText)"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                        >{{ $t('managerForm.settingPage.textAfterremainingnumber') }}
                                    </label>
                                    <div class="col-sm-6">
                                        <InputText
                                            :model.sync="formBlock.block_remaining_number.text_after_remaining_number"
                                            :config.sync="configFormInput.configFieldTextAfterremainingnumber"
                                            @blur="handleValidateField(configFormInput.configFieldTextAfterremainingnumber)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="formBlock.block_remaining_number?.display_content == 2" class="d-flex">
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 50px"
                                        >{{ $t('managerForm.settingPage.remainingRatio') }}≦
                                    </label>
                                    <div class="col-sm-9">
                                        <div class="d-flex">
                                            <InputText
                                                :model.sync="formBlock.block_remaining_number.remaining_ratio"
                                                :config.sync="configFormInput.configFieldRemainingRatio"
                                                @blur="handleValidateField(configFormInput.configFieldRemainingRatio)"
                                            />
                                            <span class="mt-2 ml-2">%</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 text-nowrap" style="min-width: 50px"
                                        >{{ $t('managerForm.settingPage.labelOther') }}
                                    </label>
                                    <div class="col-sm-9">
                                        <InputText
                                            :model.sync="formBlock.block_remaining_number.label_other"
                                            :config.sync="configFormInput.configFieldRemainingLabelOther"
                                            @blur="handleValidateField(configFormInput.configFieldRemainingLabelOther)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-3 border-top-1-color">
                                <label class="col-sm-3 text-nowrap">{{ $t('managerForm.settingPage.colorText') }} </label>
                                <div class="col-sm-9">
                                    <InputText
                                        style="max-width: 100px"
                                        :model.sync="formBlock.block_remaining_number.color_text"
                                        :config="{
                                            type: 'color',
                                            classCustom: 'form-control-color',
                                            error: false
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActiveCheckNumberLimit" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.checkNumberLimit') }} </label>
                <div class="col-sm-9">
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <Multiselect
                                style="max-width: 200px"
                                :id="`checkNumberLimit`"
                                :value.sync="formBlock.operator_limit_check"
                                :options="OPTION_LIMIT_CHECK"
                                :config="{ trackBy: 'id', label: 'value', error: false, isRemove: false }"
                                @select="onHandleOperatorLimit"
                                @remove="onHandleRemoveOperatorLimit"
                            />
                        </div>
                        <div class="col-sm-5">
                            <InputText
                                :model.sync="formBlock.number_limit_check"
                                :config.sync="configFormInput.configFieldNumberLimitCheck"
                                :disabled="!formBlock.operator_limit_check"
                                @blur="handleValidateField(configFormInput.configFieldNumberLimitCheck, 'numberLimit')"
                            />
                        </div>
                    </div>
                    <span>{{ $t('managerForm.settingPage.noteCheckNumberLimit') }}</span>
                </div>
            </div>
            <OptionValueMatrix v-if="isActiveOptionMatrix" :formBlock="formBlock" :configFormInput="configFormInput" />
            <div v-if="isActiveDisplayConfirm" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">
                    <p v-if="config?.type !== 'div_1' && config?.type !== 'div_2'" class="mb-1">
                        {{ $t('managerForm.settingPage.confirmationPageDisplay1') }}
                    </p>
                    <p class="mb-0">{{ $t('managerForm.settingPage.confirmationPageDisplay2') }}</p>
                </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap" style="gap: 12px">
                        <div v-for="(confirm, idx) in CONFIRMATION_PAGE_DISPLAY" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.confirmation_page_display"
                                :name="`confirmationPageDisplay`"
                                :id="`confirmationPageDisplay-${confirm.id}`"
                                :value="confirm.id"
                                :label="confirm.name"
                                :disabled="isDisabledDisplayConfirm"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 個人情報項目設定 -->
            <div v-if="isActivePersonalSettings" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.personalInformationItemSettings') }}
                </label>
                <div class="col-sm-9">
                    <InputCheckBox
                        class="mb-2"
                        :model.sync="formBlock.personal_information_setting"
                        :id="`check-personal_information_setting`"
                        :value="formBlock.personal_information_setting"
                        :label="`${$t('managerForm.settingPage.designatePersonalInformation')}`"
                    />
                    <span>{{ $t('managerForm.settingPage.notePersonalInformationItemSettings') }}</span>
                </div>
            </div>
            <!-- 未選択ラベル -->
            <div v-if="isActivePlaceholderSelect" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.placeholderSelect') }} </label>
                <div class="col-sm-9">
                    <InputText
                        class="mb-2"
                        style="max-width: 280px"
                        :model.sync="formBlock.placeholder"
                        :config.sync="configFormInput.configFieldPlaceholder"
                        @blur="handleValidateField(configFormInput.configFieldPlaceholder)"
                    />
                </div>
            </div>
            <!-- その他選択肢の 最大文字数 -->
            <div v-if="isActiveMaxcharacters" class="form-group row pt-3 border-top-1-color">
                <label v-if="LABEL_TYPE_1.includes(config?.type)" class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.maxCharactersInput') }} <span class="text-danger">*</span></label
                >
                <label v-else-if="LABEL_TYPE_2.includes(config?.type)" class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.maxCharactersInput') }}(入力欄毎) <span class="text-danger">*</span></label
                >
                <label v-else class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('managerForm.settingPage.maxCharacters') }} <span class="text-danger">*</span></label
                >
                <div v-if="!isNumericValueIncludingDecimalPoint" class="col-sm-9">
                    <InputText
                        class="mb-2"
                        style="max-width: 280px"
                        :model.sync="formBlock.max_characters"
                        :config.sync="configFormInput.configFieldMaxCharacters"
                        @blur="handleValidateField(configFormInput.configFieldMaxCharacters, 'maxCharacters')"
                    />
                </div>
                <div v-else>
                    <!-- 桁数 -->
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.numberOfDigits') }} </label>
                        <div class="col-sm-6">
                            <InputText
                                class="mb-2"
                                :model.sync="formBlock.block_input.number_of_digits"
                                :config.sync="configFormInput.configFieldNumberOfDigits"
                                @blur="handleValidateField(configFormInput.configFieldNumberOfDigits, 'numberOfDigits')"
                                :disabled="false"
                            />
                        </div>
                    </div>
                    <!-- 小数点以下桁数 -->
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('managerForm.settingPage.numberOfDecimalPlaces') }}
                        </label>
                        <div class="col-sm-6">
                            <InputText
                                class="mb-2"
                                :model.sync="formBlock.block_input.number_of_decimal_places"
                                :config.sync="configFormInput.configFieldNumberOfDecimalPlaces"
                                @blur="handleValidateField(configFormInput.configFieldNumberOfDecimalPlaces, 'numberOfDecimalPlaces')"
                                :disabled="false"
                            />
                        </div>
                    </div>
                    <span v-if="config?.type !== 'textarea'">
                        <span v-if="['fullname', 'input', 'keyword-box', 'get-param'].includes(config?.type)">{{
                            $t('managerForm.settingPage.noteMaxCharactersInput')
                        }}</span>
                        <span v-else>{{ $t('managerForm.settingPage.noteMaxCharacters') }}</span>
                    </span>
                </div>
            </div>

            <div v-if="isActiveDuplicateInput" class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.duplicateInput') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap" style="gap: 12px">
                        <div v-for="(duplicateItem, idx) in OPTION_YES_NO" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.block_parameter.is_duplicate"
                                :name="`duplicateInput`"
                                :id="`duplicateInput-${duplicateItem.id}`"
                                :value="duplicateItem.id"
                                :label="duplicateItem.value"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isActiveConfirmInputTwice" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.confirmInputTwice') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap mb-3" style="gap: 12px">
                        <div v-for="(checkTwice, idx) in OPTION_YES_NO" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.block_input.confirm_input_twice"
                                :name="`confirmInputTwice`"
                                :id="`confirmInputTwice-${checkTwice.id}`"
                                :value="checkTwice.id"
                                :label="checkTwice.value"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActiveDisplayedPerLine" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.numberOfItemsDisplayedPerLine') }}</label>
                <div class="col-sm-9">
                    <Multiselect
                        style="max-width: 200px"
                        :id="`numberOfItemsDisplayedPerLine`"
                        :value.sync="formBlock.number_of_items_displayed_per_line"
                        :options="NUMBER_DISPLAY_PER_LINE"
                        :config="{ trackBy: '', label: '', error: false, isRemove: false }"
                    />
                </div>
            </div>
            <!-- 選択肢配置指定 -->
            <div v-if="isActivePlacement" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.choicePlacementSpecification') }}</label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap" style="gap: 12px">
                        <div v-for="(alginText, idx) in LIST_TEXT_ALIGN" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.choice_placement_specification"
                                :name="`choicePlacementSpecification`"
                                :id="`choicePlacementSpecification-${alginText.id}`"
                                :value="alginText.value"
                                :label="alginText.label"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- レイアウト	 -->
            <div v-if="isActiveLayout" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.layout') }}</label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap" style="gap: 12px">
                        <div v-for="(alginText, idx) in LAYOUT_TYPE" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.layout"
                                :name="`layout`"
                                :id="`layout-${alginText.id}`"
                                :value="alginText.value"
                                :label="alginText.label"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- セル背景色 -->
            <div v-if="isActiveBackgroundColor" class="form-group row pt-3 border-top-1-color">
                <div class="col-sm-3 d-flex flex-column">
                    <label class="text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.cellBackgroundColor') }}</label>
                    <div>
                        <button
                            v-if="formBlock.cell_background_color.bg_question || formBlock.cell_background_color.bg_answer"
                            type="button"
                            class="btn text-nowrap return__page--design"
                            @click="handleReturnToDesign"
                        >
                            {{ $t('managerForm.settingPage.returnToPageDesign') }}
                        </button>
                    </div>
                </div>
                <div v-if="config?.type !== 'div_1'" class="col-sm-9">
                    <div class="form-group row">
                        <label class="col-sm-2 text-nowrap">{{ $t('managerForm.settingPage.backgroundColorOfQuestionField') }}</label>
                        <div class="col-sm-9">
                            <div class="form-group row">
                                <div class="col-sm-2">
                                    <InputText
                                        style="max-width: 100px"
                                        :model.sync="formBlock.cell_background_color.bg_question"
                                        :config="{
                                            type: 'color',
                                            classCustom: 'form-control-color',
                                            error: false
                                        }"
                                    />
                                </div>
                                <div class="col-sm-3">
                                    <InputText
                                        style="max-width: 100px"
                                        :model.sync="formBlock.cell_background_color.bg_question"
                                        :config="{
                                            placeholder: '#',
                                            error: false
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 text-nowrap">{{ $t('managerForm.settingPage.backgroundColorOfAnswerColumn') }}</label>
                        <div class="col-sm-9">
                            <div class="form-group row">
                                <div class="col-sm-2">
                                    <InputText
                                        style="max-width: 100px"
                                        :model.sync="formBlock.cell_background_color.bg_answer"
                                        :config="{
                                            type: 'color',
                                            classCustom: 'form-control-color',
                                            error: false
                                        }"
                                    />
                                </div>
                                <div class="col-sm-3">
                                    <InputText
                                        style="max-width: 100px"
                                        :model.sync="formBlock.cell_background_color.bg_answer"
                                        :config="{
                                            placeholder: '#',
                                            error: false
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-sm-9">
                    <div class="form-group row">
                        <div class="col-sm-2">
                            <InputText
                                style="max-width: 100px"
                                :model.sync="formBlock.cell_background_color.bg_question"
                                :config="{
                                    type: 'color',
                                    classCustom: 'form-control-color',
                                    error: false
                                }"
                            />
                        </div>

                        <div class="col-sm-2">
                            <InputText
                                style="max-width: 100px"
                                :model.sync="formBlock.cell_background_color.bg_question"
                                :config="{
                                    placeholder: '#',
                                    error: false
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActiveInputConditions" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                    ['div_1', 'div_2'].includes(config.type)
                        ? $t('managerForm.settingPage.inputConditions_Div')
                        : $t('managerForm.settingPage.inputConditions')
                }}</label>
                <div class="col-sm-9">
                    <p>{{ $t('managerForm.settingPage.noteInputConditions') }}</p>
                    <div class="d-flex flex-wrap mb-3" style="gap: 12px">
                        <div v-for="(conditionItem, idx) in LIST_CHOICE_CONDITION" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.setting_conditions.condition"
                                :name="`inputConditions`"
                                :id="`inputConditions-${conditionItem.id}`"
                                :value="conditionItem.id"
                                :label="conditionItem.value"
                            />
                        </div>
                    </div>
                    <TableCondition :formBlock="formBlock" :configFormInput="configFormInput" />
                </div>
            </div>
            <div v-if="isActiveConditionsDoNotMatch" class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.ifConditionsDoNotMatch') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap" style="gap: 12px">
                        <div v-for="(matchItem, idx) in MATCH_CHOICE_CONDITION" :key="idx">
                            <InputRadio
                                class="mr-4"
                                :model.sync="formBlock.if_conditions_do_not_match"
                                :name="`ifConditionsDoNotMatch`"
                                :id="`ifConditionsDoNotMatch-${matchItem.id}`"
                                :value="matchItem.id"
                                :label="matchItem.value"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <div class="d-flex justify-content-end">
                    <div class="mr-3">
                        <button type="button" class="btn btn-light" @click="handleCancelBlock">
                            {{ $t('managerForm.settingPage.backSetting') }}
                        </button>
                    </div>
                    <div>
                        <button type="button" class="btn btn-info" @click="handleSaveStep1">
                            {{ $t('managerForm.settingPage.nextDetailedSettingsForAnswerColumn') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <AdditionalSettings v-else @back="handleBackStep1" @save="handleSaveStep2" :formBlock="formBlock" :configFormInput="configFormInput" />
    </div>
</template>

<script>
import {
    CONFIRMATION_PAGE_DISPLAY,
    LIST_TEXT_ALIGN,
    LAYOUT_TYPE,
    LIST_CHOICE_CONDITION,
    MATCH_CHOICE_CONDITION,
    OPTION_LIMIT_CHECK,
    OPTION_INPUT_FORMAT,
    OPTION_YES_NO,
    OPTION_FORMAT_DATE,
    OPTION_CALENDAR_WEST_TIME,
    LIST_PROVINCE_BLOCK,
    DISPLAY_CONTENT_BLOCK_REMAINING,
    LIST_MATCHING_CONDITION,
    OPTION_SOURCE_PARAMS,
    OPTION_DEFINE_MATRIX
} from './constants';
import {
    ORDER_REQUIRED,
    LABEL,
    DISPLAY_CONFIRM,
    PERSONAL_SETTINGS,
    LAYOUT,
    BACKGROUND_COLOR,
    INPUT_CONDITIONS,
    LABEL_TYPE_1,
    LABEL_TYPE_2
} from './listTypeActiveField';
import { InputText, InputCheckBox, InputRadio } from '@/components/Input';
import { validateField, validateAllField } from '@/utils/validate';
import { BLOCK_COMMON } from '@/config/constants';
import { managerFormMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

import QuillEditor from '@/components/QuillEditor/main.vue';
// import Editor from '@/components/Editor/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import ActionOptionSelectCommon from './action-option-common.vue';
import OptionValueMatrix from './option-matrix.vue';
import TableCondition from './table-condition.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';
import TextareaComponent from '@/components/Textarea/main.vue';

import AdditionalSettings from './additional-settings.vue';

export default {
    name: 'SettingBlockForm',
    components: {
        InputRadio,
        InputText,
        InputCheckBox,
        QuillEditor,
        // Editor,
        Multiselect,
        ActionOptionSelectCommon,
        TableCondition,
        UploadFileCommon,
        TextareaComponent,
        AdditionalSettings,
        OptionValueMatrix
    },
    props: {
        config: {
            type: Object,
            required: true,
            default: () => null
        },
        formBlock: {
            type: Object,
            required: true,
            default: () => null
        },
        configFormInput: {
            type: Object,
            required: true,
            default: () => null
        },
        actionTimeAddBlock: {
            type: Number,
            default: () => null
        }
    },
    data() {
        return {
            CONFIRMATION_PAGE_DISPLAY: CONFIRMATION_PAGE_DISPLAY,
            LIST_TEXT_ALIGN: LIST_TEXT_ALIGN,
            LAYOUT_TYPE: LAYOUT_TYPE,
            LIST_CHOICE_CONDITION: LIST_CHOICE_CONDITION,
            MATCH_CHOICE_CONDITION: MATCH_CHOICE_CONDITION,
            OPTION_LIMIT_CHECK: OPTION_LIMIT_CHECK,
            OPTION_YES_NO: OPTION_YES_NO,
            OPTION_FORMAT_DATE: OPTION_FORMAT_DATE,
            OPTION_CALENDAR_WEST_TIME: OPTION_CALENDAR_WEST_TIME,
            LIST_PROVINCE_BLOCK: LIST_PROVINCE_BLOCK,
            DISPLAY_CONTENT_BLOCK_REMAINING: DISPLAY_CONTENT_BLOCK_REMAINING,
            LIST_MATCHING_CONDITION: LIST_MATCHING_CONDITION,
            OPTION_INPUT_FORMAT: OPTION_INPUT_FORMAT,
            OPTION_SOURCE_PARAMS: OPTION_SOURCE_PARAMS,
            OPTION_DEFINE_MATRIX: OPTION_DEFINE_MATRIX,
            NUMBER_DISPLAY_PER_LINE: Array.from({ length: 20 }).map((_, i) => i + 1),
            TYPE_BLOCKS: BLOCK_COMMON.map((item) => item.type),
            LABEL_TYPE_1: LABEL_TYPE_1,
            LABEL_TYPE_2: LABEL_TYPE_2,
            isSettingStep1: true
        };
    },
    computed: {
        isNumericValueIncludingDecimalPoint() {
            return this.formBlock?.input_format?.type_input === 'numeric_value_including_decimal_point';
        },

        isDisabledDisplayConfirm() {
            let arrayType = ['date'];
            return arrayType.includes(this.config?.type);
        },

        actionSelectCommon() {
            let arrayType = ['radio', 'select', 'checkbox', 'province', 'remaining-seats-management-box'];
            return arrayType.includes(this.config?.type);
        },

        isActiveOrderRequired() {
            return ORDER_REQUIRED.includes(this.config?.type);
        },

        isActiveLabel() {
            return LABEL.includes(this.config?.type);
        },

        // 取得元
        isActiveSourceParam() {
            let arrayType = ['get-param'];
            return arrayType.includes(this.config?.type);
        },
        // パラメータ名
        isActiveParamName() {
            let arrayType = ['get-param'];
            return arrayType.includes(this.config?.type);
        },
        // エラーリダイレクト先 URL
        isActiveParamURL() {
            let arrayType = ['get-param'];
            return arrayType.includes(this.config?.type);
        },

        isActiveMatchingCondition() {
            let arrayType = ['keyword-box'];
            return arrayType.includes(this.config?.type);
        },

        isActiveFieldMaskDisplay() {
            let arrayType = ['keyword-box'];
            return arrayType.includes(this.config?.type);
        },

        isActiveKeywordUpload() {
            let arrayType = ['keyword-box'];
            return arrayType.includes(this.config?.type);
        },

        isActivePhonePlaceholder() {
            let arrayType = ['phone'];
            return arrayType.includes(this.config?.type);
        },

        isActiveDiv2Label() {
            let arrayType = ['div_2'];
            return arrayType.includes(this.config?.type);
        },

        isActiveFormatYear() {
            let arrayType = ['date'];
            return arrayType.includes(this.config?.type);
        },

        isActiveFormatMonthDay() {
            let arrayType = ['date'];
            return arrayType.includes(this.config?.type);
        },

        isActiveMaximumFileSize() {
            let arrayType = ['upload-file'];
            return arrayType.includes(this.config?.type);
        },
        isActiveAllowedExtensions() {
            let arrayType = ['upload-file'];
            return arrayType.includes(this.config?.type);
        },

        isActiveIncludesOfInput() {
            let arrayType = ['input'];
            return arrayType.includes(this.config?.type);
        },

        // 入力形式	DefineIncludesInput
        isActiveDefineIncludesInput() {
            let arrayType = ['input', 'fullname'];
            return arrayType.includes(this.config?.type);
        },
        // 入力形式	DefineIncludesMaxtrix
        isActiveDefineIncludesMaxtrix() {
            let arrayType = ['matrix'];
            return arrayType.includes(this.config?.type);
        },

        // 入力欄サイズ
        isActiveFieldSize() {
            let arrayType = ['input', 'keyword-box'];
            return arrayType.includes(this.config?.type);
        },
        // テキストの二度入力確認
        isActiveConfirmInputTwice() {
            let arrayType = ['input'];
            return arrayType.includes(this.config?.type);
        },
        // 上記以外の選択肢 optionOther
        isActiveOptionOther() {
            let arrayType = ['radio', 'checkbox', 'remaining-seats-management-box'];
            return arrayType.includes(this.config?.type);
        },
        // 残数
        isActiveRemainingNumber() {
            let arrayType = ['remaining-seats-management-box'];
            return arrayType.includes(this.config?.type);
        },

        // チェック数制限
        isActiveCheckNumberLimit() {
            let arrayType = ['checkbox', 'matrix'];
            return arrayType.includes(this.config?.type);
        },

        isActiveOptionMatrix() {
            let arrayType = ['matrix'];
            return arrayType.includes(this.config?.type);
        },

        // 未入力時の
        // 確認ページ表示
        isActiveDisplayConfirm() {
            return DISPLAY_CONFIRM.includes(this.config?.type);
        },

        isActivePersonalSettings() {
            return PERSONAL_SETTINGS.includes(this.config?.type);
        },

        isActivePlaceholderSelect() {
            let arrayType = ['select', 'province'];
            return arrayType.includes(this.config?.type);
        },

        isActivePlaceholderInput() {
            let arrayType = ['input', 'textarea', 'keyword-box'];
            return arrayType.includes(this.config?.type);
        },
        isActivePlaceholderPostcode() {
            let arrayType = ['postcode'];
            return arrayType.includes(this.config?.type);
        },
        isActiveFeatureSearchAddress() {
            let arrayType = ['postcode'];
            return arrayType.includes(this.config?.type);
        },
        isActivePlaceholderFullname() {
            let arrayType = ['fullname'];
            return arrayType.includes(this.config?.type);
        },

        isActiveSizeTextarea() {
            let arrayType = ['textarea'];
            return arrayType.includes(this.config?.type);
        },

        isActiveMaxcharacters() {
            let arrayType = ['radio', 'checkbox', 'input', 'textarea', 'fullname', 'keyword-box', 'get-param'];
            return arrayType.includes(this.config?.type);
        },

        // 重複入力チェック
        isActiveDuplicateInput() {
            let arrayType = ['get-param'];
            return arrayType.includes(this.config?.type);
        },

        // 1行に表示する項目数
        isActiveDisplayedPerLine() {
            let arrayType = ['radio', 'checkbox', 'remaining-seats-management-box'];
            return arrayType.includes(this.config?.type);
        },

        isActivePlacement() {
            let arrayType = ['radio', 'checkbox', 'remaining-seats-management-box'];
            return arrayType.includes(this.config?.type);
        },

        isActiveLayout() {
            return LAYOUT.includes(this.config?.type);
        },

        isActiveBackgroundColor() {
            return BACKGROUND_COLOR.includes(this.config?.type);
        },
        // 入力条件
        isActiveInputConditions() {
            return INPUT_CONDITIONS.includes(this.config?.type);
        },

        isActiveConditionsDoNotMatch() {
            return this.formBlock?.setting_conditions?.table_condition?.length > 0;
        }
    },
    methods: {
        ...managerFormMethods,

        handleValidateField(configField, typeAction = null) {
            if (typeAction === 'fromRange' || typeAction === 'toRange') {
                this.onHandleCompareRange(this.formBlock.block_input.from_range, this.formBlock.block_input.to_range);
            }
            validateField(configField);
            switch (typeAction) {
                case 'maxCharacters':
                    this.handleChangeInputMaxCharacters();
                    break;
                case 'numberOfDecimalPlaces':
                    if (!this.configFormInput.configFieldNumberOfDecimalPlaces.error) {
                        this.handleFieldNumberAfterDecimalPoint();
                    }
                    break;
                case 'numberOfDigits':
                    if (!this.configFormInput.configFieldNumberOfDigits.error) {
                        this.handleFieldNumberOfDigits();
                    }
                    break;
                case 'validDigitsAnswerAfterDecimalPoint':
                    if (!this.configFormInput.configFieldNumberAfterDecimalPoint.error) this.handleChangeInputAfterDecimalPoint();
                    break;
                case 'numberLimit':
                    if (!this.configFormInput.configFieldNumberLimitCheck.error) this.onHandleNumberLimit();
                    break;
            }
        },

        onHandleNumberLimit() {
            let { operator_limit_check, number_limit_check, setting_others } = this.formBlock;
            let check = true;
            if (!number_limit_check || number_limit_check === '0') return check;
            if (!setting_others.uncheck_use) {
                this.configFormInput.configFieldNumberLimitCheck.error = false;
                return check;
            }
            if (operator_limit_check?.value === '=' || operator_limit_check?.value === '≧') {
                check = !(number_limit_check > 1);
                this.configFormInput.configFieldNumberLimitCheck.error = !!(number_limit_check > 1);
                this.configFormInput.configFieldNumberLimitCheck.errorCustomMess = 'チェック数制限に「1」以外は指定できません。';
            }
            return check;
        },

        onHandleCompareRange(from, to) {
            let checkCompare = true;
            if (!from || !to || this.isNumericValueIncludingDecimalPoint) return checkCompare;
            let { configFieldToRange } = this.configFormInput;
            if (Number(to) <= Number(from)) {
                checkCompare = false;
                configFieldToRange.error = true;
                configFieldToRange.errorCompare = '回答値の有効範囲（最小）が回答値の有効範囲（最大）を超えています。';
            } else {
                configFieldToRange.error = false;
                configFieldToRange.errorCompare = '';
            }
            return checkCompare;
        },

        handleFieldNumberAfterDecimalPoint() {
            let { block_input } = this.formBlock;
            let { configFieldNumberOfDigits, configFieldFromRange, configFieldToRange, configFieldNumberAfterDecimalPoint } = this.configFormInput;
            configFieldNumberAfterDecimalPoint.max = block_input.number_of_decimal_places;
            configFieldNumberAfterDecimalPoint.error = false;
            block_input.number_after_decimal_point = '';

            if (this.isNumericValueIncludingDecimalPoint) {
                block_input.from_range = '';
                block_input.to_range = '';
                configFieldFromRange.error = false;
                configFieldToRange.error = false;
                if (!configFieldNumberOfDigits.error) {
                    configFieldFromRange.integer = Number(block_input.number_of_digits) - Number(block_input.number_of_decimal_places);
                    configFieldFromRange.decimal = Number(block_input.number_of_decimal_places);
                    configFieldToRange.integer = Number(block_input.number_of_digits) - Number(block_input.number_of_decimal_places);
                    configFieldToRange.decimal = Number(block_input.number_of_decimal_places);
                }
            }
        },

        handleFieldNumberOfDigits() {
            let { block_input } = this.formBlock;
            let { configFieldFromRange, configFieldToRange, configFieldNumberAfterDecimalPoint } = this.configFormInput;
            if (!this.isNumericValueIncludingDecimalPoint) return;
            block_input.from_range = '';
            block_input.to_range = '';
            configFieldFromRange.error = false;
            configFieldToRange.error = false;
            if (!configFieldNumberAfterDecimalPoint.error) {
                configFieldFromRange.integer = Number(block_input.number_of_digits) - Number(block_input.number_of_decimal_places);
                configFieldFromRange.decimal = Number(block_input.number_of_decimal_places);
                configFieldToRange.integer = Number(block_input.number_of_digits) - Number(block_input.number_of_decimal_places);
                configFieldToRange.decimal = Number(block_input.number_of_decimal_places);
            }
        },

        handleChangeInputAfterDecimalPoint() {
            let { block_input } = this.formBlock;
            let { configFieldFromRange, configFieldToRange } = this.configFormInput;

            block_input.from_range = '';
            block_input.to_range = '';
            configFieldFromRange.error = false;
            configFieldToRange.error = false;
            configFieldFromRange.decimal = Number(block_input.number_after_decimal_point);
            configFieldToRange.decimal = Number(block_input.number_after_decimal_point);
        },

        onHandleOperatorLimit() {
            this.configFormInput.configFieldNumberLimitCheck.typesValidate = ['required', 'max255', 'limit-input'];
            this.formBlock.number_limit_check = '';
        },

        onHandleRemoveOperatorLimit() {
            this.formBlock.number_limit_check = '';
            this.configFormInput.configFieldNumberLimitCheck.error = false;
            this.configFormInput.configFieldNumberLimitCheck.typesValidate = ['max255', 'limit-input'];
        },

        handleCancelBlock() {
            this.$emit('cancel', false);
        },

        handleSaveStep1() {
            this.onHandleConditionOtherBeforeSave();
            let check = validateAllField(this.configFormInput);
            let uniqueCheck = this.onHandlerUniqueBlock();
            let hasCompareRange = this.onHandleCompareRange(this.formBlock.block_input.from_range, this.formBlock.block_input.to_range);
            let hasNumberLimit = this.onHandleNumberLimit();
            // for (let property in this.configFormInput) {
            //     console.log(`${property}: ${this.configFormInput[property].error}`);
            // }
            if (!check || !uniqueCheck || !hasCompareRange || !hasNumberLimit) return;
            if (this.formBlock.block_type !== 'get-param' && this.formBlock.block_type !== 'div_1') {
                this.isSettingStep1 = !check;
            } else {
                this.$emit('showSave', !check);
                this.saveStateBlockForm(this.formBlock);
            }
        },

        onHandlerUniqueBlock() {
            let result = true;
            let stateListBlockCreated = this.$store.state.managerForm.listBlockCreated;
            const valueName = this.formBlock.name?.trim() ?? '';
            if (!valueName) return result;
            if (this?.formBlock?.id) {
                const objOtherId = stateListBlockCreated.find((itemBlock) => itemBlock.id !== this?.formBlock?.id);
                if (objOtherId?.name === valueName) {
                    showMessage(400, this.$bvToast, '入力されたブロック名は既に使われています。');
                    result = false;
                }
            } else {
                if (stateListBlockCreated.some((stateBlockItem) => stateBlockItem.name === valueName)) {
                    showMessage(400, this.$bvToast, '入力されたブロック名は既に使われています。');
                    result = false;
                }
            }
            return result;
        },

        onHandleConditionOtherBeforeSave() {
            let {
                configFieldLabelBlock,
                configFieldLabel_Q_Block1,
                configFieldLabel_A_Block2,
                configFieldMaximumFileSize,
                configFieldKeywordUpload,
                configFieldParamName,
                configFieldMaxCharacters,
                configFieldNumberOfDigits,
                configFieldLabelOther,
                configFieldNumberOfDecimalPlaces,
                configFieldLabelUncheck
            } = this.configFormInput;

            if (!this.isActiveLabel) {
                configFieldLabelBlock.typesValidate = [];
            }
            if (!this.isActiveDiv2Label) {
                configFieldLabel_Q_Block1.typesValidate = [];
                configFieldLabel_A_Block2.typesValidate = [];
            }
            if (!this.isActiveMaximumFileSize) {
                configFieldMaximumFileSize.typesValidate = [];
            }

            if (!this.isActiveKeywordUpload) {
                configFieldKeywordUpload.typesValidate = [];
            }

            if (!this.isActiveParamName) {
                configFieldParamName.typesValidate = [];
            }

            if (!this.actionSelectCommon) {
                this.configFormInput.validateArrayOption = this.configFormInput.validateArrayOption.map((vld) => {
                    return {
                        configFieldValue: {
                            ...vld.configFieldValue,
                            typesValidate: []
                        },
                        configFieldNumberOfReceptions: {
                            ...vld.configFieldNumberOfReceptions,
                            typesValidate: []
                        }
                    };
                });
            } else {
                if (this?.config?.type !== 'remaining-seats-management-box') {
                    this.configFormInput.validateArrayOption = this.configFormInput.validateArrayOption.map((vld) => {
                        return {
                            configFieldValue: {
                                ...vld.configFieldValue
                            },
                            configFieldNumberOfReceptions: {
                                ...vld.configFieldNumberOfReceptions,
                                typesValidate: []
                            }
                        };
                    });
                }
            }

            if (!this.isActiveMaxcharacters) {
                configFieldMaxCharacters.typesValidate = [];
            }

            if (!this.isNumericValueIncludingDecimalPoint) {
                configFieldNumberOfDigits.typesValidate = [];
                configFieldNumberOfDecimalPlaces.typesValidate = [];
            }

            if (!this.isActiveOptionMatrix) {
                this.configFormInput.validateArrayChoicesAtTheTopMatrix = this.configFormInput.validateArrayChoicesAtTheTopMatrix.map((vld) => {
                    return {
                        configFieldChoicesAtTheTop: {
                            ...vld.configFieldChoicesAtTheTop,
                            typesValidate: []
                        }
                    };
                });
                this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix = this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix.map(
                    (vld) => {
                        return {
                            configFieldChoicesOnTheFrontSide: {
                                ...vld.configFieldChoicesOnTheFrontSide,
                                typesValidate: []
                            }
                        };
                    }
                );
            }

            if (!this.isActiveOptionOther) {
                configFieldLabelOther.typesValidate = [];
                configFieldLabelUncheck.typesValidate = [];
            }
        },

        onHandleOtherUserCheckbox() {
            this.formBlock.setting_others.value_other_required = false;
        },

        handleReturnToDesign() {
            this.formBlock.cell_background_color.bg_question = '';
            this.formBlock.cell_background_color.bg_answer = '';
        },

        handleChangeTypeYear() {
            let { block_date } = this.formBlock;
            let { configFieldLowerLimit, configFieldUpperLimit, configFieldPlaceholderYear } = this.configFormInput;

            block_date.format_year.type_calendar = 1;
            block_date.format_year.placeholder = '';
            block_date.format_year.lower_limit = '';
            block_date.format_year.upper_limit = '';
            configFieldLowerLimit.error = false;
            configFieldUpperLimit.error = false;
            configFieldPlaceholderYear.error = false;
        },

        handleChangeTypeMonthDay() {
            let { block_date } = this.formBlock;
            let { configFieldPlaceholderMonth, configFieldPlaceholderDay } = this.configFormInput;

            block_date.format_month_day.placeholder_month = '';
            block_date.format_month_day.placeholder_day = '';
            configFieldPlaceholderMonth.error = '';
            configFieldPlaceholderDay.error = '';
        },

        handleChangeRemainingItem() {
            this.formBlock.block_remaining_number.display_content = 1;
            this.handleChangeDisplayContentItem();
            this.formBlock.block_remaining_number.color_text = '';
        },

        handleChangeDisplayContentItem() {
            let { block_remaining_number } = this.formBlock;
            block_remaining_number.remaining_text = '';
            block_remaining_number.text_after_remaining_number = '';
            block_remaining_number.remaining_ratio = '';
            block_remaining_number.label_other = '';
        },

        handleFileKeywordUpload(file) {
            // console.log('file', file);
            this.formBlock.block_keyword_box.keyword_characters = file?.url;
        },

        handleBackStep1(isBack) {
            this.isSettingStep1 = isBack;
        },
        handleSaveStep2(isSave) {
            this.saveStateBlockForm(this.formBlock);
            this.$emit('showSave', isSave);
        },

        handleChangeFeatureSearchAddress(blockPostcode) {
            blockPostcode.prefecture_insertion = null;
            blockPostcode.city_insertion = null;
            blockPostcode.town_area_name_insertion = null;
        },

        onHandleChangeInputFormat() {
            let { block_input, input_format } = this.formBlock;
            let {
                configFieldFromRange,
                configFieldToRange,
                configFieldMaxCharacters,
                configFieldNumberAfterDecimalPoint,
                configFieldNumberOfDigits,
                configFieldNumberOfDecimalPlaces
            } = this.configFormInput;

            block_input.from_range = '';
            block_input.to_range = '';
            block_input.number_after_decimal_point = '';
            configFieldFromRange.error = false;
            configFieldToRange.error = false;
            configFieldMaxCharacters.error = false;
            configFieldNumberAfterDecimalPoint.error = false;

            if (input_format?.type_input === 'numerical_value') {
                this.formBlock.max_characters = 10;
                configFieldMaxCharacters.max = 10;
                configFieldFromRange.typesValidate = ['integerMaxMin'];
                configFieldToRange.typesValidate = ['integerMaxMin'];
                configFieldToRange.maxlength = 11;
                configFieldToRange.maxlength = 11;
            } else if (this.isNumericValueIncludingDecimalPoint) {
                block_input.number_of_digits = 15;
                block_input.number_of_decimal_places = 5;
                configFieldNumberOfDigits.error = false;
                configFieldNumberOfDecimalPlaces.error = false;
                configFieldNumberOfDigits.typesValidate = ['required', 'max255', 'limit-input'];
                configFieldNumberOfDecimalPlaces.typesValidate = ['required', 'max255', 'limit-input'];
                configFieldFromRange.typesValidate = ['integerDecimal'];
                configFieldToRange.typesValidate = ['integerDecimal'];
                configFieldFromRange.maxlength = '';
                configFieldToRange.maxlength = '';
            } else {
                this.formBlock.max_characters = 255;
                configFieldMaxCharacters.max = 255;
            }
        },

        handleChangeInputMaxCharacters() {
            let { block_input, input_format, max_characters } = this.formBlock;
            let { configFieldFromRange, configFieldToRange, configFieldMaxCharacters } = this.configFormInput;
            if (input_format?.type_input === 'numerical_value') {
                if (!configFieldMaxCharacters.error) {
                    let valueMinMax = this.displayIncreasingNumbers(max_characters);
                    configFieldFromRange.maxlength = Number(max_characters) + 1;
                    configFieldToRange.maxlength = Number(max_characters) + 1;
                    block_input.from_range = '';
                    block_input.to_range = '';
                    configFieldFromRange.min = max_characters < 10 ? `-${valueMinMax}` : '-2147483648';
                    configFieldFromRange.max = max_characters < 10 ? `${valueMinMax}` : '2147483647';
                    configFieldToRange.min = max_characters < 10 ? `-${valueMinMax}` : '-2147483648';
                    configFieldToRange.max = max_characters < 10 ? `${valueMinMax}` : '2147483647';
                    configFieldFromRange.error = false;
                    configFieldToRange.error = false;
                }
            } else {
                configFieldFromRange.maxlength = 255;
                configFieldToRange.maxlength = 255;
            }
        },

        displayIncreasingNumbers(input) {
            const num = parseInt(input, 10);
            return '9'.repeat(num);
        }
    },
    mounted() {
        // console.log('TYPE_BLOCKS', this.TYPE_BLOCKS);
    },
    watch: {
        actionTimeAddBlock: {
            handler: function () {
                this.isSettingStep1 = true;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.border-top-1-color {
    border-top: 1px solid #0000001a;
}
.return__page--design {
    border: #ff5800 1px solid;
    padding: 2px 10px;
    color: #ff5800;
    margin: 10px 0 0 0;
    font-size: 13px;
    border-radius: 5px;
    text-align-last: center;
    line-height: 35px;
    text-decoration: none;
    font-weight: 600;
    &:hover {
        opacity: 0.6;
    }
}
</style>
