<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

import { masterComputed, masterMethods, faqMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { keyMaster } from '@/config/constants';
import { required } from 'vuelidate/lib/validators';
import { showMessage } from '@/utils/messages.js';
import { InputCheckBox, InputRadio } from '@/components/Input';

export default {
    page: {
        title: 'FAQ新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer,
        InputCheckBox,
        InputRadio
    },
    data() {
        return {
            index: 1,
            loading: false,
            id: this.$route.query.id,
            answerDuplicate: {},
            form: {
                sheet: {
                    id: ''
                },
                campaign_type: {
                    id: ''
                },
                has_answer_default: false,
                categories: [],
                application_type: [],
                question: '',
                answers: [
                    {
                        answer: ''
                    }
                ]
            },

            listSheetFAQ: [],
            listDataCPTypes: [],
            listApplicationType: [],
            listDataMasterCondition1: [],
            listDataMasterCondition2: [],
            submitted: false,
            submit: false,
            recivedIDCP: '',
            listShortCode: [
                '《キャンペーン名》',
                '《事務局開始》',
                '《事務局終了》',
                '《キャンペーン開始》',
                '《キャンペーン終了》',
                '《キャンペーン事務局TEL》',
                '《キャンペーン事務局Mailアドレス》',
                '《クライアント名》',
                '《グループの合計》',
                '《グループの対象商品含む》',
                '《グループの対象商品》',
                '《公式Instagramアカウント名》',
                '《年末年始休み》'
            ],
            draggedItem: null
        };
    },
    computed: {
        ...masterComputed
    },
    validations: {
        form: {
            sheets: { required },
            campaign_types: { required },
            application_type: { required },
            question: { required },
            answers: { required }
        }
    },

    watch: {
        'form.campaign_type.id': function () {
            if (this.form.campaign_type.id !== this.recivedIDCP) {
                this.recivedIDCP = this.form.campaign_type.id;
                if (this.form.application_type.length) this.form.application_type = [];
            }

            if (this.$route.query.id && this.form.application_type.length) return;
            const findApplicationType = this.listDataCPTypes.find((item) => item.id === this.form.campaign_type.id);
            if (findApplicationType) {
                this.listApplicationType = findApplicationType.data;
            }
        },

        'form.application_type': function () {
            this.addForm(this.form.application_type);
            if (this.form.application_type.length !== this.form.categories.length) {
                this.form.categories = this.form.categories.filter((item) => this.form.application_type.find((el) => el === item.category.id));
            }
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...faqMethods,
        // call api

        async getListMasterTypes() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['campaign.type']);
                this.listDataCPTypes = data;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.loading = false;
            }
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getDetail(id) {
            this.loading = true;
            this.detailFaq(id).then((data) => {
                const findApplicationType = this.listDataCPTypes.find((item) => item.id === data.campaign_type.id);
                if (findApplicationType) {
                    this.listApplicationType = findApplicationType.data;
                }

                this.form.sheet.id = data.sheet.id;
                this.form.question = data.question;
                this.form.campaign_type.id = data.campaign_type.id;
                this.recivedIDCP = data.campaign_type.id;
                this.form.has_answer_default = data.has_answer_default;
                this.form.answers = data.answers;
                this.form.application_type = data.categories.map((item) => item.category.id);
                this.form.categories = [];
                for (let i = 0; i < data.categories.length; i++) {
                    const findArrayCondition1 = this.listApplicationType.find((item) => item.id === data.categories[i].category.id);
                    let obj = {
                        id: data.categories[i].id,
                        category: {
                            id: data.categories[i].category.id
                        },
                        listDataMasterCondition1: findArrayCondition1.data,
                        category_conditions: [],
                        application_condition_1: []
                    };
                    data.categories[i].category_conditions.forEach((item) => {
                        // let dataObject = []
                        findArrayCondition1.data.forEach((jtem) => {
                            if (jtem.id == item.application_condition_1.id) {
                                obj.category_conditions.push({
                                    application_condition_1: item.application_condition_1,
                                    application_condition_2_ids: item.application_condition_2_ids.map((item) => item.id),
                                    answer_ids: item.answers_ids.length <= 0 ? [{ answer: '' }] : item.answers_ids,
                                    listDataMasterCondition2: jtem.data
                                });
                                obj.application_condition_1.push(item.application_condition_1.id);
                            }
                        });
                    });

                    this.form.categories.push(obj);
                    this.loading = false;
                }
            });
        },

        async getListMasterSheets() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['faq_master.sheets']);
                this.listSheetFAQ = data;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.loading = false;
            }
        },
        // handle Form
        resetForm() {
            this.recivedIDCP = '';
            this.form = {
                sheet: {
                    id: ''
                },
                campaign_type: {
                    id: ''
                },
                has_answer_default: false,
                categories: [],
                application_type: [],
                question: '',
                answers: [
                    {
                        answer: ''
                    }
                ]
            };
            this.listApplicationType = this.listDataMasterCondition1 = this.listDataMasterCondition2 = [];
        },
        addCommonAnswers() {
            if (this.form.answers.length > 9) return;
            this.form.answers.push({
                answer: ''
            });
        },
        removeCommonAnswers(k) {
            this.form.answers.splice(k, 1);
        },
        subString(string) {
            if (string) {
                if (string.length > 30) return string.substring(0, 30) + '...';
                return string;
            }
            return '';
        },
        addAnswers(params, k) {
            for (let i = 0; i < this.form.categories.length; i++) {
                if (this.form.categories[i].category.id === params) {
                    if (this.form.categories[i].category_conditions[k].answer_ids.length > 9) return;
                    this.form.categories[i].category_conditions[k].answer_ids.push({
                        answer: ''
                    });
                }
            }
        },

        removeAnswers(params, k, k1) {
            for (let i = 0; i < this.form.categories.length; i++) {
                if (this.form.categories[i].category.id === params) {
                    this.form.categories[i].category_conditions[k].answer_ids.splice(k1, 1);
                }
            }
        },

        removeBox(params) {
            const findIdx = this.form.application_type.findIndex((item) => item === params);
            this.form.application_type.splice(findIdx, 1);
        },

        addForm(paramId) {
            for (let i = 0; i < paramId.length; i++) {
                const findArrayCondition1 = this.listApplicationType.find((item) => item.id === paramId[i]);
                if (!this.form.categories.length) {
                    this.form.categories.push({
                        category: {
                            id: paramId[i]
                        },
                        category_conditions: [],
                        application_condition_1: [],
                        validate_condition_1: false,
                        listDataMasterCondition1: findArrayCondition1.data,
                        application_condition_2_id: [],
                        answers: [
                            {
                                answer: ''
                            }
                        ]
                    });
                } else {
                    const findIdAlike = this.form.categories.find((item) => item.category.id === paramId[i]);
                    if (!findIdAlike) {
                        this.form.categories.push({
                            category: {
                                id: paramId[i]
                            },
                            category_conditions: [],
                            listDataMasterCondition1: findArrayCondition1.data,
                            application_condition_1: [],
                            validate_condition_1: false,
                            application_condition_2_id: [],
                            answers: [
                                {
                                    answer: ''
                                }
                            ]
                        });
                    }
                }
            }
        },
        handleChangeCondition1() {
            for (let i = 0; i < this.form.categories.length; i++) {
                this.form.categories[i].application_condition_1.forEach((item) => {
                    let arr = JSON.parse(JSON.stringify(this.form.categories[i].category_conditions));
                    const findIdAlike = arr.find((i) => i.application_condition_1 && i.application_condition_1.id === item);
                    if (!findIdAlike) {
                        this.form.categories[i].listDataMasterCondition1.forEach((xtem) => {
                            if (xtem.id == item) {
                                this.form.categories[i].category_conditions.push({
                                    application_condition_1: { id: item },
                                    listDataMasterCondition2: xtem.data,
                                    application_condition_2_ids: [],
                                    answer_ids: [{ answer: '' }]
                                });
                            }
                        });
                    }
                });

                this.form.categories[i].category_conditions.forEach((item, k) => {
                    let id = this.form.categories[i].application_condition_1.findIndex((x) => {
                        return item.application_condition_1 && x === item.application_condition_1.id;
                    });
                    if (id < 0) {
                        this.form.categories[i].category_conditions.splice(k, 1);
                    }
                });
            }
        },

        handleValidate() {
            let check = true;
            if (!this.form.sheet.id || !this.form.application_type.length) {
                check = false;
            }

            if (this.form.has_answer_default) {
                if (this.form.answers.length) {
                    for (let y = 0; y < this.form.answers.length; y++) {
                        if (!this.form.answers[y].answer) {
                            check = false;
                        }
                    }
                }
            }

            for (let i = 0; i < this.form.categories.length; i++) {
                if (!this.form.categories[i].application_condition_1) {
                    check = false;
                }

                if (!this.form.has_answer_default) {
                    for (let x = 0; x < this.form.categories[i].category_conditions.length; x++) {
                        for (let y = 0; y < this.form.categories[i].category_conditions[x].answer_ids.length; y++) {
                            if (!this.form.categories[i].category_conditions[x].answer_ids[y].answer) {
                                check = false;
                            }
                        }
                    }
                }
            }

            return check;
        },
        checkDisabel(dt, it) {
            let i = dt.findIndex((x) => {
                return x.answer && x.answer == it;
            });
            if (i >= 0) return true;
            else return false;
        },
        renderTitleBox(idCategory) {
            return this.listApplicationType.find((item) => item.id === idCategory)?.value;
        },
        renderTitleBoxChild(idCategoryChild, data) {
            return data.find((item) => item.id === idCategoryChild)?.value;
        },
        // send serve
        formSubmit() {
            this.submitted = true;
            this.form.categories.forEach((item) => {
                if (item.application_condition_1 && item.application_condition_1.length <= 0) {
                    return true;
                }
            });

            this.$v.$touch();
            const isValidate = this.handleValidate();
            if (!isValidate) return;
            this.register();
        },
        validateDuplicate(k, data) {
            this.answerDuplicate = {};
            this.form.answers.forEach((item, i) => {
                if (i != k) {
                    if (item.answer == data) this.answerDuplicate[k] = true;
                }
            });
            this.form.categories.forEach((item) => {
                item.category_conditions.forEach((jtem) => {
                    jtem.answer_ids.forEach((ktem) => {
                        let i = this.form.answers.findIndex((atem) => {
                            return atem.answer == ktem.answer;
                        });
                        if (i < 0) {
                            ktem.answer = '';
                        }
                    });
                });
            });
        },
        register() {
            if (this.form.question && !this.loading) {
                let obj = JSON.parse(JSON.stringify(this.form));
                delete obj.application_type;
                // loại bỏ 1 số trường
                let check = false;
                obj.categories.filter((x) => {
                    if (x.application_condition_1 && x.application_condition_1.length <= 0) {
                        check = true;
                    }
                    delete x.application_condition_1;
                    delete x.application_condition_2_id;
                    delete x.answers;
                });
                if (check) return;
                this.loading = true;
                if (this.$route.query.id) {
                    obj.id = this.$route.query.id;
                    this.updateFaqs(obj).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, 'FAQが更新されました。');
                            this.getDetail(this.$route.query.id);
                        }
                        if (data.code != 200) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                            this.loading = false;
                        }
                    });
                } else {
                    this.loading = true;
                    this.registerFaq(obj).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/faq/view', query: { id: data.data.id } }).then(() => {
                                showMessage(data.code, this.$bvToast, 'FAQが登録されました。');
                            });
                        }
                        if (data.code != 200) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                            this.loading = false;
                        }
                    });
                }
            }
        },
        dragShortCode(item) {
            this.draggedItem = item;
        },
        dropPositionShortCode(event) {
            if (event.toElement.id === 'question') {
                this.form.question += this.draggedItem;
                return;
            }
            this.form.answers.forEach((item, i) => {
                if (event.toElement.id === `answer-${i}`) {
                    this.form.answers[i].answer += this.draggedItem;
                    return;
                }
            });
        }
    },
    async mounted() {
        await this.getListMasterTypes();
        this.getListMasterSheets();
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
        }
    },
    beforeRouteUpdate(to, from, next) {
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
        }
        next();
    },
    beforeDestroy() {
        this.recivedIDCP = '';
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-12 row flex-nowrap">
                                <div>
                                    <form
                                        class="needs-validation"
                                        @submit.prevent="formSubmit"
                                        autocomplete="off"
                                        @dragover.prevent
                                        @drop="dropPositionShortCode"
                                    >
                                        <div class="form-group">
                                            <label class="col-sm-3 mw--form" for="validationCustom05"
                                                >{{ $t('faq.sheets') }}
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="col-sm-9">
                                                <div class="d-flex flex-wrap">
                                                    <div style="min-width: 100px" v-for="item in listSheetFAQ" :key="item.id">
                                                        <InputRadio
                                                            class="mr-4 py-1"
                                                            :model.sync="form.sheet.id"
                                                            :id="`radio_sheet${item.id}`"
                                                            :value="item.id"
                                                            :label="item.value"
                                                        />
                                                    </div>
                                                </div>
                                                <span
                                                    :class="{
                                                        'is-invalid': submitted && $v.form.sheets.$error
                                                    }"
                                                ></span>
                                                <div v-if="submitted && $v.form.sheets.$error" class="invalid-feedback">
                                                    <span v-if="!$v.form.sheets.required && !form.sheet.id">{{
                                                        $t('validateField.requiredSelect', {
                                                            field: $t('faq.sheets')
                                                        })
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-3 mw--form" for="validationCustom05"
                                                >{{ $t('faq.campaignType') }}
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="col-sm-9">
                                                <div class="d-flex">
                                                    <div v-for="cpt in listDataCPTypes" :key="cpt.id + 's'">
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="form.campaign_type.id"
                                                            :id="`radio${cpt.id}`"
                                                            :value="cpt.id"
                                                            :label="cpt.value"
                                                        />
                                                    </div>
                                                </div>
                                                <span
                                                    :class="{
                                                        'is-invalid': submitted && $v.form.campaign_types.$error
                                                    }"
                                                ></span>
                                                <div v-if="submitted && $v.form.campaign_types.$error" class="invalid-feedback">
                                                    <span v-if="!$v.form.campaign_types.required && !form.campaign_type.id">{{
                                                        $t('validateField.requiredSelect', {
                                                            field: $t('faq.campaignType')
                                                        })
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="form?.campaign_type?.id" class="form-group">
                                            <label class="col-sm-3 mw--form" for="validationCustom05"
                                                >{{ $t('faq.applicationType') }}
                                                <span v-if="listApplicationType.length > 0" class="text-danger">*</span>
                                            </label>
                                            <div class="col-sm-9">
                                                <div class="d-flex flex-wrap">
                                                    <div style="min-width: 100px" v-for="apTypes in listApplicationType" :key="apTypes.id">
                                                        <InputCheckBox
                                                            class="mr-4 py-1"
                                                            :model.sync="form.application_type"
                                                            :id="`check_app${apTypes.id}`"
                                                            :value="apTypes.id"
                                                            :label="apTypes.value"
                                                        />
                                                    </div>
                                                </div>
                                                <span
                                                    :class="{
                                                        'is-invalid': submitted && $v.form.application_type.$error && listApplicationType.length > 0
                                                    }"
                                                ></span>
                                                <div
                                                    v-if="submitted && $v.form.application_type.$error && listApplicationType.length > 0"
                                                    class="invalid-feedback"
                                                >
                                                    <span v-if="!$v.form.application_type.required && listApplicationType.length > 0">{{
                                                        $t('validateField.requiredSelect', {
                                                            field: $t('faq.applicationType')
                                                        })
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-3 mw--form" for="question"
                                                >{{ $t('faq.question') }}
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="col-sm-9">
                                                <textarea
                                                    id="question"
                                                    v-model="form.question"
                                                    class="form-control"
                                                    rows="4"
                                                    :class="{
                                                        'is-invalid': submitted && $v.form.question.$error
                                                    }"
                                                    :placeholder="$t('faq.placeholderQuestion')"
                                                ></textarea>
                                                <div v-if="submitted && $v.form.question.$error" class="invalid-feedback">
                                                    <span v-if="!$v.form.question.required">{{
                                                        $t('validateField.required', {
                                                            field: $t('faq.question')
                                                        })
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('faq.commonAsk') }} </label>
                                            <div class="col-sm-9">
                                                <InputCheckBox
                                                    :model.sync="form.has_answer_default"
                                                    :id="`check-has_answer_default`"
                                                    :value="form.has_answer_default"
                                                    :label="``"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group" v-for="(pASk, k) in form.answers" :key="'p' + k">
                                            <label class="col-sm-3 mw--form" :for="`answer-${k}`">回答 <span class="text-danger">*</span> </label>
                                            <div class="col-sm-9">
                                                <div class="mb-3">
                                                    <div class="d-flex align-items-center" style="column-gap: 15px">
                                                        <div class="w-100">
                                                            <textarea
                                                                :id="`answer-${k}`"
                                                                v-model="form.answers[k].answer"
                                                                class="form-control"
                                                                @change="validateDuplicate(k, form.answers[k].answer)"
                                                                :class="{
                                                                    'is-invalid': (submitted && !form.answers[k].answer) || answerDuplicate[k]
                                                                }"
                                                            ></textarea>
                                                            <div v-if="submitted && !form.answers[k].answer" class="invalid-feedback">
                                                                <span>{{
                                                                    $t('validateField.required', {
                                                                        field: '回答'
                                                                    })
                                                                }}</span>
                                                            </div>

                                                            <div v-if="answerDuplicate[k]" class="invalid-feedback">
                                                                <span>重複回答を入力しないようにご注意ください。</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button
                                                                v-if="k === 0"
                                                                type="button"
                                                                class="btn btn-sm btn-outline-info"
                                                                style="border-radius: 50%"
                                                                @click="addCommonAnswers()"
                                                            >
                                                                <i class="fa fa-plus"></i>
                                                            </button>
                                                            <button
                                                                v-else
                                                                type="button"
                                                                class="btn btn-outline-danger btn-sm"
                                                                style="border-radius: 50%"
                                                                @click="removeCommonAnswers(k)"
                                                            >
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Loop Form  -->
                                        <div v-if="form.application_type.length" class="px-2 pr-3">
                                            <div
                                                v-for="(ct, dx) in form.categories"
                                                :key="dx"
                                                class="py-3 mt-3"
                                                style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px"
                                            >
                                                <label class="col-sm-3 mw--form" for="validationCustom05"
                                                    >{{ renderTitleBox(ct.category.id) }}
                                                </label>

                                                <div class="text-right">
                                                    <button
                                                        type="button"
                                                        class="btn btn-outline-danger btn-sm mr-3 mb-2"
                                                        style="border-radius: 50%"
                                                        @click="removeBox(ct.category.id)"
                                                    >
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                                <div class="form-group">
                                                    <label class="col-sm-3 mw--form" for="validationCustom05"
                                                        >{{ $t('faq.condition1') }}
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <div class="col-sm-9">
                                                        <div class="d-flex flex-wrap">
                                                            <div
                                                                style="min-width: 100px"
                                                                v-for="apTypes in ct.listDataMasterCondition1"
                                                                :key="apTypes.id"
                                                            >
                                                                <InputCheckBox
                                                                    class="mr-4 py-1"
                                                                    :model.sync="ct.application_condition_1"
                                                                    :id="`${dx}check_appss${apTypes.id}`"
                                                                    :value="apTypes.id"
                                                                    :label="apTypes.value"
                                                                    @change="handleChangeCondition1"
                                                                />
                                                            </div>
                                                        </div>
                                                        <span
                                                            :class="{
                                                                'is-invalid':
                                                                    submitted && ct.application_condition_1 && ct.application_condition_1.length == 0
                                                            }"
                                                        ></span>
                                                        <div
                                                            v-if="submitted && ct.application_condition_1 && ct.application_condition_1.length == 0"
                                                            class="invalid-feedback"
                                                        >
                                                            <span v-if="ct.application_condition_1 && ct.application_condition_1.length == 0">{{
                                                                $t('validateField.requiredSelect', {
                                                                    field: $t('faq.condition1')
                                                                })
                                                            }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-for="(ch, k) in ct.category_conditions" :key="`ch${k}`">
                                                    <hr />
                                                    <label class="" style="padding-left: 15px">{{
                                                        renderTitleBoxChild(ch.application_condition_1.id, ct.listDataMasterCondition1)
                                                    }}</label>
                                                    <div class="form-group">
                                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('faq.condition2') }} </label>
                                                        <div class="col-sm-9">
                                                            <div class="d-flex flex-wrap">
                                                                <div
                                                                    style="min-width: 100px"
                                                                    v-for="cdt2 in ch.listDataMasterCondition2"
                                                                    :key="cdt2.id"
                                                                >
                                                                    <InputCheckBox
                                                                        class="mr-4 py-1"
                                                                        :model.sync="ch.application_condition_2_ids"
                                                                        :id="`${k}check_condition2${cdt2.id}`"
                                                                        :value="cdt2.id"
                                                                        :label="cdt2.value"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="!form.has_answer_default" class="form-group">
                                                        <label class="col-sm-3 mw--form" for="validationCustom052222"
                                                            >{{ $t('faq.ask') }}
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <div class="col-sm-9">
                                                            <div>
                                                                <div v-for="(pASk, k1) in ch.answer_ids" :key="'p' + k1" class="mb-3">
                                                                    <div class="d-flex align-items-center" style="column-gap: 15px">
                                                                        <div class="w-100">
                                                                            <select
                                                                                v-model="ch.answer_ids[k1].answer"
                                                                                class="form-control box-se"
                                                                                :class="{
                                                                                    'is-invalid': submitted && !ch.answer_ids[k1].answer
                                                                                }"
                                                                            >
                                                                                <option
                                                                                    :disabled="checkDisabel(ch.answer_ids, item.answer)"
                                                                                    :title="item.answer"
                                                                                    :value="item.answer"
                                                                                    v-for="(item, ks) in form.answers"
                                                                                    :key="item.answer + ks"
                                                                                >
                                                                                    {{ subString(item.answer) }}
                                                                                </option>
                                                                            </select>
                                                                            <div
                                                                                v-if="submitted && !ch.answer_ids[k1].answer"
                                                                                class="invalid-feedback"
                                                                            >
                                                                                <span>{{
                                                                                    $t('validateField.required', {
                                                                                        field: $t('faq.ask')
                                                                                    })
                                                                                }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <button
                                                                                v-if="k1 === 0"
                                                                                type="button"
                                                                                class="btn btn-sm btn-outline-info"
                                                                                style="border-radius: 50%"
                                                                                @click="addAnswers(ct.category.id, k)"
                                                                            >
                                                                                <i class="fa fa-plus"></i>
                                                                            </button>
                                                                            <button
                                                                                v-if="k1 !== 0"
                                                                                type="button"
                                                                                class="btn btn-outline-danger btn-sm"
                                                                                style="border-radius: 50%"
                                                                                @click="removeAnswers(ct.category.id, k, k1)"
                                                                            >
                                                                                <i class="fa fa-minus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-3" style="border-left: 1px solid #c1c1c173">
                                    <ul style="list-style: none; padding-left: 0">
                                        <li
                                            v-for="(itemShortCode, idx) in listShortCode"
                                            :key="idx"
                                            @dragstart="dragShortCode(itemShortCode)"
                                            draggable="true"
                                            style="
                                                border-radius: 5px;
                                                border: 1px solid rgb(221, 221, 221);
                                                margin-bottom: 12px;
                                                cursor: grab;
                                                width: 100%;
                                                text-align: center;
                                                padding: 4px 5px;
                                            "
                                        >
                                            {{ itemShortCode }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/faqs'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            v-if="checkPermission('faq.register') || (checkPermission('faq.edit') && $route.query.id)"
                            class="btn btn-primary ml-3"
                            @click="formSubmit"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
