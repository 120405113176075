<template>
    <div>
        <div class="mb-3 pb-3" style="border-bottom: 1px solid #949494">
            <div>
                <h5>
                    {{ this.$t('managerForm.settingPage.basicSettings') }}
                </h5>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-2">
                <label class="text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.nameBlock') }} </label>
                <div class="my-3">
                    <button type="button" class="btn text-nowrap return__edit_with_editor" @click="handleToggleEditor">
                        {{ !isActiveEditor ? $t('managerForm.settingPage.editWithEditor') : $t('managerForm.settingPage.previewDisplay') }}
                    </button>
                </div>
                <div>
                    <p class="btn text-nowrap return__restore--default--settings mb-0" @click="handleRestoreDefaultSettings">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                        {{ $t('managerForm.settingPage.restoreDefaultSettings') }}
                    </p>
                </div>
            </div>

            <div class="col-sm-10">
                <div v-if="!isActiveEditor">
                    <div class="d-flex" style="gap: 15px">
                        <span>{{ $t('managerForm.settingPage.noteReset') }}</span>
                        <span class="btn p-0" @click="handleSetValueDefault" style="color: #339162"
                            ><u>{{ $t('managerForm.settingPage.uncheckRadioButton') }} </u>
                        </span>
                    </div>
                </div>

                <div v-else>
                    <!-- <Editor
                        :id="`managerForm_settingPage_editWithEditor`"
                        :model.sync="formBlock.additional_setting.edit_with_editor"
                        :configError.sync="configFormInput.configFieldEditWithEditor"
                        @change="handleValidateField(configFormInput.configFieldEditWithEditor)"
                    /> -->
                    <QuillEditor
                        :id="`managerForm_settingPage_editWithEditor`"
                        :refQuill="`managerForm_settingPage_editWithEditor`"
                        :model.sync="formBlock.additional_setting.edit_with_editor"
                        :config.sync="configFormInput.configFieldEditWithEditor"
                        @blur="handleValidateField(configFormInput.configFieldEditWithEditor)"
                    ></QuillEditor>
                </div>
            </div>
        </div>

        <div v-if="isActiveTableMess" class="form-group row pt-3 border-top-1-color">
            <div class="col-sm-2">
                <label class="text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.errorMessage') }} </label>
            </div>

            <div class="col-sm-10">
                <div v-if="!isTableErrorMsg">
                    <button type="button" class="btn text-nowrap return__edit_with_editor" @click="handleToggleErrorMsg">
                        {{ $t('managerForm.settingPage.editErrorMessage') }}
                    </button>
                    <p class="mt-3">{{ $t('managerForm.settingPage.noteEditErrorMessage') }}</p>
                </div>
                <div v-else>
                    <table class="table table-bordered">
                        <thead>
                            <th
                                v-for="(item, k) in TABLE_ERROR_MESSAGES.headers"
                                :key="k"
                                style="background: #ededed"
                                :style="`min-width: ${item.width}px`"
                            >
                                <div class="d-flex align-items-center">
                                    {{ item.label }} <span v-if="item.required" class="text-danger ml-2">*</span>
                                </div>
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(msgItem, idx) in listMessages" :key="`tr-msg-${idx}`">
                                <td v-if="msgItem.action.includes(formBlock.block_type) && onHandleConditionEachMessage(msgItem)">
                                    <div>{{ msgItem.label }}</div>
                                </td>
                                <td v-if="msgItem.action.includes(formBlock.block_type) && onHandleConditionEachMessage(msgItem)">
                                    <div>
                                        <InputText
                                            :model.sync="msgItem.messages"
                                            :config.sync="configFormInput.validateArrayErrMsg[idx].configFieldErrorMsg"
                                            @blur="handleValidateField(configFormInput.validateArrayErrMsg[idx].configFieldErrorMsg)"
                                        />
                                    </div>
                                </td>
                                <td v-if="msgItem.action.includes(formBlock.block_type) && onHandleConditionEachMessage(msgItem)">
                                    <div>
                                        <button class="restore-default-error-msg text-nowrap" @click="handleRestoreDefaultErrorMsg(idx)">
                                            {{ $t('managerForm.settingPage.restoreDefaultSettings') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-light text-nowrap" @click="handleCancelErrorMsg">{{ $t('btn.cancel') }}</button>
                        <button type="button" class="btn btn-success text-nowrap ml-3" @click="handleSaveErrorMsg">
                            {{ $t('managerForm.settingPage.saveErrorMsg') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-light" @click="handleBackStep1">{{ $t('managerForm.settingPage.backSetting') }}</button>
                <button type="button" class="btn btn-info ml-3" :disabled="isSaveSettingBlock" @click="handleSaveSettingBlock">
                    {{ $t('managerForm.settingPage.saveErrorMsg') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { validateField, validateAllField } from '@/utils/validate';
import { TABLE_ERROR_MESSAGES } from './constants';
import { InputText } from '@/components/Input';

import _ from 'lodash';
import QuillEditor from '@/components/QuillEditor/main.vue';
// import Editor from '@/components/Editor/main.vue';

export default {
    name: 'AdditionalSettings',
    props: {
        formBlock: {
            type: Object,
            required: true,
            default: () => null
        },
        configFormInput: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    components: {
        // Editor,
        QuillEditor,
        InputText
    },
    data() {
        return {
            isActiveEditor: false,
            isTableErrorMsg: false,
            isSaveSettingBlock: false,
            TABLE_ERROR_MESSAGES: TABLE_ERROR_MESSAGES,
            listErrorMessagesCopy: [],
            listMessages: _.cloneDeep(this.$store.state.managerForm.listMessageBlocks),
            getBlockType: this.formBlock.block_type
        };
    },

    computed: {
        isActiveTableMess() {
            let { order_required, operator_limit_check, number_limit_check, setting_others } = this.formBlock;
            let active = false;

            if (this.getBlockType !== 'input' && order_required) {
                active = true;
            }

            switch (this.getBlockType) {
                case 'input':
                    active = true;
                    break;
                case 'checkbox':
                    active =
                        order_required ||
                        operator_limit_check ||
                        number_limit_check ||
                        setting_others?.uncheck_use ||
                        setting_others.value_other_required;
                    break;
            }

            return active;
        }
    },

    methods: {
        handleBackStep1() {
            this.$emit('back', true);
        },

        handleSaveSettingBlock() {
            this.handleMapMessages();
            this.$emit('save', false);
        },

        handleToggleEditor() {
            this.isActiveEditor = !this.isActiveEditor;
        },

        handleRestoreDefaultSettings() {
            this.formBlock.additional_setting.edit_with_editor = '';
            this.isActiveEditor = false;
        },

        handleSetValueDefault() {},

        handleValidateField(configField) {
            validateField(configField);
        },

        handleToggleErrorMsg() {
            this.isTableErrorMsg = !this.isTableErrorMsg;
            this.isSaveSettingBlock = !!this.isTableErrorMsg;
            if (this.isTableErrorMsg) {
                this.listErrorMessagesCopy = _.cloneDeep(this.listMessages);
            }
        },

        handleRestoreDefaultErrorMsg(index) {
            this.listMessages[index].messages = this.listMessages[index].msg_default;
        },

        handleCancelErrorMsg() {
            this.configFormInput.validateArrayErrMsg = this.configFormInput.validateArrayErrMsg.map((err) => {
                return {
                    configFieldErrorMsg: {
                        ...err.configFieldErrorMsg,
                        error: false
                    }
                };
            });
            this.listMessages = this.listErrorMessagesCopy;
            this.handleToggleErrorMsg();
        },

        handleSaveErrorMsg() {
            let checkError = validateAllField(this.configFormInput.validateArrayErrMsg);
            if (!checkError) return;
            this.handleMapMessages();
            this.handleToggleErrorMsg();
        },

        handleMapMessages() {
            this.formBlock.additional_setting.error_message_block = this.listMessages;
        },

        onHandleConditionEachMessage(object) {
            return this.isRequiredValueOther(object) && this.handleMessForBlockInput(object) && this.handleMessForBlockCheckbox(object);
        },

        isRequiredValueOther(object) {
            if (object.type_msg !== 'required_other') return true;
            let result = object.required_value_other == this.formBlock.setting_others.value_other_required;
            return result;
        },

        handleMessForBlockInput(object) {
            let result = true;
            if (this.getBlockType !== 'input') return result;
            switch (object.type_msg) {
                case 'required':
                    result = object.required_input === this.formBlock.order_required;
                    break;
                case 'character_range':
                    result =
                        this.formBlock.input_format?.type_input !== 'numeric_value_including_decimal_point' &&
                        this.formBlock.input_format?.type_input !== 'numerical_value';
                    break;
                case 'format_numerical_value':
                    result = this.formBlock.input_format?.type_input === 'numerical_value';
                    break;
                case 'email':
                case 'number':
                case 'half-width_alphanumeric_characters':
                case 'full-width_characters':
                case 'hiragana':
                case 'full-width_katakana':
                case 'half-width_katakana':
                case 'numerical_value':
                case 'numeric_value_including_decimal_point':
                    result = object.type_msg == this.formBlock.input_format?.type_input;
                    break;
            }
            return result;
        },

        handleMessForBlockCheckbox(object) {
            let { order_required, operator_limit_check, number_limit_check, setting_others } = this.formBlock;
            let result = true;
            if (this.getBlockType !== 'checkbox') return result;
            switch (object.type_msg) {
                case 'required':
                    result = object.required_checkbox === order_required;
                    break;
                case 'checked_quantity_error':
                    result = operator_limit_check?.id && number_limit_check;
                    break;
                case 'exclusive_check_error':
                    result = setting_others.uncheck_use === true;
                    break;
            }
            return result;
        }
    }
};
</script>

<style lang="scss" scoped>
.return__edit_with_editor {
    background: #ffffff;
    border: solid 1px #339162;
    color: #339162;
    font-size: 13px;
    &:hover {
        opacity: 0.7;
    }
}

.return__restore--default--settings {
    color: #339162;
}

.border-top-1-color {
    border-top: 1px solid #0000001a;
}

.restore-default-error-msg {
    background: #ffffff;
    color: #339162;
    border: solid 1px #339162;
    padding: 9px 9px;
    border-radius: 2px;
}
</style>
