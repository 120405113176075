<script>
import { partnersMethods, masterMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { ARRTYPEFIXED } from '@/config/constants';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Footer from '@/components/Footer/main.vue';
import TableListComponent from '@/components/TableList/main.vue';

export default {
    page: {
        title: '外部パートナー一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Layout,
        Footer,
        TableListComponent
    },
    data() {
        return {
            form: {
                name: '',
                person_in_charge: ''
            },
            configKeyword: {
                arrayTag: [],
                placeholder: '会社名　担当者　会社名(カナ)　電話番号　郵便番号　都道府県　住所　会社概要　URL　',
                isShowLable: true
            },
            configTable: {
                headers: [
                    {
                        label: this.$t('pagePartner.nameCompany')
                    },
                    {
                        label: `${this.$t('pagePartner.nameCompany')}(カナ)`
                    },
                    {
                        label: this.$t('pagePartner.numberCP')
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            },
            listDataDepartment: ARRTYPEFIXED,
            checkShowFilter: false,
            listData: [],
            listTagFreeWord: [],
            listTagKeyWord: [],
            isLoading: false
        };
    },
    watch: {
        'configKeyword.arrayTag': function (newOld) {
            if (!this.checkShowFilter) {
                this.listTagFreeWord = newOld;
            } else {
                this.listTagKeyWord = newOld;
            }
        }
    },
    mounted() {
        this.getList();
    },

    methods: {
        ...masterMethods,
        ...partnersMethods,

        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.isShowLable = false;
                this.configKeyword.arrayTag = this.listTagKeyWord;
            } else {
                this.configKeyword.placeholder = '会社名　担当者　会社名(カナ)　電話番号　郵便番号　都道府県　住所　会社概要　URL　';
                this.configKeyword.arrayTag = this.listTagFreeWord;
                this.configKeyword.isShowLable = true;
            }
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) {
                            query.push(`search[${key}]=${encodeURIComponent(value)}`);
                        }
                    }
                }
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`filters[keywords]=${this.configKeyword.arrayTag.toString()}`);
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        async getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            try {
                const response = await this.listPartners(query);
                this.listData = response.data;
                this.configPage.total = response._metadata.total;
                this.configPage.limit = response._metadata.limit * 1;
                const getTypes = response.data.map((item) => item.types);
                if (getTypes.length > 0) {
                    for (let i = 0; i < getTypes.length; i++) {
                        const getNameTypes = this.listDataDepartment.filter((item) => getTypes[i]?.find((element) => element == item.id));
                        this.listData[i].types = getNameTypes.map((item) => item.value).join(', ');
                    }
                }
                if (!this.listData.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                console.error('Error fetching posts:', error);
            }
        },

        search() {
            this.getList();
        },
        clear() {
            this.form = {
                name: '',
                person_in_charge: ''
            };
            this.listTagFreeWord = this.listTagKeyWord = this.configKeyword.arrayTag = [];

            this.getList();
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">外部パートナー検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>
                                    <div v-if="checkShowFilter">
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.nameCompany') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom05" v-model="form.name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.manager') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom05" v-model="form.person_in_charge" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.keyword') }} </label>
                                            <div class="w-100">
                                                <KeyWordTag :config="configKeyword" class="" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            外部パートナー検索結果 <span>件数（{{ configPage.total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableListComponent
                            :config="configTable"
                            :dataList="listData"
                            :isLoading="isLoading"
                            :configPage.sync="configPage"
                            @onChangePage="getList()"
                        >
                            <template #row="{ row }">
                                <tr @dblclick="goToEdit('/partner/view', row)">
                                    <td>
                                        <div class="row-data-small">
                                            <a href="javascript:void(0)" @click="goToEdit('/partner/form', row)">{{ row.name }} </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.kana_name ? row.kana_name : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            <a
                                                href="javascript:void(0)"
                                                @click="
                                                    $router.push({
                                                        path: '/campaign/list',
                                                        query: { partner_id: row.id }
                                                    })
                                                "
                                                >({{ row.campaigns_count }})
                                            </a>
                                        </div>
                                    </td>
                                    <td align="center">
                                        <div class="row-data-small">
                                            <a href="javascript:void(0)" class="text-info border-right-1" @click="goToEdit('/partner/view', row)">{{
                                                $t('btn.detail')
                                            }}</a>
                                            <a
                                                v-if="checkPermiss('partner.edit')"
                                                href="javascript:void(0)"
                                                class="text-success"
                                                @click="goToEdit('/partner/form', row)"
                                                >{{ $t('btn.edit') }}</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </TableListComponent>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
