<template>
    <div>
        <div v-if="!isLoading" class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingBasic.nameCampaign') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <Multiselect
                            v-if="!dataForm?.id"
                            :id="`select-campaign-for-landing-page`"
                            :value.sync="dataForm.campaign"
                            :options="listDataMasterCampaign"
                            :config.sync="configForm.configFieldSelectCampaign"
                            @select="getDetailCampaignForLP"
                            @remove="handleValidateField(configForm.configFieldSelectCampaign)"
                        />
                        <span v-else> {{ dataForm.campaign?.name }}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.idLP') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.code ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingBasic.nameLandingPage') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.name"
                            :config.sync="configForm.configFieldNameLandingPage"
                            @blur="handleValidateField(configForm.configFieldNameLandingPage)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.numberCP') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.number_cp ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.businessAssistant') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-sale_staff`"
                            :value.sync="dataForm.sale_staff"
                            :options="listDataMasterSaleEmploy"
                            :config.sync="configForm.configFieldSaleStaff"
                            @remove="handleValidateField(configForm.configFieldSaleStaff)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.picInstructions') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-person_in_charge`"
                            :value.sync="dataForm.person_in_charge"
                            :options="listDataMasterInstructorStaf"
                            :config.sync="configForm.configFieldPICinstructions"
                            @remove="handleValidateField(configForm.configFieldPICinstructions)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.ZACID') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.zac_id ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.SFID') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.sfid ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.campaignEntityId') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.campaign_entity_id ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.campaignStartDate') }} </label>
                    <div class="col-sm-9">
                        <DateTime
                            id="select-campaign-start-date-time"
                            :full_date.sync="dataForm.campaign_start_date"
                            :full_time.sync="dataForm.campaign_start_time"
                            :config.sync="configForm.configFieldCampaignStartDateTime"
                            @blur="handleValidateField(configForm.configFieldCampaignStartDateTime)"
                        >
                            <span v-if="configForm.configFieldCampaignStartDateTime.error" class="invalid-feedback d-block">{{
                                configForm.configFieldCampaignStartDateTime.errorText
                            }}</span>
                        </DateTime>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.campaignEndDate') }} </label>
                    <div class="col-sm-9">
                        <DateTime
                            id="select-campaign-end-date-time"
                            :full_date.sync="dataForm.campaign_end_date"
                            :full_time.sync="dataForm.campaign_end_time"
                            :config.sync="configForm.configFieldCampaignEndDateTime"
                            @blur="
                                handleValidateField(configForm.configFieldCampaignEndDateTime, [
                                    'compare-date',
                                    'configFieldCampaignStartDateTime',
                                    'configFieldCampaignEndDateTime'
                                ])
                            "
                        >
                            <span v-if="configForm.configFieldCampaignEndDateTime.error" class="invalid-feedback d-block">{{
                                configForm.configFieldCampaignEndDateTime.errorText
                            }}</span>
                        </DateTime>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.landingPageStartDate') }} </label>
                    <div class="col-sm-9">
                        <DateTime
                            id="select-lp-start-date-time"
                            :full_date.sync="dataForm.lp_start_date"
                            :full_time.sync="dataForm.lp_start_time"
                            :config.sync="configForm.configFieldLPStartDateTime"
                            @blur="handleValidateField(configForm.configFieldLPStartDateTime)"
                        >
                            <span v-if="configForm.configFieldLPStartDateTime.error" class="invalid-feedback d-block">{{
                                configForm.configFieldLPStartDateTime.errorText
                            }}</span>
                        </DateTime>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.landingPageEndDate') }} </label>
                    <div class="col-sm-9">
                        <DateTime
                            id="select-lp-end-date-time"
                            :full_date.sync="dataForm.lp_end_date"
                            :full_time.sync="dataForm.lp_end_time"
                            :config.sync="configForm.configFieldLPEndDateTime"
                            @blur="
                                handleValidateField(configForm.configFieldLPEndDateTime, [
                                    'compare-date',
                                    'configFieldLPStartDateTime',
                                    'configFieldLPEndDateTime'
                                ])
                            "
                        >
                            <span v-if="configForm.configFieldLPEndDateTime.error" class="invalid-feedback d-block">{{
                                configForm.configFieldLPEndDateTime.errorText
                            }}</span>
                        </DateTime>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.status') }} </label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div v-for="item in STATUS_SETTING_BASIC" :key="item.id + 's'">
                                <InputRadio
                                    class="mr-4"
                                    :model.sync="dataForm.status"
                                    :name="`status_basic_setting`"
                                    :id="`status_basic_setting-${item.id}`"
                                    :value="item.id"
                                    :label="item.value"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.meta') }} </label>
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.keyword') }} </label>
                            <div class="col-sm-9">
                                <KeyWordTag2
                                    :id="`tag-keywords`"
                                    :model.sync="dataForm.keywords"
                                    :config.sync="configForm.configFieldKeywordSite"
                                    @blur="handleValidateField(configForm.configFieldKeywordSite)"
                                >
                                    <span v-if="configForm.configFieldKeywordSite.error">
                                        {{ configForm.configFieldKeywordSite.errorCustomMess }}
                                    </span>
                                </KeyWordTag2>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.description') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.description"
                                    :config.sync="configForm.configFieldDescriptions"
                                    @blur="handleValidateField(configForm.configFieldDescriptions)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.analytics') }} </label>
                    <div class="col-sm-9">
                        <textarea row="3" v-model="dataForm.analytics" class="form-control"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingBasic.urlPublic') }}
                        <span class="text-danger">*</span>
                        <div class="mt-1">
                            <span>{{ $t('landingPage.settingBasic.desUrlPublic') }}</span>
                        </div>
                    </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.url_public"
                            :config.sync="configForm.configFieldUrlPublic"
                            @blur="handleValidateField(configForm.configFieldUrlPublic)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.linkTemplate') }} </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.application_form_link"
                            :config.sync="configForm.configFieldLinkTemplate"
                            @blur="handleValidateField(configForm.configFieldLinkTemplate)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.ogImage') }} </label>
                    <div class="col-sm-9">
                        <UploadFileCommon @dataFile="handleFileImageOG" title="ファイルアップロード" :id="`ImageOG`" />
                        <div v-if="dataForm.og_image?.origin_name" class="d-flex align-items-center my-2">
                            <a
                                target="_blank"
                                :href="dataForm.og_image?.path"
                                @click.prevent="downLoadFile(dataForm.og_image?.path, dataForm.og_image?.origin_name)"
                                >{{ dataForm.og_image?.origin_name }}</a
                            >
                            <span class="ml-2" style="cursor: pointer; font-size: 20px" @click="dataForm.og_image = null"
                                ><i class="ri-close-line" style="vertical-align: bottom"></i
                            ></span>
                        </div>
                        <b-img
                            v-if="dataForm.og_image?.path && arrExtension.includes(dataForm.og_image.extension)"
                            class=""
                            thumbnail
                            fluid
                            :src="dataForm.og_image?.path"
                            :alt="dataForm.og_image.origin_name"
                            rounded
                            style="width: 150px; height: 150px; object-fit: cover; object-position: center"
                        ></b-img>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.sns') }} </label>
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.xURL') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.x_url"
                                    :config.sync="configForm.configFieldXURL"
                                    @blur="handleValidateField(configForm.configFieldXURL)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.xShareText') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.x_share_text"
                                    :config.sync="configForm.configFieldXShareText"
                                    @blur="handleValidateField(configForm.configFieldXShareText)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.xHashtag') }} </label>
                            <div class="col-sm-9">
                                <KeyWordTag2
                                    :id="`tag-x_hashtag`"
                                    :model.sync="dataForm.x_hashtag"
                                    :checkPre="`hash`"
                                    :config.sync="configForm.configFieldXhashtag"
                                    @blur="handleValidateField(configForm.configFieldXhashtag)"
                                >
                                    <span v-if="configForm.configFieldXhashtag.error">
                                        {{ configForm.configFieldXhashtag.errorCustomMess }}
                                    </span>
                                </KeyWordTag2>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { InputText, InputRadio } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { STATUS_SETTING_BASIC } from '../../constants';
import { handleMapDataFromStateLP, validateEndDate } from '../../handlers';
import { handleDownFile, IMAGE_EXTENSIONS } from '@/utils/format';
import { masterMethods, landingPageMethods } from '@/state/helpers';

import KeyWordTag2 from '@/components/KeywordTag/main2.vue';
import DateTime from '@/components/DateCustoms/DateTime/main.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

export default {
    name: 'SettingBasicLP',
    components: {
        InputText,
        DateTime,
        InputRadio,
        KeyWordTag2,
        UploadFileCommon,
        Multiselect,
        LoadingIcon
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    computed: {
        ...mapState({
            ...mapState('landingPage', ['allInforCampaignLP'])
        })
    },
    data() {
        return {
            STATUS_SETTING_BASIC: STATUS_SETTING_BASIC,
            arrExtension: IMAGE_EXTENSIONS,
            listDataMasterCampaign: [],
            listDataMasterSaleEmploy: [],
            listDataMasterInstructorStaf: [],
            isLoading: false
        };
    },
    methods: {
        ...masterMethods,
        ...landingPageMethods,

        handleValidateField(configField, action = []) {
            validateField(configField);
            if (action.includes('compare-date')) {
                validateEndDate(this.configForm[action[1]], this.configForm[action[2]]);
            }
        },

        handleFileImageOG(file) {
            this.dataForm.og_image = file;
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        },

        async getMasterCampaign() {
            this.isLoading = true;
            try {
                const dataRes = await this.listMaster('all/campaigns');
                this.listDataMasterCampaign = dataRes;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
        },

        async getListCommonUse() {
            try {
                const dataRes = await this.listMaster('campaign/common-user');
                this.listDataMasterSaleEmploy = dataRes['sale']; //2
                this.listUserFilterRole = dataRes['admin_and_instructor']; //1,3
                this.listCustomerTeam = dataRes['customer_team'];
                this.getListMasterInstructorStaf(this.listCustomerTeam, this.listUserFilterRole);
            } catch (error) {
                console.log('error', error);
            }
        },

        getListMasterInstructorStaf(listCustomerTeam, listUserFilterRole) {
            let arrTwoInstructorStaf = [...listUserFilterRole, ...listCustomerTeam];
            this.listDataMasterInstructorStaf = arrTwoInstructorStaf.reduce((acc, current) => {
                const x = acc.find((item) => item.id === current.id);
                if (!x) {
                    acc.push(current);
                }
                return acc;
            }, []);
        },

        async getDetailCampaignForLP() {
            try {
                await this.getDetailCampaignForLandingPage(this.dataForm.campaign.id);
                handleMapDataFromStateLP(this.dataForm, this.allInforCampaignLP);
            } catch (error) {
                console.log('error', error);
            }
        }
    },
    mounted() {
        this.getListCommonUse();
        if (this.dataForm.campaign?.id && !this.$route?.query?.id) {
            this.getMasterCampaign();
            this.getDetailCampaignForLP();
        }
    }
};
</script>

<style lang="scss" scoped></style>
