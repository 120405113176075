<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import Footer from '@/components/Footer/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import DatePicker from 'vue2-datepicker';

import { masterMethods } from '@/state/helpers';
import { checkPermission, isValidDate } from '@/utils/handlers';
import { maxLength, required } from 'vuelidate/lib/validators';
import { showMessage } from '@/utils/messages';
import { STR_DATE } from '@/utils/format';

export default {
    page: {
        title: '休日マスタ',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        DatePicker,
        Layout,
        Footer,
        LoadingIcon
    },
    watch: {
        'form.date': function (newName) {
            if (newName) {
                const date = newName.split('-');
                this.form.date_y = date[0];
                this.form.date_m = date[1];
                this.form.date_d = date[2];
            }
        }
    },
    data() {
        return {
            submitted: false,
            submittedList: false,
            disabled: false,
            is_editable: false,
            loading: false,
            listData: [],
            dataUpdate: '',
            form: {
                title: '',
                date: '',
                date_y: '',
                date_m: '',
                date_d: ''
            },
            customFormatDate: STR_DATE,
            isLoading: false
        };
    },
    validations: {
        listData: {
            $each: {
                title: { required, maxLength: maxLength(255) },
                date: {
                    required(value) {
                        if (value) {
                            var parts = value.split('-');
                            var day = parts[2];
                            var month = parts[1];
                            var year = parts[0];
                            if (day || month || year) {
                                return true;
                            }
                        }
                        return false;
                    },
                    validate(value) {
                        if (value) {
                            var parts = value.split('-');
                            var day = parts[2];
                            var month = parts[1];
                            var year = parts[0];
                            var result = day + '/' + month + '/' + year;
                            return isValidDate(result);
                        }
                        return true;
                    }
                }
            }
        },
        form: {
            title: { required, maxLength: maxLength(255) },
            date: {
                required() {
                    if (this.form.date_m || this.form.date_d || this.form.date_y) {
                        return true;
                    }
                    return false;
                },
                validate() {
                    if (this.form.date_m || this.form.date_d || this.form.date_y) {
                        return isValidDate(this.form.date_d + '/' + this.form.date_m + '/' + this.form.date_y);
                    }
                    return true;
                }
            }
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        ...masterMethods,
        goToEdit(path, key) {
            this.$router.push({ path: path, query: { key: key } });
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        formSubmit() {
            this.submitted = true;
            this.loading = true;
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                return;
            } else {
                this.register();
            }
        },
        formSubmitList() {
            if (this.listData.length <= 0 || (this.dataUpdate == '' && this.dataUpdate != 0)) return;

            this.submittedList = true;
            this.$v.$touch();
            if (this.$v.listData.$invalid) {
                return;
            } else {
                this.update();
            }
        },
        getList() {
            this.isLoading = true;
            this.listHoliday().then((data) => {
                if (data.code == 200) {
                    data.data.forEach((item, k) => {
                        const date = item.date.split('-');
                        data.data[k].date_y = date[0];
                        data.data[k].date_m = date[1];
                        data.data[k].date_d = date[2];
                    });
                    this.listData = data.data;
                }
                this.isLoading = false;
            });
        },
        register() {
            if (this.form.title) {
                let object = JSON.parse(JSON.stringify(this.form));
                object.date = object.date_y + '-' + object.date_m + '-' + object.date_d;
                this.registerHoliday(object).then((data) => {
                    if (data && data.code == 200) {
                        this.getList();
                        this.form = {
                            title: '',
                            date: '',
                            date_y: '',
                            date_m: '',
                            date_d: ''
                        };
                        this.submitted = false;
                        showMessage(data.code, this.$bvToast, '登録されました。');
                    }
                });
            }
        },
        edit(item, key) {
            if (!item.disabled) {
                this.dataUpdate = key;
                this.listData[key].disabled = true;
                this.listData.forEach((item, k) => {
                    const date = item.date.split('-');
                    this.listData[k].date_y = date[0];
                    this.listData[k].date_m = date[1];
                    this.listData[k].date_d = date[2];
                });
                this.listData = [].concat(this.listData);
            }
        },
        update() {
            if (this.dataUpdate >= 0) {
                let object = JSON.parse(JSON.stringify(this.listData[this.dataUpdate]));
                object.date = object.date_y + '-' + object.date_m + '-' + object.date_d;
                this.updateHoliday(object).then((data) => {
                    this.listData[this.dataUpdate].disabled = false;
                    this.dataUpdate = '';
                    if (data.code == 200) showMessage(data.code, this.$bvToast, '更新されました。');
                    this.listData = [].concat(this.listData);
                });
            }
        },
        updateDate(item, k) {
            this.listData[k].date = item.date_y + '-' + item.date_m + '-' + item.date_d;
        },
        deleteObject(data) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteMasterHoliday(data).then((res) => {
                        if (res.code == 200) {
                            showMessage(res.code, this.$bvToast, '削除されました。');
                            this.getList();
                        }
                    });
                }
            });
        },
        changeDate(item, k) {
            const end_date = item.date.split('-');
            this.listData[k].date_y = end_date[0];
            this.listData[k].date_m = end_date[1];
            this.listData[k].date_d = end_date[2];
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!isLoading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="formSubmitList" autocomplete="off">
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered">
                                    <thead>
                                        <tr>
                                            <th>名称</th>
                                            <th>日付</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, k) in listData" :key="item.id">
                                            <td>
                                                <div>
                                                    <input
                                                        type="text"
                                                        :disabled="!item.disabled"
                                                        v-model="item.title"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submittedList && $v.listData.$each[k].title.$error
                                                        }"
                                                    />
                                                    <div v-if="submittedList && $v.listData.$each[k].title.$error" class="invalid-feedback">
                                                        <span v-if="!$v.listData.$each[k].title.required">{{
                                                            $t('validateField.required', {
                                                                field: '名称'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.listData.$each[k].title.maxLength">
                                                            {{
                                                                $t('validateField.max255', {
                                                                    field: '名称'
                                                                })
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div class="d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.date_y"
                                                            style="width: 80px; padding-right: 0 !important"
                                                            @change="updateDate(item, k)"
                                                            class="form-control mr-2"
                                                            :class="{
                                                                'is-invalid': submittedList && $v.listData.$each[k].date.$error
                                                            }"
                                                        />
                                                        年
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.date_m"
                                                            @change="updateDate(item, k)"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid': submittedList && $v.listData.$each[k].date.$error
                                                            }"
                                                        />
                                                        月
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.date_d"
                                                            @change="updateDate(item, k)"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid': submittedList && $v.listData.$each[k].date.$error
                                                            }"
                                                        />
                                                        日
                                                        <div class="btn-sm btn btn-info mx-2 box-date" style="min-width: 80px">
                                                            カレンダー
                                                            <date-picker
                                                                @close="changeDate(item, k)"
                                                                :disabled="!item.disabled"
                                                                v-model="item.date"
                                                                :value-type="customFormatDate"
                                                                :first-day-of-week="1"
                                                                lang="ja"
                                                            ></date-picker>
                                                        </div>
                                                    </div>
                                                    <span
                                                        :class="{
                                                            'is-invalid': submittedList && $v.listData.$each[k].date.$error
                                                        }"
                                                    ></span>
                                                    <div v-if="submittedList && $v.listData.$each[k].date.$error" class="invalid-feedback">
                                                        <span v-if="!$v.listData.$each[k].date.required">{{
                                                            $t('validateField.required', {
                                                                field: '日付'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.listData.$each[k].date.validate">{{
                                                            $t('validateField.incorrectFormat', {
                                                                field: '日付'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <a
                                                    href="javascript:void(0)"
                                                    v-if="!item.disabled"
                                                    type="button"
                                                    @click="edit(item, k)"
                                                    class="btn btn-info mx-2"
                                                    style="white-space: nowrap"
                                                    >編集</a
                                                >
                                                <button v-else type="submit" class="btn btn-success mx-2" style="white-space: nowrap">保存</button>
                                                <button type="button" @click="deleteObject(item)" class="btn btn-danger" style="white-space: nowrap">
                                                    削除
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                        <hr />
                        <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                            <div class="form-group">
                                <div class="d-flex align-items-center">
                                    <div class="row w-100">
                                        <div class="col-sm-6">
                                            <div class="row align-items-center">
                                                <label class="col-sm-2">名称</label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.title"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.title.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.title.required">{{
                                                            $t('validateField.required', {
                                                                field: '名称'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.form.title.maxLength">
                                                            {{
                                                                $t('validateField.max255', {
                                                                    field: '名称'
                                                                })
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row align-items-center">
                                                <label class="col-sm-3">日付 </label>
                                                <div class="col-sm-9 ml-0">
                                                    <div class="d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            v-model="form.date_y"
                                                            style="width: 80px; padding-right: 0 !important"
                                                            class="form-control mr-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.date.$error
                                                            }"
                                                        />
                                                        年
                                                        <input
                                                            type="text"
                                                            v-model="form.date_m"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.date.$error
                                                            }"
                                                        />
                                                        月
                                                        <input
                                                            type="text"
                                                            v-model="form.date_d"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.date.$error
                                                            }"
                                                        />
                                                        日
                                                        <div class="btn-sm btn btn-info mx-2 box-date" style="min-width: 80px">
                                                            カレンダー
                                                            <date-picker
                                                                v-model="form.date"
                                                                :value-type="customFormatDate"
                                                                :first-day-of-week="1"
                                                                lang="ja"
                                                            ></date-picker>
                                                        </div>
                                                    </div>
                                                    <span
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.date.$error
                                                        }"
                                                    ></span>
                                                    <div v-if="submitted && $v.form.date.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.date.required">{{
                                                            $t('validateField.required', {
                                                                field: '日付'
                                                            })
                                                        }}</span>

                                                        <span v-if="!$v.form.date.validate">{{
                                                            $t('validateField.incorrectFormat', {
                                                                field: '日付'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" class="btn btn-info mx-2" style="white-space: nowrap; opacity: 0">編集</button>
                                    <button type="button" class="btn btn-danger" style="white-space: nowrap; opacity: 0">削除</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center">
                        <button type="submit" class="btn btn-primary" @click="formSubmit">登録</button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
