import { mapState, mapGetters, mapActions } from 'vuex';

const authComputed = {
    ...mapState('authfack', {
        currentUser: (state) => state.user
    }),
    ...mapGetters('authfack', ['loggedIn'])
};

const masterComputed = {
    ...mapState('master', {
        dataList: (state) => state.dataList
    })
};

const layoutComputed = {
    ...mapState('layout', {
        layoutType: (state) => state.layoutType,
        leftSidebarType: (state) => state.leftSidebarType,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar,
        loader: (state) => state.loader
    })
};

const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue']);

const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout', 'profile', 'forgot', 'resetPass']);

const masterMethods = mapActions('master', [
    'setDefaultCampaign',
    'postDocumentFAQPreview',
    'postDocumentFMT',
    'postDocumentFAQ',
    'deleteDocumentFAQ',
    'updateDocumentFAQ',
    'getDocumentFAQ',
    'getDefaultRule',
    'createMasterRuleDetail1',
    'getDetailDocuments',
    'getAllDocuments',
    'updateMasterRuleDetail1',
    'detailMasterRule1',
    'getAllMasterRule',
    'deleteMasterRule2',
    'createMasterRule1',
    'deleteMasterRule1',
    'updateMasterRule1',
    'updateMasterRule',
    'listMasterRule1',
    'registerMasterRule',
    'updateRule2',
    'deleteRule2',
    'createRule2',
    'listRule',
    'documentPreview',
    'templateDelete',
    'templateCreate',
    'templateUpdate',
    'removeDraf',
    'saveDraf',
    'templateGetDraf',
    'templateGetDetail',
    'templateList',
    'deleteMasterHolidayYear',
    'updateHolidayYear',
    'listHolidayYear',
    'oneHolidayYear',
    'registerHolidayYear',
    'deleteMasterHoliday',
    'updateHoliday',
    'listHoliday',
    'registerHoliday',
    'histories',
    'findCompany',
    'searchAddress',
    'registerPermission',
    'dataPermission',
    'listPermission',
    'listMaster',
    'registerMaster',
    'deleteMaster',
    'updateMaster',
    'masterCampaigns',
    'getListScheduleFile',
    'getDetailScheduleFile',
    'createScheduleFile',
    'updateScheduleFile',
    'deleteScheduleFile',
    'partnerRequestSheet',
    'getPartnerRequestSheet',
    'updatePartnerRequestSheet',
    'getMasterOrderScope'
]);

const employeeMethods = mapActions('employee', [
    'listEmployeePartTime',
    'registerEmployeePartTime',
    'registerEmployee',
    'detailEmployeePartTime',
    'updateEmployeePartTime',
    'updateEmployee',
    'listEmployee',
    'deleteEmployee',
    'detailEmployee',
    'deleteEmployeePartTime'
]);

const partnersMethods = mapActions('partners', [
    'registerPartners',
    'updatePartners',
    'listPartners',
    'deletePartners',
    'detailPartners',
    'listPartnersSupport',
    'registerPartnersSupport',
    'updatePartnersSupport',
    'detailPartnersSupport',
    'deletePartnersSupport',
    'getPartnersSettingPhone'
]);

const clientMethods = mapActions('client', [
    'registerClient',
    'updateClient',
    'listClient',
    'deleteClient',
    'detailClientSupport',
    'listClientSupport',
    'registerClientSupport',
    'updateClientSupport',
    'deleteClientSupport',
    'detailClient'
]);

const campaignMethods = mapActions('campaign', [
    'historyDocuments',
    'postDocuments',
    'copyCampaign',
    'uploadFile',
    'getDocuments',
    'triggerDocuments',
    'deleteBookmark',
    'getBookmark',
    'addBookmark',
    'approveCampaign',
    'unLockCampaign',
    'lockCampaign',
    'registerCampaign',
    'updateCampaign',
    'listCampaign',
    'deleteCampaign',
    'detailCampaign',
    'getDataDocuments',
    'getRuleSavePayLoad',
    'postRuleSavePayLoad',
    'setBookmarkCampaign',
    'unsetBookmarkCampaign'
]);

const faqMethods = mapActions('faq', [
    'registerFaq',
    'updateFaqs',
    'updateFaqsAnswerDefault',
    'listFaqs',
    'deleteFaq',
    'detailFaq',
    'listPartnersSupport',
    'registerAnswer',
    'updateAnswer',
    'detailPartnersSupport',
    'deleteAnswer'
]);

const prizeMethods = mapActions('prize', [
    'deletePrizeReturn',
    'listPrizeReturn',
    'registerPrizeReturn',
    'updatePrizeReturn',
    'registerPrize',
    'updatePrize',
    'updateFaqsAnswerDefault',
    'listPrize',
    'deletePrize',
    'detailPrize',
    'registerAnswer',
    'updateAnswer',
    'detailPartnersSupport',
    'deleteAnswer',
    'getPrizesAll'
]);

const notificationMethods = mapActions('notification', ['success', 'error', 'clear']);

const dashboardMethods = mapActions('dashboard', ['listCountEmployeeDashboard']);

const settingPhoneMethods = mapActions('settingPhone', [
    'detailPhone',
    'registerPhone',
    'updatePhone',
    'updateStepWorkflow',
    'getListUserInRoom',
    'getInfoChatWork',
    'getTemplateConfig'
]);

const manualService = mapActions('manual', ['detailManual', 'updateManual']);

const landingPageMethods = mapActions('landingPage', [
    'registerLandingPage',
    'updateLandingPage',
    'getListLandingPage',
    'getDetailLandingPage',
    'deleteLandingPage',
    'getDetailCampaignForLandingPage'
]);

const managerFormMethods = mapActions('managerForm', [
    'registerManagerForm',
    'updateManagerForm',
    'getListManagerForm',
    'getDetailManagerForm',
    'deleteManagerForm',
    'saveStateBlockForm',
    'updateBlockList',
    'updateMessages'
]);

const editorMethods = mapActions('editor', ['registerDataEditor']);

const uploadImageMethods = mapActions('uploadImage', ['acUploadImage']);

export {
    authComputed,
    masterComputed,
    layoutComputed,
    layoutMethods,
    authFackMethods,
    masterMethods,
    employeeMethods,
    partnersMethods,
    clientMethods,
    campaignMethods,
    faqMethods,
    prizeMethods,
    notificationMethods,
    dashboardMethods,
    settingPhoneMethods,
    manualService,
    landingPageMethods,
    managerFormMethods,
    editorMethods,
    uploadImageMethods
};
