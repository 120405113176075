<script>
import simplebar from 'simplebar-vue';
import MetisMenu from 'metismenujs/dist/metismenujs';

import { layoutComputed } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { menuItems } from '@/config/menu';

export default {
    components: {
        simplebar
    },
    props: {
        isCondensed: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            required: true
        },
        width: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            menuItems: menuItems,
            user: JSON.parse(localStorage.getItem('user'))
        };
    },
    computed: {
        ...layoutComputed
    },
    mounted: function () {
        // eslint-disable-next-line no-unused-vars
        var menuRef = new MetisMenu('#side-menu');
        var links = document.getElementsByClassName('side-nav-link-ref');
        var matchingMenuItem = null;
        for (var i = 0; i < links.length; i++) {
            if (
                window.location.pathname === links[i].pathname ||
                (links[i].pathname !== '/' &&
                    window.location.pathname !== '/employee-part-time/form' &&
                    window.location.pathname.includes(links[i].pathname))
            ) {
                matchingMenuItem = links[i];
                break;
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add('active');
            var parent = matchingMenuItem.parentElement;
            if (parent) {
                parent.classList.add('mm-active');
                const parent2 = parent.parentElement.closest('ul');
                if (parent2 && parent2.id !== 'side-menu') {
                    parent2.classList.add('mm-show');

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.add('mm-active');
                        var childAnchor = parent3.querySelector('.has-arrow');
                        var childDropdown = parent3.querySelector('.has-dropdown');
                        if (childAnchor) childAnchor.classList.add('mm-active');
                        if (childDropdown) childDropdown.classList.add('mm-active');

                        const parent4 = parent3.parentElement;
                        if (parent4 && parent4.id !== 'side-menu') {
                            parent4.classList.add('mm-show');
                            const parent5 = parent4.parentElement;
                            if (parent5 && parent5.id !== 'side-menu') {
                                parent5.classList.add('mm-show');
                                const childanchor = parent5.querySelector('.is-parent');
                                if (childanchor && parent5.id !== 'side-menu') {
                                    childanchor.classList.add('mm-active');
                                }

                                const parent6 = parent5.parentElement;
                                if (parent6 && parent6.id !== 'side-menu') {
                                    parent6.classList.add('mm-show');
                                    const childanchor = parent6.querySelector('.is-parent');
                                    if (childanchor && parent6.id !== 'side-menu') {
                                        childanchor.classList.add('mm-active');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        hasItems(item) {
            return item.subItems !== undefined ? item.subItems.length > 0 : false;
        },
        onRoutechange() {
            setTimeout(() => {}, 300);
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        isActive(path) {
            return this.$route.path === path;
        }
    },
    watch: {
        $route: {
            handler: 'onRoutechange',
            immediate: true,
            deep: true
        },
        type: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case 'dark':
                            document.body.setAttribute('data-sidebar', 'dark');
                            document.body.removeAttribute('data-topbar');
                            document.body.removeAttribute('data-sidebar-size');
                            break;
                        case 'light':
                            document.body.setAttribute('data-topbar', 'dark');
                            document.body.removeAttribute('data-sidebar');
                            document.body.removeAttribute('data-sidebar-size');
                            document.body.classList.remove('vertical-collpsed');
                            break;
                        case 'compact':
                            document.body.setAttribute('data-sidebar-size', 'small');
                            document.body.setAttribute('data-sidebar', 'dark');
                            document.body.classList.remove('vertical-collpsed');
                            document.body.removeAttribute('data-topbar', 'dark');
                            break;
                        case 'icon':
                            document.body.setAttribute('data-keep-enlarged', 'true');
                            document.body.classList.add('vertical-collpsed');
                            document.body.setAttribute('data-sidebar', 'dark');
                            document.body.removeAttribute('data-topbar', 'dark');
                            break;
                        case 'colored':
                            document.body.setAttribute('data-sidebar', 'colored');
                            document.body.removeAttribute('data-keep-enlarged');
                            document.body.classList.remove('vertical-collpsed');
                            document.body.removeAttribute('data-sidebar-size');
                            break;
                        default:
                            document.body.setAttribute('data-sidebar', 'dark');
                            break;
                    }
                }
            }
        },
        width: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case 'boxed':
                            document.body.setAttribute('data-layout-size', 'boxed');
                            break;
                        case 'fluid':
                            document.body.setAttribute('data-layout-mode', 'fluid');
                            document.body.removeAttribute('data-layout-size');
                            break;
                        default:
                            document.body.setAttribute('data-layout-mode', 'fluid');
                            break;
                    }
                }
            }
        }
    }
};
</script>
<template>
    <div class="vertical-menu">
        <simplebar class="h-100" ref="currentMenu" id="my-element">
            <div id="sidebar-menu">
                <ul class="metismenu list-unstyled" id="side-menu">
                    <template v-for="item in menuItems">
                        <li class="menu-title" v-if="item.isTitle" :key="'z' + item.id">
                            {{ $t(item.label) }}
                        </li>

                        <li v-if="!item.isTitle && !item.isLayout" :key="`y` + item.id">
                            <a
                                v-if="(!item.role && hasItems(item)) || (item.role && checkPermiss(item.role))"
                                href="javascript:void(0);"
                                class="is-parent"
                                :class="{
                                    'has-arrow': !item.badge,
                                    'has-dropdown': item.badge
                                }"
                            >
                                <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                                <span>{{ $t(item.label) }}</span>
                                <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{
                                    $t(item.badge.text)
                                }}</span>
                            </a>
                            <template v-if="(!item.role && !hasItems(item)) || (item.role && checkPermiss(item.role))">
                                <router-link v-if="typeof item.link === 'string'" :to="item.link" class="side-nav-link-ref">
                                    <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                                    <span>{{ $t(item.label) }}</span>
                                    <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{
                                        $t(item.badge.text)
                                    }}</span>
                                </router-link>
                            </template>

                            <ul
                                v-if="hasItems(item)"
                                class="sub-menu"
                                :class="{
                                    'custome__menu--client--order': item.id === 12
                                }"
                                aria-expanded="false"
                            >
                                <template v-for="(subitem, index) of item.subItems">
                                    <li class="menu-title px-0" style="padding: 0 !important" v-if="subitem.isTitle" :key="`kl_` + index">
                                        <a href="">{{ $t(subitem.label) }}</a>
                                    </li>
                                    <li v-if="!subitem.isTitle && !subitem.isLayout" :key="`k_` + index">
                                        <template
                                            v-if="
                                                (!subitem.role && !hasItems(subitem)) ||
                                                (subitem.role && checkPermiss(subitem.role) && !hasItems(subitem))
                                            "
                                        >
                                            <router-link v-if="typeof subitem.link === 'string'" :to="subitem.link" class="side-nav-link-ref">{{
                                                $t(subitem.label)
                                            }}</router-link>
                                        </template>

                                        <a
                                            v-if="
                                                (!subitem.role && hasItems(subitem)) ||
                                                (subitem.role && checkPermiss(subitem.role) && hasItems(subitem))
                                            "
                                            :style="`color: ${subitem.color}`"
                                            class="side-nav-link-a-ref has-arrow"
                                            href="javascript:void(0);"
                                            >{{ $t(subitem.label) }}</a
                                        >
                                        <ul
                                            v-if="hasItems(subitem)"
                                            class="sub-menu mm-collapse"
                                            aria-expanded="false"
                                            :class="{
                                                'collap--custom': index === 0
                                            }"
                                        >
                                            <li v-for="(subSubitem, index) of subitem.subItems" :key="'u' + index">
                                                <template
                                                    v-if="
                                                        (!subSubitem.role && !hasItems(subSubitem)) ||
                                                        (subSubitem.role && checkPermiss(subSubitem.role) && !hasItems(subSubitem))
                                                    "
                                                >
                                                    <router-link
                                                        v-if="typeof subSubitem.link === 'string'"
                                                        :style="`color: ${subSubitem.color}`"
                                                        :to="subSubitem.link"
                                                        class="side-nav-link-ref"
                                                        :class="{
                                                            'menu__client--order': item.id === 12
                                                        }"
                                                        >{{ $t(subSubitem.label) }}</router-link
                                                    >
                                                </template>

                                                <a
                                                    v-if="
                                                        (!subSubitem.role && hasItems(subSubitem)) ||
                                                        (subSubitem.role && checkPermiss(subSubitem.role) && hasItems(subSubitem))
                                                    "
                                                    :style="`color: ${subSubitem.color} !important`"
                                                    class="side-nav-link-a-ref has-arrow menu__client--order"
                                                    href="javascript:void(0);"
                                                    >{{ $t(subSubitem.label) }}</a
                                                >
                                                <ul v-if="hasItems(subSubitem)" class="sub-menu mm-collapse menu__client--order" aria-expanded="true">
                                                    <li v-for="(subSubSubitem, index) of subSubitem.subItems" :key="'h' + index">
                                                        <template
                                                            v-if="
                                                                (!subSubSubitem.role && !hasItems(subSubSubitem)) ||
                                                                (subSubSubitem.role && checkPermiss(subSubSubitem.role) && !hasItems(subSubSubitem))
                                                            "
                                                        >
                                                            <router-link
                                                                v-if="typeof subSubSubitem.link === 'string'"
                                                                :style="`color: ${
                                                                    isActive(subSubSubitem.link) ? '#ffff' : subSubitem.color
                                                                } !important; background-color: ${
                                                                    isActive(subSubSubitem.link) ? subSubitem.bg_active : ''
                                                                }!important`"
                                                                :to="subSubSubitem.link"
                                                                class="side-nav-link-ref"
                                                                >{{ $t(subSubSubitem.label) }}</router-link
                                                            >
                                                        </template>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </ul>
            </div>
        </simplebar>
    </div>
</template>
