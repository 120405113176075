import { authHeader, handleResponse } from '@/api/handler/main';

function GET_COUNT_EMPLOYEE_DASHBOARD(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.VUE_APP_APIURL}/dashboard/count-employee-campaign?${query}`, requestOptions).then(handleResponse);
}

export const dashboardService = {
    GET_COUNT_EMPLOYEE_DASHBOARD
};
