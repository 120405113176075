<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-1">発注元</label>
            <div class="col-sm-3">
                <Multiselect
                    :id="`select-client_order_source-object`"
                    :value.sync="form.client_order_source"
                    :options="listClientOrderSource"
                    :config.sync="configClientOrderSource"
                    @open="handleOpenListOrderSource"
                />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <div class="text-right mb-3">
                    <button type="button" class="btn btn-sm btn-info" style="white-space: nowrap" @click="addClients(form?.clients?.length ?? 0)">
                        登録
                    </button>
                    <button
                        v-if="checkPermission('client.register')"
                        v-b-modal.modal-client
                        type="button"
                        class="btn btn-sm btn-primary ml-3"
                        style="white-space: nowrap"
                    >
                        新規登録
                    </button>
                </div>

                <div class="table-responsive" style="height: 500px">
                    <table class="table table-bordered table-box">
                        <thead>
                            <tr>
                                <th>エンドクライアント名 <span class="text-danger">*</span></th>
                                <th>担当</th>
                                <th>部署</th>
                                <th>メールアドレス</th>
                                <th>顧客種別</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, k) in form.clients" :key="k + 'fc'">
                                <td class="text-left" style="width: 320px">
                                    <Multiselect
                                        :id="`select-client-object`"
                                        :value.sync="item.client_id"
                                        :options="listDataMasterClients"
                                        :config.sync="item.configFieldSelectClient"
                                        @select="handleRemoveClient(item)"
                                        @remove="handleRemoveClient(item, 'remove')"
                                    />
                                </td>
                                <td class="text-left" style="width: 250px">
                                    <Multiselect
                                        :id="`select-client_pic-object`"
                                        :value.sync="item.client_pic_id"
                                        :options="listClientsSupport"
                                        :config="item.configFieldSelectClientPic"
                                        @open="getListMasterClientsSupport(item)"
                                    />
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        {{ item?.client_pic_id?.department ?? '' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        {{ item?.client_pic_id?.email ?? '' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        {{ item?.client_id?.type_id ? renderType(item.client_id.type_id) : '' }}
                                    </div>
                                </td>

                                <td>
                                    <div class="d-flex align-items-center" style="gap: 12px">
                                        <button type="button" class="btn btn-sm btn-info rounded-circle" @click.prevent="addClients(k)">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-danger rounded-circle" @click.prevent="deleteClient(k, item)">
                                            <i class="fa fa-minus"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { campaignMethods, clientMethods, masterMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { OPTIONS_TYPE } from './constants';
import { initObjectClient } from '@/views/pages/campaign/edit/index';

import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'tab3',
    components: { Multiselect },
    props: {
        dataObject: {
            type: Object
        },
        submitted: {
            type: Boolean,
            required: true
        },
        validate: {
            type: Object
        },
        listDataMasterClientsS: {
            type: Array
        }
    },
    watch: {
        dataObject(newValue) {
            this.form = newValue;
        },
        listDataMasterClientsS(newValue) {
            this.listDataMasterClients = newValue;
        }
    },
    data: function () {
        return {
            form: this.dataObject,
            listDataMasterClients: this.listDataMasterClientsS,
            listClientsSupport: [],
            listClientOrderSource: [],
            configClientOrderSource: {
                trackBy: 'id',
                label: 'name',
                errorField: '発注元',
                valueSync: null,
                error: false,
                isRemove: false,
                isRequired: false,
                typesValidate: []
            }
        };
    },
    mounted() {},
    methods: {
        checkPermission,
        ...campaignMethods,
        ...masterMethods,
        ...clientMethods,

        renderType(type) {
            let index = OPTIONS_TYPE.findIndex((x) => x.id == type);
            if (index >= 0) return OPTIONS_TYPE[index].name;
        },

        getListMasterClientsSupport(obj) {
            this.listClientsSupport = [];
            if (obj.configFieldSelectClientPic.loading || !obj?.client_id?.id) return;
            obj.configFieldSelectClientPic.loading = true;
            this.listClientSupport(obj.client_id.id).then((data) => {
                data.data.forEach((item, k) => {
                    let index = this.form.clients.findIndex((x) => x.client_pic_id?.id == item.id);
                    if (index >= 0) {
                        data.data[k].$isDisabled = true;
                    }
                });
                this.listClientsSupport = data.data;
                obj.configFieldSelectClientPic.loading = false;
            });
        },

        addClients(idClient) {
            this.form.clients.splice(idClient + 1, 0, initObjectClient());
        },
        deleteClient(k, obj) {
            this.form.clients.splice(k, 1);
            this.handleRemoveClient(obj, 'remove');
        },

        handleRemoveClient(obj, typeHandle) {
            if (typeHandle == 'remove') {
                if (obj.client_id?.id == this.form.client_order_source?.id) {
                    this.form.client_order_source = null;
                }
            }
            obj.client_pic_id = null;
        },

        handleOpenListOrderSource() {
            this.listClientOrderSource = this.form.clients
                .map((formClient) => {
                    return {
                        id: formClient?.client_id?.id ?? null,
                        name: formClient?.client_id?.name ?? ''
                    };
                })
                .filter((client, index, self) => index === self.findIndex((c) => c.id === client.id));
        }
    },
    computed: {}
};
</script>

<style scoped lang="scss"></style>
