import i18n from '@/i18n';
import { getDateFromFullDate, isValidDateTime, isDateRangeYear } from '@/utils/format';
import {
    regexEmail,
    regexUrl,
    regexKatakana,
    regexNumberWithHyphens,
    regexPassword,
    regexSubdomain,
    regexHalfWidthAlphanumeric,
    regexFullWidthCharacters,
    regexHiragana,
    regexFullWidthKatakana,
    regexHalfWidthKatakana,
    regexDynamicRange,
    regexNumberIntDecimal
} from '@/utils/regex';

function validateField(field, isValid = true) {
    let valueTrim = field?.valueSync ? String(field.valueSync).trim() : null;
    let lengthValueTrim = valueTrim?.length;

    if (field?.typesValidate?.includes('required')) {
        if (!valueTrim) {
            field.isRequired = field?.messageCustom ? false : true;
            field.error = true;
            isValid = false;
        }
    }

    if (field?.typesValidate?.includes('requiredCustomMsg')) {
        if (!valueTrim) {
            field.errorCustomMess = i18n.t('validateField.required', {
                field: field.errorField
            });
            field.error = true;
            isValid = false;
        }
    }

    if (field?.typesValidate?.includes('maxCustom') && valueTrim && field?.length && lengthValueTrim > field.length) {
        field.errorCustomMess = i18n.t('validateField.maxCustom', {
            field: field.errorField,
            length: field.length
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('max10') && valueTrim && lengthValueTrim > 10) {
        field.errorCustomMess = i18n.t('validateField.max10', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('max20') && valueTrim && lengthValueTrim > 20) {
        field.errorCustomMess = i18n.t('validateField.max20', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('max255') && valueTrim && lengthValueTrim > 255) {
        field.errorCustomMess = i18n.t('validateField.max255', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('number-hyphens') && valueTrim && lengthValueTrim <= 20 && !regexNumberWithHyphens(valueTrim)) {
        field.errorCustomMess = i18n.t('validateField.incorrectFormat', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('sub-domain') && valueTrim && lengthValueTrim <= 255 && !regexSubdomain(valueTrim)) {
        field.errorCustomMess = i18n.t('validateField.incorrectFormat', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (
        field?.typesValidate?.includes('half-width_alphanumeric_characters') &&
        valueTrim &&
        lengthValueTrim <= 255 &&
        !regexHalfWidthAlphanumeric(valueTrim)
    ) {
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('full-width_characters') && valueTrim && lengthValueTrim <= 255 && !regexFullWidthCharacters(valueTrim)) {
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('hiragana') && valueTrim && lengthValueTrim <= 255 && !regexHiragana(valueTrim)) {
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('full-width_katakana') && valueTrim && lengthValueTrim <= 255 && !regexFullWidthKatakana(valueTrim)) {
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('half-width_katakana') && valueTrim && lengthValueTrim <= 255 && !regexHalfWidthKatakana(valueTrim)) {
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('format_numerical_value') && valueTrim && lengthValueTrim <= 255 && !regexDynamicRange(valueTrim, '', '')) {
        field.error = true;
        isValid = false;
    }

    if (
        field?.typesValidate?.includes('range_numerical') &&
        valueTrim &&
        lengthValueTrim <= 255 &&
        !regexDynamicRange(valueTrim, field?.from_range, field?.to_range)
    ) {
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('password') && valueTrim && lengthValueTrim <= 255 && !regexPassword(valueTrim)) {
        field.isPassword = true;
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('email') && valueTrim && lengthValueTrim <= 255 && !regexEmail(valueTrim)) {
        field.isEmail = field?.messageCustom ? false : true;
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('url') && valueTrim && lengthValueTrim <= 255 && !regexUrl(valueTrim)) {
        field.isUrl = true;
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('katakana') && valueTrim && lengthValueTrim <= 255 && !regexKatakana(valueTrim)) {
        field.isKatakana = true;
        field.error = true;
        isValid = false;
    }

    if (
        field?.typesValidate?.includes('limit-input') &&
        valueTrim &&
        lengthValueTrim <= 255 &&
        field?.isNumber &&
        field?.min?.toString() &&
        field?.max?.toString()
    ) {
        valueTrim = parseInt(valueTrim, 10);
        if (valueTrim < field.min || valueTrim > field.max || isNaN(valueTrim)) {
            field.errorCustomMess = field?.messageCustom
                ? ''
                : i18n.t('validateField.limitInput', {
                      field: field.errorField,
                      min: field.min,
                      max: field.max
                  });
            field.error = true;
            isValid = false;
        }
    }
    if (
        field?.typesValidate?.includes('limit-character') &&
        valueTrim &&
        lengthValueTrim <= 255 &&
        field?.min?.toString() &&
        field?.max?.toString()
    ) {
        if (lengthValueTrim < field.min || lengthValueTrim > field.max) {
            field.error = true;
            isValid = false;
        }
    }

    if (field?.typesValidate?.includes('integerMaxMin') && valueTrim && lengthValueTrim <= 255 && field?.min?.toString() && field?.max?.toString()) {
        const regex = /^-?\d{1,10}$/;
        if (!regex.test(valueTrim)) {
            field.error = true;
            isValid = false;
            return false;
        }
        const number = parseInt(valueTrim, 10);
        let checkRegex = number >= field.min && number <= field.max;
        if (!checkRegex) {
            field.error = true;
            isValid = false;
        }
    }

    if (
        field?.typesValidate?.includes('integerDecimal') &&
        valueTrim &&
        lengthValueTrim <= 255 &&
        !regexNumberIntDecimal(valueTrim, field?.integer, field?.decimal)
    ) {
        field.error = true;
        isValid = false;
    }

    // date validation
    if (field?.typesValidate?.includes('requiredDate')) {
        if (!valueTrim) {
            field.error = true;
            field.errorText = i18n.t('validateField.requiredDate', {
                field: field.errorField
            });
            isValid = false;
        }
    }

    if (field?.typesValidate?.includes('date')) {
        if (valueTrim) {
            if (!getDateFromFullDate(valueTrim)) {
                field.error = true;
                field.errorText = i18n.t('validateField.incorrectFormat', {
                    field: field.errorField
                });
                isValid = false;
            }
        }
    }
    if (field?.typesValidate?.includes('date-year-range')) {
        if (valueTrim && getDateFromFullDate(valueTrim) && field.minYear) {
            if (!isDateRangeYear(valueTrim, field.minYear, field.maxYear)) {
                field.error = true;
                field.errorText = i18n.t('validateField.dateMinMaxYear', {
                    minDateYear: field?.minDateYear ? field?.minDateYear : `1800/01/01`,
                    maxDateYear: field?.maxDateYear ? field?.maxDateYear : `2100/12/31`
                });
                isValid = false;
            }
        }
    }

    if (field?.typesValidate?.includes('dateTime')) {
        if (valueTrim) {
            if (!isValidDateTime(valueTrim)) {
                field.error = true;
                field.errorText = i18n.t('validateField.incorrectFormat', {
                    field: field.errorField
                });
                isValid = false;
            }
        }
    }

    if (field?.typesValidate?.includes('requiredSelect')) {
        if (!valueTrim) {
            field.error = true;
            field.isRequired = field?.messageCustom ? false : true;
            isValid = false;
        }
    }

    return isValid;
}

function validateAllField(form) {
    let isValid = true;

    Object.keys(form).forEach((key) => {
        const field = form[key];
        if (key.startsWith('configField')) {
            isValid = validateField(field, isValid);
        }

        // Nếu là một mảng, gọi đệ quy validateAllField cho từng phần tử
        if (Array.isArray(field)) {
            field.forEach((item) => {
                if (typeof item === 'object' && item !== null) {
                    // Gọi lại validateAllField đệ quy cho từng object trong mảng
                    isValid = validateAllField(item) && isValid;
                }
            });
        }
        // // Nếu là một object, gọi đệ quy validateAllField
        else if (typeof field === 'object' && field !== null) {
            isValid = validateAllField(field) && isValid;
        }
    });

    return isValid;
}

export { validateAllField, validateField };
