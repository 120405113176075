import { authHeaderUpload, handleResponse } from '@/api/handler/main';

function uploadImage() {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderUpload()
    };
    return fetch(process.env.VUE_APP_APIURL + `/upload/image`, requestOptions).then(handleResponse);
}

export const uploadImgService = {
    uploadImage
};
