import { formatDateCommon, formatMergeDateAndTime, STR_DATE_TIME } from '@/utils/format';
import { showMessage } from '@/utils/messages';
import { TABS } from './constants';
import moment from 'moment';
import i18n from '@/i18n';

function handleDataBeforeSaveLP(dataForm) {
    return {
        ...(dataForm.id !== null &&
            dataForm.id !== undefined && {
                id: dataForm.id
            }),
        campaign: dataForm?.campaign?.id
            ? {
                  id: dataForm?.campaign?.id
              }
            : null,
        instruction_pic: dataForm.sale_staff?.id
            ? {
                  id: dataForm?.sale_staff?.id
              }
            : null,
        business_pic: dataForm.person_in_charge?.id
            ? {
                  id: dataForm?.person_in_charge?.id
              }
            : null,
        name: dataForm.name,
        url_public: dataForm.url_public,
        lp_start_date: formatMergeDateAndTime(dataForm.lp_start_date, dataForm.lp_start_time),
        lp_end_date: formatMergeDateAndTime(dataForm.lp_end_date, dataForm.lp_end_time),
        campaign_start_date: formatMergeDateAndTime(dataForm.campaign_start_date, dataForm.campaign_start_time),
        campaign_end_date: formatMergeDateAndTime(dataForm.campaign_end_date, dataForm.campaign_end_time),
        status: dataForm.status,
        keywords: dataForm.keywords,
        description: dataForm.description,
        analytics: dataForm.analytics,
        application_form_link: dataForm.application_form_link,
        og_image: dataForm?.og_image?.id
            ? {
                  id: dataForm?.og_image?.id
              }
            : null,
        x_url: dataForm.x_url ?? '',
        x_share_text: dataForm.x_share_text,
        x_hashtag: dataForm.x_hashtag,

        //----- start setting blocks --------------
        blocks_settings: dataForm.blocks_settings,
        //----- end setting blocks --------------

        //----- start setting firstview --------------
        first_view: {
            title_image: dataForm.first_view.title_image?.id
                ? {
                      id: dataForm.first_view.title_image?.id
                  }
                : null,
            detail: dataForm.first_view?.detail
        },
        application_period: {
            title: dataForm.application_period.title,
            style: dataForm.application_period.style,
            start_date: dataForm.application_period.start_date,
            end_date: dataForm.application_period.end_date,
            detail: dataForm.application_period.detail
        }
        //----- end setting firstview --------------
    };
}

function handleDataResponse(dataForm, data) {
    dataForm.id = data?.id ?? null;
    dataForm.zac_id = data?.campaign?.zac_id;
    dataForm.sfid = data?.campaign?.sfid;
    dataForm.number_cp = data?.campaign?.id;
    dataForm.campaign_entity_id = data?.campaign?.campaign_entity_id;
    dataForm.sale_staff = data?.instruction_pic ?? data?.campaign?.sale_staff ?? '';
    dataForm.person_in_charge = data?.business_pic ?? data?.campaign?.person_in_charge;
    dataForm.campaign = data?.campaign ?? null;
    dataForm.code = data?.code ?? null;
    dataForm.name = data?.name ?? '';
    dataForm.url_public = data?.url_public;
    dataForm.lp_start_time = formatDateCommon(data?.lp_start_date, 'dateTime');
    dataForm.lp_end_time = formatDateCommon(data?.lp_end_date, 'dateTime');
    dataForm.campaign_start_time = formatDateCommon(data?.campaign_start_date, 'dateTime');
    dataForm.campaign_end_time = formatDateCommon(data?.campaign_end_date, 'dateTime');
    dataForm.lp_start_date = formatDateCommon(data?.lp_start_date, 'date');
    dataForm.lp_end_date = formatDateCommon(data?.lp_end_date, 'date');
    dataForm.campaign_start_date = formatDateCommon(data?.campaign_start_date, 'date');
    dataForm.campaign_end_date = formatDateCommon(data?.campaign_end_date, 'date');
    dataForm.status = data?.status;
    dataForm.keywords = data?.keywords;
    dataForm.description = data?.description;
    dataForm.analytics = data?.analytics;
    dataForm.og_image = data?.og_image ?? null;
    dataForm.x_url = data?.x_url ?? '';
    dataForm.x_share_text = data?.x_share_text;
    dataForm.x_hashtag = data?.x_hashtag;

    //----- start setting firstview --------------
    dataForm.first_view = {
        title_image: data?.first_view?.title_image ?? null,
        detail: data?.first_view?.detail ?? ''
    };
    dataForm.application_period = {
        title: data?.application_period?.title ?? '応募期間',
        style: data?.application_period?.style ?? {
            id: 1,
            name: 'A',
            srcBgr: 'https://api-paldia.supenient.vn/storage/images/image1.png',
            contents:
                '<div style="background-image: url(《sourceBgr》);background-repeat: no-repeat;background-size: contain;background-position: center;height: 310px;width: 100%;"><div style="position: relative; top: 50%; transform: translateY(-50%)"><div style="display: flex; justify-content: space-around; align-items: center"><div><p style="padding: 10px; background-color: #ffffff; color: #36a6b4; border-radius: 10px">《タイトル》</p></div><div class=""><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #ffffff">《開始》</h3></div><div style="text-align: center; margin-block: 2px; color: #ffffff">▼</div><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #ffffff">《終了》</h3></div></div></div></div></div>'
        },
        start_date: data?.application_period?.start_date,
        end_date: data?.application_period?.end_date,
        detail: data?.application_period?.detail
    };
    //----- end setting firstview --------------

    //----- start setting prize settings  --------------
    dataForm.prize_settings = {
        title: '',
        background: {
            image: '',
            color: '#ffffff'
        },
        is_repeat: false,
        style: {
            id: 1,
            name: 'A'
        },
        event_prizes: []
    };
    //----- end setting prize settings  --------------
    return dataForm;
}

function handleMapDataFromStateLP(dataForm, allInforCampaignLP) {
    dataForm.sale_staff = allInforCampaignLP?.sale_staff ?? '';
    dataForm.zac_id = allInforCampaignLP?.zac_id;
    dataForm.sfid = allInforCampaignLP?.sfid;
    dataForm.campaign_entity_id = allInforCampaignLP?.campaign_entity_id;
    dataForm.campaign_start_time = formatDateCommon(allInforCampaignLP?.campaign_start_date, 'dateTime');
    dataForm.campaign_end_time = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'dateTime');
    dataForm.campaign_start_date = formatDateCommon(allInforCampaignLP?.campaign_start_date, 'date');
    dataForm.campaign_end_date = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'date');
    return dataForm;
}

function validateEndDate(startDateStr, endDateStr) {
    let check = true;
    const startDate = moment(startDateStr.valueSync, STR_DATE_TIME);
    const endDate = moment(endDateStr.valueSync, STR_DATE_TIME);
    if (!startDateStr.valueSync && !endDateStr.valueSync) return check;
    if (!endDate.isSameOrAfter(startDate)) {
        check = false;
        endDateStr.error = true;
        endDateStr.errorText = i18n.t('validateField.compareDate');
    }
    return check;
}

function handleAlertValidateTabs(conditionTab, bvToast, modelTab) {
    let check = true;
    const tabBasic = !conditionTab.checkBasicLP || !conditionTab.checkEndDateCP || !conditionTab.checkEndDateLP;
    const tabTimeApplication = !conditionTab.checkTimeApplication;
    const tabPrize = !conditionTab.checkTabPrize;

    if (tabBasic && modelTab !== 0) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: TABS[0].name
            })
        );
    }

    if (tabTimeApplication && modelTab !== 4) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: TABS[4].name
            })
        );
    }

    if (tabPrize && modelTab !== 5) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: TABS[5].name
            })
        );
    }

    if (tabBasic || tabTimeApplication || tabPrize) {
        check = false;
    }

    return check;
}
export { handleDataBeforeSaveLP, handleMapDataFromStateLP, validateEndDate, handleDataResponse, handleAlertValidateTabs };
