import i18n from '@/i18n';

const dataMaster = [
    {
        name: 'パルディア社員',
        key: 'employee',
        rowspan: 1,
        childMain: '権限・チーム関連',
        keyMain: 'department',
        child: []
    },
    {
        name: 'FAQ',
        key: 'faq_master',
        rowspan: 1,
        childMain: 'FAQシート項目',
        keyMain: 'sheets',
        child: []
    },
    {
        name: 'キャンペーン',
        key: 'campaign',
        rowspan: 36,
        childMain: '',
        keyMain: 'status',
        child: [
            {
                name: i18n.t('pageCampaign.targetProductCategory'),
                key: 'product_target'
            },
            {
                name: '当選数',
                key: 'number_prizes'
            },
            {
                name: 'SNS応募条件',
                key: 'conditions_applying_sns'
            },
            {
                name: 'タレント・キャラクター',
                key: 'talent_character'
            },
            {
                name: '告知方法',
                key: 'announcement_method'
            },
            {
                name: 'エンドクライエントカテゴリ',
                key: 'category_customer'
            },
            {
                name: 'メーカー',
                key: 'maker'
            },
            {
                name: '流通',
                key: 'distribution'
            },
            {
                name: '食品',
                key: 'food'
            },
            {
                name: '食料',
                key: 'drink'
            },
            {
                name: '医薬品',
                key: 'pharmaceuticals'
            },
            {
                name: '化粧品',
                key: 'cosmetics'
            },
            {
                name: 'トイレタリー・日用品',
                key: 'toiletries_daily_necessities'
            },
            {
                name: '家電製品',
                key: 'home_appliances'
            },
            {
                name: 'AV・コンピューター及び周辺機器',
                key: 'AVComputers_and_peripherals'
            },
            {
                name: '通信サービス',
                key: 'communication_service'
            },
            {
                name: '衣料･ファッション･装飾品',
                key: 'clothing_fashion_and_accessories'
            },
            {
                name: 'おもちゃ',
                key: 'toy'
            },
            {
                name: '出版･趣味･娯楽品',
                key: 'publishing_hobbies_entertainment_items'
            },
            {
                name: '家庭用品',
                key: 'household_goods'
            },
            {
                name: 'ペット用品',
                key: 'pet_supplies'
            },
            {
                name: '案件内容',
                key: 'project_details'
            },
            {
                name: 'ツール',
                key: 'tool'
            },
            {
                name: '購買条件',
                key: 'purchase_conditions_1'
            },
            {
                name: '対応指示',
                key: 'prize_less_status'
            },
            {
                name: 'お問い合わせ',
                key: 'inquiry'
            },
            {
                name: '種別',
                key: 'type'
            },
            {
                name: '賞品カテゴリ',
                key: 'gift_category'
            },
            {
                name: '賞品あまりステータス',
                key: 'prize.remain_status'
            },
            {
                name: 'デジタルギフト',
                key: 'prize.digital_gift'
            }
        ]
    }
];

const nameMaster = {
    'employee.team': 'チームマスタ',
    'employee.group': 'グループマスタ'
};

const keyMaster = {
    'employee.team': 'employee.team',
    'employee.department': 'employee.department',
    'foreign_partner.type': 'foreign_partner.type',
    'faq_master.category': 'faq_master.category',
    'faq_master.sheets': 'faq_master.sheets',
    'prize.prize_less_status': 'prize.prize_less_status',
    'campaign.type': 'campaign.type',
    'campaign.status': 'campaign.status',
    'campaign.conditions_applying_admission': 'campaign.conditions_applying_admission',
    'campaign.project_details': 'campaign.project_details',
    'campaign.conditions_applying_sns': 'campaign.conditions_applying_sns',
    'campaign.product_target': 'campaign.product_target',
    'campaign.purchase_condition': 'campaign.purchase_condition',
    'campaign.purchase_conditions_2': 'campaign.purchase_conditions_2',
    'campaign.tool': 'campaign.tool',
    'campaign.information_ip': 'campaign.information_ip',
    'prize.corresponding_status': 'prize.corresponding_status',
    'prize.storage_warehouse': 'prize.storage_warehouse',
    'campaign.sponsor_information': 'campaign.sponsor_information',
    'campaign.advertising': 'campaign.advertising',
    'campaign.social_platform': 'campaign.social_platform',
    'client.agency_type': 'client.agency_type',
    'client.maker_type': 'client.maker_type',
    'client.circulate_type': 'client.circulate_type'
};

const BLOCK_COMMON = [
    {
        id: 1,
        name: 'ラジオボタン(単一選択) - Radio',
        icon: '',
        type: 'radio'
    },
    {
        id: 2,
        name: 'セレクトボックス(単一選択) - Select',
        icon: '',
        type: 'select'
    },
    {
        id: 3,
        name: 'チェックボックス(複数選択) - Checkbox',
        icon: '',
        type: 'checkbox'
    },
    {
        id: 4,
        name: 'テキスト(単一行) - Input',
        icon: '',
        type: 'input'
    },
    {
        id: 5,
        name: 'テキスト(複数行) - Textarea',
        icon: '',
        type: 'textarea'
    },
    {
        id: 6,
        name: 'マトリクス - Matrix',
        icon: '',
        type: 'matrix'
    },
    {
        id: 7,
        name: '表示用ブロック1 - Div1',
        icon: '',
        type: 'div_1'
    },
    {
        id: 8,
        name: '表示用ブロック2 - Div2',
        icon: '',
        type: 'div_2'
    },
    {
        id: 9,
        name: 'ファイルアップロード - UploadFile',
        icon: '',
        type: 'upload-file'
    },
    {
        id: 10,
        name: '日付 - Date',
        icon: '',
        type: 'date'
    },
    {
        id: 11,
        name: '氏名 - Fullname',
        icon: '',
        type: 'fullname'
    },
    {
        id: 12,
        name: '住所ブロック一括作成 - Postcode and Province',
        icon: '',
        type: 'postcode-and-province'
    },
    {
        id: 13,
        name: '郵便番号 - Postcode',
        icon: '',
        type: 'postcode'
    },
    {
        id: 14,
        name: '都道府県 - Province',
        icon: '',
        type: 'province'
    },
    {
        id: 15,
        name: '電話番号 - Phone',
        icon: '',
        type: 'phone'
    },
    {
        id: 16,
        name: '残席管理ボックス - Remaining Seats Management Box',
        icon: '',
        type: 'remaining-seats-management-box'
    },
    {
        id: 17,
        name: 'キーワードボックス - Keywords Box',
        icon: '',
        type: 'keyword-box'
    },
    {
        id: 18,
        name: 'パラメータ取得 - Get param',
        icon: '',
        type: 'get-param'
    }
];

//2 電話, 5 メール,8 DM, 13 その他
// 9 Twitter, 10 Instagram, 11 Tiktok, 12 Line
const LIST_INQUIRY_FIT = [2, 5, 8, 13];
const LIST_CHILD_DM = [9, 10, 11, 12];

const PHONE_SETTING_EMPLOYEE = [
    {
        id: 1,
        label: 'テスト・ガイダンス担当'
    },
    {
        id: 2,
        label: '電話工事担当'
    },
    {
        id: 3,
        label: '振り分け担当'
    }
];

const ARRTYPEFIXED = [
    { id: 1, value: '発送' },
    { id: 2, value: '運営事務局' },
    { id: 3, value: '電話工事' },
    { id: 4, value: 'デザイン' },
    { id: 5, value: '印刷' },
    { id: 6, value: 'SNS投稿' }
];

const NAMEROLEFIXED = [
    {
        id: 1,
        text: '管理者'
    },
    {
        id: 2,
        text: '営業'
    },
    {
        id: 3,
        text: 'ディレクション'
    },
    {
        id: 4,
        text: 'アルバイト'
    },
    {
        id: 5,
        text: '外部パートナー'
    }
];

export { dataMaster, nameMaster, keyMaster, BLOCK_COMMON, LIST_INQUIRY_FIT, LIST_CHILD_DM, PHONE_SETTING_EMPLOYEE, ARRTYPEFIXED, NAMEROLEFIXED };
