<script>
import i18n from '@/i18n';
import Swal from 'sweetalert2';

import { authComputed, authFackMethods } from '@/state/helpers';
import { NAMEROLEFIXED } from '@/config/constants';
import { clearInfoUser } from '@/api/handler/main';

export default {
    data() {
        return {
            current_language: 'ja',
            isShowProfile: true
        };
    },
    computed: {
        ...authComputed,
        resultRole() {
            return NAMEROLEFIXED.find((item) => this.currentUser && this.currentUser.role && item.id === this.currentUser.role.id);
        }
    },
    mounted() {
        if (this.currentUser.role && document.getElementById('page-topbar')?.style) {
            if (this.currentUser.role.name == 'part_time') {
                document.getElementById('page-topbar').style.backgroundColor = '#fbbc04';
            }
            if (this.currentUser.role.name == 'administrator' || this.currentUser.role.name == 'sale' || this.currentUser.role.name == 'instructor') {
                document.getElementById('page-topbar').style.backgroundColor = '#ea4335';
            }
            if (this.currentUser.role.name == 'partner') {
                document.getElementById('page-topbar').style.backgroundColor = '#4285f4';
                this.isShowProfile = false;
            }
        }
    },
    methods: {
        ...authFackMethods,
        logoutUser() {
            Swal.fire({
                title: 'ログアウトしてもよろしいでしょうか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.logout().then(() => {
                        clearInfoUser();
                        this.$router.push('/login');
                    });
                }
            });
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        initFullScreen() {
            document.body.classList.toggle('fullscreen-enable');
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        setLanguage(locale) {
            i18n.locale = locale;
            this.current_language = i18n.locale;
        },
        handleGoToEditProfile() {
            if (this.$route.path == '/employee/form' && this.$route.query.id == this.currentUser.id) return;
            this.$router.push({ path: '/employee/form', query: { id: this.currentUser.id } });
        },
        goDashboard() {
            if (this.$route.name == 'home') return;
            this.$router.push('/');
        }
    }
};
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a href="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-paldia.png" alt height="22" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo-paldia.png" alt height="20" />
                        </span>
                    </a>

                    <a href="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-paldia.png" alt height="22" class="w-full" style="margin-left: 5px" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo-paldia.png" alt height="20" style="width: 70%; height: 60px; margin-left: 20px" />
                        </span>
                    </a>
                </div>

                <button @click="toggleMenu" type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                    <i class="ri-menu-2-line align-middle text-white"></i>
                </button>
            </div>

            <div class="d-flex">
                <div class="dropdown d-inline-block d-lg-none ml-2">
                    <button
                        type="button"
                        class="btn header-item noti-icon waves-effect"
                        id="page-header-search-dropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i class="ri-search-line"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-search-dropdown">
                        <form class="p-3">
                            <div class="form-group m-0">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search ..." />
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="submit">
                                            <i class="ri-search-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
                    <template v-slot:button-content>
                        <div style="text-align: right">
                            <span class="d-none d-xl-inline-block ml-1 text-white"
                                >{{ resultRole?.text }}: {{ currentUser ? currentUser.name : '' }}</span
                            >
                            <i class="mdi mdi-chevron-down d-none d-xl-inline-block text-white"></i>
                        </div>
                        <div class="d-flex justify-content-end" style="max-width: 500px" @click="goDashboard()">
                            <div style="color: #fff; text-overflow: ellipsis; overflow: hidden" class="">
                                {{ currentUser && currentUser.default_campaign ? currentUser.default_campaign.name : '' }}
                            </div>
                        </div>
                    </template>
                    <a v-if="isShowProfile" class="dropdown-item" href="javascript:void(0)" @click="handleGoToEditProfile()">
                        <i class="ri-user-line align-middle mr-1"></i>
                        {{ $t('common.inforUser') }}
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="logoutUser()">
                        <span>
                            <i class="ri-shut-down-line align-middle mr-1"></i>
                            {{ $t('common.logout') }}
                        </span>
                    </a>
                </b-dropdown>
            </div>
        </div>
    </header>
</template>

<style lang="scss" scoped>
.notify-item {
    .active {
        color: #16181b;
        background-color: #f8f9fa;
    }
}
</style>
