import { faqService } from '@/api/services/faq.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    registerFaq({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return faqService.registerFaq(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateFaqs({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return faqService.updateFaqs(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateFaqsAnswerDefault({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return faqService.updateFaqsAnswerDefault(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerAnswer({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return faqService.registerAnswer(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateAnswer({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return faqService.updateAnswer(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    listFaqs({ dispatch, commit }, query) {
        commit('listRequest', []);
        return faqService.listFaqs(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailFaq({ dispatch, commit }, data) {
        commit('listRequest', []);
        return faqService.detailFaq(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteAnswer({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return faqService.deleteAnswer(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteFaq({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return faqService.deleteFaq(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
