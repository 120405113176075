import i18n from '@/i18n';
import { STATUS_FORM, TABS } from './constants';
import { showMessage } from '@/utils/messages';
import { PAGE_TYPE_FIXED } from './constants';

const handleStatus = (status, list = STATUS_FORM) => {
    if (!status) return '';
    return list.find((s) => s.id == status)?.value ?? '';
};

const handleDataBeforeSendServer = (formData) => {
    return {
        ...(formData.id !== null &&
            formData.id !== undefined && {
                id: formData.id,
                form_pages: handleDataFormPage(formData)
            }),
        campaign: formData?.campaign?.id
            ? {
                  id: formData?.campaign?.id
              }
            : null,
        client: formData?.client?.id
            ? {
                  id: formData?.client?.id
              }
            : null,
        name: formData.name,
        url_public: formData.url_public,
        status: formData.status,
        application_limit: formData.application_limit,
        url_redirect_when_limit: formData.url_redirect_when_limit,
        reservation_display_url: formData.reservation_display_url,
        expired_display_url: formData.expired_display_url,
        mail_to: formData.mail_to,
        save_form_temporarily: formData.save_form_temporarily,
        temporary_expiration_date: formData.temporary_expiration_date,
        hashtag: formData.hashtag,
        url_parameters: formData.url_parameters,
        type: formData?.page_type?.id ?? null,
        meta_title: formData?.title ?? '',
        description: formData?.description ?? '',
        header: formData?.header ?? '',
        footer: formData?.footer ?? '',
        google_analytics: formData?.ga ?? ''
    };
};

const handleDataResponse = (dataForm, data) => {
    dataForm.id = data?.id ?? null;
    dataForm.campaign = data?.campaign ?? null;
    dataForm.client = data?.client ?? null;
    dataForm.name = data?.name ?? '';
    dataForm.url_public = data?.url_public ?? '';
    dataForm.status = data?.status ?? '';
    dataForm.application_limit = data?.application_limit ?? '';
    dataForm.url_redirect_when_limit = data?.url_redirect_when_limit ?? '';
    dataForm.reservation_display_url = data?.reservation_display_url ?? '';
    dataForm.expired_display_url = data?.expired_display_url ?? '';
    dataForm.mail_to = data?.mail_to ?? '';
    dataForm.save_form_temporarily = data?.save_form_temporarily ?? '';
    dataForm.temporary_expiration_date = data?.temporary_expiration_date ?? '';
    dataForm.hashtag = data?.hashtag ?? '';
    dataForm.url_parameters = data?.url_parameters ?? '';
    dataForm.page_type = dataForm.type ? PAGE_TYPE_FIXED.find((pty) => pty.id == dataForm.type) : PAGE_TYPE_FIXED[0];

    if (data?.form_pages.page_inputs.length >= 0 && data?.form_pages.page_confirms && data?.form_pages.page_completes) {
        dataForm.setting_page['pages'] = dataForm.setting_page['pages'].map((pageItem) => {
            const matchingPage =
                pageItem.title == '入力画面'
                    ? 'page_inputs'
                    : pageItem.title == '確認画面'
                      ? 'page_confirms'
                      : pageItem.title == '完了画面'
                        ? 'page_completes'
                        : '';

            if (data?.form_pages[matchingPage].title !== '入力画面' && data?.form_pages[matchingPage]?.id) {
                pageItem.id = data?.form_pages[matchingPage]?.id;
            }
            const formatArray = Array.isArray(data?.form_pages[matchingPage]) ? data?.form_pages[matchingPage] : [data?.form_pages[matchingPage]];

            if (matchingPage !== 'page_confirms') {
                pageItem.page_child = formatArray.map((inputPageItem) => {
                    return {
                        type_page_wl: matchingPage,
                        order_index: inputPageItem.order_index,
                        id: inputPageItem.id,
                        name_page: inputPageItem?.title,
                        is_default: inputPageItem.is_default == 1 ? true : false,
                        blocks: inputPageItem.blocks.map((blockItem) => {
                            return {
                                ...blockItem.extension,
                                id: blockItem?.id,
                                name: blockItem?.block_name ?? '',
                                option_value: blockItem.options,
                                additional_setting: {
                                    edit_with_editor: null,
                                    error_message_block: handleDataErrorMsg(blockItem.error_messages, 'res')
                                }
                            };
                        })
                    };
                });
            } else {
                let pageConfirmDefault = data?.form_pages['page_inputs'].filter((vPageChildItem) => vPageChildItem.is_default);
                pageItem.page_child = pageConfirmDefault.map((inputPageItem) => {
                    return {
                        type_page_wl: matchingPage,
                        order_index: inputPageItem.order_index,
                        id: inputPageItem.id,
                        name_page: inputPageItem?.title,
                        is_default: inputPageItem.is_default == 1 ? true : false,
                        blocks: inputPageItem.blocks.map((blockItem) => {
                            return {
                                ...blockItem.extension,
                                id: blockItem?.id,
                                name: blockItem?.block_name ?? '',
                                option_value: blockItem.options,
                                additional_setting: {
                                    edit_with_editor: null,
                                    error_message_block: handleDataErrorMsg(blockItem.error_messages, 'res')
                                }
                            };
                        })
                    };
                });
            }
            return pageItem;
        });
    }

    dataForm.title = data?.meta_title ?? '';
    dataForm.description = data?.description ?? '';
    dataForm.header = data?.header ?? '';
    dataForm.footer = data?.footer ?? '';
    dataForm.ga = data?.google_analytics ?? '';

    return dataForm;
};

const handleDataFormPage = (initData) => {
    let vPageChildInput = initData.setting_page['pages'][0].page_child;
    let vPageChildConfirm = initData.setting_page['pages'][1].page_child[0];
    let vPageChildComplete = initData.setting_page['pages'][2].page_child[0];

    initData.form_pages['page_inputs'] = vPageChildInput.map((pageCItem, idx) => {
        return {
            order_index: idx,
            ...(pageCItem.id !== null &&
                pageCItem.id !== undefined && {
                    id: pageCItem.id
                }),
            title: pageCItem.name_page,
            blocks: pageCItem.blocks.map((blockItem, idx) => {
                return {
                    order_index: idx,
                    id: blockItem.id,
                    block_type: blockItem.block_type,
                    block_name: blockItem.name,
                    option_values: blockItem.option_value,
                    extension: {
                        ...blockItem,
                        option_value: [],
                        additional_setting: {
                            edit_with_editor: blockItem.additional_setting.edit_with_editor,
                            error_message_block: []
                        }
                    },
                    error_messages: handleDataErrorMsg(blockItem.additional_setting.error_message_block)
                };
            }),
            is_default: pageCItem.is_default
        };
    });

    initData.form_pages.page_confirms = {
        ...(initData.setting_page['pages'][1]?.id !== null &&
            initData.setting_page['pages'][1]?.id !== undefined && {
                id: initData.setting_page['pages'][1].id
            }),
        is_default: vPageChildConfirm.is_default,
        title: vPageChildConfirm.name_page
    };

    initData.form_pages.page_completes = {
        ...(initData.setting_page['pages'][2]?.id !== null &&
            initData.setting_page['pages'][2]?.id !== undefined && {
                id: initData.setting_page['pages'][2].id
            }),
        is_default: vPageChildComplete.is_default,
        title: vPageChildComplete.name_page,
        blocks: vPageChildComplete.blocks.map((blockItem, idx) => {
            return {
                order_index: idx,
                id: blockItem.id,
                block_type: blockItem.block_type,
                block_name: blockItem.name,
                extension: {
                    ...blockItem,
                    additional_setting: {
                        edit_with_editor: blockItem.additional_setting.edit_with_editor,
                        error_message_block: []
                    }
                },
                error_messages: handleDataErrorMsg(blockItem.additional_setting.error_message_block)
            };
        })
    };

    return initData.form_pages;
};

const handleDataErrorMsg = (data, type = 'req') => {
    if (type === 'res') {
        return data.map((itemError) => {
            return {
                label_id: itemError.label_id,
                messages: itemError.message,
                type_msg: itemError.type_msg,
                required_value_other: itemError?.required_value_other ?? false,
                required_input: itemError?.required_input ?? false
            };
        });
    } else {
        return data.map((itemError) => {
            return {
                label_id: itemError.label_id,
                message: itemError.messages,
                type_msg: itemError.type_msg,
                required_value_other: itemError?.required_value_other ?? false,
                required_input: itemError?.required_input ?? false
            };
        });
    }
};

const handleAddConfigURL = (validateArrayURL, url_parameters) => {
    validateArrayURL = url_parameters.map((o, idx) => {
        const varConfigValue = validateArrayURL[idx]?.configFieldUrl;
        return {
            configFieldUrl: {
                isRequired: varConfigValue?.isRequired ?? false,
                error: varConfigValue?.error ?? false,
                errorField: varConfigValue?.errorField ?? i18n.t('managerForm.settingBasic.urlParam'),
                errorCustomMess: varConfigValue?.errorCustomMess ?? '',
                valueSync: o.url_parameter ? o.url_parameter : '',
                typesValidate: varConfigValue?.typesValidate ?? ['required', 'max255']
            }
        };
    });

    return validateArrayURL;
};

const handleAlertValidateTabs = (conditionTab, bvToast, modelTab) => {
    let check = true;
    const tabBasic = !conditionTab.checkFormBasic;
    const tabSettingPage = !conditionTab.checkFormPage;

    if (tabBasic && modelTab !== 0) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: TABS[0].name
            })
        );
    }
    if (tabSettingPage && modelTab !== 2) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: TABS[2].name
            })
        );
    }

    if (tabBasic || tabSettingPage) {
        check = false;
    }

    return check;
};
export { handleAddConfigURL, handleDataBeforeSendServer, handleDataResponse, handleStatus, handleAlertValidateTabs };
