<template>
    <div :index="index">
        <!-- {{ inforBlock }} -->
        <div v-if="inforBlock.block_type == 'radio'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div
                :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer text-${inforBlock.choice_placement_specification}`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`"
            >
                <div v-if="defineShowTypeBlock">
                    <div class="" :style="`display: grid; grid-template-columns: repeat(${inforBlock.number_of_items_displayed_per_line}, auto)`">
                        <div v-for="item in inforBlock.option_value" :key="`RadioForm-${item.id}-${inforBlock.id}`">
                            <InputRadio
                                :model.sync="configBlock.modelRadio[inforBlock.id].radio"
                                :name="`RadioForm-${inforBlock.id}`"
                                :id="`RadioForm-${indexRadio}-${item.id}-${inforBlock.id}`"
                                :value="item.id"
                                :label="item.value"
                                :disabled="false"
                                @change="handleChangeRadio"
                            />
                        </div>
                    </div>
                    <div
                        v-if="inforBlock?.setting_others?.other_use"
                        :class="`d-flex`"
                        :style="`gap: 10px; justify-content:${inforBlock.choice_placement_specification}`"
                    >
                        <InputRadio
                            :model.sync="configBlock.modelRadio[inforBlock.id].radio"
                            :name="`RadioForm-${inforBlock.id}`"
                            :id="`RadioForm-Others-${indexRadio}-${inforBlock.id}`"
                            :value="'etc'"
                            :label="inforBlock.setting_others.label_other"
                            :disabled="false"
                            @change="handleChangeRadio"
                        />
                        <div>
                            <InputText
                                :id="`RadioForm-Others-${index}-${indexRadio}-${inforBlock.id}`"
                                :model.sync="configBlock.modelRadio[inforBlock.id].text"
                                :config.sync="configBlock.validateRadio[inforBlock.id].configFieldText"
                                :disabled="configBlock.modelRadio[inforBlock.id].radio !== 'etc'"
                                @blur="handleValidateField(configBlock.validateRadio[inforBlock.id].configFieldText, 'RadioForm-Others')"
                            />
                            <div v-if="configBlock.validateRadio[inforBlock.id].configFieldText.error" class="d-block invalid-feedback">
                                {{ configBlock.validateRadio[inforBlock.id].configFieldText.errorField }}
                            </div>
                        </div>
                    </div>
                    <div v-if="inforBlock?.setting_others?.uncheck_use" class="mt-2">
                        <button class="btn btn-light" :class="`${true ? 'disabled_cursor' : ''}`" :disabled="false" @click="onHandleRemoveRadio">
                            {{
                                inforBlock.setting_others?.label_uncheck
                                    ? inforBlock.setting_others.label_uncheck
                                    : $t('managerForm.settingPage.uncheck')
                            }}
                        </button>
                    </div>
                    <div
                        v-if="
                            configBlock.validateRadio[inforBlock.id].configField.error &&
                            !configBlock.validateRadio[inforBlock.id].configField.valueSync
                        "
                        class="d-block invalid-feedback"
                    >
                        {{ configBlock.validateRadio[inforBlock.id].configField.errorField }}
                    </div>
                </div>
                <div v-else>
                    {{
                        configBlock.modelRadio[inforBlock.id].radio == 'etc'
                            ? `${inforBlock.setting_others.label_other}(${configBlock.modelRadio[inforBlock.id].text})`
                            : renderValueRadio(configBlock.modelRadio[inforBlock.id].radio)
                    }}
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'select'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer`" :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`">
                <div v-if="defineShowTypeBlock">
                    <Multiselect
                        :id="`SelectForm-${inforBlock.id}-${index}-${indexMultiselect}`"
                        :value.sync="configBlock.modelSelect[inforBlock.id]"
                        :options="inforBlock.option_value"
                        :config.sync="configBlock.validateSelect[inforBlock.id].configField"
                        @remove="handleRemoveMultiselect(configBlock.validateSelect[inforBlock.id].configField)"
                        @select="handleChangeMultiselect"
                        :disabled="false"
                    />
                    <div v-if="configBlock.validateSelect[inforBlock.id].configField.error" class="d-block invalid-feedback">
                        {{ configBlock.validateSelect[inforBlock.id].configField.errorField }}
                    </div>
                </div>
                <div v-else>
                    {{ configBlock.modelSelect[inforBlock.id]?.value ?? '' }}
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'checkbox'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer`" :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`">
                <div v-if="defineShowTypeBlock">
                    <div :style="`display: grid; grid-template-columns: repeat(${inforBlock.number_of_items_displayed_per_line}, auto)`">
                        <div v-for="item in inforBlock.option_value" :key="`CheckBoxForm-${item.id}-${inforBlock.id}`">
                            <InputCheckBox
                                class="mr-3 mb-2"
                                :id="`CheckBoxForm-${indexCheckbox}-${item.id}-${inforBlock.id}`"
                                :model.sync="configBlock.modelCheckbox[inforBlock.id].checked"
                                :config.sync="configBlock.validateCheckbox[inforBlock.id].configField"
                                :value="item.id"
                                :label="item.value"
                                :disabled="disabledLimit[item.id]"
                                @change="onChangeCheckbox(configBlock.modelCheckbox[inforBlock.id], configBlock.validateCheckbox[inforBlock.id])"
                            />
                        </div>
                    </div>
                    <div v-if="inforBlock?.setting_others?.other_use" class="d-flex">
                        <InputCheckBox
                            :id="`OtherCheckBoxForm-${indexCheckbox}-${inforBlock.id}`"
                            class="mr-3 mb-2"
                            :model.sync="configBlock.modelCheckbox[inforBlock.id].checked"
                            :value="`etc`"
                            :label="inforBlock.setting_others.label_other"
                            :disabled="disabledLimit['etc']"
                            @change="onChangeCheckbox(configBlock.modelCheckbox[inforBlock.id], configBlock.validateCheckbox[inforBlock.id])"
                        />
                        <div>
                            <InputText
                                :id="`OtherCheckBoxForm-${index}-${indexTextCheckbox}-${inforBlock.id}`"
                                :model.sync="configBlock.modelCheckbox[inforBlock.id].text"
                                :config.sync="configBlock.validateCheckbox[inforBlock.id].configFieldText"
                                :disabled="!configBlock.modelCheckbox[inforBlock.id].checked.includes('etc')"
                                @blur="handleValidateField(configBlock.validateCheckbox[inforBlock.id].configFieldText, 'OtherCheckBoxForm')"
                            />
                            <div v-if="configBlock.validateCheckbox[inforBlock.id].configFieldText.error" class="d-block invalid-feedback">
                                {{ configBlock.validateCheckbox[inforBlock.id].configFieldText.errorField }}
                            </div>
                        </div>
                    </div>
                    <div v-if="inforBlock?.setting_others?.uncheck_use" class="d-flex">
                        <InputCheckBox
                            :id="`NotApplicableCheckBoxForm-${indexCheckbox}-${inforBlock.id}`"
                            :model.sync="configBlock.modelCheckbox[inforBlock.id].checked"
                            :value="`nac`"
                            :label="inforBlock.setting_others.label_uncheck"
                            :disabled="disabledLimit['nac']"
                            @change="
                                onChangeCheckbox(
                                    configBlock.modelCheckbox[inforBlock.id],
                                    configBlock.validateCheckbox[inforBlock.id],
                                    'labelUncheck'
                                )
                            "
                        />
                    </div>
                    <div v-if="configBlock.validateCheckbox[inforBlock.id].configField.error" class="d-block invalid-feedback">
                        {{ configBlock.validateCheckbox[inforBlock.id].configField.errorField }}
                    </div>
                </div>
                <div v-else>
                    {{ onHandleJoinNameCheckbox(inforBlock.option_value, configBlock.modelCheckbox[inforBlock.id]) }}
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'upload-file'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer`" :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`">
                <div v-if="defineShowTypeBlock">
                    <UploadFileCommon
                        @dataFile="handleFileForm"
                        title="ファイルアップロード"
                        :id="`UploadFileForm-${inforBlock.id}`"
                        :typeUpload="`file`"
                        :config="{
                            maxSize: 1024
                        }"
                        :disabled="true"
                    />
                    <div v-if="infoFile?.origin_name" class="d-flex align-items-center my-2">
                        <a target="_blank" :href="infoFile?.path">{{ infoFile?.origin_name }}</a>
                        <span class="ml-2" style="cursor: pointer; font-size: 20px" @click="infoFile = null"
                            ><i class="ri-close-line" style="vertical-align: bottom"></i
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'province'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer`" :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`">
                <div v-if="defineShowTypeBlock">
                    <Multiselect
                        :id="`SelectForm-${inforBlock.id}`"
                        :value="``"
                        :options="LIST_PROVINCE_BLOCK"
                        :config.sync="configBlock.validateProvince[inforBlock.id].configField"
                        @remove="handleValidateField(configBlock.validateProvince[inforBlock.id].configField)"
                        :disabled="false"
                    />
                </div>
            </div>
        </div>
        <div v-if="inforBlock.block_type == 'remaining-seats-management-box'" :class="`form-group ${formGroupLayout}`">
            <div
                :class="`col-sm-${labelLayout ? '3' : '12'} mb-0 fm-question`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_question}`"
            >
                <label v-html="textLineBreaks(inforBlock.label)"></label>
                <span v-if="inforBlock.order_required" class="text-danger pl-1">{{ $t('common.fieldRequired') }}</span>
            </div>
            <div
                :class="`col-sm-${labelLayout ? '9' : '12'} fm-answer text-${inforBlock.choice_placement_specification}`"
                :style="`background-color:${inforBlock?.cell_background_color?.bg_answer}`"
            >
                <div v-if="defineShowTypeBlock">
                    <div class="" :style="`display: grid; grid-template-columns: repeat(${inforBlock.number_of_items_displayed_per_line}, auto)`">
                        <div
                            v-for="item in [
                                { id: 1, value: 'Seats Management Box 1', number_of_receptions: 1 },
                                { id: 2, value: 'Seats Management Box 2', number_of_receptions: 2 },
                                { id: 3, value: 'Seats Management Box 3', number_of_receptions: 3 }
                            ]"
                            :key="`RemainingSeats-${item.id}-${inforBlock.id}`"
                        >
                            <div class="d-flex" style="gap: 5px">
                                <InputRadio
                                    :model="``"
                                    :name="`RemainingSeats`"
                                    :id="`RemainingSeats-${item.id}-${inforBlock.id}`"
                                    :value="item.id"
                                    :label="item.value"
                                    :disabled="false"
                                />
                                <span style="color: #d83636; font-size: 12px" class="fm_limit_radio_remainder pt-1">
                                    ({{ item.number_of_receptions }})</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <button class="btn btn-light" :class="`${true ? 'disabled_cursor' : ''}`" :disabled="true">
                            {{ $t('managerForm.settingPage.uncheck') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { InputRadio, InputCheckBox, InputText } from '@/components/Input';
import { LIST_PROVINCE_BLOCK } from '../../components/setting-block-form/constants';
import { validateField } from '@/utils/validate';
import { renderLineBreaks } from '@/utils/format';

import Multiselect from '@/components/Multiselect/main.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';

export default {
    name: 'CommonOption',
    components: {
        InputRadio,
        InputCheckBox,
        InputText,
        Multiselect,
        UploadFileCommon
    },
    props: {
        index: {
            type: Number,
            default: () => 0
        },
        conditionPageShow: {
            type: String,
            default: () => 'input'
        },
        inforBlock: {
            type: Object,
            default: () => null
        },
        configBlock: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            LIST_PROVINCE_BLOCK: LIST_PROVINCE_BLOCK,
            infoFile: null,
            indexMultiselect: 0,
            indexCheckbox: 0,
            indexTextCheckbox: 0,
            indexRadio: 0,
            disabledLimit: {},
            previousSCheckedtate: []
        };
    },
    computed: {
        formGroupLayout() {
            return this.inforBlock.layout == 'default' ? 'row' : this.inforBlock.layout;
        },
        labelLayout() {
            return ['row', 'default'].includes(this.inforBlock.layout);
        },
        defineShowTypeBlock() {
            return this.conditionPageShow == 'page_inputs';
        }
    },
    methods: {
        handleFileForm(file) {
            this.infoFile = file;
        },

        handleChangeMultiselect() {
            this.indexMultiselect++;
            this.indexMultiselect = this.indexMultiselect >= 100 ? 0 : this.indexMultiselect;
        },

        handleRemoveMultiselect(configField) {
            this.indexMultiselect--;
            validateField(configField);
        },

        handleValidateField(configField, type = null) {
            if (type == 'OtherCheckBoxForm') {
                this.indexTextCheckbox++;
                this.indexTextCheckbox = this.indexTextCheckbox >= 100 ? 0 : this.indexTextCheckbox;
            }
            if (type == 'RadioForm-Others') {
                this.incrementIndexRadio();
            }
            validateField(configField);
        },

        textLineBreaks(text) {
            return renderLineBreaks(text);
        },

        handleChangeRadio(modelRadio) {
            this.incrementIndexRadio();
            this.configBlock.validateRadio[this.inforBlock.id].configField.valueSync = modelRadio;
            if (modelRadio == 'etc') {
                if (this.inforBlock.setting_others.value_other_required) {
                    this.configBlock.validateRadio[this.inforBlock.id].configFieldText.typesValidate = ['required'];
                }
            } else {
                this.configBlock.validateRadio[this.inforBlock.id].configFieldText.error = false;
                this.configBlock.validateRadio[this.inforBlock.id].configFieldText.typesValidate = [];
                this.configBlock.modelRadio[this.inforBlock.id].text = '';
                this.configBlock.validateRadio[this.inforBlock.id].configFieldText.valueSync = '';
            }
        },

        onHandleRemoveRadio() {
            this.incrementIndexRadio();
            this.configBlock.modelRadio[this.inforBlock.id].radio = '';
            this.configBlock.modelRadio[this.inforBlock.id].text = '';
            this.configBlock.validateRadio[this.inforBlock.id].configField.valueSync = '';
            this.configBlock.validateRadio[this.inforBlock.id].configFieldText.valueSync = '';
            this.configBlock.validateRadio[this.inforBlock.id].configFieldText.error = false;
            this.configBlock.validateRadio[this.inforBlock.id].configFieldText.typesValidate = [];
        },

        incrementIndexRadio() {
            this.indexRadio++;
            this.indexRadio = this.indexRadio >= 100 ? 0 : this.indexRadio;
        },

        onChangeCheckbox(modelInforBlock, configInforBlock, typeAction = null) {
            this.indexCheckbox++;
            this.indexCheckbox = this.indexCheckbox >= 100 ? 0 : this.indexCheckbox;

            if (typeAction === 'labelUncheck') {
                if (modelInforBlock.checked.includes('nac')) {
                    this.previousCheckedState = [...this.configBlock.modelCheckbox[this.inforBlock.id].checked];
                    this.configBlock.modelCheckbox[this.inforBlock.id].checked = ['nac'];
                    this.inforBlock.option_value.forEach((optionItem) => {
                        this.disabledLimit[optionItem.id] = true;
                    });
                    this.disabledLimit['etc'] = true;
                    configInforBlock.configFieldText.typesValidate = [];
                } else {
                    this.previousCheckedState = this.previousCheckedState.filter((prItem) => prItem !== 'nac');
                    this.configBlock.modelCheckbox[this.inforBlock.id].checked = [...this.previousCheckedState];
                    this.inforBlock.option_value.forEach((optionItem) => {
                        this.disabledLimit[optionItem.id] = false;
                    });
                    this.disabledLimit['etc'] = false;
                    configInforBlock.configFieldText.typesValidate = ['required'];
                }
                modelInforBlock.text = '';
                configInforBlock.configFieldText.error = false;
                return;
            }

            if (!modelInforBlock.checked.includes('etc')) {
                modelInforBlock.text = '';
                configInforBlock.configFieldText.error = false;
                configInforBlock.configFieldText.typesValidate = [];
            } else {
                configInforBlock.configFieldText.typesValidate = ['required'];
            }

            if (this.inforBlock.operator_limit_check && this.inforBlock.number_limit_check) {
                const operator = this.inforBlock.operator_limit_check.value;
                const limit = Number(this.inforBlock.number_limit_check);
                const checked = modelInforBlock.checked;
                const isDisabled = (checkedLength, operator, limit) => {
                    switch (operator) {
                        case '=':
                            return checkedLength == limit;
                        case '≧':
                            return checkedLength >= limit && checkedLength === limit;
                        case '≦':
                            return checkedLength <= limit && checkedLength === limit;
                        default:
                            return false;
                    }
                };
                const disable = isDisabled(checked.length, operator, limit);
                this.inforBlock.option_value.forEach((optionItem) => {
                    const isIncluded = checked.includes(optionItem.id);
                    this.disabledLimit[optionItem.id] = disable && !isIncluded;
                });
                this.disabledLimit['etc'] = disable && !checked.includes('etc');
                this.disabledLimit['nac'] = disable && !checked.includes('nac');
            }
        },

        renderValueRadio(idRadio) {
            return this.inforBlock.option_value.find((optionValueItem) => optionValueItem.id === idRadio)?.value ?? '';
        },

        onHandleJoinNameCheckbox(options, configChecked) {
            const { checked, text } = configChecked;
            const { setting_others } = this.inforBlock;
            const listName = options.filter((opItem) => checked.includes(opItem.id)).map((opItem) => opItem.value);
            if (checked.includes('etc')) {
                listName.push(`${setting_others.label_other}(${text})`);
            }
            if (checked.includes('nac')) {
                listName.push(setting_others.label_uncheck);
            }
            return listName.join(',');
        }
    }
};
</script>

<style lang="scss" scoped></style>
