<script>
import { campaignStatus, listContactInformations } from '@/config/var-common';
import { masterMethods, campaignMethods, clientMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { keyMaster } from '@/config/constants';
import { InputRadio, InputCheckBox } from '@/components/Input';
import { formatDateCommon } from '@/utils/format';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Multiselect from '@/components/Multiselect/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Footer from '@/components/Footer/main.vue';
import TableListComponent from '@/components/TableList/main.vue';

export default {
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Layout,
        Footer,
        Multiselect,
        DateNormal,
        InputRadio,
        InputCheckBox,
        TableListComponent
    },
    data() {
        return {
            bookmarkName: '',
            clientObject: null,
            person_in_charge: null,
            sale_staff: null,
            clientPicObject: null,
            checkShowFilter: false,
            isLoading: false,
            campaignStatus: campaignStatus,
            listContactInformations: listContactInformations,
            listDataBookmark: [],
            listData: [],
            listDataMasterClients: [],
            listClientsSupport: [],
            listDataMasterSaleStaf: [],
            listDataMasterInstructorStaf: [],
            listDataMasterCampaignType: [],
            listTagFreeWord: [],
            listTagKeyWord: [],
            form: {
                name: ''
            },
            date_from: {
                campaign_end_date: '',
                campaign_end_date_y: '',
                campaign_end_date_m: '',
                campaign_end_date_d: '',

                campaign_start_date: '',
                campaign_start_date_y: '',
                campaign_start_date_m: '',
                campaign_start_date_d: '',

                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: ''
            },
            date_to: {
                campaign_start_date: '',
                campaign_start_date_y: '',
                campaign_start_date_m: '',
                campaign_start_date_d: '',

                campaign_end_date: '',
                campaign_end_date_y: '',
                campaign_end_date_m: '',
                campaign_end_date_d: '',

                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: ''
            },

            filter: {
                campaign_type: '',
                sale_staff: '',
                keywords: '',
                progress_status: [],
                person_in_charge: ''
            },
            has: {
                client: '',
                client_pic: '',
                contact_information: []
            },
            paramsQuery: {
                is_approved: '',
                team: '',
                sale_staff_team: '',
                progress_status: '',
                partner: ''
            },
            numberQuery: {
                client: '',
                partner: ''
            },
            configKeyword: {
                arrayTag: [],
                placeholder: 'キャンペーン名　担当者 ...',
                isShowLable: true
            },
            configKeyword1: {
                arrayTag: [],
                placeholder: ''
            },
            configTable: {
                headers: [
                    {
                        label: 'CP 番号'
                    },
                    {
                        label: '営業担当'
                    },
                    {
                        label: 'メイン担当'
                    },
                    {
                        label: 'エンドクライアント'
                    },
                    {
                        label: '種別'
                    },
                    {
                        label: 'キャンペーン名'
                    },
                    {
                        label: 'ステータス'
                    },
                    {
                        label: '事務局開始日'
                    },
                    {
                        label: '事務局終了日'
                    },
                    {
                        label: '最終更新者'
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            }
        };
    },
    watch: {
        clientObject: function (newName) {
            this.has.client = newName?.id ?? '';
        },
        sale_staff: function (newName) {
            this.filter.sale_staff = newName?.id ?? '';
        },
        person_in_charge: function (newName) {
            this.filter.person_in_charge = newName?.id ?? '';
        },
        clientPicObject: function (newName) {
            this.has.client_pic = newName?.id ?? '';
        },

        'configKeyword.arrayTag': function (newOld) {
            if (!this.checkShowFilter) {
                this.listTagFreeWord = newOld;
            } else {
                this.listTagKeyWord = newOld;
            }
        },
        'configKeyword1.arrayTag': function (newOld) {
            this.filter.keywords = newOld;
        }
    },
    mounted() {
        this.getList();
        this.getListBookmark();
    },

    methods: {
        checkPermission,
        ...masterMethods,
        ...campaignMethods,
        ...clientMethods,

        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.arrayTag = this.listTagKeyWord;
                this.getListMasterCampaignType();
                this.getListMasterClients();
                this.getListMasterSaleStaf();
                this.getListMasterInstructorStaf();
            } else {
                this.configKeyword.placeholder = 'キャンペーン名　担当者 ...';
                this.configKeyword.arrayTag = this.listTagFreeWord;
            }
        },
        getListMasterCampaignType() {
            this.listMaster(keyMaster['campaign.type']).then((data) => {
                this.listDataMasterCampaignType = data;
            });
        },
        getListBookmark() {
            this.getBookmark('filter[page]=campaign').then((data) => {
                if (data.code == 200) {
                    this.listDataBookmark = data.data;
                }
            });
        },
        getListMasterClients() {
            this.listMaster('all/clients').then((data) => {
                this.listDataMasterClients = data;
            });
        },
        getListMasterClientsSupport() {
            this.listClientsSupport = [];
            this.clientPicObject = null;
            if (this.clientObject?.id) {
                this.listClientSupport(this.clientObject.id).then((data) => {
                    this.listClientsSupport = data.data;
                    this.loading = false;
                });
            }
        },

        getListMasterSaleStaf() {
            this.listMaster('users?filters[role]=2' + '&limit=999').then((data) => {
                this.listDataMasterSaleStaf = data;
            });
        },
        getListMasterInstructorStaf() {
            this.listMaster('users?filters[role]=3' + '&limit=999').then((data) => {
                this.listDataMasterInstructorStaf = data;
            });
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];
            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${decodeURIComponent(value)}`);
                    }
                }

                if (this.filter) {
                    for (const [key, value] of Object.entries(this.filter)) {
                        if (key && value) query.push(`filters[${key}]=${value}`);
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${value}`);
                    }
                }
                if (this.date_from) {
                    let date_from = Object.assign({}, this.date_from);
                    delete date_from.campaign_end_date_m;
                    delete date_from.campaign_end_date_d;
                    delete date_from.campaign_end_date_y;
                    delete date_from.campaign_start_date_y;
                    delete date_from.campaign_start_date_m;
                    delete date_from.campaign_start_date_d;
                    delete date_from.secretariat_end_date_y;
                    delete date_from.secretariat_end_date_m;
                    delete date_from.secretariat_end_date_d;
                    for (const [key, value] of Object.entries(date_from)) {
                        if (key && value) query.push(`from[${key}]=${value}`);
                    }
                }
                if (this.date_to) {
                    let date_to = Object.assign({}, this.date_to);
                    delete date_to.campaign_end_date_m;
                    delete date_to.campaign_end_date_d;
                    delete date_to.campaign_end_date_y;
                    delete date_to.campaign_start_date_y;
                    delete date_to.campaign_start_date_m;
                    delete date_to.campaign_start_date_d;
                    delete date_to.secretariat_end_date_y;
                    delete date_to.secretariat_end_date_m;
                    delete date_to.secretariat_end_date_d;
                    for (const [key, value] of Object.entries(date_to)) {
                        if (key && value) query.push(`to[${key}]=${value}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            if (this.$route.query) {
                if (this.$route.query.is_approved) {
                    this.paramsQuery.is_approved = this.$route.query.is_approved;
                }
                if (this.$route.query.team_id) {
                    this.paramsQuery.team = this.$route.query.team_id;
                }
                if (this.$route.query.sale_staff_team) {
                    this.paramsQuery.sale_staff_team = this.$route.query.sale_staff_team;
                }
                if (this.$route.query.progress_status) {
                    this.paramsQuery.progress_status = this.$route.query.progress_status;
                }
                if (this.$route.query.partner_dashboard) {
                    this.paramsQuery.partner = this.$route.query.partner_dashboard;
                }

                if (this.$route.query.client_id) {
                    this.numberQuery.client = this.$route.query.client_id;
                }
                if (this.$route.query.partner_id) {
                    this.numberQuery.partner = this.$route.query.partner_id;
                }

                if (this.numberQuery.client || this.numberQuery.partner) {
                    for (const [key, value] of Object.entries(this.numberQuery)) {
                        if (key && value) {
                            query.push(`has[${key}]=${value}`);
                        }
                    }
                } else {
                    for (const [key, value] of Object.entries(this.paramsQuery)) {
                        if (key && value) {
                            if (key === 'partner' || key === 'sale_staff_team') {
                                query.push(`has[${key}]=${value}`);
                            } else {
                                query.push(`filters[${key}]=${value}`);
                            }
                        }
                    }
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listCampaign(query).then((data) => {
                this.listData = data.data;
                this.configPage.total = data._metadata.total;
                this.configPage.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            });
        },

        search() {
            this.getList();
        },
        setBookmark(item) {
            let query = JSON.parse(item.query.data);
            this.checkShowFilter = query.checkShowFilter;
            this.configKeyword = query.configKeyword;
            this.date_from = query.date_from;
            this.date_to = query.date_to;
            this.filter = query.filter;
            this.form = query.form;
            this.getList();
        },
        deleteBookmarks(event, item) {
            event.stopPropagation();
            this.deleteBookmark(item.id).then((data) => {
                if (data.code == 200) {
                    this.getListBookmark();
                }
            });
        },
        clear() {
            this.configKeyword = {
                arrayTag: [],
                placeholder: 'キャンペーン名　担当者 ...'
            };
            this.configKeyword1 = {
                arrayTag: [],
                placeholder: ''
            };
            this.form = {
                name: ''
            };
            this.filter = {
                campaign_type: '',
                sale_staff: '',
                keywords: '',
                progress_status: [],
                person_in_charge: ''
            };
            this.clientObject = null;
            this.clientPicObject = null;
            this.has = {
                client: '',
                client_pic: '',
                contact_information: []
            };
            this.date_from = {
                campaign_end_date: '',
                campaign_end_date_y: '',
                campaign_end_date_m: '',
                campaign_end_date_d: '',

                campaign_start_date: '',
                campaign_start_date_y: '',
                campaign_start_date_m: '',
                campaign_start_date_d: '',

                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: ''
            };
            this.date_to = {
                campaign_start_date: '',
                campaign_start_date_y: '',
                campaign_start_date_m: '',
                campaign_start_date_d: '',

                campaign_end_date: '',
                campaign_end_date_y: '',
                campaign_end_date_m: '',
                campaign_end_date_d: '',

                secretariat_end_date: '',
                secretariat_end_date_y: '',
                secretariat_end_date_m: '',
                secretariat_end_date_d: ''
            };
            this.sale_staff = null;
            this.person_in_charge = null;
            this.getList();
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        renderStatus(status) {
            let index = campaignStatus.findIndex((x) => {
                return x.id == status;
            });
            if (index >= 0) {
                return campaignStatus[index].text;
            }
        },
        removeDuplicates(array) {
            if (!array || array?.length <= 0) return '';
            return array
                .map((item) => item.client.name)
                .filter((name, index, self) => self.indexOf(name) === index)
                .join('、');
        },
        addBookMark() {
            let query = {
                checkShowFilter: this.checkShowFilter,
                configKeyword: this.configKeyword,
                form: this.form,
                filter: this.filter,
                date_to: this.date_to,
                date_from: this.date_from
            };
            let objectData = {
                name: this.bookmarkName,
                page: 'campaign',
                query: {
                    data: JSON.stringify(query)
                }
            };
            this.addBookmark(objectData).then((data) => {
                if (data.code == 200) this.getListBookmark();
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">キャンペーン検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-8">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>

                                    <div v-if="checkShowFilter">
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン名</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" v-model="form.name" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン開始年月日</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :year.sync="date_from.campaign_start_date_y"
                                                        :month.sync="date_from.campaign_start_date_m"
                                                        :day.sync="date_from.campaign_start_date_d"
                                                        :full_date.sync="date_from.campaign_start_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :year.sync="date_to.campaign_start_date_y"
                                                        :month.sync="date_to.campaign_start_date_m"
                                                        :day.sync="date_to.campaign_start_date_d"
                                                        :full_date.sync="date_to.campaign_start_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン終了年月日</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :year.sync="date_from.campaign_end_date_y"
                                                        :month.sync="date_from.campaign_end_date_m"
                                                        :day.sync="date_from.campaign_end_date_d"
                                                        :full_date.sync="date_from.campaign_end_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :year.sync="date_to.campaign_end_date_y"
                                                        :month.sync="date_to.campaign_end_date_m"
                                                        :day.sync="date_to.campaign_end_date_d"
                                                        :full_date.sync="date_to.campaign_end_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">事務局終了年月日</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :year.sync="date_from.secretariat_end_date_y"
                                                        :month.sync="date_from.secretariat_end_date_m"
                                                        :day.sync="date_from.secretariat_end_date_d"
                                                        :full_date.sync="date_from.secretariat_end_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    から
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                    <DateNormal
                                                        :year.sync="date_to.secretariat_end_date_y"
                                                        :month.sync="date_to.secretariat_end_date_m"
                                                        :day.sync="date_to.secretariat_end_date_d"
                                                        :full_date.sync="date_to.secretariat_end_date"
                                                        :config="{
                                                            error: false
                                                        }"
                                                    >
                                                    </DateNormal>
                                                    まで
                                                </div>
                                                <small>※年、年月のみの入力でも検索可能です。</small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン種別</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex">
                                                    <div v-for="item in listDataMasterCampaignType" :key="item.id + 's'">
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="filter.campaign_type"
                                                            :name="`CampaignType`"
                                                            :id="`CampaignType-${item.id}`"
                                                            :value="item.id"
                                                            :label="item.value"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">エンドクライアント</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-clientObject-default`"
                                                    :value.sync="clientObject"
                                                    :options="listDataMasterClients"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'name',
                                                        error: false,
                                                        isRemove: false,
                                                        loading: false
                                                    }"
                                                    @select="getListMasterClientsSupport"
                                                    @remove="
                                                        {
                                                            clientPicObject = null;
                                                            listClientsSupport = [];
                                                        }
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 pr-0" for="validationCustom05">エンドクライアント担当者</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-clientPicObject-default`"
                                                    :value.sync="clientPicObject"
                                                    :options="listClientsSupport"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'name',
                                                        error: false,
                                                        isRemove: false,
                                                        loading: false
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">PL営業担当者</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-sale_staff-default`"
                                                    :value.sync="sale_staff"
                                                    :options="listDataMasterSaleStaf"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'name',
                                                        error: false,
                                                        isRemove: false,
                                                        loading: false
                                                    }"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">PLディレクション担当</label>
                                            <div class="col-sm-9">
                                                <Multiselect
                                                    :id="`select-person_in_charge-default`"
                                                    :value.sync="person_in_charge"
                                                    :options="listDataMasterInstructorStaf"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'name',
                                                        error: false,
                                                        isRemove: false,
                                                        loading: false
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">進捗ステータス</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex flex-wrap">
                                                    <div v-for="item in campaignStatus" :key="item.id + 's'">
                                                        <InputCheckBox
                                                            class="mr-3 mb-2"
                                                            :model.sync="filter.progress_status"
                                                            :id="`check-progress_status_${item.id}`"
                                                            :value="item.id"
                                                            :label="item.text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">検索キーワード</label>
                                            <div class="col-sm-9">
                                                <KeyWordTag :config="configKeyword1" class="" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">お問い合わせ窓口</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex flex-wrap">
                                                    <div v-for="item in listContactInformations" :key="item.id + 'ss'">
                                                        <InputCheckBox
                                                            class="mr-3 mb-2"
                                                            :model.sync="has.contact_information"
                                                            :id="`check-has_contact_information_${item.id}`"
                                                            :value="item.id"
                                                            :label="item.text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                                <div class="form-group row mt-4">
                                    <label class="col-sm-3" for="validationCustom05">Bookmark</label>
                                    <div class="col-sm-9">
                                        <button
                                            type="button"
                                            v-for="(item, k) in listDataBookmark"
                                            @click="setBookmark(item)"
                                            :key="k"
                                            class="btn btn-outline-info mr-2"
                                            style="position: relative"
                                        >
                                            {{ item.name }}
                                            <i
                                                @click="deleteBookmarks($event, item)"
                                                style="position: absolute; top: -2px; right: -2px"
                                                class="fa fa-window-close"
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            キャンペーン検索結果 <span>件数（{{ configPage.total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="d-flex align-items-center input-group-sm" style="white-space: nowrap">
                                    検索条件：
                                    <input type="text" class="form-control ml-3" v-model="bookmarkName" style="width: 200px" />
                                    <button class="btn btn-sm btn-primary ml-2" @click="addBookMark()">保存</button>
                                </div>
                            </div>
                        </div>

                        <TableListComponent
                            :config="configTable"
                            :dataList="listData"
                            :isLoading="isLoading"
                            :configPage.sync="configPage"
                            :stickyAction="true"
                            @onChangePage="getList()"
                        >
                            <template #row="{ row, index }">
                                <tr @dblclick="goToEdit('/campaign/form/view', row)">
                                    <td>
                                        {{ index }}
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.sale_staff ? row.sale_staff.name : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.person_in_charge ? row.person_in_charge.name : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small" v-b-tooltip.hover :title="removeDuplicates(row.clients)">
                                            {{ removeDuplicates(row.clients) }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.campaign_type ? row.campaign_type.value : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-medium" v-b-tooltip.hover :title="row.name">
                                            {{ row.name }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ renderStatus(row.progress_status) }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ formatDateCommon(row.secretariat_start_date, 'dateTimeJP') }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ formatDateCommon(row.secretariat_end_date, 'dateTimeJP') }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.updated_by ? row.updated_by.name : '' }}
                                        </div>
                                    </td>

                                    <td :align="checkPermission('campaign.edit') ? 'left' : 'center'" class="sticky_action">
                                        <div class="row-data-small">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-info"
                                                :class="
                                                    row.is_approved != 0 && row.is_locked != 1 && checkPermission('campaign.edit')
                                                        ? 'border-right-1'
                                                        : ''
                                                "
                                                @click="goToEdit('/campaign/form/view', row)"
                                                >詳細</a
                                            >
                                            <a
                                                v-if="row.is_approved != 0 && row.is_locked != 1 && checkPermission('campaign.edit')"
                                                :class="
                                                    row.is_approved != 0 && row.is_locked != 1 && checkPermission('campaign.edit')
                                                        ? 'border-right-1'
                                                        : ''
                                                "
                                                href="javascript:void(0)"
                                                class="text-success"
                                                @click="goToEdit('/campaign/edit', row)"
                                                >編集</a
                                            >
                                            <a
                                                v-if="
                                                    row.is_approved != 0 &&
                                                    row.is_locked != 1 &&
                                                    checkPermission('campaign.edit') &&
                                                    checkPermission('campaign.register')
                                                "
                                                href="javascript:void(0)"
                                                @click="goToEdit('/documents', row)"
                                                class="text-primary"
                                                >資料生成</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </TableListComponent>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped></style>
