// 運営事務局 : ban thư ký vận hành
// TEL : te le phôn
// 発送 : vận chuyển
// デザイン : thiết kế
// 印刷 : in ấn
// 賞品発送 : vận chuyển phần thưởng
// SNS投稿 : đăng lên mạng xã hội

const formPrize = {
    client: null,
    digital_gifts: {},
    type: {
        id: ''
    },
    coupon_value: '',
    name: '',
    quantity: '',
    number_of_win_prize: '',
    scheduled_arrived_at: '',
    scheduled_arrived_at_y: '',
    scheduled_arrived_at_m: '',
    scheduled_arrived_at_d: '',
    expiration_date: '',
    expiration_date_y: '',
    expiration_date_m: '',
    expiration_date_d: '',
    warehousing_date: '',
    warehousing_date_y: '',
    warehousing_date_m: '',
    warehousing_date_d: '',
    warehousing_quantity: '',
    shipment_quantity: '',
    remaining_quantity: '',
    delivery_company: '',
    is_packaging_photo_required: '1'
};

const formPrizeRegister = {
    digital_gifts: {},
    type: {
        id: ''
    },
    campaign: {
        id: ''
    },
    name: '',
    title: '',
    quantity: '',
    is_packaging_photo_required: false,
    number_of_win_prize: '',
    scheduled_arrived_at: '',
    scheduled_arrived_at_y: '',
    scheduled_arrived_at_m: '',
    scheduled_arrived_at_d: '',
    expiration_date: '',
    expiration_date_y: '',
    expiration_date_m: '',
    expiration_date_d: '',
    warehousing_date: '',
    warehousing_date_y: '',
    warehousing_date_m: '',
    warehousing_date_d: '',
    warehousing_quantity: '',
    shipment_quantity: '',
    remaining_quantity: '',
    delivery_company: ''
};

const formCampaign = {
    source_data: {
        type: '',
        id: ''
    },
    total_number_of_win_prize: '',
    application_date: '',
    name: '',
    progress_status: '',

    campaign_start_date: '',
    campaign_start_time: '',

    campaign_end_date: '',
    campaign_end_time: '',

    secretariat_start_date: '',
    secretariat_start_time: '',

    secretariat_end_date: '',
    secretariat_end_time: '',

    configCampaignStartDateTime: { minuteStep: 1, placeholderTime: '', errorDateTimeStartCampaign: '', error: false },
    configCampaignEndDateTime: { minuteStep: 1, placeholderTime: '', errorDateTimeEndCampaign: '', error: false },
    configSecretariatStartDateTime: { minuteStep: 1, placeholderTime: '', errorDateTimeStartSecretariat: '', error: false },
    configSecretariatEndDateTime: { minuteStep: 1, placeholderTime: '', errorDateTimeEndSecretariat: '', error: false },

    url_publish_time_from: '',
    url_publish_time_from_time: '',

    url_publish_time_to: '',
    url_publish_time_to_time: '',

    configUrlPublishTimeFrom: {
        minuteStep: 1,
        placeholderTime: '',
        errorText: '',
        error: false
    },
    configUrlPublishTimeTo: {
        minuteStep: 1,
        placeholderTime: '',
        errorText: '',
        error: false
    },

    receipt_expiration_date_y: '',
    receipt_expiration_date_m: '',
    receipt_expiration_date_d: '',
    receipt_expiration_date: '',
    configReceiptExpirationDate: {
        error: false,
        errorText: ''
    },

    campaign_type: {
        id: null,
        key: '',
        value: ''
    },
    types: [],
    hashtag: [],
    inform: '',
    content_projects: [],
    application_form: '',
    target_product_lists: [],
    listCheckedTargetLv1: [],
    listCheckedTargetLv2: [],
    purchase_conditions: [],
    contact_informations: [],
    tool_useds: [],
    url: '',

    application_method: '',
    lottery_number_of_winners: '',
    prize_shipping_period: '',
    inquiry_office: '',
    sale_staff: {
        id: ''
    },
    team: {
        id: ''
    },
    person_in_charge: {
        id: ''
    },
    responsible_sub_persons: [],
    part_time_employees: [],
    partners: [],
    agencies: [],
    clients: [],
    templates: [],
    prizes: [],
    keywords: [],
    sns_collections_tool: [],
    campaign_sns_collections_tools: [],
    application_condition_1: {
        id: ''
    },
    application_condition_2: {
        id: ''
    },
    sns_application_condition_1: {
        id: ''
    },
    sns_application_condition_2: {
        id: ''
    },
    purchase_condition_2: {
        id: ''
    },
    number_of_sns_followers: '',
    free_word: '',
    give_away: 0,
    give_away_information: {
        inflow_destination: '',
        inflow_rate: '',
        attrition_rate: ''
    },
    advertising_result: '',
    advertising_cost: '',
    advertising_media: '',
    line_friends_growth: '',
    sns_winners_without_registration_rate: '',
    defects_in_cash_back_applicants_rate: '',
    categories: [],
    phone_pic: null,
    customer_team_pic: null,
    chat_rooms: [
        {
            room_id: '',
            room_name: ''
        }
    ],
    zac_id: '',
    isShowPublicTime: false,
    isShowReceiptExpirationDate: false,
    isShowConditionBuyAndSell: false,
    event_prizes: [],
    project_management: null,
    campaign_master_order_scopes: [],
    campaign_master_order_scope_partner: [],
    has_tel_phone_setting: false,
    customer_sub_person: [],
    associated_campaign: null,
    sfid: '',
    information_ips: [],
    folder_project: '',
    folder_quotation: '',
    campaign_entity_id: '',
    sponsor_informations: [],
    number_of_applications: [],
    total_number_of_applications: 0,
    number_of_inquiries: [],
    total_number_of_inquiries: 0,
    platform_followers_number: [],
    advertising_activity: null,
    social_platforms: [],
    give_away_related: null,
    account_infor_on_screen: '',
    pdca_file_report: null,
    cashback_file_report: null
};

const formPartner = {
    department: '',
    keywords: [],
    name: '',
    kana_name: '',
    email: '',
    phone_number: '',
    types: [],
    note: '',
    province: '',
    address_1: '',
    address_2: '',
    url: '',
    postal_code: '',
    keyword: '',
    namePic: '',
    emailPic: '',
    passwordPic: ''
};

const formClient = {
    department: '',
    email: '',
    keywords: [],
    name: '',
    kana_name: '',
    phone_number: '',
    profile: '',
    address_1: '',
    address_2: '',
    url: '',
    postal_code: '',
    province: '',
    namePic: '',
    type_id: null
};

const listContactInformations = [
    {
        id: 2,
        text: 'TEL',
        subText: 'TEL入力',
        configInput: {
            isMaxLength: false,
            error: false,
            errorField: 'TEL入力'
        }
    },
    {
        id: 5,
        text: 'メール',
        subText: 'メールアドレス入力',
        configInput: {
            isEmail: false,
            isMaxLength: false,
            error: false,
            errorField: 'メールアドレス入力'
        }
    },
    {
        id: 8,
        text: 'DM',
        subText: 'DM入力',
        child: [
            {
                id: 9,
                text: 'Twitter',
                subText: 'DM入力',
                configInput: {
                    isMaxLength: false,
                    error: false,
                    errorField: 'Twitter'
                }
            },
            {
                id: 10,
                text: 'Instagram',
                subText: 'DM入力',
                configInput: {
                    isMaxLength: false,
                    error: false,
                    errorField: 'Instagram'
                }
            },
            {
                id: 11,
                text: 'Tiktok',
                subText: 'DM入力',
                configInput: {
                    isMaxLength: false,
                    error: false,
                    errorField: 'Tiktok'
                }
            },
            {
                id: 12,
                text: 'LINE',
                subText: 'DM入力',
                configInput: {
                    isMaxLength: false,
                    error: false,
                    errorField: 'LINE'
                }
            }
        ]
    },
    {
        id: 13,
        text: 'その他',
        subText: 'その他',
        configInput: {
            isMaxLength: false,
            error: false,
            errorField: 'その他'
        }
    }
];

const campaignStatus = [
    {
        id: 1,
        text: 'ほぼ確'
    },
    {
        id: 2,
        text: '未確'
    },
    {
        id: 3,
        text: '確定'
    },
    {
        id: 4,
        text: '進行中'
    },
    {
        id: 5,
        text: '終了'
    },
    {
        id: 6,
        text: '廃棄待ち'
    }
];

const numberOfApplications = {
    total: '',
    postcard_mail: '',
    web: '',
    palnavi: '',
    sns: '',
    twitter: '',
    instagram: '',
    line: '',
    others: '',
    invalid: ''
};

const numberOfInquiries = {
    total: '',
    tel: '',
    email: '',
    number_of_cases: '',
    irregular: '',
    irregular_content: ''
};

export {
    numberOfInquiries,
    numberOfApplications,
    formPrizeRegister,
    campaignStatus,
    listContactInformations,
    formPrize,
    formClient,
    formCampaign,
    formPartner
};
