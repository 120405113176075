<template>
    <div>
        <ModalCommon :id="`ModalFormPreview-${configModal?.id}`" :config="configModal" @save="handleSave()" @close="handleClose()" :isView="true">
            <div class="preview_link d-flex justify-content-end">
                <div>
                    <p class="btn text_link mb-0" @click="onHandleOpenModalSendMail">
                        <i class="fa fa-envelope mr-1"></i> <span>プレビューURLを送る</span>
                    </p>
                </div>
                <div>
                    <p class="btn text_link mb-0"><i class="fa fa-external-link-alt mr-1"></i><span>ブラウザで確認する</span></p>
                </div>
            </div>
            <p class="text-right" style="font-size: 12px">※プレビューURLの有効期限は2025/01/10 11:56です。</p>
            <div class="white_wrap mb-3">
                <div class="row" style="gap: 12px">
                    <p class="btn mb-0 h-100" :class="activePreview == 'PC' ? 'btn-outline-success' : ''" @click="handleSwitchDevice('PC')">
                        <i class="fa fa-laptop"></i> PC
                    </p>
                    <p class="btn mb-0 h-100" :class="activePreview == 'Mobile' ? 'btn-outline-success' : ''" @click="handleSwitchDevice('Mobile')">
                        <i class="fa fa-mobile"></i> Mobile
                    </p>
                    <div class="ml-auto">
                        <div class="d-flex align-items-center justify-content-end mr-3 mb-2">
                            <InputText
                                class="mr-1"
                                style="max-width: 100px"
                                :model.sync="pixel_input"
                                :config="{
                                    isNumber: false,
                                    error: false
                                }"
                                @change="handleInputPixel"
                            />
                            <div>px</div>
                        </div>
                        <div style="font-size: 12px">{{ $t('managerForm.settingPage.noteResponsive') }}</div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <IframComponent
                    :masterPage="configModal.masterPage"
                    :typeHandle="`preview`"
                    :typePage="propTypePage"
                    :dataForm="dataForm"
                    :style="`width:${pixel_action ? `${pixel_action}px` : '100%'}`"
                />
            </div>

            <ModalSendMailed :configModal="configModalSendMailed" />
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';
import IframComponent from '../../iframe-page/main.vue';
import ModalSendMailed from './modal-send-mail.vue';

import { InputText } from '@/components/Input';
import { configModalSendMailed } from './constants';

export default {
    name: 'ModalPreviewForm',
    components: {
        ModalCommon,
        ModalSendMailed,
        IframComponent,
        InputText
    },
    props: {
        dataForm: {
            type: Object,
            default: () => null
        },
        configModal: {
            type: Object,
            required: true,
            default: () => null
        },
        masterPage: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            pixel_action: '',
            pixel_input: '',
            propTypePage: 'page_inputs',
            activePreview: 'PC',
            configModalSendMailed: configModalSendMailed
        };
    },
    methods: {
        handleSave() {},

        handleClose() {},

        handleSwitchDevice(device) {
            this.activePreview = device;
            this.pixel_input = device == 'PC' ? '' : device == 'Mobile' ? '400' : '';
            this.pixel_action = this.pixel_input;
        },

        handleInputPixel(value) {
            this.activePreview = '';
            if (value < 300) {
                this.pixel_input = 300;
            } else if (value > 2000) {
                this.pixel_input = 2000;
            }
            this.pixel_action = this.pixel_input;
        },

        onHandleOpenModalSendMail() {
            this.configModalSendMailed.model = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.preview_link {
    .text_link {
        color: rgb(51, 145, 98);
        &:hover {
            opacity: 0.7;
        }
    }
}
</style>
