<template>
    <!--  'matrix' -->
    <div class="m-5">
        <div>
            <div class="text-center">
                <div v-html="HEADER"></div>
            </div>
            <div v-if="!isPageComplete" class="content_block p-3 h-100 my-5">
                <div v-for="(itemBlock, idx) in listBlockSaved" :key="`preview-${idx}`">
                    <CommonOption
                        v-if="
                            ['radio', 'select', 'checkbox', 'province', 'upload-file', 'remaining-seats-management-box'].includes(
                                itemBlock.block_type
                            )
                        "
                        :index="indexCommonOption"
                        :inforBlock="itemBlock"
                        :configBlock="configBlockForm"
                        :conditionPageShow="typePageShow"
                    />
                    <CommonBlockText
                        v-if="
                            ['div_1', 'div_2', 'input', 'textarea', 'date', 'fullname', 'postcode', 'phone', 'keyword-box', 'get-param'].includes(
                                itemBlock.block_type
                            )
                        "
                        :index="indexCommonOption"
                        :inforBlock="itemBlock"
                        :configBlock="configBlockForm"
                        :conditionPageShow="typePageShow"
                    />
                </div>
            </div>
            <div v-if="!isPageComplete" class="text-center mt-2">
                <div class="d-flex justify-content-center" style="gap: 12px">
                    <button
                        v-if="typePageShow == 'page_inputs'"
                        type="button"
                        class="btn btn-secondary text-nowrap"
                        style="width: 45% !important"
                        @click="handleSubmit('page_inputs')"
                    >
                        {{ $t('managerForm.settingPage.btnSaveForm') }}
                    </button>
                    <button
                        v-if="isPageConfirm"
                        type="button"
                        class="btn btn-secondary text-nowrap"
                        style="width: 45% !important"
                        @click="handleSubmit('page_confirms')"
                    >
                        {{ $t('btn.register') }}
                    </button>
                    <button v-if="isPageConfirm" type="button" class="text-nowrap btn-back-form" @click="handleBackForm">
                        {{ $t('managerForm.settingPage.btnBack') }}
                    </button>
                </div>
                <div class="mt-5" v-html="FOOTER"></div>
            </div>
            <div v-else class="content_block p-3 h-100 my-5">
                <div v-for="(itemBlock, idx) in listBlockComplete" :key="`preview-${idx}`">
                    <CommonBlockText
                        v-if="['div_1'].includes(itemBlock.block_type)"
                        :inforBlock="itemBlock"
                        :configBlock="configBlockForm"
                        :conditionPageShow="typePageShow"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommonOption from './components/common-option.vue';
import CommonBlockText from './components/common-text.vue';

import { configBlockForm, handleFilterBlock, validateFieldPhone, validatePostcode, validateKeywordBox, matchingMessageBlocks } from './handlers';
import { validateAllField } from '@/utils/validate';
// import { mapState } from 'vuex';

export default {
    name: 'IframComponent',
    components: {
        CommonOption,
        CommonBlockText
    },
    props: {
        dataForm: {
            type: Object,
            default: () => null
        },
        masterPage: {
            type: Array,
            default: () => []
        },
        typePage: {
            type: String,
            default: () => 'page_inputs'
        },
        typeHandle: {
            type: String,
            default: () => 'use'
        }
    },
    data() {
        return {
            indexCommonOption: 0,
            typePageShow: 'page_inputs',
            listBlockSaved: [],
            listBlockComplete: [],
            HEADER: this.dataForm?.header ? this.dataForm?.header : 'HEADER',
            FOOTER: this.dataForm?.footer ? this.dataForm?.footer : 'FOOTER'
        };
    },
    computed: {
        // ...mapState({
        //     ...mapState('managerForm', ['listBlockCreated'])
        // }),
        configBlockForm() {
            return handleFilterBlock(this.listBlockSaved, configBlockForm());
        },

        isPageComplete() {
            return this.typePageShow === 'page_completes';
        },

        isPageConfirm() {
            return this.typePageShow === 'page_confirms';
        }
    },
    methods: {
        handleBackForm() {
            if (this.typeHandle == 'edit') return;
            if (this.typePageShow == 'page_confirms') this.typePageShow = 'page_inputs';
        },

        handleSubmit(typeSubmit) {
            if (typeSubmit == 'page_inputs') {
                this.indexCommonOption = this.indexCommonOption > 100 ? 0 : this.indexCommonOption + 1;
                let checkField = validateAllField(this.configBlockForm);
                let checkPhone = validateFieldPhone(this.configBlockForm, this.listBlockSaved);
                let checkPostcode = validatePostcode(this.configBlockForm, this.listBlockSaved);
                let checkKeywordBox = validateKeywordBox(this.configBlockForm, this.listBlockSaved);
                matchingMessageBlocks(this.configBlockForm, this.listBlockSaved);
                console.log('checkField', checkField, checkPhone, checkPostcode, checkKeywordBox);
                if (!checkField || this.typeHandle == 'edit') return;
                this.typePageShow = 'page_confirms';
            } else if (typeSubmit == 'page_confirms') {
                if (this.typeHandle == 'edit') return;
                this.typePageShow = 'page_completes';
            }
        }
    },
    watch: {
        typePage: {
            handler(newTypePage) {
                this.typePageShow = newTypePage ? newTypePage : 'page_inputs';
            },
            immediate: true
        },

        '$store.state.managerForm.listBlockCreated': {
            handler(newList) {
                this.listBlockSaved = newList;
                this.listBlockComplete = newList;
            },
            immediate: true
        },

        typeHandle: {
            handler(newTypeHandle) {
                if (newTypeHandle == 'edit') {
                    this.listBlockSaved = this.$store.state.managerForm.listBlockCreated;
                } else if (newTypeHandle == 'preview') {
                    const getPageChildrenInput = this.masterPage[0].page_child;
                    const getPageChildrenComplete = this.masterPage[2].page_child;
                    const foundChildDefault = getPageChildrenInput.find((childItem) => childItem.is_default);
                    this.listBlockSaved = foundChildDefault.blocks;
                    this.listBlockComplete = getPageChildrenComplete.find((childItem) => childItem.is_default)?.blocks ?? [];
                } else {
                    this.listBlockSaved = [];
                    this.listBlockComplete = [];
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-back-form {
    width: 45% !important;
    white-space: normal;
    color: #777777;
    font-weight: bold;
    padding: 5px 6px;
    font-size: clamp(16px, (100% * 0.85), 22px);
    background: #ffffff;
    border: 1px solid #777777;
    border-radius: 5px;
    &:hover {
        opacity: 0.8;
    }
}
</style>
<style lang="scss">
.content_block {
    border-color: #c0c0c0;
    border-style: double;
    border-width: 4px;
    border-radius: 4px;

    .fm-question,
    .fm-answer {
        padding: 20px;
        border-radius: 3px;
        text-align: left;
        vertical-align: middle;
    }

    .fm-question {
        background-color: #f5f5f5;
    }
    .fm-answer {
    }
}
</style>
