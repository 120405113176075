/* eslint-disable no-case-declarations */
// import i18n from '@/i18n';
import {
    regexEmail,
    regexHalfWidthAlphanumeric,
    regexFullWidthCharacters,
    regexHiragana,
    regexFullWidthKatakana,
    regexHalfWidthKatakana,
    regexDynamicRange,
    regexNumberIntDecimal
} from '@/utils/regex';
import { state } from '@/state/modules/manager-form.js';

const configBlockForm = () => {
    return {
        modelRadio: {},
        modelSelect: {},
        modelCheckbox: {},
        modelProvince: {},
        modelInput: {},
        modelTextarea: {},
        modelDateForm: {},
        modelInputPhone_1: {},
        modelInputPhone_2: {},
        modelInputPhone_3: {},
        modelFirstName: {},
        modelLastName: {},
        modelPostcode_1: {},
        modelPostcode_2: {},
        modelKeywordBox: {},

        validateRadio: {},
        validateSelect: {},
        validateCheckbox: {},
        validateProvince: {},
        validateInput: {},
        validateTextarea: {},
        validateDateForm: {},
        validateInputPhone_1: {},
        validateInputPhone_2: {},
        validateInputPhone_3: {},
        validateFirstName: {},
        validateLastName: {},
        validatePostcode_1: {},
        validatePostcode_2: {},
        validateKeywordBox: {}
    };
};

const handleFilterBlock = (listBlock, configBlockForm) => {
    for (let list = 0; list < listBlock?.length; list++) {
        let {
            id,
            block_type,
            block_input,
            input_format,
            max_characters,
            order_required,
            input_placeholder,
            input_field_size,
            placeholder,
            setting_others
        } = listBlock[list];

        switch (block_type) {
            case 'radio':
                configBlockForm.modelRadio[id] = {
                    radio: '',
                    text: ''
                };
                configBlockForm.validateRadio[id] = {
                    configField: {
                        isRequired: false,
                        error: false,
                        errorField: '',
                        valueSync: null,
                        typesValidate: order_required ? ['required'] : []
                    },
                    configFieldText: {
                        size: setting_others.width_other,
                        maxlength: max_characters,
                        errorField: '',
                        valueSync: '',
                        messageCustom: true,
                        isRequired: false,
                        error: false,
                        typesValidate: []
                    }
                };
                break;
            case 'select':
                configBlockForm.modelSelect[id] = '';
                configBlockForm.validateSelect[id] = {
                    configField: {
                        trackBy: 'id',
                        label: 'value',
                        errorField: '',
                        placeholder: placeholder ? placeholder : '選択してください',
                        valueSync: null,
                        error: false,
                        isRemove: false,
                        messageCustom: true,
                        typesValidate: order_required ? ['requiredSelect'] : []
                    }
                };
                break;
            case 'checkbox':
                configBlockForm.modelCheckbox[id] = {
                    checked: [],
                    text: ''
                };
                configBlockForm.validateCheckbox[id] = {
                    configField: {
                        classCustom: '',
                        type: 'checkbox',
                        errorField: '',
                        valueSync: [],
                        messageCustom: true,
                        isRequired: false,
                        error: false,
                        typesValidate: order_required ? ['requiredSelect'] : []
                    },
                    configFieldText: {
                        size: setting_others.width_other,
                        maxlength: max_characters,
                        errorField: '',
                        valueSync: '',
                        messageCustom: true,
                        isRequired: false,
                        error: false,
                        typesValidate: []
                    }
                };
                break;
            case 'province':
                configBlockForm.validateProvince[id] = {
                    configField: {
                        trackBy: 'id',
                        label: 'value',
                        errorField: '',
                        placeholder: 'Province',
                        valueSync: null,
                        error: false,
                        isRemove: false,
                        typesValidate: []
                    }
                };
                break;
            case 'input':
                let validateDefault = [];
                let maxLengthHandle = max_characters ? max_characters : 255;
                if (order_required) {
                    validateDefault.push('required');
                }
                switch (input_format?.type_input) {
                    case 'email':
                    case 'number':
                    case 'half-width_alphanumeric_characters':
                    case 'full-width_characters':
                    case 'hiragana':
                    case 'full-width_katakana':
                    case 'half-width_katakana':
                        validateDefault.push(input_format?.type_input, 'limit-character');
                        break;
                    case 'numerical_value':
                        validateDefault.push(input_format?.type_input, 'format_numerical_value', 'range_numerical', 'limit-character');
                        break;
                    case 'numeric_value_including_decimal_point':
                        maxLengthHandle = block_input.number_after_decimal_point
                            ? Number(block_input.number_of_digits) -
                              Number(block_input.number_of_decimal_places) +
                              Number(block_input.number_after_decimal_point) +
                              2
                            : Number(block_input.number_of_digits) + 2;
                        validateDefault.push('integerDecimal');
                        break;
                }

                configBlockForm.modelInput[id] = '';
                configBlockForm.validateInput[id] = {
                    configField: {
                        isNumber: input_format?.type_input == 'number' || false,
                        placeholder: input_placeholder ? input_placeholder : '',
                        min: block_input.minimum_number ?? '',
                        max: block_input.maximum_number ?? '',
                        from_range: block_input.from_range ?? '',
                        to_range: block_input.to_range ?? '',
                        integer:
                            block_input?.number_of_digits && block_input?.number_of_decimal_places
                                ? Number(block_input.number_of_digits) - Number(block_input.number_of_decimal_places)
                                : '',
                        decimal: block_input.number_after_decimal_point
                            ? Number(block_input.number_after_decimal_point)
                            : block_input?.number_of_decimal_places
                              ? Number(block_input.number_of_decimal_places)
                              : '',
                        maxlength: maxLengthHandle,
                        size: input_field_size ? input_field_size : '',
                        valueSync: '',
                        errorField: '',
                        error: false,
                        messageCustom: true,
                        typesValidate: validateDefault
                    }
                };
                break;
            case 'keyword-box':
                configBlockForm.validateKeywordBox[id] = {
                    configField: {
                        isRequired: false,
                        placeholder: '',
                        min: '',
                        max: '',
                        size: '',
                        valueSync: '',
                        maxlength: 32,
                        error: false,
                        typesValidate: []
                    }
                };
                break;
            case 'fullname':
                configBlockForm.validateFirstName[id] = {
                    configField: {
                        placeholder: 'FirstName',
                        min: '',
                        max: '',
                        size: '',
                        maxlength: 255,
                        valueSync: '',
                        error: false,
                        typesValidate: []
                    }
                };
                configBlockForm.validateLastName[id] = {
                    configField: {
                        placeholder: 'LastName',
                        min: '',
                        max: '',
                        size: '',
                        maxlength: 255,
                        valueSync: '',
                        error: false,
                        typesValidate: []
                    }
                };
                break;
            case 'postcode':
                configBlockForm.validatePostcode_1[id] = {
                    configField: {
                        placeholder: 'XXX',
                        min: '',
                        max: '',
                        size: '',
                        valueSync: '',
                        errorText: '',
                        maxlength: 3,
                        error: false
                    }
                };
                configBlockForm.validatePostcode_2[id] = {
                    configField: {
                        placeholder: 'XXXX',
                        min: '',
                        max: '',
                        size: '',
                        valueSync: '',
                        errorText: '',
                        maxlength: 4,
                        error: false
                    }
                };
                break;
            case 'phone':
                configBlockForm.validateInputPhone_1[id] = {
                    configField: {
                        placeholder: 'XXXXX',
                        min: '',
                        max: '',
                        size: '',
                        valueSync: '',
                        errorText: '',
                        maxlength: 5,
                        isNumber: true,
                        error: false
                    }
                };
                configBlockForm.validateInputPhone_2[id] = {
                    configField: {
                        placeholder: 'XXXX',
                        min: '',
                        max: '',
                        size: '',
                        errorText: '',
                        valueSync: '',
                        maxlength: 4,
                        isNumber: true,
                        error: false
                    }
                };
                configBlockForm.validateInputPhone_3[id] = {
                    configField: {
                        placeholder: 'XXXX',
                        min: '',
                        max: '',
                        size: '',
                        errorText: '',
                        valueSync: '',
                        maxlength: 4,
                        isNumber: true,
                        error: false
                    }
                };
                break;
            case 'textarea':
                configBlockForm.validateTextarea[id] = {
                    configField: {
                        placeholder: '',
                        errorField: '',
                        errorCustomMess: '',
                        valueSync: '',
                        length: '',
                        maxlength: 30000,
                        error: false,
                        typesValidate: []
                    }
                };
                break;
            case 'date':
                configBlockForm.validateDateForm[id] = {
                    configField: {
                        minYear: 1800,
                        maxYear: 2100,
                        placeholder_year: '2024',
                        placeholder_month: '12',
                        placeholder_day: '19',
                        minDateYear: '1800/01/01',
                        maxDateYear: '2100/12/31',
                        errorText: '',
                        errorField: '',
                        valueSync: '',
                        error: false,
                        isSelectDate: false,
                        typesValidate: ['date', 'date-year-range']
                    }
                };
                break;
        }
    }
    return configBlockForm;
};

const validateFieldPhone = (configField, inforBlock) => {
    let check = true;
    if (Array.isArray(inforBlock)) {
        inforBlock = inforBlock.find((item) => item.type == 'phone');
    }
    // if (
    //     !configField.validateInputPhone_1[inforBlock.id].configField.valueSync ||
    //     !configField.validateInputPhone_2[inforBlock.id].configField.valueSync ||
    //     !configField.validateInputPhone_3[inforBlock.id].configField.valueSync
    // ) {
    //     configField.validateInputPhone_1[inforBlock.id].configField.error =
    //         configField.validateInputPhone_2[inforBlock.id].configField.error =
    //         configField.validateInputPhone_3[inforBlock.id].configField.error =
    //             true;
    //     configField.validateInputPhone_1[inforBlock.id].configField.errorText = i18n.t('validateField.required', {
    //         field: ''
    //     });
    //     check = false;
    // } else {
    //     configField.validateInputPhone_1[inforBlock.id].configField.error =
    //         configField.validateInputPhone_2[inforBlock.id].configField.error =
    //         configField.validateInputPhone_3[inforBlock.id].configField.error =
    //             false;
    //     configField.validateInputPhone_1[inforBlock.id].configField.errorText = '';
    // }
    return check;
};

const validatePostcode = (configField, inforBlock) => {
    let check = true;
    if (Array.isArray(inforBlock)) {
        inforBlock = inforBlock.find((item) => item.type == 'postcode');
    }
    // if (
    //     !configField.validatePostcode_1[inforBlock.id].configField.valueSync ||
    //     !configField.validatePostcode_2[inforBlock.id].configField.valueSync
    // ) {
    //     configField.validatePostcode_1[inforBlock.id].configField.error = configField.validatePostcode_2[inforBlock.id].configField.error = true;
    //     configField.validatePostcode_1[inforBlock.id].configField.errorText = i18n.t('validateField.required', {
    //         field: ''
    //     });
    //     check = false;
    // } else {
    //     configField.validatePostcode_1[inforBlock.id].configField.error = configField.validatePostcode_2[inforBlock.id].configField.error = false;
    //     configField.validatePostcode_1[inforBlock.id].configField.errorText = '';
    // }
    return check;
};

const validateKeywordBox = (configField, inforBlock) => {
    let check = true;
    if (Array.isArray(inforBlock)) {
        inforBlock = inforBlock.find((item) => item.type == 'keyword-box');
    }
    if (inforBlock?.type !== 'keyword-box') return;
    if (
        configField.validateKeywordBox[inforBlock.id].configField.valueSync &&
        configField.validateKeywordBox[inforBlock.id].configField.valueSync !== 'key'
    ) {
        configField.validateKeywordBox[inforBlock.id].configField.error = true;
        configField.validateKeywordBox[inforBlock.id].configField.errorText = '入力内容が誤っています。';
        check = false;
    } else {
        configField.validateKeywordBox[inforBlock.id].configField.errorText = '';
    }
    return check;
};

const handleMatchingAction = (parlistBlockCreated, parblockItem) => {
    const msgState = state().listMessageBlocks;
    const matchingMessages = (errorBlocks) => {
        errorBlocks.forEach((itemError) => {
            if (itemError?.action?.length > 0) return;
            const objMsg = msgState.find((upItem) => upItem.label_id === itemError.label_id);
            if (!objMsg?.label_id) return;
            itemError.action = objMsg.action;
        });
    };
    if (parblockItem?.id) {
        matchingMessages(parblockItem.additional_setting.error_message_block);
        return;
    }
    if (Array.isArray(parlistBlockCreated) && parlistBlockCreated.length > 0) {
        parlistBlockCreated.forEach(({ additional_setting }) => {
            matchingMessages(additional_setting.error_message_block);
        });
    }
};

const matchingMessageBlocks = (configBlockForm, listBlockCreated, blockItem = null) => {
    const blockMapping = {
        radio: 'validateRadio',
        select: 'validateSelect',
        input: 'validateInput',
        checkbox: 'validateCheckbox'
    };
    handleMatchingAction(listBlockCreated, blockItem);
    // check message blockItem
    if (blockItem?.id) {
        const validateKey = blockMapping[blockItem.block_type];
        if (!validateKey) {
            console.warn(`Unsupported block type: ${blockItem.block_type}`);
            return;
        }
        const filterMsg = blockItem.additional_setting.error_message_block.filter((addItem) => addItem.action.includes(blockItem.block_type));
        const getConditionField = configBlockForm[validateKey]?.[blockItem.id]?.configField;
        const getConfigField = configBlockForm[validateKey][blockItem.id].configField;
        const getValidationsField = configBlockForm[validateKey][blockItem.id].configField.typesValidate;
        filterMsg.forEach((msgItem) => {
            switch (msgItem.type_msg) {
                case 'required':
                    if (getConditionField && !getConditionField.valueSync && getValidationsField.includes(msgItem.type_msg)) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'character_input':
                    if (getConditionField && getConditionField.valueSync && getValidationsField.includes('character_input')) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                // case 'duplicate':
                //     if (getConditionField && getConditionField.valueSync) {
                //         getConfigField.errorField = msgItem?.messages || null;
                //     }
                //     break;
                case 'character_range':
                    if (getConditionField && getConditionField.valueSync && getValidationsField.includes('limit-character')) {
                        const replacements = {
                            0: getConfigField.min,
                            1: getConfigField.max
                        };
                        if (getConditionField.valueSync.length < getConfigField.min || getConditionField.valueSync.length > getConfigField.max) {
                            getConfigField.errorField = tranferMessagesFormShortCode(msgItem?.messages ?? '', replacements) || null;
                        }
                    }
                    break;
                case 'email':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexEmail(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'number':
                    const regexMultiple = /^[0-9]+$/;
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexMultiple.test(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'half-width_alphanumeric_characters':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexHalfWidthAlphanumeric(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'full-width_characters':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexFullWidthCharacters(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'hiragana':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexHiragana(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'full-width_katakana':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexFullWidthKatakana(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'half-width_katakana':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexHalfWidthKatakana(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'format_numerical_value':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes('format_numerical_value') &&
                        !regexDynamicRange(getConditionField.valueSync, '', '')
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }

                    break;
                case 'numerical_value':
                    if (regexDynamicRange(getConditionField.valueSync, '', '')) {
                        if (getConditionField && getConditionField.valueSync && getValidationsField.includes('range_numerical')) {
                            const replacements = {
                                0: getConfigField.from_range,
                                1: getConfigField.to_range
                            };
                            getConfigField.errorField = tranferMessagesFormShortCode(msgItem?.messages ?? '', replacements) || null;
                        }
                    }
                    break;
                case 'numeric_value_including_decimal_point':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes('integerDecimal') &&
                        !regexNumberIntDecimal(getConditionField.valueSync, getConfigField.integer, getConfigField.decimal)
                    ) {
                        const replacements = {
                            0: getConfigField.integer,
                            1: getConfigField.decimal,
                            2: getConfigField.from_range,
                            3: getConfigField.to_range
                        };
                        getConfigField.errorField = tranferMessagesFormShortCode(msgItem?.messages ?? '', replacements) || null;
                    }
                    break;
            }
        });
        return;
    }

    // check message all block
    if (!listBlockCreated || listBlockCreated.length <= 0) return;
    listBlockCreated.forEach((blockCreateItem) => {
        const { block_type, id, additional_setting } = blockCreateItem;
        const validateKey = blockMapping[block_type];
        if (!validateKey) {
            console.warn(`Unsupported block type: ${block_type}`);
            return;
        }
        const filterMsg = additional_setting.error_message_block.filter((addItem) => addItem.action.includes(block_type));
        const getConditionField = configBlockForm[validateKey]?.[id]?.configField;
        const getConfigField = configBlockForm[validateKey][id].configField;
        const getValidationsField = configBlockForm[validateKey][id].configField.typesValidate;

        filterMsg.forEach((msgItem) => {
            switch (msgItem.type_msg) {
                case 'required':
                    if (
                        getConditionField &&
                        (!getConditionField.valueSync || getConditionField?.valueSync?.length <= 0) &&
                        (getValidationsField.includes(msgItem.type_msg) || getValidationsField.includes('requiredSelect'))
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'required_other':
                    if (configBlockForm[validateKey]?.[id]?.configFieldText) {
                        configBlockForm[validateKey][id].configFieldText.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'character_input':
                    if (getConditionField && getConditionField.valueSync && getValidationsField.includes('character_input')) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                // case 'duplicate':
                //     if (getConditionField && getConditionField.valueSync) {
                //         getConfigField.errorField = msgItem?.messages || null;
                //     }
                //     break;
                case 'character_range':
                    if (getConditionField && getConditionField.valueSync && getValidationsField.includes('limit-character')) {
                        const replacements = {
                            0: getConfigField.min,
                            1: getConfigField.max
                        };
                        if (getConditionField.valueSync.length < getConfigField.min || getConditionField.valueSync.length > getConfigField.max) {
                            getConfigField.errorField = tranferMessagesFormShortCode(msgItem?.messages ?? '', replacements) || null;
                        }
                    }
                    break;
                case 'email':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexEmail(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'number':
                    const regexMultiple = /^[0-9]+$/;
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexMultiple.test(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'half-width_alphanumeric_characters':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexHalfWidthAlphanumeric(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'full-width_characters':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexFullWidthCharacters(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'hiragana':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexHiragana(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'full-width_katakana':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexFullWidthKatakana(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'half-width_katakana':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes(msgItem.type_msg) &&
                        !regexHalfWidthKatakana(getConditionField.valueSync)
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }
                    break;
                case 'format_numerical_value':
                    if (
                        getConditionField &&
                        getConditionField.valueSync &&
                        getValidationsField.includes('format_numerical_value') &&
                        !regexDynamicRange(getConditionField.valueSync, '', '')
                    ) {
                        getConfigField.errorField = msgItem?.messages || null;
                    }

                    break;
                case 'numerical_value':
                    if (getConditionField && getConditionField.valueSync && getValidationsField.includes('range_numerical')) {
                        const replacements = {
                            0: getConfigField.from_range,
                            1: getConfigField.to_range
                        };
                        let msgFormat = filterMsg.find((msItem) => msItem.type_msg === 'format_numerical_value')?.messages ?? '';
                        getConfigField.errorField = regexDynamicRange(getConditionField.valueSync, '', '')
                            ? tranferMessagesFormShortCode(msgItem?.messages ?? '', replacements) || null
                            : msgFormat;
                    }
                    break;
            }
        });
    });
};

const tranferMessagesFormShortCode = (dataShortCode, replacements) => {
    let result = dataShortCode;
    if (!result) return '';
    return result.replace(/{(.*?)}/g, (match, p1) => {
        return replacements[p1] || '';
    });
};

export { configBlockForm, handleFilterBlock, validateFieldPhone, validatePostcode, validateKeywordBox, matchingMessageBlocks };
