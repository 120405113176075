var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-5"},[_c('div',[_c('div',{staticClass:"text-center"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.HEADER)}})]),(!_vm.isPageComplete)?_c('div',{staticClass:"content_block p-3 h-100 my-5"},_vm._l((_vm.listBlockSaved),function(itemBlock,idx){return _c('div',{key:`preview-${idx}`},[(
                        ['radio', 'select', 'checkbox', 'province', 'upload-file', 'remaining-seats-management-box'].includes(
                            itemBlock.block_type
                        )
                    )?_c('CommonOption',{attrs:{"index":_vm.indexCommonOption,"inforBlock":itemBlock,"configBlock":_vm.configBlockForm,"conditionPageShow":_vm.typePageShow}}):_vm._e(),(
                        ['div_1', 'div_2', 'input', 'textarea', 'date', 'fullname', 'postcode', 'phone', 'keyword-box', 'get-param'].includes(
                            itemBlock.block_type
                        )
                    )?_c('CommonBlockText',{attrs:{"index":_vm.indexCommonOption,"inforBlock":itemBlock,"configBlock":_vm.configBlockForm,"conditionPageShow":_vm.typePageShow}}):_vm._e()],1)}),0):_vm._e(),(!_vm.isPageComplete)?_c('div',{staticClass:"text-center mt-2"},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"gap":"12px"}},[(_vm.typePageShow == 'page_inputs')?_c('button',{staticClass:"btn btn-secondary text-nowrap",staticStyle:{"width":"45% !important"},attrs:{"type":"button"},on:{"click":function($event){return _vm.handleSubmit('page_inputs')}}},[_vm._v(" "+_vm._s(_vm.$t('managerForm.settingPage.btnSaveForm'))+" ")]):_vm._e(),(_vm.isPageConfirm)?_c('button',{staticClass:"btn btn-secondary text-nowrap",staticStyle:{"width":"45% !important"},attrs:{"type":"button"},on:{"click":function($event){return _vm.handleSubmit('page_confirms')}}},[_vm._v(" "+_vm._s(_vm.$t('btn.register'))+" ")]):_vm._e(),(_vm.isPageConfirm)?_c('button',{staticClass:"text-nowrap btn-back-form",attrs:{"type":"button"},on:{"click":_vm.handleBackForm}},[_vm._v(" "+_vm._s(_vm.$t('managerForm.settingPage.btnBack'))+" ")]):_vm._e()]),_c('div',{staticClass:"mt-5",domProps:{"innerHTML":_vm._s(_vm.FOOTER)}})]):_c('div',{staticClass:"content_block p-3 h-100 my-5"},_vm._l((_vm.listBlockComplete),function(itemBlock,idx){return _c('div',{key:`preview-${idx}`},[(['div_1'].includes(itemBlock.block_type))?_c('CommonBlockText',{attrs:{"inforBlock":itemBlock,"configBlock":_vm.configBlockForm,"conditionPageShow":_vm.typePageShow}}):_vm._e()],1)}),0)])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }