<template>
    <div>
        <!-- <div class="form-group row">
            <label class="col-sm-3">GA有無（LP）</label>
            <div class="col-sm-6">
                <select name="" id="" class="form-control" v-model="form.give_away">
                    <option value="0">NO</option>
                    <option value="1">YES</option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">GAからの取得情報<br />（LPからの）⇒</label>
            <div class="col-sm-9"></div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">流入先</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.give_away_information.inflow_destination" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">流入率</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.give_away_information.inflow_rate" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">離脱率</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.give_away_information.attrition_rate" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">ATELU（SNS収集ツール）<br />レポート格納</label>
            <div class="col-sm-9">
                <div class="row mb-1" v-for="(item, k) in form.campaign_sns_collections_tools" :key="k + 'sns' + item.id">
                    <div class="col-sm-6 align-items-center">
                        <a target="_blank" :href="item.path">{{ item.origin_name }}</a>
                    </div>
                    <div class="col-sm-3">
                        <button class="btn btn-sm btn-danger" @click="deleteSNS(k)"><i class="fa fa-minus"></i></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-7">
                        <UploadFileCommon @dataFile="handleFileATELU" title="ファイルアップロード" :id="`ATELU`" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">キャッシュバックの応募者不備件数/当選者に対しての％</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.defects_in_cash_back_applicants_rate" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">SNS当選者フォーム登録なし件数/当選者に対しての％</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.sns_winners_without_registration_rate" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">LINE友達増減数</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.line_friends_growth" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告媒体</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.advertising_media" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告費</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.advertising_cost" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告出稿による結果</label>
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.advertising_result" :config="{ error: false }" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"
                >SNSのフォロワー増加数<br />
                <small>※SNSの時に分かれば入力</small></label
            >
            <div class="col-sm-4">
                <InputText :id="`流入先`" :model.sync="form.number_of_sns_followers" :config="{ error: false }" />
            </div>
        </div> -->

        <!-- total_number_of_applications -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.numberOfApplications') }}</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.totalNumberOfApplications') }}</label>
                    <div class="col-sm-4">
                        <InputText :id="`総応募数`" :model.sync="form.total_number_of_applications" :config="configTotal" disabled />
                    </div>
                </div>

                <!-- number_of_applications -->
                <div v-for="(itemApplycation, idx) in form.number_of_applications" :key="`scope-${idx}`" class="form-group row">
                    <label class="col-sm-2">{{ itemApplycation?.master?.value ?? '' }}</label>
                    <div class="col-sm-4">
                        <InputText
                            :id="itemApplycation?.master?.value ?? ''"
                            :model.sync="itemApplycation.quantity"
                            :config.sync="itemApplycation.configQuantity"
                            @input="handleMathTotal(form.number_of_applications, 'applycation')"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- number_of_inquiries -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.numberOfInquiries') }}</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.totalnumberOfInquiries') }}</label>
                    <div class="col-sm-4">
                        <InputText :id="`総問い合わせ数`" :model.sync="form.total_number_of_inquiries" :config="configTotal" disabled />
                    </div>
                </div>
                <div v-for="(itemScope, idx) in listScope" :key="`scope-${idx}`" class="form-group row">
                    <label class="col-sm-2">{{ itemScope?.master_order_scope?.value ?? '' }}</label>
                    <div class="col-sm-4">
                        <InputText
                            :id="itemScope?.master_order_scope?.value ?? ''"
                            :model.sync="itemScope.quantity"
                            :config.sync="itemScope.configQuantity"
                            @input="handleMathTotal(listScope, 'scope')"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- platform_followers_number -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.SNSAccount') }}</label>
            <div class="col-sm-6">
                <div class="table-responsive" style="max-height: 400px; overflow-y: auto">
                    <table class="table table-bordered custom__table--sticky table-box">
                        <thead>
                            <tr>
                                <th v-for="(item, k) in configTableSNS.headers" :key="k">
                                    <div class="d-flex align-items-center">
                                        {{ item.label }}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(itemTb, idx) in form.platform_followers_number" :key="idx">
                                <td>
                                    <div class="row-data-small">
                                        {{ itemTb?.master?.value ?? '' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        <InputText
                                            :id="`${itemTb.value}-${idx}`"
                                            :model.sync="itemTb.initial_quantity"
                                            :config.sync="itemTb.configInitialQuantity"
                                            @input="handleMathIncreaseQuantity(itemTb)"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        <InputText
                                            :id="`${itemTb.value}-${idx}`"
                                            :model.sync="itemTb.final_quantity"
                                            :config.sync="itemTb.configFinalQuantity"
                                            @input="handleMathIncreaseQuantity(itemTb)"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        <InputText
                                            :id="`${itemTb.value}-${idx}`"
                                            :model.sync="itemTb.increase_quantity"
                                            :config.sync="itemTb.configIncreaseQuantity"
                                            disabled
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- advertising_activity -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.advertisingRelated') }}</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.advertisingOperation') }}</label>
                    <div class="col-sm-4">
                        <Multiselect
                            :id="`select-advertisingOperation`"
                            :value.sync="form.advertising_activity"
                            :options="listAdvertising"
                            :config="configAdvertisingOperation"
                            @open="getListMasterAdvertising"
                            @remove="handleRemoveAdvertising"
                            @select="handleSelectAdvertising"
                        ></Multiselect>
                    </div>
                </div>
            </div>
        </div>

        <!-- social_platforms -->
        <div v-if="form.advertising_activity?.value == 'あり（自社）' || form.advertising_activity?.value == 'あり（他社）'">
            <div class="form-group row">
                <label class="col-sm-3"></label>
                <div class="col-sm-8">
                    <div class="form-group row">
                        <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.mediaCosts') }}</label>
                        <div class="col-sm-6">
                            <div class="d-flex flex-wrap">
                                <div v-for="(itemMedia, idx) in listMediaCosts" :key="`Media-${idx}`">
                                    <InputCheckBox
                                        class="mr-3"
                                        :model.sync="listCheckedMediaCosts"
                                        :id="`check_IP--info${itemMedia.id}`"
                                        :value="itemMedia.id"
                                        :label="itemMedia.value"
                                        @change="handleChangeMediaCosts(itemMedia)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3"></label>
                <div class="col-sm-8">
                    <div v-for="(media, idx) in dataMediaCosts" :key="`Media-${idx}`" class="form-group row">
                        <label class="col-sm-2" style="white-space: nowrap">{{ media?.master?.value }}</label>
                        <div class="col-sm-6">
                            <textarea rows="3" v-model="media.note.detail" :class="`form-control`"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- give_away_related -->
        <div>
            <div class="form-group row">
                <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.GArelated') }}</label>
                <div class="col-sm-8">
                    <div class="form-group row">
                        <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.embedTags') }}</label>
                        <div class="col-sm-4">
                            <Multiselect
                                :id="`select-embedTags`"
                                :value.sync="form.give_away_related"
                                :options="listGiveAwayRelated"
                                :config="configEmbedTags"
                                @select="handleChangeGiveAway"
                            ></Multiselect>
                        </div>
                    </div>
                </div>
            </div>
            <!-- account_infor_on_screen -->
            <div v-if="form.give_away_related?.value == 'あり'" class="form-group row">
                <label class="col-sm-3"></label>
                <div class="col-sm-8">
                    <div class="form-group row">
                        <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.managementScreen') }}</label>
                        <div class="col-sm-6">
                            <textarea rows="3" v-model="form.account_infor_on_screen" :class="`form-control`" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- pdca_file_report -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.report') }}</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.PDCAReportStorage') }}</label>
                    <div class="col-sm-9">
                        <div v-if="form.pdca_file_report?.origin_name" class="row mb-1">
                            <div class="col-sm-6 align-items-center">
                                <a
                                    target="_blank"
                                    :href="form.pdca_file_report?.path"
                                    @click.prevent="downLoadFile(form.pdca_file_report?.path, form.pdca_file_report?.origin_name)"
                                    >{{ form.pdca_file_report?.origin_name ?? '' }}</a
                                >
                            </div>
                            <div class="col-sm-3">
                                <button class="btn btn-sm btn-danger" @click="deleteFilePdca()"><i class="fa fa-minus"></i></button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-7">
                                <UploadFileCommon @dataFile="handleFilePdca" title="ファイルアップロード" :id="`FilePdca`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- cashback_file_report -->
        <div class="form-group row">
            <label class="col-sm-3"></label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.cashBack') }}</label>
                    <div class="col-sm-9">
                        <div v-if="form.cashback_file_report?.origin_name" class="row mb-1">
                            <div class="col-sm-6 align-items-center">
                                <a
                                    target="_blank"
                                    :href="form.cashback_file_report?.path"
                                    @click.prevent="downLoadFile(form.cashback_file_report?.path, form.cashback_file_report?.origin_name)"
                                    >{{ form.cashback_file_report?.origin_name ?? '' }}</a
                                >
                            </div>
                            <div class="col-sm-3">
                                <button class="btn btn-sm btn-danger" @click="deleteFileReport"><i class="fa fa-minus"></i></button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-7">
                                <UploadFileCommon @dataFile="handleFileReport" title="ファイルアップロード" :id="`FileReport`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { masterMethods } from '@/state/helpers';
import { keyMaster } from '@/config/constants';
import { InputText, InputCheckBox } from '@/components/Input';
import { CONFIG_ADVERTISING_OPERATION, CONFIG_TABLE_SNS, CONFIG_TOTAL, CONFIG_EMBED_TAGS, LIST_GIVE_AWAY_RELATED } from './constants';
import { funcRecivedDataApplycation, funcRecivedDataScope, funcChangeMediaCosts } from './index';
import { handleDownFile } from '@/utils/format';

import UploadFileCommon from '@/components/Uploads/Files/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'tab7',
    components: { InputText, InputCheckBox, Multiselect, UploadFileCommon },
    props: {
        dataObject: {
            type: Object
        },
        dataScope: {
            type: Array,
            default: () => []
        },
        dataApplycation: {
            type: Array,
            default: () => []
        },
        dataMasterCampaignType: {
            type: Array,
            default: () => []
        },
        dataCampaignSocialPlatform: {
            type: Array,
            default: () => []
        }
    },
    data: function () {
        return {
            form: this.dataObject,
            listScope: [],
            listApplycation: [],
            listFollowers: [],
            listMediaCosts: this.dataCampaignSocialPlatform,
            listCheckedMediaCosts: [],
            dataMediaCosts: [],
            listAdvertising: [],
            listGiveAwayRelated: LIST_GIVE_AWAY_RELATED,
            configTotal: CONFIG_TOTAL,
            configTableSNS: CONFIG_TABLE_SNS,
            configAdvertisingOperation: CONFIG_ADVERTISING_OPERATION,
            configEmbedTags: CONFIG_EMBED_TAGS
        };
    },

    methods: {
        ...masterMethods,

        async getListMasterAdvertising() {
            if (this.configAdvertisingOperation.loading) return;
            this.configAdvertisingOperation.loading = true;
            this.listAdvertising = [];
            try {
                const data = await this.listMaster(keyMaster['campaign.advertising']);
                this.listAdvertising = data;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.configAdvertisingOperation.loading = false;
            }
        },

        deleteSNS(idx) {
            this.form.campaign_sns_collections_tools.splice(idx, 1);
        },

        handleMathTotal(itemList, typeTotal) {
            const totalQuantity = itemList.reduce((total, item) => total + Number(item.quantity), 0).toString();
            typeTotal == 'scope' ? (this.form.total_number_of_inquiries = totalQuantity) : (this.form.total_number_of_applications = totalQuantity);
        },

        handleMathIncreaseQuantity(paramItem) {
            paramItem.increase_quantity = Number(paramItem.final_quantity) - Number(paramItem.initial_quantity);
        },

        handleRecivedDataApplycation(newApplycation) {
            const resultDataApplication = funcRecivedDataApplycation(
                this.listApplycation,
                this.listFollowers,
                newApplycation,
                this.dataMasterCampaignType,
                this.dataObject
            );
            this.dataObject.number_of_applications = resultDataApplication.listApplycation;
            this.dataObject.platform_followers_number = resultDataApplication.listFollowers;
            this.handleMathTotal(this.dataObject.number_of_applications, 'applycation');
        },

        handleRecivedDataScope(newScope) {
            this.listScope = funcRecivedDataScope(newScope, this.listScope, this.dataObject);
            this.$emit('update:listScope', this.listScope);
            this.handleMathTotal(this.listScope, 'scope');
        },

        handleChangeMediaCosts(paramMedia) {
            this.dataMediaCosts = funcChangeMediaCosts(paramMedia, this.listCheckedMediaCosts, this.dataMediaCosts, this.form);
        },

        handleSelectAdvertising() {
            this.handleRemoveAdvertising();
        },

        handleRemoveAdvertising() {
            this.listCheckedMediaCosts = [];
            this.dataMediaCosts = [];
        },

        handleChangeGiveAway() {
            this.form.account_infor_on_screen = '';
        },

        handleFileATELU(file) {
            this.form.campaign_sns_collections_tools.push(file);
        },
        handleFilePdca(file) {
            this.form.pdca_file_report = file;
        },
        deleteFilePdca() {
            this.form.pdca_file_report = null;
        },

        handleFileReport(file) {
            this.form.cashback_file_report = file;
        },
        deleteFileReport() {
            this.form.cashback_file_report = null;
        },
        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        }
    },
    watch: {
        dataObject(newValue) {
            this.form = newValue;
        },
        dataScope: {
            handler: function (newScope) {
                this.handleRecivedDataScope(newScope);
            },
            immediate: true,
            deep: true
        },
        dataApplycation: {
            handler: function (newApplycation) {
                this.handleRecivedDataApplycation(newApplycation);
            },
            immediate: true,
            deep: true
        },
        dataMediaCosts: {
            handler: function (newdataMediaCosts) {
                this.form.social_platforms = newdataMediaCosts;
            },
            deep: true
        },
        'form.social_platforms': {
            handler: function () {
                this.dataMediaCosts = this.form.social_platforms;
                this.listCheckedMediaCosts = this.form.social_platforms.map((item) => item?.master?.id);
            },
            immediate: true
        }
    }
};
</script>

<style scoped lang="scss"></style>
