<template>
    <div>
        <div>
            <div class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.choicesAtTheTop') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex mb-3">
                        <div>
                            <button type="button" class="btn btn-outline-success" @click="handleAddOneOption('choicesAtTheTop')">
                                <i class="fa fa-plus"></i>
                                <span class="ml-2">{{ $t('managerForm.settingPage.addOneItem') }}</span>
                            </button>
                            <button type="button" class="btn btn-outline-success ml-3" @click="handleAddMoreOption('choicesAtTheTop')">
                                <i class="fa fa-plus"></i>
                                <span class="ml-2">{{ $t('managerForm.settingPage.addAllItem') }}</span>
                            </button>
                        </div>
                        <div class="ml-auto">
                            <button
                                type="button"
                                class="btn btn-outline-secondary"
                                :disabled="formBlock.option_maxtrix.choices_at_the_top.length <= 1"
                                @click="handleDeleteAllOption('choicesAtTheTop')"
                            >
                                <i class="fa fa-minus"></i>
                                <span class="ml-2">{{ $t('managerForm.settingPage.addAllItem') }}</span>
                            </button>
                        </div>
                    </div>
                    <div v-if="isAddMoreChoicesAtTheTop">
                        <h5 class="text-center font-weight-bold mb-4">{{ $t('managerForm.settingPage.addAllAtOnce') }}</h5>
                        <div class="mb-3">
                            <span>{{ $t('managerForm.settingPage.noteAddAllAtOnce') }}</span>
                        </div>
                        <div class="mb-4">
                            <textarea
                                v-model="text_area_more_the_top"
                                rows="5"
                                class="form-control"
                                placeholder="選択肢1
選択肢2
選択肢3"
                            ></textarea>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-outline-light" @click="handleCancelMoreOption('choicesAtTheTop')">
                                <span>{{ $t('managerForm.settingPage.cancel') }}</span>
                            </button>
                            <button type="button" class="btn btn-success ml-3" @click="handleSaveMoreOption('choicesAtTheTop')">
                                <span>{{ $t('managerForm.settingPage.add') }}</span>
                            </button>
                        </div>
                    </div>
                    <div v-else class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <th v-for="(item, k) in TABLE_OPTION_BLOCK_COMMON.headers" :key="k" style="background: #ededed">
                                    <div class="d-flex align-items-center">
                                        {{ item.label }} <span v-if="item.required" class="ml-1 text-danger">*</span>
                                    </div>
                                </th>
                            </thead>
                            <Draggable
                                :list="formBlock.option_maxtrix.choices_at_the_top"
                                tag="tbody"
                                ghost-class="ghost--option"
                                @start="dragList = true"
                                @end="dragList = false"
                            >
                                <tr v-for="(itemOp, idx) in formBlock.option_maxtrix.choices_at_the_top" :key="`tr-${idx}`">
                                    <td>
                                        <div>
                                            {{ itemOp.id }}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <InputText
                                                :model.sync="itemOp.value"
                                                :config.sync="configFormInput.validateArrayChoicesAtTheTopMatrix[idx].configFieldChoicesAtTheTop"
                                                @blur="
                                                    handleValidateField(
                                                        configFormInput.validateArrayChoicesAtTheTopMatrix[idx].configFieldChoicesAtTheTop
                                                    )
                                                "
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left">
                                            <button
                                                type="button"
                                                class="btn btn-outline-secondary"
                                                :disabled="formBlock.option_maxtrix.choices_at_the_top.length <= 1"
                                                @click="handleDeleteOneOption(idx, 'choicesAtTheTop')"
                                            >
                                                <span>{{ $t('btn.delete') }}</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </Draggable>
                        </table>
                    </div>
                </div>
            </div>

            <div class="form-group row pt-3 border-top-1-color">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.choicesOnTheFrontSide') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex mb-3">
                        <div>
                            <button type="button" class="btn btn-outline-success" @click="handleAddOneOption('choicesOnTheFrontSide')">
                                <i class="fa fa-plus"></i>
                                <span class="ml-2">{{ $t('managerForm.settingPage.addOneItem') }}</span>
                            </button>
                            <button type="button" class="btn btn-outline-success ml-3" @click="handleAddMoreOption('choicesOnTheFrontSide')">
                                <i class="fa fa-plus"></i>
                                <span class="ml-2">{{ $t('managerForm.settingPage.addAllItem') }}</span>
                            </button>
                        </div>
                        <div class="ml-auto">
                            <button
                                type="button"
                                class="btn btn-outline-secondary"
                                :disabled="formBlock.option_maxtrix.choices_on_the_front_side.length <= 1"
                                @click="handleDeleteAllOption('choicesOnTheFrontSide')"
                            >
                                <i class="fa fa-minus"></i>
                                <span class="ml-2">{{ $t('managerForm.settingPage.addAllItem') }}</span>
                            </button>
                        </div>
                    </div>
                    <div v-if="isAddMoreChoicesOnTheFrontSide">
                        <h5 class="text-center font-weight-bold mb-4">{{ $t('managerForm.settingPage.addAllAtOnce') }}</h5>
                        <div class="mb-3">
                            <span>{{ $t('managerForm.settingPage.noteAddAllAtOnce') }}</span>
                        </div>
                        <div class="mb-4">
                            <textarea
                                v-model="text_area_more_the_front"
                                rows="5"
                                class="form-control"
                                placeholder="選択肢1
選択肢2
選択肢3"
                            ></textarea>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-outline-light" @click="handleCancelMoreOption('choicesOnTheFrontSide')">
                                <span>{{ $t('managerForm.settingPage.cancel') }}</span>
                            </button>
                            <button type="button" class="btn btn-success ml-3" @click="handleSaveMoreOption('choicesOnTheFrontSide')">
                                <span>{{ $t('managerForm.settingPage.add') }}</span>
                            </button>
                        </div>
                    </div>
                    <div v-else class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <th v-for="(item, k) in TABLE_OPTION_BLOCK_COMMON.headers" :key="k" style="background: #ededed">
                                    <div class="d-flex align-items-center">
                                        {{ item.label }} <span v-if="item.required" class="ml-1 text-danger">*</span>
                                    </div>
                                </th>
                            </thead>
                            <Draggable
                                :list="formBlock.option_maxtrix.choices_on_the_front_side"
                                tag="tbody"
                                ghost-class="ghost--option"
                                @start="dragList = true"
                                @end="dragList = false"
                            >
                                <tr v-for="(itemOp, idx) in formBlock.option_maxtrix.choices_on_the_front_side" :key="`tr-${idx}`">
                                    <td>
                                        <div>
                                            {{ itemOp.id }}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <InputText
                                                :model.sync="itemOp.value"
                                                :config.sync="
                                                    configFormInput.validateArrayChoicesOnTheFrontSideMatrix[idx].configFieldChoicesOnTheFrontSide
                                                "
                                                @blur="
                                                    handleValidateField(
                                                        configFormInput.validateArrayChoicesOnTheFrontSideMatrix[idx].configFieldChoicesOnTheFrontSide
                                                    )
                                                "
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left">
                                            <button
                                                type="button"
                                                class="btn btn-outline-secondary"
                                                :disabled="formBlock.option_maxtrix.choices_on_the_front_side.length <= 1"
                                                @click="handleDeleteOneOption(idx, 'choicesOnTheFrontSide')"
                                            >
                                                <span>{{ $t('btn.delete') }}</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </Draggable>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TABLE_OPTION_BLOCK_COMMON, handleAddConfigChoicesAtTheTopMatrix, handleAddConfigChoicesOnTheFrontSideMatrix } from './constants';
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';

import Draggable from 'vuedraggable';
import Swal from 'sweetalert2';

export default {
    name: 'OptionValueMatrix',
    components: {
        Draggable,
        InputText
    },
    props: {
        formBlock: {
            type: Object,
            required: true,
            default: () => null
        },
        configFormInput: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            TABLE_OPTION_BLOCK_COMMON: TABLE_OPTION_BLOCK_COMMON,
            isAddMoreChoicesAtTheTop: false,
            isAddMoreChoicesOnTheFrontSide: false,
            dragList: false,
            text_area_more_the_top: '',
            text_area_more_the_front: ''
        };
    },

    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddOneOption(type) {
            if (type == 'choicesAtTheTop') {
                this.formBlock.option_maxtrix.choices_at_the_top.push({
                    id: this.formBlock.option_maxtrix.choices_at_the_top.length + 1,
                    value: ''
                });
                this.configFormInput.validateArrayChoicesAtTheTopMatrix = handleAddConfigChoicesAtTheTopMatrix(
                    this.configFormInput.validateArrayChoicesAtTheTopMatrix,
                    this.formBlock.option_maxtrix.choices_at_the_top
                );
            } else if (type == 'choicesOnTheFrontSide') {
                this.formBlock.option_maxtrix.choices_on_the_front_side.push({
                    id: this.formBlock.option_maxtrix.choices_on_the_front_side.length + 1,
                    value: ''
                });
                this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix = handleAddConfigChoicesOnTheFrontSideMatrix(
                    this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix,
                    this.formBlock.option_maxtrix.choices_on_the_front_side
                );
            }
        },

        handleDeleteOneOption(index, type) {
            if (type == 'choicesAtTheTop') {
                this.formBlock.option_maxtrix.choices_at_the_top.splice(index, 1);
                this.configFormInput.validateArrayChoicesAtTheTopMatrix = handleAddConfigChoicesAtTheTopMatrix(
                    this.configFormInput.validateArrayChoicesAtTheTopMatrix,
                    this.formBlock.option_maxtrix.choices_at_the_top
                );
            } else if (type == 'choicesOnTheFrontSide') {
                this.formBlock.option_maxtrix.choices_on_the_front_side.splice(index, 1);
                this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix = handleAddConfigChoicesOnTheFrontSideMatrix(
                    this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix,
                    this.formBlock.option_maxtrix.choices_on_the_front_side
                );
            }
        },

        handleAddMoreOption(type) {
            if (type == 'choicesAtTheTop') {
                this.isAddMoreChoicesAtTheTop = true;
            } else if (type == 'choicesOnTheFrontSide') {
                this.isAddMoreChoicesOnTheFrontSide = true;
            }
        },

        handleCancelMoreOption(type) {
            if (type == 'choicesAtTheTop') {
                this.text_area_more_the_top = '';
                this.isAddMoreChoicesAtTheTop = false;
            } else if (type == 'choicesOnTheFrontSide') {
                this.text_area_more_the_front = '';
                this.isAddMoreChoicesOnTheFrontSide = false;
            }
        },

        handleSaveMoreOption(type) {
            if (type == 'choicesAtTheTop') {
                const resultArray = this.text_area_more_the_top.split('\n').filter((line) => line.trim() !== '');
                for (let moreValue of resultArray) {
                    this.formBlock.option_maxtrix.choices_at_the_top.push({
                        id: this.formBlock.option_maxtrix.choices_at_the_top.length + 1,
                        value: moreValue
                    });
                    this.configFormInput.validateArrayChoicesAtTheTopMatrix = handleAddConfigChoicesAtTheTopMatrix(
                        this.configFormInput.validateArrayChoicesAtTheTopMatrix,
                        this.formBlock.option_maxtrix.choices_at_the_top
                    );
                }
                this.isAddMoreChoicesAtTheTop = false;
                this.text_area_more_the_top = '';
            } else if (type == 'choicesOnTheFrontSide') {
                const resultArray = this.text_area_more_the_front.split('\n').filter((line) => line.trim() !== '');
                for (let moreValue of resultArray) {
                    this.formBlock.option_maxtrix.choices_on_the_front_side.push({
                        id: this.formBlock.option_maxtrix.choices_on_the_front_side.length + 1,
                        value: moreValue
                    });
                    this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix = handleAddConfigChoicesOnTheFrontSideMatrix(
                        this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix,
                        this.formBlock.option_maxtrix.choices_on_the_front_side
                    );
                }
                this.isAddMoreChoicesOnTheFrontSide = false;
                this.text_area_more_the_front = '';
            }
        },

        handleDeleteAllOption(type) {
            if (type == 'choicesAtTheTop') {
                if (this.formBlock.option_maxtrix.choices_at_the_top.length > 1) {
                    Swal.fire({
                        title: '項目をすべて削除します。よろしいですか？ ※公開済みの項目は削除されません。',
                        text: '',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#34c38f',
                        cancelButtonColor: '#e8ecf4',
                        confirmButtonText: 'OK',
                        cancelButtonText: 'キャンセル'
                    }).then((result) => {
                        if (result.value) {
                            this.formBlock.option_maxtrix.choices_at_the_top.splice(0, this.formBlock.option_maxtrix.choices_at_the_top.length);
                            this.handleAddOneOption('choicesAtTheTop');
                            this.configFormInput.validateArrayChoicesAtTheTopMatrix = handleAddConfigChoicesAtTheTopMatrix(
                                this.configFormInput.validateArrayChoicesAtTheTopMatrix,
                                this.formBlock.option_maxtrix.choices_at_the_top
                            );
                        }
                    });
                }
            } else if (type == 'choicesOnTheFrontSide') {
                if (this.formBlock.option_maxtrix.choices_on_the_front_side.length > 1) {
                    Swal.fire({
                        title: '項目をすべて削除します。よろしいですか？ ※公開済みの項目は削除されません。',
                        text: '',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#34c38f',
                        cancelButtonColor: '#e8ecf4',
                        confirmButtonText: 'OK',
                        cancelButtonText: 'キャンセル'
                    }).then((result) => {
                        if (result.value) {
                            this.formBlock.option_maxtrix.choices_on_the_front_side.splice(
                                0,
                                this.formBlock.option_maxtrix.choices_on_the_front_side.length
                            );
                            this.handleAddOneOption('choicesOnTheFrontSide');
                            this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix = handleAddConfigChoicesOnTheFrontSideMatrix(
                                this.configFormInput.validateArrayChoicesOnTheFrontSideMatrix,
                                this.formBlock.option_maxtrix.choices_on_the_front_side
                            );
                        }
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.border-top-1-color {
    border-top: 1px solid #0000001a;
}
</style>
