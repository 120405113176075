<template>
    <div>
        <Layout>
            <div v-if="!isLoading" class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-body">
                        <b-tabs vertical justified nav-class="nav-tabs-custom" content-class="p-3 text-muted scroll--content__managerForm">
                            <b-tab v-for="(tab, index) in TABS" :key="index" class="border-0" :disabled="tab.disabled">
                                <template v-slot:title>
                                    <span class="">{{ tab.name }}</span>
                                </template>
                                <div>
                                    <SettingBasic v-if="tab.id === 1" :dataForm="formData" />
                                    <SettingDesign v-else-if="tab.id === 2" :dataForm="formData" />
                                    <SettingPage v-else-if="tab.id === 3" :dataForm="formData" />
                                    <SettingSerialCode v-else-if="tab.id === 6" :dataForm="formData" />
                                    <SettingDigitalGift v-else-if="tab.id === 7" :dataForm="formData" />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <Footer>
                    <div class="d-flex justify-content-center">
                        <div class="text-center flex-fill">
                            <button type="submit" @click="handleCancel" class="btn btn-light mr-3">{{ $t('btn.cancel') }}</button>
                            <button type="submit" @click="handleGoTo" class="btn btn-primary">{{ $t('btn.edit') }}</button>
                        </div>
                        <div v-if="$route.query.id">
                            <button type="button" class="btn btn-danger ml-3" @click="handleDeleteManagerForm({ id: $route.query.id })">
                                {{ $t('btn.delete') }}
                            </button>
                        </div>
                    </div>
                </Footer>
            </div>
            <div class="d-flex justify-content-center" v-else>
                <LoadingIcon />
            </div>
        </Layout>
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import SettingBasic from './components/setting-basic/main.vue';
import SettingDesign from './components/setting-design/main.vue';
import SettingPage from './components/setting-page/main.vue';
import SettingSerialCode from './components/setting-serial_code/main.vue';
import SettingDigitalGift from './components/setting-digital_gift/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Swal from 'sweetalert2';

import { TABS, initForm } from '../constants';
import { handleDataResponse } from '../handlers';
import { managerFormMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

export default {
    name: 'ManagerFormView',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        SettingBasic,
        SettingDesign,
        SettingPage,
        SettingSerialCode,
        SettingDigitalGift,
        LoadingIcon
    },
    data() {
        return {
            TABS: TABS.map((tab) => {
                tab.disabled = false;
                return tab;
            }),
            formData: initForm(),
            isLoading: false
        };
    },
    methods: {
        ...managerFormMethods,

        handleCancel() {
            this.$router.push({
                path: '/manager-form/list'
            });
        },

        handleGoTo() {
            this.$router.push({
                path: '/manager-form/edit',
                query: {
                    id: this.$route.query.id
                }
            });
        },

        handleDeleteManagerForm(payload) {
            Swal.fire({
                title: 'このキャンペーンを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'OK'
            }).then(async (result) => {
                if (result.value) {
                    try {
                        const response = await this.deleteManagerForm(payload);
                        if (response.code !== 200) return;
                        this.$router.push({ path: '/manager-form/list' }).then(() => {
                            showMessage(response.code, this.$bvToast, 'キャンペーンが削除されました。');
                        });
                    } catch (error) {
                        console.log('error', error);
                    }
                }
            });
        },

        async getDetail() {
            if (!this.$route?.query?.id) return;
            this.isLoading = true;
            try {
                const response = await this.getDetailManagerForm(this.$route.query.id);
                handleDataResponse(this.formData, response);
                console.log('response', response);
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    mounted() {
        this.getDetail();
    }
};
</script>

<style lang="scss">
.scroll--content__managerForm {
    height: 74vh;
    overflow-y: auto;
}
</style>
