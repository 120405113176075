import { authHeader, handleResponse } from '@/api/handler/main';

export const landingPageService = {
    getListLandingPage,
    getDetailLandingPage,
    registerLandingPage,
    updateLandingPage,
    deleteLandingPage,
    getDetailCampaignForLandingPage
};

function getListLandingPage(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/landing-pages?${query}`, requestOptions).then(handleResponse);
}

function getDetailLandingPage(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/landing-pages/${id}`, requestOptions).then(handleResponse);
}

function registerLandingPage(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_APIURL + `/landing-pages`, requestOptions).then(handleResponse);
}

function updateLandingPage(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_APIURL + `/landing-pages/${payload.id}`, requestOptions).then(handleResponse);
}

function deleteLandingPage(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };
    return fetch(process.env.VUE_APP_APIURL + `/landing-pages/${payload.id}`, requestOptions).then(handleResponse);
}

function getDetailCampaignForLandingPage(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        body: JSON.stringify()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${id}/for-landing-page`, requestOptions).then(handleResponse);
}
