<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <!-- <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingPage.pageType') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-page`"
                            :value.sync="dataForm.page_type"
                            :options="listPage"
                            :config="configForm.configFieldSelectPage"
                            :disabled="isQueryId"
                            @remove="handleValidateField(configForm.configFieldSelectPage)"
                        />
                    </div>
                </div> -->

                <div v-if="isPageTypeId">
                    <div v-if="dataForm.page_type?.name" class="d-flex align-items-center">
                        <h5 class="my-3">{{ dataForm.page_type?.name ?? '' }}</h5>
                        <span class="ml-auto btn popupw" @click="onHandlePreviewForm">
                            <i class="fa fa-eye"></i>
                        </span>
                    </div>
                    <div v-if="!isQueryId" class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"></label>
                        <div class="col-sm-9">
                            <img src="@/assets/images/workflow-form/wl_form_a.png" alt="" />
                        </div>
                    </div>
                    <div v-else class="form-group row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-9">
                            <div v-for="(master_page, idx) in dataForm.setting_page.pages" :key="idx">
                                <div class="form-group row">
                                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ master_page.title }}</label>
                                    <div v-if="master_page.is_accept_add" class="col-sm-9">
                                        <button
                                            type="button"
                                            class="btn btn-outline-info btn-sm rounded-circle"
                                            @click="handleAddFormInput(master_page)"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div v-for="(page, k) in master_page.page_child" :key="k" class="form-group row">
                                    <label class="col-sm-9 text-nowrap" style="min-width: 150px">{{ page.name_page }}</label>
                                    <div class="col-sm-3 d-flex flex-wrap">
                                        <InputRadio
                                            class="mt-1 pr-2"
                                            :model.sync="page.is_default"
                                            :name="`isDefaultPage-${master_page.id}`"
                                            :id="`isDefaultPage-${k}`"
                                            :value="true"
                                            :label="``"
                                            @change="setDefaultPage(idx, k)"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-outline-warning btn-sm rounded-circle mr-3 mb-2"
                                            style="width: 30px; height: 30px"
                                            @click="handleEditFormInput(master_page, page, idx, k)"
                                        >
                                            <i class="fa fa-edit"></i>
                                        </button>
                                        <button
                                            :disabled="master_page.page_child.length == 1"
                                            type="button"
                                            class="btn btn-outline-danger btn-sm rounded-circle"
                                            style="width: 30px; height: 30px"
                                            @click="handleDeleteFormInput(k, master_page.page_child)"
                                        >
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                                $t('managerForm.settingPage.specifiedNumberOfApplicationsEndScreen')
                            }}</label>
                            <div class="col-sm-9">
                                <InputCheckBox
                                    :model.sync="dataForm.setting_page.specified_number_of_applications_end_screen"
                                    :id="`check-specifiedNumberOfApplicationsEndScreen`"
                                    :value="false"
                                    :label="``"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                                $t('managerForm.settingPage.screenBeforeApplicationPeriodStarts')
                            }}</label>
                            <div class="col-sm-9">
                                <InputCheckBox
                                    :model.sync="dataForm.setting_page.screen_before_application_period_starts"
                                    :id="`check-screenBeforeApplicationPeriodStarts`"
                                    :value="false"
                                    :label="``"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                                $t('managerForm.settingPage.applicationPeriodEndScreen')
                            }}</label>
                            <div class="col-sm-9">
                                <InputCheckBox
                                    :model.sync="dataForm.setting_page.application_period_end_screen"
                                    :id="`check-applicationPeriodEndScreen`"
                                    :value="false"
                                    :label="``"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.unknownError') }}</label>
                            <div class="col-sm-9">
                                <InputCheckBox
                                    :model.sync="dataForm.setting_page.unknown_error"
                                    :id="`check-unknownError`"
                                    :value="false"
                                    :label="``"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.login') }}</label>
                            <div class="col-sm-9">
                                <InputCheckBox :model.sync="dataForm.setting_page.login" :id="`check-login`" :value="false" :label="``" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.mypage') }}</label>
                            <div class="col-sm-9">
                                <InputCheckBox :model.sync="dataForm.setting_page.my_page" :id="`check-Mypage`" :value="false" :label="``" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <ModalAddPage :configModal="configModalAddPage" />
        <modalEditPage :dataForm="dataForm" :configModal="configModalEditPage" @updateConfig="onHandleConfigEditPage" />
        <ModalPreviewForm :dataForm="dataForm" :configModal="configModalPreviewForm" />
    </div>
</template>

<script>
import { configModalAddPage, configModalEditPage, configModalPreviewForm } from './constants';
import { validateField } from '@/utils/validate';
import { InputCheckBox, InputRadio } from '@/components/Input';
import { managerFormMethods } from '@/state/helpers';

// import Multiselect from '@/components/Multiselect/main.vue';
import ModalAddPage from './modal-add-page.vue';
import modalEditPage from './modal-edit-page.vue';
import ModalPreviewForm from './modal-preview-form.vue';

export default {
    name: 'SettingPage',
    components: {
        // Multiselect,
        ModalAddPage,
        modalEditPage,
        ModalPreviewForm,
        InputCheckBox,
        InputRadio
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        },
        listPage: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    computed: {
        isPageTypeId() {
            return this.dataForm.page_type?.id ? true : false;
        },

        isQueryId() {
            return this.$route.query?.id ? true : false;
        }
    },
    data() {
        return {
            configModalAddPage: configModalAddPage,
            configModalEditPage: configModalEditPage,
            configModalPreviewForm: configModalPreviewForm
        };
    },
    methods: {
        ...managerFormMethods,

        setDefaultPage(parentIdx, childrenIndex) {
            this.dataForm.setting_page.pages[parentIdx].page_child.forEach((page, index) => {
                page.is_default = index === childrenIndex;
            });
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddFormInput(obj) {
            this.configModalAddPage.model = true;
            this.configModalAddPage.pageAdd = obj.page_child;
        },

        handleEditFormInput(master_obj, obj, idx, k) {
            this.configModalEditPage.model = true;
            this.configModalEditPage.id = `edit_page-${idx}_${k}`;
            this.configModalEditPage.title = obj.name_page;
            this.configModalEditPage.parentPage = master_obj;
            this.configModalEditPage.childPage = obj;
            this.updateBlockList(obj.blocks);
        },

        handleDeleteFormInput(k, arrayPage) {
            arrayPage.splice(k, 1);
        },

        onHandleConfigEditPage(evtConfig) {
            const { parentPage, childPage, title } = evtConfig;
            const foundMasterPage = this.dataForm.setting_page.pages.find((masterItem) => masterItem.id === parentPage.id);
            if (!foundMasterPage) {
                console.warn(`Master page with id ${parentPage.id} not found`);
                return;
            }
            const foundChildPage = foundMasterPage.page_child?.find((childPageItem) => childPageItem.id === childPage.id);
            if (!foundChildPage) {
                console.warn(`Child page with id ${childPage.id} not found`);
                return;
            }
            foundChildPage.name_page = title;
        },

        onHandlePreviewForm() {
            this.configModalPreviewForm.masterPage = this.dataForm.setting_page.pages;
            this.configModalPreviewForm.model = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.popupw {
    font-size: 20px;
    &:hover {
        opacity: 0.8;
    }
}
</style>
