function authHeader() {
    // return authorization header with jwt token
    let token = window.$cookies.get('token');
    if (token) {
        return { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token };
    } else {
        return {};
    }
}
function authHeaderUpload() {
    // return authorization header with jwt token
    let token = window.$cookies.get('token');
    if (token) {
        return { 'Content-Type': 'multipart/form-data' };
    } else {
        return {};
    }
}

function clearInfoUser() {
    localStorage.removeItem('user');
    window.$cookies.remove('token');
}

function refreshToken() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                clearInfoUser();
                location.reload(true);
            }
        });
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                switch (data.message) {
                    case 'token.expired':
                        refreshToken();
                        return;
                    default:
                        clearInfoUser();
                        location.reload(true);
                        break;
                }
            }
        }
        return data;
    });
}

export { authHeader, authHeaderUpload, clearInfoUser, refreshToken, handleResponse };
