import i18n from '@/i18n';
import moment from 'moment';
import { isValidDate } from '@/utils/handlers';
import { WORKFLOW_TYPE } from '@/views/pages/setting-phone/constants';
import { STR_DATE_TIME } from '@/utils/format';

function getDateFromFullDate(fullDate) {
    let day = fullDate.split('-')[2];
    let month = fullDate.split('-')[1];
    let year = fullDate.split('-')[0];
    return isValidDate(day + '/' + month + '/' + year);
}

function handleValidate(newDataInitForm) {
    let check = true;
    if (!newDataInitForm.campaign) {
        check = false;
        newDataInitForm.configSelectCampaign.error = true;
        newDataInitForm.errorCampaign = i18n.t('validateField.required', {
            field: i18n.t('settingPhone.nameCampaign')
        });
    } else {
        newDataInitForm.configSelectCampaign.error = false;
    }

    if (!newDataInitForm.partner && newDataInitForm.workflow_type?.id === WORKFLOW_TYPE.PARTNER) {
        check = false;
        newDataInitForm.configPartner.error = true;
        newDataInitForm.configPartner.errorPartner = i18n.t('validateField.requiredSelect', {
            field: i18n.t('settingPhone.correspondingPerson')
        });
    }

    if (newDataInitForm.filling_date) {
        newDataInitForm.configFilling_Date.error = !getDateFromFullDate(newDataInitForm.filling_date);
        if (newDataInitForm.configFilling_Date.error) {
            check = false;
            newDataInitForm.errorFillingDate = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('settingPhone.inDate')
            });
        }
    }

    if (newDataInitForm.filling_by) {
        if (newDataInitForm.filling_by.length >= 255) {
            check = false;
            newDataInitForm.errorFillingBy = i18n.t('validateField.max255', {
                field: i18n.t('settingPhone.userCreateForm'),
                length: 50
            });
        } else {
            newDataInitForm.errorFillingBy = '';
        }
    }

    if (newDataInitForm.phone_setting_start_date) {
        newDataInitForm.configPhoneSettingStartDate.error = !getDateFromFullDate(newDataInitForm.phone_setting_start_date);
        if (newDataInitForm.configPhoneSettingStartDate.error) {
            check = false;
            newDataInitForm.errorPhoneSettingStartDate = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('settingPhone.timePhoneOpenSetting')
            });
        }
    }

    if (newDataInitForm.phone_setting_end_date) {
        if (!getDateFromFullDate(newDataInitForm.phone_setting_end_date)) {
            check = false;
            newDataInitForm.configPhoneSettingEndDate.error = true;
            newDataInitForm.errorPhoneSettingEndDate = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('settingPhone.timePhoneOpenSetting')
            });
        } else {
            if (newDataInitForm.phone_setting_start_date) {
                let startDate = moment(newDataInitForm.phone_setting_start_date);
                let endDate = moment(newDataInitForm.phone_setting_end_date);
                if (endDate.isBefore(startDate)) {
                    check = false;
                    newDataInitForm.configPhoneSettingEndDate.error = true;
                    newDataInitForm.errorPhoneSettingEndDate = i18n.t('validateField.compareDate');
                } else {
                    newDataInitForm.configPhoneSettingEndDate.error = false;
                }
            }
        }
    }

    if (newDataInitForm.desired_release_date) {
        newDataInitForm.configDesiredReleaseDate.error = !getDateFromFullDate(newDataInitForm.desired_release_date);
        if (newDataInitForm.configDesiredReleaseDate.error) {
            check = false;
            newDataInitForm.errorDesiredReleaseDate = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('settingPhone.desiredReleaseDate')
            });
        }
    }

    if (newDataInitForm.desired_open_phone_date) {
        newDataInitForm.configDesiredOpenPhoneDate.error = !getDateFromFullDate(newDataInitForm.desired_open_phone_date);
        if (newDataInitForm.configDesiredOpenPhoneDate.error) {
            check = false;
            newDataInitForm.errorDesiredOpenPhoneDate = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('settingPhone.desiredOpenPhoneDate')
            });
        }
    }

    if (newDataInitForm.desired_date_to_end_the_call) {
        newDataInitForm.configDesiredDateToEndTheCall.error = !getDateFromFullDate(newDataInitForm.desired_date_to_end_the_call);
        if (newDataInitForm.configDesiredDateToEndTheCall.error) {
            check = false;
            newDataInitForm.configDesiredDateToEndTheCall.errorDesiredDateToEndTheCall = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('settingPhone.desiredDateToEndTheCall')
            });
        }
    }

    return {
        check,
        data: mapDataObject(newDataInitForm)
    };
}

function mapDataObject(newDataInitForm) {
    return {
        campaign: newDataInitForm.campaign ?? '',
        campaign_id: newDataInitForm.campaign.id ?? '',
        sale_staff: newDataInitForm.sale_staff ?? '',
        phone_setting_start_date: newDataInitForm.phone_setting_start_time
            ? moment(newDataInitForm.phone_setting_start_date + ' ' + newDataInitForm.phone_setting_start_time, STR_DATE_TIME).format(STR_DATE_TIME)
            : newDataInitForm.phone_setting_start_date ?? '',
        phone_setting_end_date: newDataInitForm.phone_setting_end_time
            ? moment(newDataInitForm.phone_setting_end_date + ' ' + newDataInitForm.phone_setting_end_time, STR_DATE_TIME).format(STR_DATE_TIME)
            : newDataInitForm.phone_setting_end_date ?? '',
        phone_number_type: newDataInitForm.phone_number_type?.id ?? '',
        desired_release_date: newDataInitForm.desired_release_date ?? '',
        desired_open_phone_date: newDataInitForm.desired_open_phone_date ?? '',
        pre_order_phone_numbers: newDataInitForm.pre_order_phone_numbers ?? '',
        reservation: newDataInitForm.reservation ?? '',
        desired_date_to_end_the_call: newDataInitForm.desired_date_to_end_the_call ?? '',
        setting_details: newDataInitForm.setting_details ?? '',
        setting_content_type: newDataInitForm.setting_content_type?.id ?? '',
        setting_content_data: newDataInitForm.setting_content_data ?? '',
        workflow_type: newDataInitForm.workflow_type?.id ?? '',
        partner:
            newDataInitForm.workflow_type?.id === WORKFLOW_TYPE.PARTNER
                ? {
                      id: newDataInitForm.partner?.id ?? ''
                  }
                : null,
        planning_to_set_up: newDataInitForm.planning_to_set_up ?? ''
    };
}

export { handleValidate };
