<script>
import Layout from '@/views/layouts/main';
import MasterChild from '@/components/Masters/master-child.vue';
import MasterChildEdit from '@/components/Masters/master-child-edit.vue';
import Swal from 'sweetalert2';
import Footer from '@/components/Footer/main.vue';
import appConfig from '@/app.config';

import { masterComputed, masterMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { showMessage } from '@/utils/messages';
import { nameMaster } from '@/config/constants';

export default {
    page: {
        title: 'マスタ新規登録・編集',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        MasterChild,
        MasterChildEdit,
        Footer
    },
    data() {
        return {
            index: 1,
            loading: false,
            objectData: {
                key: this.$route.query.key,
                id: '',
                values: []
            },
            data: [
                {
                    value: '',
                    data: []
                }
            ],
            listData: [],
            nameMaster: nameMaster,
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            checkNull: false
        };
    },
    validations: {},
    computed: {
        ...masterComputed
    },
    mounted() {
        this.getListMaster();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        getListMaster() {
            if (this.objectData.key) {
                this.loading = false;
                this.listMaster(this.objectData.key).then((data) => {
                    this.loading = true;
                    this.index++;
                    this.listData = data;
                });
            }
        },
        addChild(parent) {
            parent.data.push({
                value: '',
                data: []
            });
            this.data = Object.assign({}, this.data);
        },
        checkAndAlert(obj) {
            for (let key in obj) {
                if (typeof obj[key] === 'object') {
                    this.checkAndAlert(obj[key]);
                } else {
                    if (obj[key] === '') {
                        this.checkNull = true;
                        return true;
                    }
                }
            }
        },

        register() {
            this.checkNull = false;
            this.checkAndAlert(this.data);
            if (this.checkNull) {
                showMessage(422, this.$bvToast, 'すべての情報を入力してください。');
            } else {
                this.checkNull = false;
                if (this.objectData.key && this.data[0].value && this.loading) {
                    this.objectData.values = this.data;
                    this.loading = false;
                    this.registerMaster(this.objectData).then((data) => {
                        if (data.code == 200) {
                            setTimeout(() => {
                                this.objectData = Object.assign({
                                    key: this.$route.query.key,
                                    values: [],
                                    id: ''
                                });
                                this.data = [{ value: '', data: [] }];
                            }, 1000);
                            this.getListMaster();
                            showMessage(data.code, this.$bvToast, '登録されました。');
                            this.loading = true;
                        }
                    });
                }
            }
        },
        edit(item, key) {
            if (!item.disabled) {
                this.listData[key].disabled = true;
                this.listData = [].concat(this.listData);
            } else {
                if (item.value) {
                    let data = Object.assign(item);

                    let object = {
                        id: data.id,
                        key: this.$route.query.key,
                        values: [data]
                    };
                    this.updateMaster(object).then((data) => {
                        this.listData[key].disabled = false;
                        this.listData = [].concat(this.listData);
                        if (data.code == 200) showMessage(data.code, this.$bvToast, '更新されました。');
                    });
                }
            }
        },
        deleteObject(data) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteMaster(data).then((res) => {
                        if (res.code == 200) {
                            showMessage(res.code, this.$bvToast, '削除されました。');
                            this.getListMaster();
                        }
                    });
                }
            });
        },
        deleteChild(parentIndex, childIndex) {
            this.listData[parentIndex].data.splice(childIndex, 1);
        },
        deleteChildAdd(parentIndex, childIndex) {
            this.data[parentIndex].data.splice(childIndex, 1);
        }
    }
};
</script>

<template>
    <Layout>
        <div>
            <div class="col-lg-12">
                <div class="card form--sroll">
                    <div class="card-header">
                        <h3>{{ nameMaster[this.$route.query.key] }}</h3>
                    </div>
                    <div class="card-body" :key="index">
                        <div v-for="(item, k) in listData" :key="item.id">
                            <div class="d-flex align-items-start justify-content-center mb-3">
                                <button :disabled="!item.disabled" type="button" class="btn btn-success mr-3" @click="addChild(item)">+</button>

                                <div class="w-100">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <input
                                            :disabled="!item.disabled || !item.is_editable"
                                            v-model="item.value"
                                            type="text"
                                            :maxlength="255"
                                            class="form-control"
                                            :class="{ 'is-invalid': item.value.length > 255 }"
                                        />
                                        <button
                                            v-if="!item.disabled"
                                            type="button"
                                            @click="edit(item, k)"
                                            class="btn btn-info mx-2"
                                            style="white-space: nowrap"
                                        >
                                            編集
                                        </button>
                                        <button v-else type="button" @click="edit(item, k)" class="btn btn-success mx-2" style="white-space: nowrap">
                                            保存
                                        </button>
                                        <button
                                            v-if="item.is_editable"
                                            type="button"
                                            @click="deleteObject(item)"
                                            class="btn btn-danger"
                                            style="white-space: nowrap"
                                        >
                                            削除
                                        </button>
                                    </div>

                                    <div class="pl-3">
                                        <master-child-edit
                                            :dis="item.disabled"
                                            :parent="item"
                                            @deleteChild="deleteChild(k, childIndex)"
                                            v-for="(child, childIndex) in item.data"
                                            :key="'c' + childIndex"
                                            :child="child"
                                        ></master-child-edit>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.value.length > 255" class="invalid-feedback">
                                <span>255文字以内で入力してください。</span>
                            </div>
                        </div>

                        <hr />
                        <form>
                            <label for="">新規登録</label>
                            <div v-for="(item, index) in data" :key="index">
                                <div class="d-flex align-items-start">
                                    <button type="button" class="btn btn-success mr-3" @click="addChild(item)">+</button>
                                    <div class="w-100">
                                        <input
                                            v-model="item.value"
                                            type="text"
                                            :maxlength="255"
                                            class="form-control"
                                            :class="{ 'is-invalid': submitted && $v.item.value.$error }"
                                        />
                                        <div class="pl-3">
                                            <master-child
                                                v-for="(child, childIndex) in item.data"
                                                @deleteChild="deleteChildAdd(index, childIndex)"
                                                :key="'c' + childIndex"
                                                :child="child"
                                            ></master-child>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center">
                        <button type="button" @click="register" class="btn btn-primary">登録</button>
                    </div>
                </div>
            </Footer>
        </div>
    </Layout>
</template>
