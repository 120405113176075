const configModalRule = {
    id: '',
    title: '新規登録',
    titleBtnSave: '登録',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: 'xl',
    validate: true
};

const shortCode = [
    '《キャンペーン名》',
    '《クライアント名》',
    '《年末年始休み》',
    '《キャンペーン開始》',
    '《キャンペーン終了》',
    '《対象商品名》',
    '《賞品名》',
    '《キャンペーン事務局TEL》',
    '《キャンペーン事務局Mailアドレス》',
    '《事務局開始》',
    '《事務局終了》',
    '《流通エンド》',
    '《単体の合計》',
    '《メーカーエンド》',
    '《単体の対象商品含む》',
    '《単体の対象商品》',
    '《グループの合計》',
    '《グループの対象商品含む》',
    '《グループの対象商品》',
    '《Amazonギフト券》',
    '《EJOICAセレクトギフト》',
    '《QUOカードpay》',
    '《公式Twitterアカウント名》',
    '《公式Instagramアカウント名》',
    '《抽選(当選人数)》',
    '《賞品発送時期》',
    '《CP指定ハッシュタグ》',
    '《応募種別》',
    '《Amazon当選者数》',
    '《EJOICA当選者数》',
    '《QUO当選者数》'
];

function configForm() {
    return {
        configFieldOption: {
            trackBy: 'id',
            label: 'value',
            error: false,
            isRemove: false,
            errorField: '配置',
            valueSync: null,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        configFieldClassic: {
            trackBy: 'id',
            label: 'value',
            error: false,
            isRemove: false,
            errorField: '区分',
            valueSync: null,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        configFieldDetail: {
            error: false,
            valueSync: null,
            isRequired: false,
            errorField: '条文・細則',
            typesValidate: ['required']
        }
    };
}

function initDataForm() {
    return {
        detail: '',
        option: null,
        classification: null,
        is_default: false
    };
}

export { configModalRule, shortCode, configForm, initDataForm };
