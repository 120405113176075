import { employeeService } from '@/api/services/employee.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    registerEmployeePartTime({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return employeeService.registerEmployeePartTime(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error.message, { root: true });
            }
        );
    },

    updateEmployeePartTime({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return employeeService.updateEmployeePartTime(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    listEmployeePartTime({ dispatch, commit }, query) {
        commit('listRequest', []);
        return employeeService.listEmployeePartTime(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    listEmployee({ dispatch, commit }, query) {
        commit('listRequest', []);
        return employeeService.listEmployee(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailEmployeePartTime({ dispatch, commit }, data) {
        commit('listRequest', []);
        return employeeService.detailEmployeePartTime(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    detailEmployee({ dispatch, commit }, data) {
        commit('listRequest', []);
        return employeeService.detailEmployee(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteEmployee({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return employeeService.deleteEmployee(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteEmployeePartTime({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return employeeService.deleteEmployeePartTime(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    registerEmployee({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return employeeService.registerEmployee(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateEmployee({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return employeeService.updateEmployee(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
