<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import PopUpInfomation from '@/views/pages/campaign/view/components/TabPrizeView/components/Infomation/pop-up-infomation.vue';
import PopUpCompatible from '@/views/pages/campaign/view/components/TabPrizeView/components/NotVeryCompatible/pop-up-compatible.vue';

import { masterMethods, prizeMethods, partnersMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { showMessage } from '@/utils/messages';
import { formatDateCommon } from '@/utils/format';

export default {
    page: {
        title: '賞品詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer,
        PopUpInfomation,
        PopUpCompatible
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            form: {
                name: '',
                campaign: null,
                kana_name: '',
                email: '',
                phone_number: '',
                type: {
                    id: ''
                },
                note: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                keyword: ''
            },
            configPopInfomation: {
                isShow: false
            },
            configPopCompatible: {
                isShow: false
            }
        };
    },
    mounted() {
        if (this.id) {
            this.getDetail(this.id);
        }
    },
    methods: {
        ...masterMethods,
        ...prizeMethods,
        ...partnersMethods,

        handleFormatDate(date, type) {
            return formatDateCommon(date, type);
        },

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        getDetail(id) {
            this.loading = true;
            this.detailPrize(id).then((data) => {
                this.form = {
                    ...this.form,
                    ...data,
                    campaign: data.event_prizes.campaign
                };
                this.loading = false;
            });
        },

        goToEdit(path, id, partner_id) {
            this.$router.push({ path: path, query: { id: id, partner_id: partner_id } });
        },

        deleteObject(data) {
            Swal.fire({
                title: 'この賞品を削除してもよろしいですか',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deletePrize(data).then((data) => {
                        if (data && data.code == 200) {
                            this.$router.push({ path: '/prize/list' }).then(() => {
                                showMessage(data.code, this.$bvToast, '賞品が削除されました。');
                            });
                        }
                    });
                }
            });
        },

        handleShowPopup(typeShow) {
            if (typeShow == 'info') {
                this.configPopInfomation.isShow = true;
            } else {
                this.configPopCompatible.isShow = true;
            }
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-8">
                                <form class="needs-validation" autocomplete="off">
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">キャンペーン名 <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <div>
                                                {{ form.campaign?.name ?? '' }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品タイトル</label>
                                        <div class="col-sm-8">
                                            {{ form?.event_prizes?.title ?? '' }}
                                        </div>
                                    </div>

                                    <div>
                                        <div class="form-group row">
                                            <label class="col-sm-4" for="validationCustom05">種類</label>
                                            <div class="col-sm-8">
                                                <div>
                                                    {{ form.type?.value ?? '' }}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="form-group row"
                                            v-if="
                                                form.type?.value == '通常の金券' ||
                                                form.type?.value == '電子版の金券' ||
                                                form.type?.value == '旅行・宿泊・ﾚｼﾞｬｰへの招待券' ||
                                                form.type?.value == 'ファッション・高級ブランド・貴金属品'
                                            "
                                        >
                                            <label class="col-sm-4" for="validationCustom05">金額入力</label>
                                            <div class="col-sm-8">
                                                <div>
                                                    {{ form.coupon_value }}
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="form.digital_gifts?.length > 0">
                                            <div class="form-group row" v-if="form.type?.value == 'デジタルギフト'">
                                                <label class="col-sm-4" for="validationCustom05">デジタルギフト ★ </label>
                                                <div class="col-sm-8">
                                                    <div>
                                                        {{ form?.digital_gifts[0]?.value ?? '' }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row" v-if="form?.digital_gifts[0]?.id">
                                                <label class="col-sm-4" for="validationCustom05">{{ form?.digital_gifts[0].value }}★ </label>
                                                <div class="col-sm-8">
                                                    <div class="d-flex align-items-center">
                                                        {{ form?.digital_gifts[0]?.extension?.value_note ?? '' }}
                                                        <span style="width: 50px; margin-left: 9px">円分</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">当選人数</label>
                                        <div class="col-sm-8">
                                            {{ form?.event_prizes?.number_of_win_prize ?? 0 }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">抽選人数</label>
                                        <div class="col-sm-8">
                                            {{ form?.event_prizes?.number_of_people_drawing_lots ?? 0 }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品名</label>
                                        <div class="col-sm-8">
                                            {{ form.name }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">数量</label>
                                        <div class="col-sm-4">
                                            {{ form.quantity }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品必要数</label>
                                        <div class="col-sm-4">
                                            {{ form.quantity_for_event_prize }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫予定日</label>
                                        <div class="col-sm-8">
                                            {{ (handleFormatDate(form.scheduled_arrived_at), 'dateJP' ?? '') }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫日</label>
                                        <div class="col-sm-8">
                                            {{ (handleFormatDate(form.warehousing_date), 'dateJP' ?? '') }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">入庫数</label>
                                        <div class="col-sm-4">
                                            {{ form.warehousing_quantity }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">残予定数</label>
                                        <div class="col-sm-4">
                                            {{ form.estimated_remaining_quantity }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">詳細情報</label>
                                        <div class="col-sm-4">
                                            <button class="btn btn-sm btn-info" type="button" @click="handleShowPopup('info')">詳細情報</button>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送・進呈予定日</label>
                                        <div class="col-sm-4">
                                            {{ handleFormatDate(form?.event_prizes, 'dateJP'?.delivery_date_schedule) ?? '' }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送日・進呈日</label>
                                        <div class="col-sm-4">
                                            {{ handleFormatDate(form?.event_prizes, 'dateJP'?.delivery_date) ?? '' }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送件数</label>
                                        <div class="col-sm-4">
                                            {{ form?.event_prizes?.delivery_quantity ?? 0 }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">実あまり数</label>
                                        <div class="col-sm-4">
                                            {{ form.remaining_quantity }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">あまり対応</label>
                                        <div class="col-sm-4">
                                            <button class="btn btn-sm btn-info" type="button" @click="handleShowPopup('compatible')">
                                                実あまり数
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">梱包写真必要有無</label>
                                        <div class="col-sm-4">
                                            {{ form.is_packaging_photo_required == '0' ? 'なし' : 'あり' }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送日</label>
                                        <div class="col-sm-8">
                                            {{ handleFormatDate(form?.shipping_date, 'dateJP') ?? '' }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">発送数</label>
                                        <div class="col-sm-4">
                                            {{ form.shipment_quantity }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">連絡事項</label>
                                        <div class="col-sm-8">
                                            {{ form.notification }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">再確認日程</label>
                                        <div class="col-sm-8">
                                            {{ form.reconfirmation_schedule }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">賞品あまり対応指示項目</label>
                                        <div class="col-sm-8">
                                            {{ form.instructions_regarding_prizes }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">保管料がかかり始める月</label>
                                        <div class="col-sm-8">
                                            {{ form.month_start_fee_apply }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">保管料</label>
                                        <div class="col-sm-8">
                                            {{ form.storage_material }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">箱数</label>
                                        <div class="col-sm-8">
                                            {{ form.boxes_number }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">応募数</label>
                                        <div class="col-sm-8">
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">総応募数</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.total }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">ハガキ・郵送</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.postcard_mail ?? '' }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">web</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.web ?? '' }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">パルなび</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.palnavi }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">SNS</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.sns }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">Twitter</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.twitter }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">Instagram</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.instagram }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">LINE</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.line }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">その他</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.others }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">無効</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_applications?.invalid }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4" for="validationCustom05">問い合わせ件数</label>
                                        <div class="col-sm-8">
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">総問い合わせ数</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_inquiries?.total ?? '' }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">TEL</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_inquiries?.tel ?? '' }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">メール</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_inquiries?.email ?? '' }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">それ以外</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_inquiries?.number_of_cases ?? '' }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4" for="validationCustom05">イレギュラー</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_inquiries?.irregular ?? '' }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-4 pr-0" for="validationCustom05">イレギュラー内容</label>
                                                <div class="col-sm-5">
                                                    {{ form.number_of_inquiries?.irregular_content ?? '' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <PopUpInfomation
                    :id="`popup-info-master-detail-prize`"
                    :title="form.name"
                    :isShow.sync="configPopInfomation.isShow"
                    :dataInfo.sync="form.detail_information"
                />

                <PopUpCompatible
                    :id="`popup-compatible-master-detail-prize`"
                    :title="form.name"
                    :isShow.sync="configPopCompatible.isShow"
                    :dataCompatible.sync="form.remaining_response"
                />
                <!-- <ViewDetail2 :form="form" /> -->
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/prize/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermiss('prize.register')"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/prize/form', $route.query.id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss('prize.delete')"
                            type="btn"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
