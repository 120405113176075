import { managerFormService } from '@/api/services/manager-form.service';

export const state = () => ({
    listDataManagerForm: [],
    listBlockCreated: [],
    listMessageBlocks: [
        {
            label: '必須未選択エラー',
            messages: '必ず選択してください。',
            msg_default: '必ず選択してください。',
            action: [
                'radio',
                'select',
                'textarea',
                'matrix',
                'div_1',
                'div_2',
                'upload-file',
                'date',
                'fullname',
                'postcode',
                'province',
                'phone',
                'remaining-seats-management-box',
                'keyword-box',
                'get-param'
            ],
            type_msg: 'required',
            label_id: 1
        },
        {
            label: '必須未チェックエラー',
            messages: '必ずチェックしてください。 ',
            msg_default: '必ずチェックしてください。',
            action: ['radio', 'checkbox'],
            type_msg: 'required',
            required_checkbox: true,
            label_id: 17
        },
        {
            label: '必須未入力エラー(その他)',
            messages: '必ず入力してください。 ',
            msg_default: '必ず入力してください。',
            action: ['radio', 'checkbox'],
            type_msg: 'required_other',
            label_id: 2,
            required_value_other: true
        },
        {
            label: 'チェック個数エラー(相違)',
            messages: '{0}個チェックしてください。 ',
            msg_default: '{0}個チェックしてください。',
            action: ['checkbox'],
            type_msg: 'checked_quantity_error',
            label_id: 18
        },
        {
            label: '排他チェックエラー',
            messages: '複数選択できない項目が選択されています。',
            msg_default: '複数選択できない項目が選択されています。',
            action: ['checkbox'],
            type_msg: 'exclusive_check_error',
            label_id: 19
        },
        {
            label: '禁止文字入力エラー(機種依存文字、絵文字等)',
            messages: '機種依存文字、絵文字などが含まれています。[{0}]',
            msg_default: '機種依存文字、絵文字などが含まれています。[{0}]',
            action: ['input'],
            type_msg: 'character_input',
            label_id: 3
        },
        {
            label: '必須未入力エラー',
            messages: '必ず入力してください。',
            msg_default: '必ず入力してください。',
            action: ['input'],
            type_msg: 'required',
            label_id: 4,
            required_input: true
        },
        {
            label: '重複エラー',
            messages: 'すでに登録されています。',
            msg_default: 'すでに登録されています。',
            action: ['input'],
            type_msg: 'duplicate',
            label_id: 5
        },
        {
            label: '入力形式エラー(半角英数字)',
            messages: '半角英数字で入力してください。',
            msg_default: '半角英数字で入力してください。',
            action: ['input'],
            type_msg: 'half-width_alphanumeric_characters',
            label_id: 6
        },
        {
            label: '入力形式エラー(全角文字)',
            messages: '全角文字で入力してください。',
            msg_default: '全角文字で入力してください。',
            action: ['input'],
            type_msg: 'full-width_characters',
            label_id: 7
        },
        {
            label: '入力形式エラー(ひらがな)',
            messages: 'ひらがなで入力してください。',
            msg_default: 'ひらがなで入力してください。',
            action: ['input'],
            type_msg: 'hiragana',
            label_id: 8
        },
        {
            label: '入力形式エラー(全角カタカナ)',
            messages: '全角カタカナで入力してください。',
            msg_default: '全角カタカナで入力してください。',
            action: ['input'],
            type_msg: 'full-width_katakana',
            label_id: 9
        },
        {
            label: '入力形式エラー(半角カタカナ)',
            messages: '半角カタカナで入力してください。',
            msg_default: '半角カタカナで入力してください。',
            action: ['input'],
            type_msg: 'half-width_katakana',
            label_id: 10
        },
        {
            label: '入力形式エラー(数値)',
            messages: '数値を半角で入力してください。',
            msg_default: '数値を半角で入力してください。',
            action: ['input'],
            type_msg: 'format_numerical_value',
            label_id: 11
        },
        {
            label: '回答有効値の範囲外／桁数超過エラー',
            messages: '{0}から{1}の範囲で数値を入力してください。',
            msg_default: '{0}から{1}の範囲で数値を入力してください。',
            action: ['input'],
            type_msg: 'numerical_value',
            label_id: 12
        },
        {
            label: '回答有効値の範囲外／桁数超過エラー',
            messages: '整数部{0}桁、小数部{1}桁までの数値を{2}から{3}の範囲で入力してください。',
            msg_default: '整数部{0}桁、小数部{1}桁までの数値を{2}から{3}の範囲で入力してください。',
            action: ['input'],
            type_msg: 'numeric_value_including_decimal_point',
            label_id: 13
        },
        {
            label: '文字数範囲エラー',
            messages: '{0}文字から{1}文字で入力してください。',
            msg_default: '{0}文字から{1}文字で入力してください。',
            action: ['input'],
            type_msg: 'character_range',
            label_id: 14
        },
        {
            label: '入力形式エラー(メールアドレス)',
            messages: 'メールアドレスを正しい形式で入力してください。',
            msg_default: 'メールアドレスを正しい形式で入力してください。',
            action: ['input'],
            type_msg: 'email',
            label_id: 15
        },
        {
            label: '入力形式エラー(数字(0-9))',
            messages: '数字で半角で入力してください。',
            msg_default: '数字で半角で入力してください。',
            action: ['input'],
            type_msg: 'number',
            label_id: 16
        }
    ]
});

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    },
    listDataManagerForm: (state) => state.listDataManagerForm,
    listBlockCreated: (state) => state.listBlockCreated,
    listMessageBlocks: (state) => state.listMessageBlocks
};

export const actions = {
    registerManagerForm({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return managerFormService.registerManagerForm(data).then(
            (data) => {
                commit('registerSuccess', data);
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    updateManagerForm({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return managerFormService.updateManagerForm(data).then(
            (data) => {
                commit('registerSuccess', data);
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getListManagerForm({ dispatch, commit }, query) {
        commit('listRequest', []);
        return managerFormService.getListManagerForm(query).then(
            (data) => {
                commit('listManagerFormSuccess', data.data);
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    getDetailManagerForm({ dispatch, commit }, data) {
        commit('listRequest', []);
        return managerFormService.getDetailManagerForm(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    deleteManagerForm({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return managerFormService.deleteManagerForm(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    saveStateBlockForm({ commit }, data) {
        commit('handleSaveStateBlockForm', data);
    },

    updateBlockList({ commit }, data) {
        commit('handleUpdateBlockList', data);
    },

    updateMessages({ commit }, data) {
        commit('handleUpdateMsg', data);
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        state.dataList = data;
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    },

    listManagerFormSuccess(state, data) {
        state.listDataManagerForm = data;
    },

    handleSaveStateBlockForm(state, data) {
        const editBlock = state.listBlockCreated.find((upItem) => upItem.id == data.id);
        if (!state.nextId) {
            state.nextId = 1;
        }
        if (!editBlock?.id) {
            state.listBlockCreated.push({ id: state.nextId, ...data });
            state.nextId += 1;
        } else {
            const idxUpdate = state.listBlockCreated.findIndex((stItem) => stItem.id == editBlock.id);
            state.listBlockCreated[idxUpdate] = data;
        }
    },

    handleUpdateBlockList(state, data) {
        state.listBlockCreated = data;
    },

    handleUpdateMsg(state, data) {
        if (!data || data?.length <= 0) {
            state.listMessageBlocks.forEach((stateItemMsg) => {
                stateItemMsg.messages = stateItemMsg.msg_default;
            });
            return;
        }
        data.forEach((itemData) => {
            const editMsg = state.listMessageBlocks.find((upItem) => upItem.label_id === itemData.label_id);
            if (!editMsg) return;
            editMsg.messages = itemData.messages;
        });
    }
};
