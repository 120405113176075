<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import Swal from 'sweetalert2';
import Footer from '@/components/Footer/main.vue';
import ModalRule from './modal-rule.vue';
import TableListComponent from '@/components/TableList/main.vue';

import { masterMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { configModalRule, initDataForm } from './index';
import { showMessage } from '@/utils/messages';
import { InputRadio } from '@/components/Input';

export default {
    page: {
        title: '規約詳細一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Multiselect,
        KeyWordTag,
        Layout,
        Footer,
        ModalRule,
        InputRadio,
        TableListComponent
    },
    data() {
        return {
            listData: [],
            option: null,
            classification: null,
            listDataMasterOption: [],
            listDataMasterClassic: [],
            dataForm: initDataForm(),
            form: {
                detail: ''
            },
            filterTypes: {
                option: '',
                classification: '',
                is_default: ''
            },
            configKeyword: {
                arrayTag: [],
                placeholder: '条文・細則',
                isShowLable: true
            },
            configTable: {
                headers: [
                    {
                        label: 'No'
                    },
                    {
                        label: '条文・細則'
                    },
                    {
                        label: '配置'
                    },
                    {
                        label: '区分'
                    },
                    {
                        label: 'デフォルト有無'
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            },
            checkShowFilter: false,
            isLoading: false,
            configModalCommonRule: configModalRule
        };
    },
    watch: {
        option: function (value) {
            this.filterTypes.option = value?.id ?? '';
        },
        classification: function (value) {
            this.filterTypes.classification = value?.id ?? '';
        },

        'configKeyword.arrayTag': function (newOld) {
            if (!this.checkShowFilter) {
                this.listTagFreeWord = newOld;
            } else {
                this.listTagKeyWord = newOld;
            }
        }
    },

    mounted() {
        this.getList();
        this.getListMasterOption();
        this.getListMasterClassic();
    },

    methods: {
        ...masterMethods,

        getListMasterOption() {
            this.listMaster('master_rule_detail.option' + '?limit=999').then((data) => {
                this.listDataMasterOption = data;
            });
        },
        getListMasterClassic() {
            this.listMaster('master_rule_detail.classification' + '?limit=999').then((data) => {
                this.listDataMasterClassic = data;
            });
        },
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.isShowLable = false;
                this.configKeyword.arrayTag = this.listTagKeyWord;
            } else {
                this.configKeyword.placeholder = '条文・細則';
                this.configKeyword.arrayTag = this.listTagFreeWord;
                this.configKeyword.isShowLable = true;
            }
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) {
                            query.push(`search[${key}]=${encodeURIComponent(value)}`);
                        }
                    }
                }
                if (this.filterTypes) {
                    for (const [key, value] of Object.entries(this.filterTypes)) {
                        if (key && value !== '') {
                            query.push(`filters[${key}]=${encodeURIComponent(value)}`);
                        }
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        async getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            try {
                const response = await this.listRule(query);
                this.listData = response.data;
                this.configPage.total = response._metadata.total;
                this.configPage.limit = response._metadata.limit * 1;
                const getTypes = response.data.map((item) => item.types);
                if (getTypes.length > 0) {
                    for (let i = 0; i < getTypes.length; i++) {
                        const getNameTypes = this.listDataDepartment.filter((item) => getTypes[i].find((element) => element == item.id));
                        this.listData[i].types = getNameTypes.map((item) => item.value).join(', ');
                    }
                }
                if (!this.listData.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
            }
        },

        search() {
            this.getList();
        },
        clear() {
            this.form = {
                detail: ''
            };
            this.filterTypes = {
                option: '',
                classification: '',
                is_default: ''
            };
            this.option = null;
            this.classification = null;
            this.configKeyword.arrayTag = [];
            this.getList();
        },
        openModalRule() {
            this.configModalCommonRule.model = true;
            this.dataForm = initDataForm();
        },
        editData(data) {
            this.dataForm = JSON.parse(JSON.stringify(data));
            this.configModalCommonRule.id = data.id;
            this.configModalCommonRule.model = true;
        },
        deleteData(item) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteRule2(item).then((data) => {
                        if (data.code !== 200) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                            return;
                        }
                        showMessage(data.code, this.$bvToast, '削除されました。');
                        this.getList();
                    });
                }
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">規約詳細検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>
                                    <div v-if="checkShowFilter">
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">条文・細則</label>
                                            <div class="w-100">
                                                <input id="validationCustom05" v-model="form.detail" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">配置</label>
                                            <div class="w-100">
                                                <Multiselect
                                                    :id="`select-set-option-default`"
                                                    :value.sync="option"
                                                    :options="listDataMasterOption"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'value',
                                                        error: false,
                                                        isRemove: false,
                                                        loading: false
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">区分</label>
                                            <div class="w-100">
                                                <Multiselect
                                                    :id="`select-set-classification`"
                                                    :value.sync="classification"
                                                    :options="listDataMasterClassic"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'value',
                                                        error: false,
                                                        isRemove: false,
                                                        loading: false
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">デフォルト有無</label>
                                            <div class="w-100">
                                                <div class="d-flex">
                                                    <div>
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="filterTypes.is_default"
                                                            id="両方"
                                                            value=""
                                                            :label="`両方`"
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="filterTypes.is_default"
                                                            id="有"
                                                            value="1"
                                                            :label="`有`"
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="filterTypes.is_default"
                                                            id="無"
                                                            value="0"
                                                            :label="`無`"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <h3 class="mb-0 title--screen">
                                規約詳細検索結果 <span>（{{ configPage.total }})</span>
                            </h3>
                            <button @click="openModalRule" class="btn btn-primary">新規登録</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <TableListComponent
                            :config="configTable"
                            :dataList="listData"
                            :isLoading="isLoading"
                            :configPage.sync="configPage"
                            @onChangePage="getList()"
                        >
                            <template #row="{ row, index }">
                                <tr @dblclick="editData(row)">
                                    <td>
                                        <div class="row-data-small">
                                            {{ index }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-large" v-b-tooltip.hover :title="row.detail ?? ''">
                                            {{ row.detail }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.option ? row.option.value : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.classification ? row.classification.value : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.is_default ? '有' : '無' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            <a href="javascript:void(0)" class="text-info border-right-1" @click="editData(row)">編集</a>
                                            <a
                                                v-if="checkPermiss('partner.edit')"
                                                href="javascript:void(0)"
                                                class="text-danger"
                                                @click="deleteData(row)"
                                                >削除</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </TableListComponent>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
        <ModalRule
            :dataRule="dataForm"
            :config="configModalCommonRule"
            :listDataMasterOption="listDataMasterOption"
            :listDataMasterClassic="listDataMasterClassic"
            @refresh="getList()"
        />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
