import i18n from '@/i18n';
import moment from 'moment';

import { isValidDate } from '@/utils/handlers';
import { omit } from 'lodash';

const STR_DATE_TIME = 'YYYY-MM-DD HH:mm';
const STR_DATE = 'YYYY-MM-DD';
const STR_FULL_DATE_TIME_JP = 'YYYY年MM月DD日 HH:mm';
const IMAGE_EXTENSIONS = ['png', 'jped', 'jpg', 'gif', 'bmp', 'tiff', 'svg', 'eps', 'pdf', 'raw', 'nef', 'cr2', 'arw', 'webp', 'heif', 'heic', 'ico'];

function removeWhitespace(valueForm) {
    return valueForm.replace(/\s+/g, ' ').trim();
}

function truncateText(text, lengthCut = 100) {
    if (text?.length > lengthCut) {
        return text.slice(0, lengthCut) + '...';
    }
    return text;
}

function renderLineBreaks(text) {
    if (!text) return '';
    return text?.replace(/\n/g, '<br>');
}

function isNumber(evt) {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
    } else {
        return true;
    }
}

function formatDateOrTimeJP(dateString, type) {
    if (type === 'dateTimeJP') {
        const getTime = dateString ? moment(dateString).format('HH:mm') : '';
        const [year, month, day] = (dateString ? moment(dateString).format(STR_DATE) : '').split('-');
        return `${year}${i18n.t('text.year')}${month}${i18n.t('text.month')}${day}${i18n.t('text.day')} ${getTime}`;
    } else if (type === 'dateJP') {
        const [year, month, day] = dateString.split('-');
        return `${year}${i18n.t('text.year')}${month}${i18n.t('text.month')}${day}${i18n.t('text.day')}`;
    } else if (type === 'fullDateTimeJP') {
        return moment(dateString, STR_DATE_TIME).format(STR_FULL_DATE_TIME_JP);
    }
}

function formatDateOrTime(paramDate, type) {
    if (type === 'dateTime') {
        return paramDate ? moment(paramDate).format('HH:mm') : '';
    } else if (type === 'date') {
        return paramDate ? moment(paramDate).format(STR_DATE) : '';
    }
}

function formatMergeDateAndTime(date, time) {
    if (!date && !time) return '';
    return moment(date + '' + time, STR_DATE_TIME).format(STR_DATE_TIME);
}

function formatDateCommon(dateString, type = null) {
    // type: ['dateJP', 'dateTimeJP', 'date', 'dateTime]
    if (!dateString) return '';
    switch (type) {
        case 'dateJP':
            return formatDateOrTimeJP(dateString, type);
        case 'dateTimeJP':
            return formatDateOrTimeJP(dateString, type);
        case 'fullDateTimeJP':
            return formatDateOrTimeJP(dateString, type);
        case 'date':
            return formatDateOrTime(dateString, type);
        case 'dateTime':
            return formatDateOrTime(dateString, type);
        default:
            return '';
    }
}

function getDateFromFullDate(fullDate) {
    let day = fullDate?.split('-')[2];
    let month = fullDate?.split('-')[1];
    let year = fullDate?.split('-')[0];
    return isValidDate(day + '/' + month + '/' + year);
}

function tranferShortCode(dataShortCode, replacements) {
    let result = dataShortCode;
    if (!result) return '';
    return result.replace(/《(.*?)》/g, (match, p1) => {
        return replacements[p1] || '';
    });
}

function convertTagsToHashTags(tags) {
    if (tags.length <= 0) return '';
    return tags.map((tag) => `#${tag}`);
}

function handleDownFile(path, filename) {
    let nameFile = filename;
    fetch(path)
        .then((resp) => resp.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = nameFile;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        });
}

function cleanConfigFormData(formData) {
    if (!formData) return formData;
    Object.keys(formData).forEach((key) => {
        if (key.startsWith('configField')) {
            formData = omit(formData, [`${key}`]);
        }
        if (typeof formData[key] === 'object' && formData[key] !== null) {
            formData[key] = cleanConfigFormData(formData[key]);
        }
    });
    return formData;
}

function isValidDateTime(dateTimeString) {
    return moment(dateTimeString, STR_DATE_TIME, true).isValid();
}

function isDateRangeYear(fullDate, minYear, maxYear) {
    const minYearStr = minYear.toString();
    let yearPattern = '';
    if (minYear === maxYear) {
        yearPattern = `${minYearStr}`;
    } else {
        let yearsRange = [];
        for (let i = minYear; i <= maxYear; i++) {
            yearsRange.push(i.toString());
        }
        yearPattern = `(${yearsRange.join('|')})`;
    }
    const monthPattern = '(0[1-9]|1[0-2])';
    const dayPattern = '(0[1-9]|[12][0-9]|3[01])';
    const dateRegex = new RegExp(`^${yearPattern}-${monthPattern}-${dayPattern}$`);
    const isValid = dateRegex.test(fullDate);
    if (!isValid) {
        return false;
    }
    const [year, month, day] = fullDate.split('-').map(Number);
    if (year === maxYear) {
        const daysInMonth = new Date(year, month, 0).getDate();
        if (month === 12 && day > daysInMonth) {
            return false;
        }
    }
    return true;
}

function formattedPostalCode(data) {
    if (!data) return '';
    return data.replace(/(\d{3})(\d{4})/, '$1-$2');
}

export {
    STR_DATE_TIME,
    STR_DATE,
    STR_FULL_DATE_TIME_JP,
    IMAGE_EXTENSIONS,
    removeWhitespace,
    truncateText,
    renderLineBreaks,
    isNumber,
    getDateFromFullDate,
    tranferShortCode,
    convertTagsToHashTags,
    handleDownFile,
    cleanConfigFormData,
    isValidDateTime,
    formatDateCommon,
    formatMergeDateAndTime,
    isDateRangeYear,
    formattedPostalCode
};
