import i18n from '@/i18n';
import { isValidDate } from '@/utils/handlers';

function getDateFromFullDate(fullDate) {
    let day = fullDate.split('-')[2];
    let month = fullDate.split('-')[1];
    let year = fullDate.split('-')[0];
    return isValidDate(day + '/' + month + '/' + year);
}

function handleValidate(newDataInitForm) {
    let check = true;
    let errorTab = null;
    if (!newDataInitForm.room_chat || newDataInitForm.room_chat.id === '' || newDataInitForm.room_chat.name === '') {
        check = false;
        newDataInitForm.configRoomChat.error = true;
        newDataInitForm.errorRoomChat = i18n.t('validateField.requiredSelect', {
            field: i18n.t('settingPhone.popUpSectionWork.nameChat')
        });
    } else {
        newDataInitForm.configRoomChat.error = false;
    }

    switch (newDataInitForm.type_send.id) {
        case 1:
            // message to
            if (newDataInitForm.messages.to.length <= 0) {
                check = false;
                newDataInitForm.messages.configSendTo.error = true;
                newDataInitForm.messages.errorSendTo = i18n.t('settingPhone.popUpSectionWork.errorTo');
            } else {
                newDataInitForm.messages.to.forEach((item) => {
                    if (item.chatwork_id === '') {
                        check = false;
                        newDataInitForm.messages.configSendTo.error = true;
                        newDataInitForm.messages.errorSendTo = i18n.t('settingPhone.popUpSectionWork.chatWorkIdNotExist');
                    } else {
                        newDataInitForm.messages.configSendTo.error = false;
                    }
                });
            }
            // message content
            if (newDataInitForm.messages.content === '') {
                check = false;
                newDataInitForm.messages.errorContent = i18n.t('validateField.required', {
                    field: i18n.t('settingPhone.popUpSectionWork.content')
                });
            } else {
                newDataInitForm.messages.errorContent = '';
            }
            break;

        case 2:
            // dua_date
            if (!getDateFromFullDate(newDataInitForm.task.date_task)) {
                newDataInitForm.configTaskDateTime.error = true;
            } else {
                let currentDate = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`);
                if (new Date(newDataInitForm.task.date_task) < currentDate) {
                    newDataInitForm.configTaskDateTime.error = true;
                } else {
                    newDataInitForm.configTaskDateTime.error = false;
                }
                if (newDataInitForm.task.time_task) {
                    let inputDateTime = new Date(newDataInitForm.task.date_task + ' ' + newDataInitForm.task.time_task);
                    let currentDateTime = new Date(
                        `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`
                    );
                    if (inputDateTime < currentDateTime) {
                        newDataInitForm.configTaskDateTime.error = true;
                    } else {
                        newDataInitForm.configTaskDateTime.error = false;
                    }
                }
            }

            if (newDataInitForm.configTaskDateTime.error) {
                check = false;
                if (newDataInitForm.task.date_task) {
                    newDataInitForm.configTaskDateTime.errorTimeTask = i18n.t('settingPhone.popUpSectionWork.timeTaskLessTimeCurrent');
                } else if (newDataInitForm.task.date_task && newDataInitForm.task.time_task) {
                    newDataInitForm.configTaskDateTime.errorTimeTask = i18n.t('settingPhone.popUpSectionWork.timeTaskLessTimeCurrent');
                } else {
                    newDataInitForm.configTaskDateTime.errorTimeTask = i18n.t('validateField.required', {
                        field: i18n.t('settingPhone.popUpSectionWork.timeTask')
                    });
                }
            }

            // task to
            if (newDataInitForm.task.to.length <= 0) {
                check = false;
                newDataInitForm.task.configSendTo.error = true;
                newDataInitForm.task.errorSendTo = i18n.t('settingPhone.popUpSectionWork.errorTo');
            } else {
                newDataInitForm.task.to.forEach((item) => {
                    if (item.chatwork_id === '') {
                        check = false;
                        newDataInitForm.task.configSendTo.error = true;
                        newDataInitForm.task.errorSendTo = i18n.t('settingPhone.popUpSectionWork.chatWorkIdNotExist');
                    } else {
                        newDataInitForm.task.configSendTo.error = false;
                    }
                });
            }

            // task content
            if (newDataInitForm.task.content === '') {
                check = false;
                newDataInitForm.task.errorContent = i18n.t('validateField.required', {
                    field: i18n.t('settingPhone.popUpSectionWork.content')
                });
            } else {
                newDataInitForm.task.errorContent = '';
            }
            break;
        case 3:
            // message to
            if (newDataInitForm.messages.to.length <= 0) {
                check = false;
                newDataInitForm.messages.configSendTo.error = true;
                newDataInitForm.messages.errorSendTo = i18n.t('settingPhone.popUpSectionWork.errorTo');
            } else {
                newDataInitForm.messages.to.forEach((item) => {
                    if (item.chatwork_id === '') {
                        check = false;
                        newDataInitForm.messages.configSendTo.error = true;
                        newDataInitForm.messages.errorSendTo = i18n.t('settingPhone.popUpSectionWork.chatWorkIdNotExist');
                    } else {
                        newDataInitForm.messages.configSendTo.error = false;
                    }
                });
            }

            // message content
            if (newDataInitForm.messages.content === '') {
                check = false;
                newDataInitForm.messages.errorContent = i18n.t('validateField.required', {
                    field: i18n.t('settingPhone.popUpSectionWork.content')
                });
            } else {
                newDataInitForm.messages.errorContent = '';
            }

            // dua_date
            if (!getDateFromFullDate(newDataInitForm.task.date_task)) {
                newDataInitForm.configTaskDateTime.error = true;
            } else {
                let currentDate = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`);
                if (new Date(newDataInitForm.task.date_task) < currentDate) {
                    newDataInitForm.configTaskDateTime.error = true;
                } else {
                    newDataInitForm.configTaskDateTime.error = false;
                }
                if (newDataInitForm.task.time_task) {
                    let inputDateTime = new Date(newDataInitForm.task.date_task + ' ' + newDataInitForm.task.time_task);
                    let currentDateTime = new Date(
                        `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`
                    );
                    if (inputDateTime < currentDateTime) {
                        newDataInitForm.configTaskDateTime.error = true;
                    } else {
                        newDataInitForm.configTaskDateTime.error = false;
                    }
                }
            }

            if (newDataInitForm.configTaskDateTime.error) {
                check = false;
                if (newDataInitForm.task.date_task) {
                    newDataInitForm.configTaskDateTime.errorTimeTask = i18n.t('settingPhone.popUpSectionWork.timeTaskLessTimeCurrent');
                } else if (newDataInitForm.task.date_task && newDataInitForm.task.time_task) {
                    newDataInitForm.configTaskDateTime.errorTimeTask = i18n.t('settingPhone.popUpSectionWork.timeTaskLessTimeCurrent');
                } else {
                    newDataInitForm.configTaskDateTime.errorTimeTask = i18n.t('validateField.required', {
                        field: i18n.t('settingPhone.popUpSectionWork.timeTask')
                    });
                }
            }

            // task to
            if (newDataInitForm.task.to.length <= 0) {
                check = false;
                newDataInitForm.task.configSendTo.error = true;
                newDataInitForm.task.errorSendTo = i18n.t('settingPhone.popUpSectionWork.errorTo');
            } else {
                newDataInitForm.task.to.forEach((item) => {
                    if (item.chatwork_id === '') {
                        check = false;
                        newDataInitForm.task.configSendTo.error = true;
                        newDataInitForm.task.errorSendTo = i18n.t('settingPhone.popUpSectionWork.chatWorkIdNotExist');
                    } else {
                        newDataInitForm.task.configSendTo.error = false;
                    }
                });
            }

            // task content
            if (newDataInitForm.task.content === '') {
                check = false;
                newDataInitForm.task.errorContent = i18n.t('validateField.required', {
                    field: i18n.t('settingPhone.popUpSectionWork.content')
                });
            } else {
                newDataInitForm.task.errorContent = '';
            }

            if (newDataInitForm.messages.configSendTo.error || newDataInitForm.messages.errorContent) {
                errorTab = 'message';
            } else if (newDataInitForm.task.configSendTo.error || newDataInitForm.configTaskDateTime.error) {
                errorTab = 'task';
            }
            break;
    }

    return {
        check,
        errorTab
    };
}
export { handleValidate };
