<script>
import { required, email } from 'vuelidate/lib/validators';

import { authFackMethods, notificationMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

export default {
    data() {
        return {
            email: '',
            submitted: false,
            loading: false
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        document.body.classList.add('auth-body-bg');
    },
    validations: {
        email: { required, email }
    },
    methods: {
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            if (this.loading) return;
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                if (email) {
                    this.loading = true;
                    this.forgot(this.email).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/login/' }).then(() => {
                                showMessage(data.code, this.$bvToast, 'パスワードを変更するには、ご登録されたメールを確認してください。');
                            });
                        }
                        if (data.code == 422) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    });
                }
            }
        }
    }
};
</script>

<template>
    <div>
        <div>
            <div class="container-fluid p-0" style="background-color: #f9fafb">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="authentication-page-content min-vh-100 d-flex align-items-center justify-content-center">
                            <div class="d-flex align-items-center justify-content-center flex-wrap-reverse">
                                <div class="form-block_account">
                                    <div class="col-md-12 text-center mb-3">
                                        <img src="@/assets/images/logo-paldia.png" alt="Image" class="img-fluid" />
                                    </div>
                                    <div class="mb-4">
                                        <h3>パスワードを忘れた方</h3>
                                    </div>
                                    <form class="needs-validation" @submit.prevent="tryToLogIn" autocomplete="off">
                                        <div class="form-group auth-form-group-custom mb-4">
                                            <i class="ri-mail-line auti-custom-input-icon align-middle mr-1"></i>
                                            <label for="email">メールアドレス <span class="text-danger">*</span> </label>
                                            <input
                                                type="email"
                                                v-model="email"
                                                class="form-control"
                                                id="email"
                                                placeholder="メールアドレス入力"
                                                :class="{ 'is-invalid': submitted && $v.email.$error }"
                                            />
                                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                                <span v-if="!$v.email.required">メールアドレスを入力してください。</span>
                                                <span v-if="!$v.email.email">メールアドレスの形式は正しくありません。</span>
                                            </div>
                                        </div>

                                        <div class="mt-4 text-center">
                                            パスワードを覚えましたか？
                                            <router-link tag="a" to="/login" class="text-danger">
                                                <i class="mdi mdi-lock mr-1"></i> ログイン
                                            </router-link>
                                        </div>

                                        <div class="mt-4 text-center">
                                            <button class="btn btn-pill text-white btn-block btn-primary" type="submit">送信</button>
                                        </div>

                                        <span class="d-block text-center my-4 text-muted"> or sign in with</span>

                                        <div class="social-login text-center">
                                            <a href="#" class="facebook">
                                                <span class="icon-facebook mr-3"><i class="ri-facebook-circle-fill"></i></span>
                                            </a>
                                            <a href="#" class="twitter">
                                                <span class="icon-twitter mr-3"><i class="ri-twitter-fill"></i></span>
                                            </a>
                                            <a href="#" class="google">
                                                <span class="icon-google mr-3"><i class="ri-google-fill"></i></span>
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
