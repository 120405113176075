<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingBasic.nameCampaign') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-campaign-for-form-page`"
                            :value.sync="dataForm.campaign"
                            :options="listDataMasterCampaign"
                            :config.sync="configForm.configFieldSelectCampaign"
                            @select="handleSelectCampaign"
                            @remove="handleRemoveSelectCampaign(configForm.configFieldSelectCampaign)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.nameClient') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-client-for-form-page`"
                            :value.sync="dataForm.client"
                            :options="listClientsCP"
                            :config.sync="configForm.configFieldSelectClient"
                            @open="getDetailCampain(configForm.configFieldSelectClient, dataForm.campaign?.id)"
                            @remove="handleValidateField(configForm.configFieldSelectClient)"
                        />
                    </div>
                </div>

                <!-- フォーム名 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingBasic.nameForm') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.name"
                            :config.sync="configForm.configFieldNameForm"
                            @blur="handleValidateField(configForm.configFieldNameForm)"
                        />
                    </div>
                </div>
                <!-- chọn kiểu  -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingPage.pageType') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-page`"
                            :value.sync="dataForm.page_type"
                            :options="listPage"
                            :config="configForm.configFieldSelectPage"
                            :disabled="isQueryId"
                            @remove="handleValidateField(configForm.configFieldSelectPage)"
                        />
                    </div>
                </div>

                <!-- ステータス -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.status') }} </label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div v-for="statusItem in STATUS_FORM" :key="statusItem.id + 's'">
                                <InputRadio
                                    class="mr-4 mb-3"
                                    :model.sync="dataForm.status"
                                    :name="`statusItem`"
                                    :id="`statusItem-${statusItem.id}`"
                                    :value="statusItem.id"
                                    :label="statusItem.value"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 公開パス -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingBasic.publicPath') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.url_public"
                            :config.sync="configForm.configFieldPublicPath"
                            @blur="handleValidateField(configForm.configFieldPublicPath)"
                        />
                    </div>
                </div>
                <!-- URLパラメータ -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('managerForm.settingBasic.urlParam') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <div v-for="(urlP, index) in dataForm.url_parameters" :key="index" class="mb-3">
                            <div class="d-flex mb-3">
                                <InputText
                                    v-if="configForm.validateArrayURL[index]?.configFieldUrl"
                                    class="flex-fill"
                                    style="min-width: 150px"
                                    :id="`${index}-url`"
                                    :model.sync="urlP.url_parameter"
                                    :config.sync="configForm.validateArrayURL[index].configFieldUrl"
                                    @blur="handleValidateField(configForm.validateArrayURL[index].configFieldUrl)"
                                />
                                <div class="col-sm-2">
                                    <div class="d-flex" style="gap: 12px">
                                        <button type="button" class="btn btn-outline-info btn-sm rounded-circle" @click="handleAddURL()">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-outline-danger btn-sm rounded-circle"
                                            :disabled="dataForm.url_parameters.length == 1"
                                            @click="handleDeleteURL(index)"
                                        >
                                            <i class="fa fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 指定応募数 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.limitPersonApplication') }} </label>
                    <div class="col-sm-9">
                        <!-- 制限件数 -->
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.limitNumber') }} </label>
                            <div class="col-sm-9">
                                <div class="d-flex">
                                    <InputText
                                        class="mr-2"
                                        :model.sync="dataForm.application_limit"
                                        :config.sync="configForm.configFieldLimitNumber"
                                        @blur="handleValidateField(configForm.configFieldLimitNumber)"
                                    />
                                    <span class="mt-2">件</span>
                                </div>
                            </div>
                        </div>
                        <!-- 超過時リダイレクトURL -->
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 180px">{{ $t('managerForm.settingBasic.redirectURL') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.url_redirect_when_limit"
                                    :config.sync="configForm.configFieldRedirectURL"
                                    @blur="handleValidateField(configForm.configFieldRedirectURL)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 公開予約中表示URL -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.urlPublicReservation') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.reservation_display_url"
                            :config.sync="configForm.configFieldUrlPublicReservation"
                            @blur="handleValidateField(configForm.configFieldUrlPublicReservation)"
                        />
                    </div>
                </div>
                <!-- 公開終了後表示URL -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.urlAfterPublicationEnd') }} </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.expired_display_url"
                            :config.sync="configForm.configFieldUrlAfterPublicationEnd"
                            @blur="handleValidateField(configForm.configFieldUrlAfterPublicationEnd)"
                        />
                    </div>
                </div>
                <!-- 送信先メールアドレス -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.sendMailed') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.mail_to"
                            :config.sync="configForm.configFieldSendMailed"
                            @blur="handleValidateField(configForm.configFieldSendMailed)"
                        />
                    </div>
                </div>
                <!-- フォームの一時保存 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.saveTemp') }} </label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div v-for="item in TEMPORARILY" :key="item.id + 's'">
                                <InputRadio
                                    class="mr-4 mb-3"
                                    :model.sync="dataForm.save_form_temporarily"
                                    :name="`save-temp`"
                                    :id="`save-temp-${item.id}`"
                                    :value="item.id"
                                    :label="item.value"
                                />
                            </div>
                            <div class="form-group row flex-fill">
                                <label class="col-sm-1 text-nowrap" style="min-width: fit-content"
                                    >{{ $t('managerForm.settingBasic.dateOfExpiry') }}
                                </label>
                                <div class="col-sm-8">
                                    <InputText
                                        style="min-width: 100px"
                                        :model.sync="dataForm.temporary_expiration_date"
                                        :config.sync="configForm.configFieldDateOfExpiry"
                                        @blur="handleValidateField(configForm.configFieldDateOfExpiry)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ソーシャルログイン設置 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 180px">{{ $t('managerForm.settingBasic.settingLoginSocial') }} </label>
                    <div class="col-sm-9">
                        <div v-for="(social, index) in SOCIAL_MEDIA_LOGIN" :key="index" class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ social?.name ?? '' }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="social.value"
                                    :config.sync="configForm.validateArraySocial[index].configField"
                                    @blur="handleValidateField(configForm.validateArraySocial[index].configField)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- マイページ機能設置 -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.settingMypage') }} </label>
                    <div class="col-sm-9">
                        <InputRadio :model.sync="dataForm.setting_my_page" :id="`setting_my_page`" :value="true" :label="``" />
                    </div>
                </div>
                <!-- TAG -->
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingBasic.tag') }}</label>
                    <div class="col-sm-9">
                        <KeyWordTag2
                            :id="`tag-keywords`"
                            :model.sync="dataForm.hashtag"
                            :config.sync="configForm.configFieldTag"
                            @blur="handleValidateField(configForm.configFieldTag)"
                        >
                            <span v-if="configForm.configFieldTag.error">
                                {{ configForm.configFieldTag.errorCustomMess }}
                            </span>
                        </KeyWordTag2>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { InputText, InputRadio } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { TEMPORARILY, SOCIAL_MEDIA_LOGIN, STATUS_FORM } from '../../constants';
import { masterMethods, campaignMethods } from '@/state/helpers';
import { handleAddConfigURL } from '../../handlers';

import Multiselect from '@/components/Multiselect/main.vue';
import KeyWordTag2 from '@/components/KeywordTag/main2.vue';

export default {
    name: 'SettingBasic',
    components: {
        InputText,
        InputRadio,
        Multiselect,
        KeyWordTag2
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        },
        listPage: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            TEMPORARILY: TEMPORARILY,
            STATUS_FORM: STATUS_FORM,
            SOCIAL_MEDIA_LOGIN: this.dataForm?.setting_login_social?.length > 0 ? this.dataForm.setting_login_social : SOCIAL_MEDIA_LOGIN,
            listDataMasterCampaign: [],
            listClientsCP: []
        };
    },
    computed: {
        isQueryId() {
            return this.$route.query?.id ? true : false;
        }
    },
    methods: {
        ...masterMethods,
        ...campaignMethods,

        handleSelectCampaign() {
            this.dataForm.client = null;
        },

        handleRemoveSelectCampaign(configFieldSelectCampaign) {
            this.dataForm.client = null;
            this.listClientsCP = [];
            this.handleValidateField(configFieldSelectCampaign);
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddURL() {
            this.dataForm.url_parameters.push({ url_parameter: '' });
            this.configForm.validateArrayURL = handleAddConfigURL(this.configForm.validateArrayURL, this.dataForm.url_parameters);
        },

        handleDeleteURL(index) {
            this.dataForm.url_parameters.splice(index, 1);
            this.configForm.validateArrayURL = handleAddConfigURL(this.configForm.validateArrayURL, this.dataForm.url_parameters);
        },

        async getMasterCampaign() {
            this.isLoading = true;
            try {
                const dataRes = await this.listMaster('all/campaigns');
                this.listDataMasterCampaign = dataRes;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
        },

        async getDetailCampain(configFieldSelectClient, idCampaign) {
            if (!idCampaign || configFieldSelectClient.loading) return;
            this.listClientsCP = [];
            configFieldSelectClient.loading = true;
            try {
                const dataRespone = await this.detailCampaign(idCampaign);
                this.listClientsCP = dataRespone?.clients
                    ?.map((o) => o.client)
                    .filter((item, index, self) => {
                        return self.findIndex((i) => i.name === item.name) === index;
                    });
                console.log('idCampaign', this.listClientsCP);
            } catch (error) {
                console.log('error', error);
            } finally {
                configFieldSelectClient.loading = false;
            }
        }
    },
    mounted() {
        this.configForm.validateArrayURL = handleAddConfigURL(this.configForm.validateArrayURL, this.dataForm.url_parameters);
        this.getMasterCampaign();
    }
};
</script>

<style lang="scss" scoped></style>
