<template>
    <div>
        <ModalCommon id="ModalSendMail" :config="configModal" @save="handleSave()" @close="handleClose()">
            <div v-if="joinEmailShow" class="normal-message-container">
                <div class="normal-message d-flex">
                    <i class="fa fa-info-circle"></i>
                    <p class="mb-0">
                        {{ `「${joinEmailShow}」にメールを送信しました。` }}
                    </p>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-3 text-nowrap" style="min-width: 150px">
                    <div class="d-flex">
                        <div>
                            <p class="mb-0" style="font-weight: 600">{{ $t('managerForm.settingPage.testPreviewURL') }}</p>
                            <p class="mb-0" style="font-weight: 600">{{ $t('managerForm.settingPage.sestinationEmailAddress') }}</p>
                        </div>
                        <span class="text-danger">*</span>
                    </div>
                </div>
                <div class="col-sm-9">
                    <div v-for="(itemMail, idx) in listEmailSend" :key="idx">
                        <div class="d-flex w-100 mb-3">
                            <InputText
                                class="col-sm-10"
                                :id="`email-${idx}`"
                                :model.sync="itemMail.email_link"
                                :config.sync="config[idx].configFieldEmailLink"
                                @blur="handleValidateField(config[idx].configFieldEmailLink)"
                            />
                            <div class="ml-auto">
                                <button type="button" class="btn btn-outline-info btn-sm rounded-circle mr-3" @click="handleAddEmailLink()">
                                    <i class="fa fa-plus"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-outline-danger btn-sm rounded-circle"
                                    :disabled="listEmailSend.length == 1"
                                    @click="handleDeleteEmailLink(idx)"
                                >
                                    <i class="fa fa-minus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';

import { InputText } from '@/components/Input';
import { validateField, validateAllField } from '@/utils/validate';

export default {
    name: 'ModalSendMailed',
    components: {
        ModalCommon,
        InputText
    },
    props: {
        configModal: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            joinEmailShow: '',
            listEmailSend: [{ email_link: '' }],
            config: [
                {
                    configFieldEmailLink: {
                        isNumber: false,
                        isMaxLength: false,
                        error: false,
                        errorField: this.$t('managerForm.settingPage.sestinationEmailAddress'),
                        errorCustomMess: '',
                        valueSync: '',
                        typesValidate: ['required', 'max255', 'email']
                    }
                }
            ]
        };
    },
    methods: {
        handleSave() {
            let check = validateAllField(this.config);
            if (!check) return;
            this.joinEmailShow = this.listEmailSend.map((emailItem) => emailItem.email_link).join('、');
        },

        handleClose() {
            this.listEmailSend = [{ email_link: '' }];
            this.joinEmailShow = '';
            this.configModal.model = false;
            this.onHandleMatchingConfig('reset');
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddEmailLink() {
            this.listEmailSend.push({ email_link: '' });
            this.onHandleMatchingConfig();
        },

        handleDeleteEmailLink(index) {
            this.listEmailSend.splice(index, 1);
            this.onHandleMatchingConfig();
        },

        onHandleMatchingConfig(typeAction = null) {
            if (typeAction == 'reset') {
                this.config = this.listEmailSend.map(() => {
                    return {
                        configFieldEmailLink: {
                            isRequired: false,
                            isEmail: false,
                            error: false,
                            errorField: this.$t('managerForm.settingPage.sestinationEmailAddress'),
                            valueSync: '',
                            typesValidate: ['required', 'max255', 'email']
                        }
                    };
                });
            } else {
                this.config = this.listEmailSend.map((itemEmail, idx) => {
                    let configExist = this.config[idx]?.configFieldEmailLink;
                    return {
                        configFieldEmailLink: {
                            isRequired: configExist?.isRequired ?? false,
                            isEmail: configExist?.isEmail ?? false,
                            error: configExist?.error ?? false,
                            errorField: configExist?.errorField ?? this.$t('managerForm.settingPage.sestinationEmailAddress'),
                            valueSync: itemEmail?.email_link ?? '',
                            typesValidate: configExist?.typesValidate ?? ['required', 'max255', 'email']
                        }
                    };
                });
            }
        }
    },
    mounted() {
        this.onHandleMatchingConfig();
    }
};
</script>

<style lang="scss" scoped>
.normal-message-container {
    border: #87acf9 1px solid;
    padding: 5px 20px;
    margin: 30px 0;
    .normal-message {
        padding: 0 0 0 40px;
        font-size: 12px;
        color: #507bd6;
        line-height: 26px;
        margin: 6px 0;
        font-weight: 700;
        & i {
            font-size: 25px;
            vertical-align: text-bottom;
        }
        & p {
            word-break: break-all;
        }
    }
}
</style>
