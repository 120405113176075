function regexNumber(zipCode) {
    return zipCode.replace(/-{2,}/g, '-').replace(/[^0-9-]/g, '');
}

/* eslint-disable no-useless-escape */
function regexUrl(url) {
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    return expression.test(url);
}

function regexEmail(email) {
    const re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function regexPassword(password) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
    return passwordRegex.test(password);
}

function regexKatakana(katakana) {
    const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;
    return katakanaRegex.test(katakana);
}

function regexNumberWithHyphens(input) {
    const numberWithHyphensRegex = /^[\d-]+$/;
    return numberWithHyphensRegex.test(input);
}

function regexSubdomain(subdomain) {
    const isSubdomain = /^(?!-)[a-z0-9-]+(?<!-)$/;
    return isSubdomain.test(subdomain);
}

function regexHalfWidthAlphanumeric(input) {
    const regex = /^[A-Za-z0-9]*$/;
    return regex.test(input);
}

function regexFullWidthCharacters(input) {
    const regex = /[\uFF01-\uFF60\uFFE0-\uFFE6\u4E00-\u9FAF\u3040-\u309F\u30A0-\u30FF]/;
    return regex.test(input);
}

function regexHiragana(input) {
    const regex = /^[\u3040-\u309F]+$/;
    return regex.test(input);
}

function regexFullWidthKatakana(input) {
    const regex = /^[\u30A0-\u30FF]+$/;
    return regex.test(input);
}

function regexHalfWidthKatakana(input) {
    const regex = /^[\uFF65-\uFF9F]+$/;
    return regex.test(input);
}

function regexDynamicRange(input, min, max) {
    const regex = /^-?\d+$/;
    if (!min || !max) {
        return regex.test(input);
    }
    if (!regex.test(input)) return false;
    const number = parseInt(input, 10);
    return number >= min && number <= max;
}

function createNumberRegex(maxIntegerDigits, maxDecimalDigits) {
    return new RegExp(`^-?\\d{1,${maxIntegerDigits}}(\\.\\d{1,${maxDecimalDigits}})?$`);
}

function regexNumberIntDecimal(input, maxIntegerDigits = 10, maxDecimalDigits = 5) {
    const regex = createNumberRegex(maxIntegerDigits, maxDecimalDigits);
    return regex.test(input);
}

export {
    regexNumber,
    regexUrl,
    regexPassword,
    regexKatakana,
    regexNumberWithHyphens,
    regexEmail,
    regexSubdomain,
    regexHalfWidthAlphanumeric,
    regexFullWidthCharacters,
    regexHiragana,
    regexFullWidthKatakana,
    regexHalfWidthKatakana,
    regexDynamicRange,
    regexNumberIntDecimal
};
