<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import TabPrizeView from './components/TabPrizeView/main.vue';
import TabTools from './components/TabTools/main.vue';
import TabClients from './components/TabClients/main.vue';
import TabSecretariat from './components/TabSecretariat/main.vue';
import TabResultRegistration from './components/TabResultRegistration/main.vue';
import TabHistories from './components/TabHistories/main.vue';
import TabBasic from './components/TabBasic/main.vue';
import ModalScopeOrder from '@/components/Campaign/InformationBasic/components/ModalScopeOrder/main.vue';
import { campaignMethods, masterMethods, prizeMethods, employeeMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { keyMaster } from '@/config/constants';
import { showMessage } from '@/utils/messages';
import { campaignStatus, listContactInformations } from '@/config/var-common';
import { handleMapDataPrizes } from '@/components/Campaign/TabPrize/index';
import { handleDataDetailView, getCurrentDateTime, mapDataCampaignsCopy } from './index';
import { FORM_CAMPAIGN_DETAIL } from './constants';

export default {
    page: {
        title: 'キャンペーン詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer,
        TabPrizeView,
        ModalScopeOrder,
        TabTools,
        TabClients,
        TabSecretariat,
        TabResultRegistration,
        TabHistories,
        TabBasic
    },
    data() {
        return {
            saleStaffObject: {},
            teamObject: {},
            personInChargeObject: {},
            progressStatus: {},
            listDataMasterConditionPurchase1: [],
            purchase_conditions: {},
            listCategories: [],
            listCategoriesSub: [],
            contact_informations: {},
            listItemPersons: [],
            loading: false,
            configKeyword: {
                error: false,
                arrayTag: []
            },
            id: this.$route.query.id,
            form: FORM_CAMPAIGN_DETAIL,
            listContactInformations: listContactInformations,
            listHistory: [],
            listDataMasterCampaignType: [],
            listDataMasterCampaignTypeChild: [],
            customerTeamPic: null,

            //
            listDataMasterOrderScope: [],
            isShowReceiptExpirationDate: false,
            isShowConditionBuyAndSell: false,
            isShowPublicTime: false,
            subDataCustomerTeamPic: [null],
            isBookmarkCampaign: false,
            hasLoadingBookmark: false,
            hasShowModalScope: false
        };
    },
    async mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('user'));
        if (this.$route.query.id) {
            this.handleGetMasterOrderScope();
            this.getListHistory(this.$route.query.id);
            await this.getListMasterCampaignType();
            await this.getListMasterConditionPurchase1();
            await this.getDetail(this.$route.query.id);
        }
    },

    methods: {
        checkPermission,
        ...prizeMethods,
        ...masterMethods,
        ...campaignMethods,
        ...employeeMethods,

        async handleGetMasterOrderScope() {
            try {
                const resultMasterOrder = await this.getMasterOrderScope();
                if (resultMasterOrder.code !== 200) return;
                this.listDataMasterOrderScope = resultMasterOrder.data;
            } catch (error) {
                console.log('error', error);
            }
        },

        async getListHistory(id) {
            let query = [`page=1`, `limit=999`, `sorts[id]=desc`, `filters[record_id]=${id}`, `filters[form_slug]=campaign`];
            try {
                const response = await this.histories(query.join('&'));
                this.listHistory = response.data;
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },

        async getListMasterConditionPurchase1() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['campaign.purchase_condition']);
                this.listDataMasterConditionPurchase1 = data;
            } catch (error) {
                console.log('error', error);
            }
        },

        async getListMasterCampaignType() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['campaign.type']);
                this.listDataMasterCampaignType = data;
            } catch (error) {
                console.log('error', error);
            }
        },

        async getDetail(id) {
            this.loading = true;
            try {
                const data = await this.detailCampaign(id);
                const resultDataViews = handleDataDetailView(
                    data,
                    this.isBookmarkCampaign,
                    this.userInfo,
                    listContactInformations,
                    this.contact_informations,
                    this.listDataMasterConditionPurchase1,
                    this.purchase_conditions,
                    campaignStatus,
                    this.progressStatus,
                    this.teamObject,
                    this.saleStaffObject,
                    this.personInChargeObject,
                    this.form,
                    this.configKeyword,
                    this.listItemPersons,
                    this.listDataMasterCampaignTypeChild,
                    this.listDataMasterCampaignType,
                    this.isShowReceiptExpirationDate,
                    this.listCategories,
                    this.listCategoriesSub,
                    this.customerTeamPic,
                    this.subDataCustomerTeamPic,
                    this.isShowPublicTime
                );
                this.isBookmarkCampaign = resultDataViews.isBookmarkCampaign;
                this.contact_informations = resultDataViews.contact_informations;
                this.purchase_conditions = resultDataViews.purchase_conditions;
                this.progressStatus = resultDataViews.progressStatus;
                this.teamObject = resultDataViews.teamObject;
                this.saleStaffObject = resultDataViews.saleStaffObject;
                this.personInChargeObject = resultDataViews.personInChargeObject;
                this.form = resultDataViews.form;
                this.configKeyword.arrayTag = resultDataViews.configKeyword.arrayTag;
                this.listItemPersons = resultDataViews.listItemPersons;
                this.listDataMasterCampaignTypeChild = resultDataViews.listDataMasterCampaignTypeChild;
                this.isShowReceiptExpirationDate = resultDataViews.isShowReceiptExpirationDate;
                this.listCategories = resultDataViews.listCategories;
                this.listCategoriesSub = resultDataViews.listCategoriesSub;
                this.customerTeamPic = resultDataViews.customerTeamPic;
                this.subDataCustomerTeamPic = resultDataViews.subDataCustomerTeamPic;
                this.isShowPublicTime = resultDataViews.isShowPublicTime;

                this.handleShowConditionBuyAndSell();
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.log('error', error);
            }
        },

        goToEdit(path, id, partner_id) {
            this.$router.push({ path: path, query: { id: id, partner_id: partner_id } });
        },

        deleteObject(data) {
            Swal.fire({
                title: 'このキャンペーンを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.value) {
                    this.deleteCampaign(data).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/campaign/list' }).then(() => {
                                showMessage(data.code, this.$bvToast, 'キャンペーンが削除されました。');
                            });
                        }
                    });
                }
            });
        },

        handleOpenScopeOrder() {
            this.hasShowModalScope = true;
        },

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        handleLockCampaign() {
            this.lockCampaign(this.$route.query.id).then((data) => {
                if (data.code == 200) {
                    showMessage(data.code, this.$bvToast, 'このキャンペーンはロックされました。');
                    this.getDetail(this.$route.query.id);
                }
            });
        },

        handleUnLockCampaign() {
            this.unLockCampaign(this.$route.query.id).then((data) => {
                if (data.code == 200) {
                    showMessage(data.code, this.$bvToast, 'ロックが解除されました。');
                    this.getDetail(this.$route.query.id);
                }
            });
        },

        handleApproveCampaign() {
            this.approveCampaign(this.$route.query.id).then((data) => {
                if (data.code == 200) {
                    this.getDetail(this.$route.query.id);
                }
            });
        },

        handleCopyCampaign() {
            if (this.form.name) {
                let object = JSON.parse(JSON.stringify(this.form));
                object.name = object.name + ' Copy ' + getCurrentDateTime(new Date());
                let content_projects = [];
                if (object.content_projects) {
                    object.content_projects.forEach((item) => {
                        content_projects.push(item.id);
                    });
                }
                object.content_projects = content_projects;
                if (object.prizes && object.prizes.length > 0) {
                    object.prizes.forEach((item, k) => {
                        let digital_gifts = {};
                        if (item.digital_gifts && item.digital_gifts[0] && item.digital_gifts[0].id) {
                            let not = JSON.parse(item.digital_gifts[0].extension);
                            digital_gifts[item.digital_gifts[0].id] = {
                                extension: {
                                    value_note: not.value_note
                                }
                            };
                        }
                        object.prizes[k].digital_gifts = digital_gifts;
                    });
                }
                object.event_prizes = handleMapDataPrizes(object.event_prizes, 'copy');

                let objectData = mapDataCampaignsCopy(
                    object,
                    this.configKeyword,
                    this.listItemPersons,
                    object.prizes,
                    this.purchase_conditions,
                    this.contact_informations,
                    this.listCategoriesSub,
                    this.subDataCustomerTeamPic
                );
                if (this.loadingExcute) return;
                this.loadingExcute = true;
                this.copyCampaign(objectData).then((data) => {
                    this.loadingExcute = false;
                    if (data.code == 200) {
                        this.$router.push({ path: '/campaign/list' });
                    } else {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                    }
                });
            }
        },

        handleShowConditionBuyAndSell() {
            if (!this.form.campaign_type || this.form.campaign_type_name !== this.$t('pageCampaign.closeCp')) return;

            this.dataApplycation2Type1 = this.listCategoriesSub.map((item) => item.listCondition2).flat();
            this.listIDSelectApplycation2Type1 = this.listCategoriesSub
                .map((item) => item.application_condition_2_id)
                .flat()
                .map(Number);

            const listSelected = this.dataApplycation2Type1.filter((itemAPT2) => this.listIDSelectApplycation2Type1.includes(itemAPT2.id));
            if (!listSelected.length) {
                this.isShowConditionBuyAndSell = false;
            } else {
                const listValue = listSelected.map((itemSelected) => itemSelected.value);
                this.isShowConditionBuyAndSell = listValue.includes('WEB明細') || listValue.includes('レシート（購買証明）');
            }
        },

        async handleSetBookmarkCampaign() {
            if (this.hasLoadingBookmark) return;
            this.hasLoadingBookmark = true;
            this.isBookmarkCampaign = true;
            try {
                this.userInfo.campaign_bookmarks.push({ id: this.form.id, name: this.form.name });
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.setBookmarkCampaign(this.form.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.hasLoadingBookmark = false;
            }
        },
        async handleUnsetBookmarkCampaign() {
            if (this.hasLoadingBookmark) return;
            this.hasLoadingBookmark = true;
            this.isBookmarkCampaign = false;
            try {
                this.userInfo.campaign_bookmarks = this.userInfo.campaign_bookmarks.filter((item) => item.id !== this.form.id);
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.unsetBookmarkCampaign(this.form.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.hasLoadingBookmark = false;
            }
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll1 mb-0">
                    <div class="card-header">
                        <h5>{{ form.name }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form class="needs-validation" autocomplete="off">
                                    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                                        <b-tab active class="border-0">
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="fas fa-home"></i>
                                                </span>
                                                <span class="">基本情報</span>
                                            </template>
                                            <div>
                                                <TabBasic
                                                    :dataForm="form"
                                                    :progressStatus="progressStatus"
                                                    :isShowReceiptExpirationDate="isShowReceiptExpirationDate"
                                                    :dataMTChild="listDataMasterCampaignTypeChild"
                                                    :dataCategories="listCategories"
                                                    :dataCategoriesSub="listCategoriesSub"
                                                    :isShowConditionBuyAndSell="isShowConditionBuyAndSell"
                                                    :dataMTPurchase1="listDataMasterConditionPurchase1"
                                                    :isShowPublicTime="isShowPublicTime"
                                                    :dataContactInfo="listContactInformations"
                                                    :contactInformations="contact_informations"
                                                    :purchaseConditions="purchase_conditions"
                                                    @openScope="handleOpenScopeOrder"
                                                    @approveCampaign="handleApproveCampaign"
                                                    @lockCampaign="handleLockCampaign"
                                                    @unLockCampaign="handleUnLockCampaign"
                                                    @copyCampaign="handleCopyCampaign"
                                                />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block" style="min-width: max-content"
                                                    >受注範囲＆パートナー＆ツール</span
                                                >
                                            </template>
                                            <div>
                                                <TabTools :dataTools="form.campaign_master_order_scope_partner" />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-user"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">お客様情報</span>
                                            </template>
                                            <div>
                                                <TabClients :dataForm="form" />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">事務局担当情報</span>
                                            </template>
                                            <div>
                                                <TabSecretariat
                                                    :dataForm="{
                                                        saleStaffObject,
                                                        teamObject,
                                                        form,
                                                        personInChargeObject,
                                                        listItemPersons,
                                                        customerTeamPic
                                                    }"
                                                />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">賞品情報</span>
                                            </template>
                                            <div>
                                                <TabPrizeView :dataEventPrizes="form.event_prizes" />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">実施結果登録</span>
                                            </template>
                                            <div>
                                                <TabResultRegistration :dataForm="form" />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">編集履歴</span>
                                            </template>
                                            <div class="col-sm-6">
                                                <TabHistories :dataHistory="listHistory" />
                                            </div>
                                        </b-tab>
                                    </b-tabs>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center align-items-center">
                    <div style="font-size: 20px; cursor: pointer">
                        <i v-if="isBookmarkCampaign" class="ri-bookmark-fill" style="color: #157efb" @click="handleUnsetBookmarkCampaign"></i>
                        <i v-else class="ri-bookmark-line" @click="handleSetBookmarkCampaign"></i>
                    </div>
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/campaign/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="form.is_approved != 0 && form.is_locked != 1 && checkPermiss('campaign.edit')"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/campaign/edit', $route.query.id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss('campaign.delete')"
                            type="button"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>

            <ModalScopeOrder
                :dataForm="form"
                :dataMasterOrderScope="listDataMasterOrderScope"
                :isShow.sync="hasShowModalScope"
                :dataScope="form?.campaign_master_order_scopes ?? []"
                :dataContactInformations="contact_informations"
                :isView="true"
            />
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>

<style lang="scss"></style>
