<template>
    <div>
        <ModalCommon :id="`configModalActionBlock-${config?.id ?? ''}`" :config="config" @save="handleSave()" @close="handleClose()">
            <div class="col-12">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingBlocksLP.titleBlock') }}
                        <span v-if="isShowRequired(configFormAddBlock.configFieldTitleBlock.typesValidate)" class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="cinitForm.title"
                            :config.sync="configFormAddBlock.configFieldTitleBlock"
                            @blur="handleValidateField(configFormAddBlock.configFieldTitleBlock)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingBlocksLP.categoryBlock') }}
                        <span v-if="isShowRequired(configFormAddBlock.configFieldCategoryBlock.typesValidate)" class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="cinitForm.category"
                            :config.sync="configFormAddBlock.configFieldCategoryBlock"
                            @blur="handleValidateField(configFormAddBlock.configFieldCategoryBlock)"
                        />
                    </div>
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';

import { InputText } from '@/components/Input';
import { configFormAddBlock } from '../constants';
import { validateField, validateAllField } from '@/utils/validate';

export default {
    name: 'ModalActionBlock',
    components: {
        ModalCommon,
        InputText
    },
    props: {
        config: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            configFormAddBlock: configFormAddBlock(),
            cinitForm: this.initForm()
        };
    },
    methods: {
        isShowRequired(typeValid) {
            if (!typeValid || typeValid?.length <= 0) return false;
            return typeValid.includes('required');
        },

        initForm() {
            return {
                title: '',
                category: ''
            };
        },

        handleValidateField(configField) {
            validateField(configField);
        },

        handleSave() {
            let check = validateAllField(this.configFormAddBlock);
            if (!check) return;
            this.$emit('evtAdd', this.cinitForm);
            this.config.model = false;
        },

        handleClose() {}
    }
};
</script>

<style lang="scss" scoped></style>
