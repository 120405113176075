import i18n from '@/i18n';
import { LIST_GIVE_AWAY_RELATED } from '@/components/Campaign/Tab7/constants';
import {
    handleDataSponsorSend,
    handleDataTargetProductSend,
    handleDataIPSend,
    handleDataNumberOfApplicationsSend,
    handleDataNumberOfInquiriesSend,
    handleDataPlatformFollowersNumberSend,
    handleCheckConditionTel,
    handleDataSocialPlatformsSend
} from '@/views/pages/campaign/edit/index';

function handleDataDetailView(
    data,
    isBookmarkCampaign,
    userInfo,
    listContactInformations,
    contact_informations,
    listDataMasterConditionPurchase1,
    purchase_conditions,
    campaignStatus,
    progressStatus,
    teamObject,
    saleStaffObject,
    personInChargeObject,
    form,
    configKeyword,
    listItemPersons,
    listDataMasterCampaignTypeChild,
    listDataMasterCampaignType,
    isShowReceiptExpirationDate,
    listCategories,
    listCategoriesSub,
    customerTeamPic,
    subDataCustomerTeamPic,
    isShowPublicTime
) {
    isBookmarkCampaign = userInfo?.campaign_bookmarks?.some((item) => item.id === data.id) ?? false;

    let arrayDataContactInfor = [];
    if (data?.contact_informations) {
        data.contact_informations.forEach((item) => {
            listContactInformations.forEach((listI) => {
                if (listI.child && listI.child.length > 0) {
                    let indexC = listI.child.findIndex((c) => {
                        return c.id == item.type?.id;
                    });
                    if (indexC >= 0) {
                        if (item.extension) {
                            contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                            arrayDataContactInfor.push(item.type.id);
                        }
                    }
                }
            });

            let index = listContactInformations.findIndex((x) => {
                return x.id == item?.type?.id;
            });

            if (index >= 0) {
                if (item.type.value === '電話') {
                    contact_informations[item.type.id] = data?.phone_setting
                        ? data.phone_setting.pre_order_phone_numbers ?? data.phone_setting.reservation
                        : '';
                } else {
                    if (item.extension) {
                        contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                    }
                }

                arrayDataContactInfor.push(item.type.id);
            }
        });
    }

    if (data?.campaign_type) {
        data.campaign_type_name = data.campaign_type.value;
        data.campaign_type = data.campaign_type.id;
    }
    let arrayDataPurchase = [];
    if (data?.purchase_conditions) {
        data.purchase_conditions.forEach((item) => {
            let index = listDataMasterConditionPurchase1.findIndex((x) => {
                return x.id == item.id;
            });
            if (index >= 0) {
                if (item.note) {
                    purchase_conditions[item.id] = item.note ? item.note.detail : '';
                }
                arrayDataPurchase.push(item.id);
            }
        });
    }
    let idex = campaignStatus.findIndex((x) => x.id == data.progress_status);
    if (idex >= 0) {
        progressStatus = campaignStatus[idex];
    }

    if (data?.responsible_sub_persons.length) {
        if (data.responsible_sub_persons.length === 1) {
            if (data?.responsible_sub_persons[0]?.sub_person_position === 1) {
                listItemPersons = [
                    ...data.responsible_sub_persons,
                    {
                        id: '',
                        name: ''
                    }
                ];
            } else {
                listItemPersons = [
                    {
                        id: '',
                        name: ''
                    },
                    ...data.responsible_sub_persons
                ];
            }
        } else if (data.responsible_sub_persons.length === 2) {
            listItemPersons = data.responsible_sub_persons;
        }
    }
    teamObject = data.team;
    saleStaffObject = data.sale_staff;
    personInChargeObject = data.person_in_charge;
    configKeyword.arrayTag = data.keywords;
    customerTeamPic = data.customer_team_pic ?? '';
    subDataCustomerTeamPic = data.customer_sub_person.length > 0 ? data.customer_sub_person : [null];

    form = {
        ...form,
        ...data,
        contact_informations: arrayDataContactInfor,
        purchase_conditions: arrayDataPurchase ?? [],
        information_ips:
            data.information_ips?.map((item) => {
                return {
                    master_id: item.id,
                    value: item.value,
                    note: {
                        detail: item?.note?.detail ?? ''
                    },
                    children_data: item.children_data.map((dataChild) => {
                        return {
                            master_id: dataChild.id,
                            value: dataChild.value,
                            note: dataChild.note,
                            children_data: dataChild.children_data
                        };
                    })
                };
            }) ?? [],
        sponsor_informations:
            data.sponsor_informations?.map((item) => {
                return {
                    master_id: item.id,
                    value: item.value,
                    note: {
                        detail: item?.note?.detail ?? '',
                        manufacturer_name: item?.note?.manufacturer_name ?? '',
                        distribution_name: item?.note?.distribution_name ?? '',
                        circulation_number: item?.note?.circulation_number ?? '',
                        circulation_distribution: item?.note?.circulation_distribution ?? '',
                        up_manufacturers: item?.note?.up_manufacturers ?? '',
                        up_manufacturers_name: item?.note?.up_manufacturers_name ?? ''
                    },
                    children_data: item.children_data.map((dataChild) => {
                        return {
                            master_id: dataChild.id,
                            value: dataChild.value,
                            note: dataChild.note,
                            children_data: dataChild.children_data
                        };
                    })
                };
            }) ?? [],
        give_away_related: data?.give_away_related !== null ? LIST_GIVE_AWAY_RELATED[data?.give_away_related] : null,
        target_product_lists: data?.target_product_lists.length <= 0 ? [] : handleSortTargetProductList(data?.target_product_lists)
    };

    if (form.campaign_type) {
        listDataMasterCampaignTypeChild = listDataMasterCampaignType
            .map((itemC) => {
                if (itemC.id == form.campaign_type) {
                    return itemC.data;
                }
            })
            .filter((arr) => arr !== undefined)[0];
        isShowReceiptExpirationDate = form.campaign_type_name === i18n.t('pageCampaign.closeCp');
    }

    form.categories.forEach((item) => {
        listCategories.push(item.category_id);
        let index = listDataMasterCampaignTypeChild.findIndex((x) => {
            return x.id == item.category_id;
        });
        let check = listDataMasterCampaignTypeChild.findIndex((x) => {
            return x.id == item.category_id && (x.value == 'Twitter' || x.value == 'Instagram' || x.value == 'LINE' || x.value == 'Tiktok');
        });
        if (index >= 0) {
            let obj = {
                category_id: item.category_id,
                application_condition_1: item.application_condition_1,
                application_condition_2_id: item.application_condition_2_id,
                listCondition1: listDataMasterCampaignTypeChild[index].data,
                listCondition2: [],
                hashtag: item.hashtag ?? [],
                configHashTag: {
                    arrayTag: item.hashtag ?? []
                },
                extension: item.extension
                    ? item.extension
                    : {
                          offical_name_sns: '',
                          offical_id_sns: '',
                          number_of_circulation: '',
                          company_information: '',
                          other_type_data: '',
                          application_method: '',
                          content_other: ''
                      },
                checkSNS: check
            };

            let i = obj.listCondition1.findIndex((x) => {
                return obj.application_condition_1 && x.id == obj.application_condition_1?.id;
            });
            if (i >= 0) {
                obj.listCondition2 = obj.listCondition1[i].data;
            }
            listCategoriesSub.push(obj);
            listCategoriesSub = listCategoriesSub.sort((a, b) => a.category_id - b.category_id);
        }
    });

    form.campaign_master_order_scopes.forEach((origin) => {
        origin?.medium_items?.forEach((mediumItem) => {
            isShowPublicTime = mediumItem?.small_items?.some((smallItems) => smallItems.small_item.value == 'LPサイト');
        });
    });

    return {
        isBookmarkCampaign,
        userInfo,
        listContactInformations,
        contact_informations,
        listDataMasterConditionPurchase1,
        purchase_conditions,
        campaignStatus,
        progressStatus,
        teamObject,
        saleStaffObject,
        personInChargeObject,
        form,
        configKeyword,
        listItemPersons,
        listDataMasterCampaignTypeChild,
        listDataMasterCampaignType,
        isShowReceiptExpirationDate,
        listCategories,
        listCategoriesSub,
        customerTeamPic,
        subDataCustomerTeamPic,
        isShowPublicTime
    };
}

function handleSortTargetProductList(data) {
    return data.sort((subA, subB) => subA.id - subB.id);
}

function getCurrentDateTime(date) {
    var now = new Date(date);
    var year = now.getFullYear();
    var month = ('0' + (now.getMonth() + 1)).slice(-2);
    var day = ('0' + now.getDate()).slice(-2);

    var hours = ('0' + now.getHours()).slice(-2);
    var minutes = ('0' + now.getMinutes()).slice(-2);
    var seconds = ('0' + now.getSeconds()).slice(-2);
    var formattedDateTime = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;

    return formattedDateTime;
}

function mapDataCampaignsCopy(
    formData,
    configKeyword,
    listItemPersons,
    listDataPrize,
    purchase_conditions_data,
    contact_informations_data,
    listCategoriesSub,
    subDataCustomerTeamPic
) {
    let objectData = JSON.parse(JSON.stringify(formData));
    if (objectData.associated_campaign) {
        objectData.associated_campaign = {
            id: objectData.associated_campaign?.id ?? null
        };
    }
    objectData.keywords = configKeyword.arrayTag;
    let purchase_conditions = [];
    objectData.purchase_conditions.forEach((item) => {
        purchase_conditions.push({
            master_id: item,
            note: {
                detail: purchase_conditions_data ? purchase_conditions_data[item] : ''
            }
        });
    });
    let contact_informations = [];
    objectData.contact_informations.forEach((item) => {
        contact_informations.push({
            type_id: item,
            extension: {
                content: contact_informations_data ? contact_informations_data[item] : ''
            }
        });
    });
    let part_time_employees = [];
    objectData.part_time_employees.forEach((item) => {
        if (item.id) part_time_employees.push(item.id);
    });
    let responsible_sub_persons = [];
    listItemPersons.forEach((x, idx) => {
        if (x.id) {
            responsible_sub_persons.push({
                user_id: x.id,
                sub_person_position: idx + 1
            });
        }
    });
    objectData.purchase_conditions = purchase_conditions;
    objectData.contact_informations = contact_informations;
    objectData.responsible_sub_persons = responsible_sub_persons;

    objectData.clients = objectData.clients.map((cl) => {
        return {
            client_id: cl?.client?.id ?? null,
            client_pic_id: cl?.client_pic?.id ?? null
        };
    });

    objectData.prizes = listDataPrize;
    objectData.part_time_employees = part_time_employees;
    objectData.categories = JSON.parse(JSON.stringify(listCategoriesSub));
    objectData.categories.forEach((item) => {
        item.hashtag = item?.configHashTag?.arrayTag ?? [];
        delete item.listCondition1;
        delete item.listCondition2;
    });
    delete objectData.updated_by;
    if (objectData.campaign_start_date_y && objectData.campaign_start_date_m && objectData.campaign_start_date_d) {
        objectData.campaign_start_date =
            objectData.campaign_start_date_y + '-' + objectData.campaign_start_date_m + '-' + objectData.campaign_start_date_d;
    }
    if (objectData.campaign_end_date_y && objectData.campaign_end_date_m && objectData.campaign_end_date_d) {
        objectData.campaign_end_date = objectData.campaign_end_date_y + '-' + objectData.campaign_end_date_m + '-' + objectData.campaign_end_date_d;
    }
    if (objectData.secretariat_start_date_y && objectData.secretariat_start_date_m && objectData.secretariat_start_date_d) {
        objectData.secretariat_start_date =
            objectData.secretariat_start_date_y + '-' + objectData.secretariat_start_date_m + '-' + objectData.secretariat_start_date_d;
    }
    if (objectData.secretariat_end_date_y && objectData.secretariat_end_date_m && objectData.secretariat_end_date_d) {
        objectData.secretariat_end_date =
            objectData.secretariat_end_date_y + '-' + objectData.secretariat_end_date_m + '-' + objectData.secretariat_end_date_d;
    }
    if (objectData.receipt_expiration_date_y && objectData.receipt_expiration_date_m && objectData.receipt_expiration_date_d) {
        objectData.receipt_expiration_date =
            objectData.receipt_expiration_date_y + '-' + objectData.receipt_expiration_date_m + '-' + objectData.receipt_expiration_date_d;
    }

    if (objectData?.client_order_source?.id) {
        objectData.client_order_source = {
            id: objectData?.client_order_source?.id
        };
    }

    objectData.campaign_master_order_scopes = objectData.campaign_master_order_scopes.map((x) => {
        return {
            large_item: x.large_item.id,
            medium_items: x.medium_items?.map((y) => {
                return {
                    medium_item: y.medium_item.id,
                    small_items: y.small_items?.map((z) => {
                        return {
                            small_item: z.small_item.id,
                            detail_items: z.detail_items?.map((t) => t.detail_item.id)
                        };
                    })
                };
            })
        };
    });

    objectData.campaign_master_order_scope_partner = objectData.campaign_master_order_scope_partner.map((itemPartner) => {
        return {
            master_order_scope_id: itemPartner?.master_order_scope?.id ?? null,
            tool_id: itemPartner?.tool?.id ?? null,
            partner_id: itemPartner?.partner?.id ?? null
        };
    });
    objectData.customer_sub_person = subDataCustomerTeamPic[0] !== null ? subDataCustomerTeamPic.map((item) => item.id) : [];
    objectData.information_ips = objectData.information_ips[0]?.master_id == null ? [] : handleDataIPSend(objectData.information_ips);
    objectData.sponsor_informations =
        objectData.sponsor_informations[0]?.master_id == null ? [] : handleDataSponsorSend(objectData.sponsor_informations);

    objectData.has_tel_phone_setting = handleCheckConditionTel(objectData.campaign_master_order_scopes, objectData.has_tel_phone_setting);
    objectData.target_product_lists = handleDataTargetProductSend(objectData.target_product_lists);
    objectData.number_of_applications = handleDataNumberOfApplicationsSend(objectData.number_of_applications);
    objectData.number_of_inquiries = handleDataNumberOfInquiriesSend(objectData.number_of_inquiries);
    objectData.platform_followers_number = handleDataPlatformFollowersNumberSend(objectData.platform_followers_number);

    objectData.social_platforms = handleDataSocialPlatformsSend(objectData.social_platforms);
    objectData.advertising_activity = {
        id: objectData?.advertising_activity?.id ?? null
    };
    objectData.give_away_related = !objectData.give_away_related?.id ? null : objectData.give_away_related.isBoolean;
    objectData.pdca_file_report = {
        id: objectData.pdca_file_report?.id ?? null
    };
    objectData.cashback_file_report = {
        id: objectData.cashback_file_report?.id ?? null
    };

    return objectData;
}
export { handleDataDetailView, getCurrentDateTime, mapDataCampaignsCopy };
