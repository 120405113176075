export const state = () => ({
    configBgImgDefault: {
        backgroundImage: '',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }
});

export const getters = {
    configBgImgDefault: (state) => state.configBgImgDefault
};

export const actions = {
    registerDataEditor({ commit }, data) {
        commit('handleRegisterDataEditor', data);
    }
};

export const mutations = {
    handleRegisterDataEditor(state, data) {
        let { backgroundImage, backgroundSize, backgroundPosition, backgroundRepeat } = data;
        state.configBgImgDefault = {
            backgroundImage: backgroundImage ?? '',
            backgroundSize: backgroundSize ?? 'cover',
            backgroundPosition: backgroundPosition ?? 'center',
            backgroundRepeat: backgroundRepeat ?? 'no-repeat'
        };
    }
};
