<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingFirstView.applicationPeriod') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.application_period.title"
                            :config.sync="configForm.configFieldTitle"
                            @input="handleShotCodeStyle"
                            @blur="handleValidateField(configForm.configFieldTitle)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.startDate') }} </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.application_period.start_date"
                            :config.sync="configForm.configFieldStartDateTime"
                            @input="handleShotCodeStyle"
                            @blur="handleValidateField(configForm.configFieldStartDateTime)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.endDate') }} </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.application_period.end_date"
                            :config.sync="configForm.configFieldEndDateTime"
                            @input="handleShotCodeStyle"
                            @blur="handleValidateField(configForm.configFieldEndDateTime)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.selectedStyle') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-application_period-style`"
                            :value.sync="dataForm.application_period.style"
                            :options="listStyleTime"
                            @select="handleSelectTypeTime"
                            :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                        />

                        <div class="mt-3">
                            <Editor
                                :id="`editorTimeApplication`"
                                :isBackgroundImageEditor="false"
                                :model.sync="dataForm.application_period.detail"
                                :modelEvents="`change keydown blur focus paste`"
                                :configInit="configEditor"
                                @init="onEditorInit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { validateEndDate } from '../../handlers';
import { tranferShortCode } from '@/utils/format';

import Multiselect from '@/components/Multiselect/main.vue';
import Editor from '@/components/Editor/main.vue';

export default {
    name: 'SettingTimeApplication',
    components: {
        InputText,
        Multiselect,
        Editor
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            listStyleTime: [
                {
                    id: 1,
                    name: 'A',
                    srcBgr: 'https://api-paldia.supenient.vn/storage/images/image1.png',
                    contents:
                        '<div style="background-image: url(《sourceBgr》);background-repeat: no-repeat;background-size: contain;background-position: center;height: 310px;width: 100%;"><div style="position: relative; top: 50%; transform: translateY(-50%)"><div style="display: flex; justify-content: space-around; align-items: center"><div><p style="padding: 10px; background-color: #ffffff; color: #36a6b4; border-radius: 10px">《タイトル》</p></div><div class=""><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #ffffff">《開始》</h3></div><div style="text-align: center; margin-block: 2px; color: #ffffff">▼</div><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #ffffff">《終了》</h3></div></div></div></div></div>'
                },
                {
                    id: 2,
                    name: 'B',
                    srcBgr: 'https://api-paldia.supenient.vn/storage/images/image2.png',
                    contents:
                        '<div style="background-image: url(《sourceBgr》); background-repeat: no-repeat; background-size: contain; background-position: center; height: 430px; width: 100%;"><div style="position: relative; top: 50%; transform: translateY(-50%)"><div><h1 style="text-align: center; color: #ffffff;font-size: clamp(14px, 4vw, 100px);">《タイトル》</h1></div><div style="display: flex; justify-content: space-around; align-items: center"><div class=""><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #ffffff">《開始》</h3></div><div style="text-align: center; margin-block: 0px; color: #ffffff; font-size: 40px">▼</div><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #ffffff">《終了》</h3></div></div></div></div></div>'
                },
                {
                    id: 3,
                    name: 'C',
                    srcBgr: 'https://api-paldia.supenient.vn/storage/images/image3.png',
                    contents:
                        '<div style="background-image: url(《sourceBgr》); background-repeat: no-repeat; background-size: contain; background-position: center; height: 310px; width: 100%;"><div style="position: relative; top: 50%; transform: translateY(-50%)"><div style="display: flex; justify-content: space-around; align-items: center"><div><p style="padding: 10px; background-color: #5d6b8f; color: #ffffff; border-radius: 10px">《タイトル》</p></div><div class=""><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #5d6b8f">《開始》</h3></div><div style="text-align: center; margin-block: 2px; color: #5d6b8f">▼</div><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #5d6b8f">《終了》</h3></div></div></div></div></div>'
                },
                {
                    id: 4,
                    name: 'D',
                    srcBgr: 'https://api-paldia.supenient.vn/storage/images/image4.png',
                    contents:
                        '<div style="background-image: url(《sourceBgr》); background-repeat: no-repeat; background-size: contain; background-position: center; height: 360px; width: 100%;"><div style="position: relative; top: 50%; transform: translateY(-50%)"><div style="display: flex; justify-content: space-around; align-items: center"><div><p style="padding: 10px; background-color: #947be8; color: #ffffff; border-radius: 50%; width: 150px; height: 150px; text-align: center; display: flex; justify-content: center; align-items: center;">《タイトル》</p></div><div class=""><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #947be8">《開始》</h3></div><div style="text-align: center; margin-block: 2px; color: #947be8">▼</div><div style="display: flex; align-items: center; gap: 10px"><h3 style="color: #947be8">《終了》</h3></div></div></div></div></div>'
                }
            ],
            objEditor: null,
            configEditor: {
                content_style: 'p { margin: 0 }',
                height: 600,
                menubar: true,
                plugins: [
                    'hr advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media template',
                    'paste code help wordcount table code lists imagetools '
                ],
                toolbar:
                    'myCustomToolbarButton hr undo redo | formatselect | bold italic | \
                                          alignleft aligncenter alignright | \
                                          link image media | \
                                          bullist numlist outdent indent | help code\
                                          table tableinsertdialog tablecellprops tableprops | fullscreen ',
                table_toolbar:
                    'tableprops tabledelete tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tablerowprops tabledeleterow | tableinsertcolbefore tableinsertcolafter tablecellprops tabledeletecol',
                images_upload_handler: () => {}
            }
        };
    },
    methods: {
        handleValidateField(configField, action = []) {
            validateField(configField);
            if (action.includes('compare-date')) {
                validateEndDate(this.configForm[action[1]], this.configForm[action[2]]);
            }
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleSelectTypeTime() {
            this.handleShotCodeStyle();
        },

        handleShotCodeStyle() {
            let { title, start_date, end_date, style } = this.dataForm.application_period;
            const replacements = {
                タイトル: title ?? '',
                sourceBgr: style.srcBgr ?? '',
                開始: start_date ?? '',
                終了: end_date ?? ''
            };
            let contentShotCode = tranferShortCode(style?.contents, replacements);
            this.objEditor.setContent(contentShotCode ?? '');
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.dataForm.application_period.style?.id && this.objEditor) this.handleShotCodeStyle();
        }, 1000);
    }
};
</script>

<style lang="scss" scoped></style>
