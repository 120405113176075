<template>
    <div>
        <Layout>
            <div v-if="!isLoading" class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-body">
                        <b-tabs
                            v-model="modelTab"
                            vertical
                            justified
                            nav-class="nav-tabs-custom"
                            content-class="p-3 text-muted scroll--content__managerForm"
                        >
                            <b-tab v-for="(tab, index) in TABS" :key="index" class="border-0" :disabled="tab.disabled">
                                <template v-slot:title>
                                    <span class="">{{ tab.name }}</span>
                                </template>
                                <div>
                                    <SettingBasic
                                        v-if="tab.id === 1"
                                        :dataForm="formData"
                                        :configForm="configFormBasic"
                                        :listPage="PAGE_TYPE_FIXED"
                                    />
                                    <SettingDesign v-else-if="tab.id === 2" :dataForm="formData" />
                                    <SettingPage
                                        v-else-if="tab.id === 3"
                                        :dataForm="formData"
                                        :listPage="PAGE_TYPE_FIXED"
                                        :configForm="configFormPage"
                                    />
                                    <SettingSerialCode v-else-if="tab.id === 6" :dataForm="formData" :configForm="configFormSerialCode" />
                                    <SettingDigitalGift v-else-if="tab.id === 7" :dataForm="formData" :configForm="configFormDigitalGift" />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <Footer>
                    <div class="d-flex justify-content-center">
                        <div class="text-center flex-fill">
                            <button type="submit" @click="handleCancel" class="btn btn-light mr-3">{{ $t('btn.cancel') }}</button>
                            <button type="submit" @click="handleSubmit" class="btn btn-primary">{{ $t('btn.register') }}</button>
                        </div>
                    </div>
                </Footer>
            </div>
            <div class="d-flex justify-content-center" v-else>
                <LoadingIcon />
            </div>
        </Layout>
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import SettingBasic from '../components/setting-basic/main.vue';
import SettingDesign from '../components/setting-design/main.vue';
import SettingPage from '../components/setting-page/main.vue';
import SettingSerialCode from '../components/setting-serial_code/main.vue';
import SettingDigitalGift from '../components/setting-digital_gift/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

import { TABS, PAGE_TYPE_FIXED, initForm } from '../constants';
import { configFormBasic } from '../components/setting-basic/constants';
import { configFormDigitalGift } from '../components/setting-digital_gift/constants';
import { configFormPage } from '../components/setting-page/constants';
import { configFormSerialCode } from '../components/setting-serial_code/constants';
import { validateAllField } from '@/utils/validate';
import { handleDataBeforeSendServer, handleAlertValidateTabs } from '../handlers';
import { managerFormMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

export default {
    name: 'ManagerFormCreate',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        SettingBasic,
        SettingDesign,
        SettingPage,
        SettingSerialCode,
        SettingDigitalGift,
        LoadingIcon
    },
    data() {
        return {
            TABS: TABS.map((tab) => {
                if (tab.id == 1) {
                    tab.disabled = false;
                } else {
                    tab.disabled = true;
                }
                return tab;
            }),
            formData: initForm(),
            configFormBasic: configFormBasic(),
            configFormDigitalGift: configFormDigitalGift(),
            configFormSerialCode: configFormSerialCode(),
            configFormPage: configFormPage(),
            PAGE_TYPE_FIXED: PAGE_TYPE_FIXED,
            isLoading: false,
            modelTab: 0
        };
    },
    methods: {
        ...managerFormMethods,

        handleCancel() {
            this.$router.push({
                path: '/manager-form/list'
            });
        },

        async handleSubmit() {
            let checkValidateTabs = handleAlertValidateTabs(
                {
                    checkFormBasic: validateAllField(this.configFormBasic),
                    checkFormPage: true //validateAllField(this.configFormPage)
                },
                this.$bvToast,
                this.modelTab
            );
            if (!checkValidateTabs) return;
            let payload = handleDataBeforeSendServer(this.formData);
            this.isLoading = true;
            try {
                const data = await this.registerManagerForm(payload);
                if (data.code !== 200) {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                    return;
                }
                this.$router.push({ path: '/manager-form/list' }).then(() => {
                    showMessage(data.code, this.$bvToast, 'フォームが作成されました。');
                });
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
            console.log('check', checkValidateTabs, payload);
        }
    }
};
</script>

<style lang="scss">
.scroll--content__managerForm {
    height: 74vh;
    overflow-y: auto;
}
</style>
