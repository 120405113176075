import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import routes from './routes';

import { state } from '@/state/modules/authfack.js';
import { clearInfoUser } from '@/api/handler/main';

Vue.use(VueRouter);
Vue.use(VueMeta, {
    keyName: 'page'
});

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((routeTo, routeFrom, next) => {
    const publicPages = ['/login', '/register', '/forgot-password'];
    const authpage = publicPages.includes(routeTo.path);
    const loggeduser = JSON.parse(localStorage.getItem('user'));
    const token = window.$cookies.get('token');

    if (!token) {
        if (!authpage) {
            localStorage.setItem('redirectAfterLogin', window.location.href);
            return next('/login');
        }
        if (loggeduser) {
            clearInfoUser();
        }
    } else {
        if (!loggeduser) {
            localStorage.setItem('redirectAfterLogin', window.location.href);
            clearInfoUser();
            if (!authpage) {
                return next('/login');
            }
        } else {
            if (authpage) {
                return next('/');
            }
        }
    }
    next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }
    next();
});

router.afterEach((to) => {
    const redirect404Paths = routes.map((route) => route.path);

    if (!redirect404Paths.includes(to.path)) {
        router.push({
            path: '/error-page'
        });
    } else {
        if (to.meta.roleDefault === 'all') return;
        if (!state.user?.role?.permissions || !to.meta?.role) return;
        const hasExistRouter = state.user.role.permissions.some((permission) => to.meta.role.includes(permission.name));
        if (hasExistRouter) return;
        router.push({
            path: '/error-page'
        });
    }
});

export default router;
