<template>
    <div>
        <div class="col-sm-12">
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.fontSize') }} </label>
                <div class="col-sm-4">
                    <Multiselect
                        :id="`select-font_page`"
                        :value.sync="designForm.design_settings.font_page"
                        :options="listFontFamily"
                        :config="{ trackBy: 'id', label: 'name', error: false, isRemove: false }"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.squareType') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex">
                        <div v-for="item in listSquareType" :key="item.id + 's'">
                            <InputRadio
                                class="mr-4"
                                :model.sync="designForm.design_settings.square_type"
                                :name="`squareType`"
                                :id="`squareType-${item.id}`"
                                :value="item.id"
                                :label="item.value"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.backgroundColor') }} </label>
                <div class="col-sm-1">
                    <InputText
                        style="min-width: 100px"
                        :model.sync="designForm.design_settings.background_color"
                        :config="{
                            type: 'color',
                            classCustom: 'form-control-color',
                            error: false
                        }"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.titleColor') }} </label>
                <div class="col-sm-1">
                    <InputText
                        style="min-width: 100px"
                        :model.sync="designForm.design_settings.title_color"
                        :config="{
                            type: 'color',
                            classCustom: 'form-control-color',
                            error: false
                        }"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.fontColor') }} </label>
                <div class="col-sm-1">
                    <InputText
                        style="min-width: 100px"
                        :model.sync="designForm.design_settings.font_color"
                        :config="{
                            type: 'color',
                            classCustom: 'form-control-color',
                            error: false
                        }"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.buttonColor') }} </label>
                <div class="col-sm-1">
                    <InputText
                        style="min-width: 100px"
                        :model.sync="designForm.design_settings.button_color"
                        :config="{
                            type: 'color',
                            classCustom: 'form-control-color',
                            error: false
                        }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { InputRadio, InputText } from '@/components/Input';
import { LIST_FONT_PAGE, SQUARE_TYPE_PAGE } from '../../constants';

import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'SettingOverall',
    components: {
        Multiselect,
        InputRadio,
        InputText
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            listFontFamily: LIST_FONT_PAGE,
            listSquareType: SQUARE_TYPE_PAGE,
            designForm: this.dataForm
        };
    }
};
</script>

<style lang="scss" scoped></style>
