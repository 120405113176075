<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import DatePicker from 'vue2-datepicker';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import TableListComponent from '@/components/TableList/main.vue';

import { masterMethods } from '@/state/helpers';
import { checkPermission } from '@/utils/handlers';
import { formatDateCommon, STR_DATE } from '@/utils/format';

export default {
    page: {
        title: '管理ログ',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Multiselect,
        Layout,
        DatePicker,
        Footer,
        TableListComponent
    },
    data() {
        return {
            listData: [],
            filters: {
                form_slug: '',
                action_slug: ''
            },
            date_to: {
                created_at: '',
                created_at_y: '',
                created_at_m: '',
                created_at_d: ''
            },
            date_from: {
                created_at: '',
                created_at_y: '',
                created_at_m: '',
                created_at_d: ''
            },
            dataFromSlug: [
                'campaign',
                'client_pic',
                'client',
                'employee',
                'faq',
                'master',
                'partner_pic',
                'partner',
                'prize',
                'return_prize',
                'permission',
                'master_holiday',
                'master_year_end_schedule'
            ],
            sort: {},
            configTable: {
                headers: [
                    {
                        label: 'No.'
                    },
                    {
                        label: this.$t('history.logID')
                    },
                    {
                        label: this.$t('history.date')
                    },
                    {
                        label: this.$t('history.email')
                    },
                    {
                        label: this.$t('history.nameLogin'),
                        sort: {
                            type: 'desc',
                            order_by: 'id'
                        }
                    },
                    {
                        label: this.$t('history.screen')
                    },
                    {
                        label: this.$t('history.action')
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            },
            dataActionSlug: ['register', 'edit', 'delete', 'copy', 'lock', 'unlock', 'approve'],
            customFormatDate: STR_DATE,
            isLoading: false
        };
    },
    watch: {
        'date_from.created_at': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_from.created_at_y = date[0];
                this.date_from.created_at_m = date[1];
                this.date_from.created_at_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'date_to.created_at': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_to.created_at_y = date[0];
                this.date_to.created_at_m = date[1];
                this.date_to.created_at_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        }
    },
    mounted() {
        this.getList();
    },

    methods: {
        ...masterMethods,

        handleSort(item) {
            this.sort[item.sort.order_by] = item.sort.type;
            this.getList();
        },

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getQueryString() {
            let query = [];
            if (Object.keys(this.sort)?.length <= 0) {
                query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];
            } else {
                query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`];
            }

            if (this.filters) {
                for (const [key, value] of Object.entries(this.filters)) {
                    if (key && value) {
                        query.push(`filters[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            }
            if (this.sort) {
                for (const [key, value] of Object.entries(this.sort)) {
                    if (key && value) {
                        query.push(`sorts[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            }
            if (this.date_from) {
                let date_from = Object.assign({}, this.date_from);
                if (date_from.created_at_y || date_from.created_at_m || date_from.created_at_d)
                    date_from.created_at = date_from.created_at_y + '-' + date_from.created_at_m + '-' + date_from.created_at_d;
                for (const [key, value] of Object.entries(date_from)) {
                    if (key && value) {
                        query.push(`from[${key}]=${value}`);
                    }
                }
            }
            if (this.date_to) {
                let date_to = Object.assign({}, this.date_to);
                if (date_to.created_at_y || date_to.created_at_m || date_to.created_at_d)
                    date_to.created_at = date_to.created_at_y + '-' + date_to.created_at_m + '-' + date_to.created_at_d;
                for (const [key, value] of Object.entries(date_to)) {
                    if (key && value) {
                        query.push(`to[${key}]=${value}`);
                    }
                }
            }
            return query.join('&');
        },
        async getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            try {
                const response = await this.histories(query);
                this.listData = response.data;
                this.configPage.total = response._metadata.total;
                this.configPage.limit = response._metadata.limit * 1;
                if (!this.listData.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                console.error('Error fetching posts:', error);
            }
        },

        search() {
            this.getList();
        },
        clear() {
            this.filters = {
                form_slug: '',
                action_slug: ''
            };
            (this.date_to = {
                created_at_y: '',
                created_at_d: '',
                created_at_m: '',
                created_at: ''
            }),
                (this.date_from = {
                    created_at_y: '',
                    created_at_m: '',
                    created_at_d: '',
                    created_at: ''
                });
            this.getList();
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },
        nameWithLang(data) {
            return this.$t(`screen.` + data);
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">{{ $t('history.title') }}</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('history.dateTime') }}</label>
                                        <div class="w-100">
                                            <div class="d-flex align-items-center mb-2">
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.created_at_y"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    {{ $t('history.year') }}
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.created_at_m"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    {{ $t('history.month') }}
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.created_at_d"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    {{ $t('history.day') }}
                                                </div>
                                                <div class="d-flex align-items-center" style="min-width: 200px">
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        {{ $t('history.calendar') }}
                                                        <date-picker
                                                            v-model="date_from.created_at"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    {{ $t('history.from') }}
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center mb-2">
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.created_at_y"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    {{ $t('history.year') }}
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.created_at_m"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    {{ $t('history.month') }}
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.created_at_d"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    {{ $t('history.day') }}
                                                </div>
                                                <div class="d-flex align-items-center" style="min-width: 200px">
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        {{ $t('history.calendar') }}
                                                        <date-picker
                                                            v-model="date_to.created_at"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    {{ $t('history.to') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('history.screen') }}</label>
                                        <div class="w-100">
                                            <Multiselect
                                                :id="`select-filters-form_slug`"
                                                :value.sync="filters.form_slug"
                                                :options="dataFromSlug"
                                                :customLabel="`screen`"
                                                :config="{
                                                    trackBy: '',
                                                    label: '',
                                                    error: false
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('history.action') }}</label>
                                        <div class="w-100">
                                            <Multiselect
                                                :id="`select-filters-action_slug`"
                                                :value.sync="filters.action_slug"
                                                :options="dataActionSlug"
                                                :customLabel="`screen`"
                                                :config="{
                                                    trackBy: '',
                                                    label: '',
                                                    error: false
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            管理ログ検索結果 <span>件数（{{ configPage.total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableListComponent
                            :config="configTable"
                            :dataList="listData"
                            :isLoading="isLoading"
                            :configPage.sync="configPage"
                            :visibleAction="false"
                            @sort="handleSort"
                            @onChangePage="getList()"
                        >
                            <template #row="{ row, index }">
                                <tr>
                                    <td>{{ index }}</td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.id }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.user ? row.user.name : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.user ? row.user.email : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ formatDateCommon(row.created_at, 'dateTimeJP') ?? '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ $t(`screen.${row.form_slug}`) }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ $t(`history.${row.action_slug}`) }}
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </TableListComponent>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
