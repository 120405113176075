import i18n from '@/i18n';

const configModalActionBlock = {
    id: '',
    title: '',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: 'lg',
    class: 'h-50',
    validate: true
};

const configFormAddBlock = () => {
    return {
        configFieldTitleBlock: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingBlocksLP.titleBlock'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldCategoryBlock: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingBlocksLP.categoryBlock'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };
};

export { configModalActionBlock, configFormAddBlock };
